import React from "react";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { useSelector } from "react-redux";

import CircularProgress from "../Spinner";

export default function FirmNameAutoComplete({ onChange, defaultValue }) {
  const fetchingFirmNames = useSelector((state) => state.application.fetchingBackground.firmNames);
  const firmNames = useSelector((state) => state.formOptions.firmNames);

  if (fetchingFirmNames) return <CircularProgress size="2em" />;

  return (
    <Autocomplete
      defaultValue={defaultValue.firm_crd.length > 0 ? defaultValue : null}
      id="combo-box-demo"
      options={firmNames}
      onChange={onChange}
      style={{ width: 300, paddingRight: 20 }}
      filterOptions={createFilterOptions({ limit: 20 })}
      renderInput={(params) => <TextField {...params} label="Firm Name" />}
      getOptionLabel={(option) => `${option.firm_name} (${option.firm_crd})`}
    />
  );
}
