import React, { forwardRef, useState } from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material";
import Alert from "@mui/material/Alert";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch } from "react-redux";
import Fab from "../../../../components/Fab";
import { setUsers } from "../../../../state";
import { API } from "../../../../utils/api";
import ResourcesInputGroup from "../ResourcesInputGroup";
import UserForm from "./UserForm";

const useStyles = makeStyles((theme) => ({
  title: {
    position: "center",
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.primary.light,
  },
  confirmButton: {
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.secondary.light,
  },
  addIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  deleteIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
}));

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function CreateUser({ isAPIUser }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [newResources, setNewResources] = useState([]);

  const [newUserData, setNewUserData] = useState({
    first_name: "",
    last_name: isAPIUser ? "API" : "",
    email: "",
    role: isAPIUser ? "API" : "",
    client: "",
  });

  const requireFieldMissing = Object.values(newUserData).filter((val) => val === "").length !== 0;

  const handleClose = () => {
    setError("");
    if (success) {
      dispatch(setUsers({ users: [] }));
    }
    setSuccess("");
    setIsOpen(false);
  };

  function createUser() {
    setLoading(true);
    setSuccess("");
    setError("");
    return API.request({ url: "/users", method: "POST", data: newUserData })
      .then(
        (response) => {
          setSuccess("-- User Successfully Created! --");
          if (newResources) {
            const requests = [];
            newResources.forEach((resource) => {
              requests.push(API.request(
                { url: `misight/users/${response.data.misight_user_id}/resources`, method: "POST", data: resource },
              ).then(
                () => {
                  setSuccess(prevMsg => prevMsg.concat(`-- Resource ${resource.resource_value} Successfully added! --`));
                },
                () => {
                  setError(prevMsg => prevMsg.concat(`-- Failed to add ${resource.resource_value} as a resource --`));
                },
              ));
            });
            Promise.all(requests)
              .then(() => {
                setSuccess(prevMsg => prevMsg.concat("-- Update complete --"));
                setLoading(false);
              })
              .catch((e) => {
                setError(e?.response?.data);
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        },
        (e) => { setError(e.response.data); console.log(e.response.data); setLoading(false); },
      );
  }

  return (
    <>
      <Fab onClick={() => { setIsOpen(true); }} color={isAPIUser ? "secondary" : "primary"}>
        {isAPIUser
          ? null
          : <PersonAddIcon />}
      </Fab>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <form className={classes.root} noValidate autoComplete="off">
          <DialogTitle id="alert-dialog-slide-title" className={classes.title}>
            Create user
          </DialogTitle>
          <DialogContent dividers>
            <UserForm
              userData={newUserData}
              setUserData={setNewUserData}
              disabled={loading}
              isAPIUser={isAPIUser}
            />
            {!isAPIUser && (
              <ResourcesInputGroup
                clientId={newUserData?.client}
                resources={newResources}
                setResources={setNewResources}
                disabled={loading || requireFieldMissing}
              />
            )}
          </DialogContent>
          {success && <div><Alert severity="success">{`${success}`}</Alert></div>}
          {loading && <div><Alert severity="info">Creating User...</Alert></div>}
          {error && <div><Alert severity="error">{`${error}`}</Alert></div>}
          {success
            ? (
              <div>
                <DialogActions>
                  <Button onClick={handleClose} className={classes.cancelButton} disabled={loading}>
                    Close
                  </Button>
                </DialogActions>
              </div>
            )
            : (
              <DialogActions>
                <Button onClick={handleClose} className={classes.cancelButton}>
                  Cancel
                </Button>
                <Button onClick={createUser} className={classes.confirmButton} disabled={requireFieldMissing}>
                  Create
                </Button>
              </DialogActions>
            )}
        </form>
      </Dialog>
    </>
  );
}
