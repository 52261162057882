import React, { useEffect } from "react";
import { Checkbox, Chip, FormControl, FormControlLabel, InputAdornment, TextField, Typography } from "@mui/material";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import CSVUpload from "../../components/input/CSVUpload";
import OptionSelect from "../../components/input/OptionSelect";

const insertTypes = [
  { id: "append", label: "Supplement" },
  { id: "replace", label: "Replacement" },
  { id: "supplace", label: "Supplacement" },
];
export default function ANNLoadTerritoryMapsFormDD({ children, taskConfig, setTaskConfig, client, csv, setCsv, submitHandler, studyType, displayHeader }) {
  useEffect(() => setTaskConfig((preConfig) => ({ ...preConfig, study: studyType, file_type: "secondary_files" })), [setTaskConfig, studyType]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      validator={validator}
    >
      <Typography variant="h4" style={{ backgroundColor: "orange" }}>Discovery Data</Typography>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["client_suffix", "append_replace"])} />
      </div>
      <Typography variant="h6">
        {studyType.toUpperCase()}
        {" Territory Map Loader"}
      </Typography>
      <CSVUpload
        onChange={(update) => {
          setCsv({ attachment: update.csv });
        }}
        value={{ csv, ...taskConfig }}
      />
      <FormControl fullWidth margin="normal">
        <TextField
          style={{ width: 500, marginBottom: -25 }}
          label="Custom Client (Add suffix)"
          value={taskConfig.client_suffix || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, client_suffix: e.target.value }));
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">{client.concat("_")}</InputAdornment>,
          }}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <OptionSelect
          required
          style={{ width: 500, marginBottom: -10 }}
          value={taskConfig.append_replace || ""}
          onChange={(insertionType) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, append_replace: insertionType }));
          }}
          options={insertTypes}
          label="Insertion Type"
        />
      </FormControl>
      <FormControl component="fieldset" margin="normal" style={{ display: "block" }}>
        <FormControlLabel
          style={{ marginBottom: -20 }}
          value={taskConfig.zip_3_digit || ""}
          control={(
            <Checkbox checked={taskConfig.zip_3_digit} />
          )}
          label="3 Digit Zips"
          onChange={(e) => setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, zip_3_digit: e.target.checked }))}
        />
      </FormControl>
      <FormControl component="fieldset" margin="normal" style={{ display: "block" }}>
        <FormControlLabel
          style={{ marginBottom: 20 }}
          value={taskConfig.broker_id_7_char || ""}
          control={(
            <Checkbox checked={taskConfig.broker_id_7_char} />
          )}
          label="Broker IDs are DBRs"
          onChange={(e) => setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, broker_id_7_char: e.target.checked }))}
        />
      </FormControl>
      {children}
    </Form>
  );
}
