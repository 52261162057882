import React from "react";
import { lmsTaskTypes } from "../../views/tasks/create_wizard/TaskMapping";
import NavigationButton from "./NavigationButton";

export default function ExportButton({ tableName, tableSet, studyType = "ann", client = "ALL_CLIENTS" }) {
  const exportTask = lmsTaskTypes.find((task) => task.id === "reporting_generic_exporter" && task.study === studyType);

  return (
    <NavigationButton
      url="/create"
      text="Export"
      state={
        {
          action: "loading",
          studyType,
          taskType: "reporting_generic_exporter",
          client,
          title: exportTask?.label,
          step: 2,
          taskConfig: {
            table_set: tableSet,
            table_name: tableName,
          },
        }
      }
    />
  );
}
