import React from "react";
import { useTheme } from "@mui/material";
import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis } from "recharts";

import { objectToArray } from "./utils";

function calculateQueryAvgAcrossUsers(jobData) {
  const queryAvgs = {};
  jobData?.forEach(job => {
    const { stats } = job;
    Object.keys(stats)?.forEach(query => {
      if (query in queryAvgs) {
        const { records, seconds } = queryAvgs[query];
        const newAvgRecords = (stats[query].records + records) / 2;
        const newAvgSeconds = (stats[query].seconds + seconds) / 2;
        queryAvgs[query] = { records: newAvgRecords, seconds: newAvgSeconds };
      } else {
        queryAvgs[query] = { seconds: stats[query].seconds, records: stats[query].records };
      }
    });
  });
  return objectToArray(queryAvgs);
}

function calculateQueryMedianAcrossUsers(jobData) {
  const allQueries = {};
  jobData?.forEach(job => {
    const { stats } = job;
    Object.keys(stats)?.forEach(query => {
      if (query in allQueries) {
        allQueries[query] = allQueries[query].concat({ records: stats[query].records, seconds: stats[query].seconds });
      } else {
        allQueries[query] = [{ seconds: stats[query].seconds, records: stats[query].records }];
      }
    });
  });
  Object.keys(allQueries)?.forEach(query => {
    allQueries[query] = allQueries[query][Math.ceil(allQueries[query].length / 2) - 1] || 0;
  });
  return objectToArray(allQueries);
}

export default function HistoricalGraphs({ graphKey, latestJobData, oldestJobData, showJustIssues, useAverage = false }) {
  const theme = useTheme();

  const latestQueryMedian = calculateQueryMedianAcrossUsers(latestJobData);
  const oldestQueryMedian = calculateQueryMedianAcrossUsers(oldestJobData);

  const latestQueryAvgs = calculateQueryAvgAcrossUsers(latestJobData);
  const oldestQueryAvgs = calculateQueryAvgAcrossUsers(oldestJobData);

  const combinedDataAvg = latestQueryAvgs.map(queryData => ({
    key: queryData.key,
    seconds_latest: Math.round(queryData.seconds),
    records_latest: Math.round(queryData.records),
    seconds_old: Math.round(oldestQueryAvgs.find(q => q.key === queryData.key)?.seconds),
    records_old: Math.round(oldestQueryAvgs.find(q => q.key === queryData.key)?.records),
  })).filter(o => !showJustIssues || ((graphKey === "seconds" && o?.seconds_latest >= 30) || (graphKey === "records" && o?.records_latest <= 0)));

  const combinedDataMedian = latestQueryMedian.map(queryData => ({
    key: queryData.key,
    seconds_latest: Math.round(queryData.seconds),
    records_latest: Math.round(queryData.records),
    seconds_old: Math.round(oldestQueryMedian.find(q => q.key === queryData.key)?.seconds),
    records_old: Math.round(oldestQueryMedian.find(q => q.key === queryData.key)?.records),
  })).filter(o => !showJustIssues || ((graphKey === "seconds" && o?.seconds_latest >= 30) || (graphKey === "records" && o?.records_latest <= 0)));

  const data = useAverage ? combinedDataAvg : combinedDataMedian;

  return (
    <BarChart
      width={500}
      height={600}
      data={data}
      layout="vertical"
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <XAxis dataKey={`${graphKey}_latest`} type="number" />
      <YAxis dataKey="key" type="category" scale="band" width={200} />
      <Tooltip />
      <Legend />
      <Bar dataKey={`${graphKey}_latest`} fill={theme.palette.success.main} />
      <Bar dataKey={`${graphKey}_old`} fill={theme.palette.error.main} />
    </BarChart>
  );
}
