import React, { useCallback, useState } from "react";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";

import usePageTitle from "../../hooks/usePageTitle";
import ExclusionsTable from "../consortium/exclusions/ExclusionsTable";
import BranchChannelFilters from "./BranchChannelFilters";

const commonColumns = () => [
  {
    header: "DD BRANCH ID",
    accessorKey: "dd_physical_branch_id",
  },
  {
    header: "Channel",
    accessorKey: "channel",
  },
];

export default function SplitBrokers() {
  usePageTitle("Split Brokers");
  const [success, setSuccess] = useState(null);
  const [response, setResponse] = useState("");
  const apiCallback = useCallback((apiSuccess, result) => {
    setSuccess(apiSuccess);
    if (apiSuccess) {
      if (result?.response?.data) {
        setResponse(JSON.stringify(result.response.data));
      } else {
        setResponse("Saved Successfully");
      }
    } else {
      setResponse(JSON.stringify(result.response.data));
    }
  }, []);
  return (
    <div>
      <div style={{ padding: 20 }}>
        <ExclusionsTable
          study={null}
          direction={null}
          title="Split Brokers"
          url="branch/channel"
          editUrl="branch/{ddPhysicalBranchId}/channel"
          columns={[
            ...commonColumns(),
            {
              header: "Notes",
              accessorKey: "notes",
            },
            {
              header: "Timestamp",
              accessorKey: "timestamp",
              enableEditing: false,
            },
          ]}
          apiCallback={apiCallback}
          filterPanel={BranchChannelFilters}
          genericEditActions
        />
      </div>
      <Snackbar
        open={success !== null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div>
          <Alert onClose={() => setSuccess(null)} severity={success ? "success" : "error"}>
            {response}
          </Alert>
        </div>
      </Snackbar>
    </div>
  );
}
