import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { HighchartsColors } from "../constants";

export function ScatterGraph({ title, hidetitle, inputData, xAxisTitle, yAxisTitle }) {
  const options = {
    accessibility: {
      enabled: false,
    },
    chart: {
      type: "scatter",
      zooming: {
        type: "xy",
      },
    },
    title: {
      text: hidetitle ? "" : title,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: xAxisTitle,
      },
    },
    yAxis: {
      title: {
        text: yAxisTitle,
      },
    },
    tooltip: {
      pointFormat: `${xAxisTitle}: {point.x} <br/>${yAxisTitle}: {point.y}`,
    },
    plotOptions: {
      colors: ["#8087e8", "#1dbfa3", "#f19e53"],
      scatter: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: inputData?.map((item, index) => (
      { ...item, color: HighchartsColors[index] }
    )),
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
