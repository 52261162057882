import React from "react";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Card, Typography, Chip } from "@mui/material";
import MaterialTable from "../../../components/Table";
import { objectToArray, userDisplay } from "./utils";

export default function JobProblemsTable({ data, users, user, setUser, setUsersSearch }) {
  const userJobsWithIssues = data?.filter(job => objectToArray(job?.stats).find(stat => stat?.records === 0 || stat?.seconds >= 30));
  const tableData = userJobsWithIssues?.map(job => ({
    userId: job.user_id,
    user: userDisplay(job.user_id, users),
    empty_results: objectToArray(job?.stats).some(stat => stat?.records === 0),
    long_query: objectToArray(job?.stats).some(stat => stat?.seconds >= 30),
  }));

  if (!tableData?.length) {
    return (
      <Card style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: 500, width: "100%" }}>
        <img alt="not found" src="/success.svg" style={{ width: 200, height: 200 }} />
        <Typography variant="h4">No Issues Found</Typography>
      </Card>
    );
  }

  return (
    <MaterialTable
      title="Potential Issues"
      columns={[
        { header: "User", accessorKey: "user", render: rowData => <Chip color={rowData.userId === user ? "primary" : "default"} label={rowData.user} onClick={() => { setUser(rowData.userId); setUsersSearch(rowData.userId); }} /> },
        { header: "Has empty query results", accessorKey: "empty_results", render: rowData => (rowData?.empty_results ? <ReportProblemIcon color="error" style={{ width: 50, borderRadius: "50%" }} /> : null) },
        { header: "Has query running > 30 sec", accessorKey: "long_query", render: rowData => (rowData?.long_query ? <ReportProblemIcon color="error" style={{ width: 50, borderRadius: "50%" }} /> : null) },
      ]}
      data={tableData}
    />
  );
}
