const territoryName = "territory_name";

export function getOptionsByClient(options, client) {
  return options.filter((option) => option.indexOf(client) === 0 || option === "");
}

export function getTerritoryConfigOptionsANN(formOptions, client = "") {
  const territories = getOptionsByClient(formOptions.territoriesVA, client);
  const territoryGroups = getOptionsByClient(formOptions.territoryGroupsANN, client);
  const channels = getOptionsByClient(formOptions.channelsANN, client);
  const terrAlts = getOptionsByClient(formOptions.terrAltLabelsANN, client);

  return {
    [territoryName]: {
      type: "string",
      title: "Territory Map",
      enum: territories,
    },
    territory_map_as_group: {
      type: "string",
      title: "Territory Map as Group",
      enum: territoryGroups,
    },
    alternate_territory_grouping: {
      type: "string",
      title: "Alternate Territory Grouping",
      enum: territoryGroups,
    },
    grouping_column_for_territory: {
      type: "string",
      title: "Grouping Column for Territory",
      enum: territoryGroups,
    },
    channel_map: {
      type: "string",
      title: "Channel Map",
      enum: channels,
    },
    alternate_territory_name_1: {
      type: "string",
      title: "Alternate Label 1 (appears on left)",
      enum: terrAlts,
    },
    alternate_territory_name_2: {
      type: "string",
      title: "Alternate Label 2 (appears on right)",
      enum: terrAlts,
    },
  };
}

export const getTerritoryModeVA = (formOptions, client = "") => ({
  oneOf: [
    {
      properties: {
        territory_mode: {
          enum: ["combined"],
        },
        combined_territory_config: {
          type: "object",
          title: "Combined Territory Configurations",
          required: [territoryName],
          properties: getTerritoryConfigOptionsANN(formOptions, client),
        },
      },
    },
    {
      properties: {
        territory_mode: {
          enum: ["split"],
        },
        va_territory_config: {
          type: "object",
          title: "VA Territory Configurations",
          required: [territoryName],
          properties: getTerritoryConfigOptionsANN(formOptions, client),
        },
        fia_territory_config: {
          type: "object",
          title: "FIA Territory Configurations",
          required: [territoryName],
          properties: getTerritoryConfigOptionsANN(formOptions, client),
        },
      },
    },
  ],
});

export function getTerritoryConfigOptionsMF(formOptions, client = "") {
  const territories = getOptionsByClient(formOptions.territoriesMF, client);
  const territoryGroups = getOptionsByClient(formOptions.territoryGroupsMF, client);
  const channels = getOptionsByClient(formOptions.channelsMF, client);
  const terrAlts = getOptionsByClient(formOptions.terrAltLabelsMF, client);

  return {
    [territoryName]: {
      type: "string",
      title: "Territory Map",
      enum: territories,
    },
    territory_map_as_group: {
      type: "string",
      title: "Territory Map as Group",
      enum: territoryGroups,
    },
    alternate_territory_grouping: {
      type: "string",
      title: "Alternate Territory Grouping",
      enum: territoryGroups,
    },
    grouping_column_for_territory: {
      type: "string",
      title: "Grouping Column for Territory",
      enum: territoryGroups,
    },
    channel_map: {
      type: "string",
      title: "Channel Map",
      enum: channels,
    },
    alternate_territory_name_1: {
      type: "string",
      title: "Alternate Label 1 (appears on left)",
      enum: terrAlts,
    },
    alternate_territory_name_2: {
      type: "string",
      title: "Alternate Label 2 (appears on right)",
      enum: terrAlts,
    },
  };
}

export function getTerritoryConfigOptionsSMA(formOptions, client = "") {
  const territories = getOptionsByClient(formOptions.territoriesSMA, client);
  const territoryGroups = getOptionsByClient(formOptions.territoryGroupsSMA, client);
  const channels = getOptionsByClient(formOptions.channelsSMA, client);
  const terrAlts = getOptionsByClient(formOptions.terrAltLabelsSMA, client);

  return {
    [territoryName]: {
      type: "string",
      title: "Territory Map",
      enum: territories,
    },
    territory_map_as_group: {
      type: "string",
      title: "Territory Map as Group",
      enum: territoryGroups,
    },
    alternate_territory_grouping: {
      type: "string",
      title: "Alternate Territory Grouping",
      enum: territoryGroups,
    },
    grouping_column_for_territory: {
      type: "string",
      title: "Grouping Column for Territory",
      enum: territoryGroups,
    },
    channel_map: {
      type: "string",
      title: "Channel Map",
      enum: channels,
    },
    alternate_territory_name_1: {
      type: "string",
      title: "Alternate Label 1 (appears on left)",
      enum: terrAlts,
    },
    alternate_territory_name_2: {
      type: "string",
      title: "Alternate Label 2 (appears on right)",
      enum: terrAlts,
    },
  };
}

export const FDSBrokerId = {
  type: "string",
  pattern: "^LMS.+$",
  title: "FDS Broker ID",
  description: "The LMS identifier for a broker",
  examples: [
    "LMS46910",
    "LMS35420",
  ],
};

export const brokerZip = {
  type: "object",
  properties: {
    fds_broker_id: {
      type: "string",
    },
    zip_code: {
      type: "string",
    },
  },
  required: [
    "fds_broker_id",
    "zip_code",
  ],
};

export const brokerZipExclusions = {
  broker_exclusions: {
    type: "array",
    title: "Broker Exclusions",
    items: {
      type: "string",
    },
    description: "Exclude the brokers from the report calculations",
  },
  broker_limitation: {
    type: "array",
    title: "Broker Limitations",
    items: {
      type: "string",
    },
    description: "Limit the report calculations to only the brokers listed here (formaly misnamed broker inclusions)",
  },
  broker_zip_exclusions: {
    type: "array",
    title: "Broker/Zip Exclusions",
    items: brokerZip,
    description: "Exclude the broker zip combinations from the report calculations",
  },
  broker_zip_limitation: {
    type: "array",
    title: "Broker/Zip Limitations",
    items: brokerZip,
    description: "Limit the report calculations to only the brokers/zip combinations listed here (formaly misnamed broker zip inclusions)",
  },
  broker_zip_exclusion_overrides: {
    type: "array",
    title: "Broker/Zip Exclusion Overrides",
    items: brokerZip,
    description: "Force the inclusion of the brokers/zip combinations listed here if they would otherwise be excluded",
  },
  client_broker_zip_exclusions: {
    type: "array",
    title: "Client Specific Broker/Zip Exclusions",
    items: brokerZip,
    description: "Exclude the broker/zip combination for the specific client only",
  },
  client_broker_zip_exclusion_overrides: {
    type: "array",
    title: "Client Specific Broker/Zip Exclusion Overrides",
    items: brokerZip,
    description: "Force the inclusion of the brokers/zip combinations listed here for the specific client if they would otherwise be excluded",
  },
};

export const verifiedAddressExclusions = {
  type: "array",
  title: "Verified Address Exclusions",
  items: {
    type: "number",
    multipleOf: 1.0,
  },
};

export const riaInclusions = {
  title: "RIA Inclusions",
  type: "array",
  items: {
    type: "array",
    minItems: 2,
    items: [
      {
        type: "string",
        title: "RIA Type",
      },
      {
        type: "string",
        title: "RIA Client",
      },
    ],
    additionalItems: false,
  },
};

export const vaProductType = {
  type: "string",
  title: "VA Product Type",
  oneOf: [
    {
      const: "",
      title: "",
    },
    {
      const: "IOVA",
      title: "IOVA: Investment Only Variable Annuity",
    },
    {
      const: "TRVA",
      title: "TRVA: Traditional Variable Annuity",
    },
    {
      const: "RILA",
      title: "RILA: Registered Index-Linked Annuity",
    },
  ],

};

export const annuityTypes = {
  title: "Annuity Types",
  type: "array",
  items: {
    type: "string",
    enum: ["FA", "IA", "VA"],
  },
  default: ["FA", "IA", "VA"],
  uniqueItems: true,
  minItems: 1,
};

export const customFileName = {
  type: "string",
  title: "Custom File Name",
  minLength: 1,
};

export const FilingType = {
  type: "object",
  required: [
    "finra_filing_type",
    "ria_custodian",
  ],
  properties: {
    finra_filing_type: {
      type: "string",
      title: "Finra Filing Type",
      oneOf: [
        {
          const: "BROKERAGE",
        },
        {
          const: "HYBRID",
        },
        {
          const: "RIA",
        },
        {
          const: "UNKNOWN",
        },
      ],
    },
    ria_custodian: {
      type: "string",
      title: "RIA Custodian",
      oneOf: [
        {
          const: "NONE",
        },
        {
          const: "CHARLES_SCHWAB",
        },
        {
          const: "FIDELITY",
        },
        {
          const: "GENERAL_RIA",
        },
        {
          const: "PERSHING",
        },
        {
          const: "TD_AMERITRADE",
        },
        {
          const: "UNKNOWN",
        },
      ],
    },
  },
};

export const ColumnOptions = [
  "MF / ANN",
  "ZIP_CODE",
  "STATE",
  "QUARTER",
  "FDS_BROKER_ID",
  "BROKER_NAME",
  "BROAD_FUND_CATEGORY",
  "FUND_CATEGORY",
  "CUSIP",
  "CLIENT_SALES",
  "GLOBAL_SALES",
  "TERRITORY",
  "REBALANCE",
  "CITY",
  "CHANNEL",
];

export const channels = [
  {
    id: "B",
    label: "B",
  },
  {
    id: "I",
    label: "I",
  },
  {
    id: "",
    label: "",
  },
];

export const filterOptions = [
  "fuzzy",
  "contains",
  "startsWith",
  "equals",
  "endsWith",
];
