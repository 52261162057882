import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import useSWR from "swr";
import MaterialTable from "../../../components/Table";
import { API, baseLMSURL, getClientDomainWhitelist, getMisightClients } from "../../../utils/api";

export default function ClientDomainWhitelist() {
  const { data: domains, error, mutate } = useSWR(`${baseLMSURL}/api/v1/client/clientdomainwhitelist`, getClientDomainWhitelist);
  const { data: clients, error: clientsError } = useSWR("/misight/clients", getMisightClients);
  const [editError, setEditError] = useState(false);
  const [loading, setLoading] = useState(false);

  const data = domains?.map((d) => {
    const oneData = { ...d };
    const matchingClient = clients?.find((c) => c.id === d.client_id);
    oneData.client_mnemonic = matchingClient?.mnemonic;
    return oneData;
  });

  const columns = [
    {
      header: "Client",
      accessorKey: "client_mnemonic",
      editVariant: "select",
      editSelectOptions: clients?.map((item) => item.mnemonic),
    },
    {
      header: "Domain",
      accessorKey: "domain",
      validate: (rowData) => (
        rowData.domain?.match("@")
          ? {
            isValid: false,
            helperText: "Domain can not contain the @ symbol",
          }
          : true
      ),
    },
    {
      header: "Notes",
      accessorKey: "notes",
    },
    {
      header: "Updated By",
      accessorKey: "updated_by",
      enableEditing: false,
    },
    {
      header: "Updated At",
      accessorKey: "updated_at",
      type: "datetime",
      enableEditing: false,
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      {error ? <div><Alert severity="error">{error}</Alert></div> : null}
      {clientsError ? <div><Alert severity="error">{clientsError}</Alert></div> : null}
      {editError ? <div><Alert onClose={() => setEditError("")} severity="error">{editError}</Alert></div> : null}
      {!error && !clientsError
        ? (
          <MaterialTable
            isLoading={!(domains && clients) || loading}
            columns={columns}
            data={data ?? []}
            title="Client Domains"
            editable={{
              onRowUpdate: async (newData, oldData) => {
                setLoading(true);
                const selectedClient = clients.find((c) => c.mnemonic === newData.client_mnemonic);
                await API.request(
                  { url: `${baseLMSURL}/api/v1/client/clientdomainwhitelist/${oldData.id}`, method: "PUT", data: { client_id: selectedClient.id, domain: newData.domain, notes: newData.notes } },
                )
                  .catch((e) => {
                    setEditError(e.message);
                  }).finally(() => {
                    setLoading(false);
                  });
                mutate();
              },
              onRowDelete: async (oldData) => {
                setLoading(true);
                await API.request(
                  { url: `${baseLMSURL}/api/v1/client/clientdomainwhitelist/${oldData.id}`, method: "DELETE" },
                )
                  .catch((e) => {
                    setEditError(e.message);
                  }).finally(() => {
                    setLoading(false);
                  });
                mutate();
              },
              onRowAdd: async (newData) => {
                setLoading(true);
                const selectedClient = clients.find((c) => newData.client_mnemonic === c.mnemonic);
                await API.request(
                  { url: `${baseLMSURL}/api/v1/client/clientdomainwhitelist`, method: "POST", data: { client_id: selectedClient.id, domain: newData.domain, notes: newData.notes } },
                )
                  .catch((e) => {
                    setEditError(e.message);
                  }).finally(() => {
                    setLoading(false);
                  });
                mutate();
              },
            }}
          />
        )
        : null}
    </div>
  );
}
