import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import EditAttributesIcon from "@mui/icons-material/EditAttributes";
import { Divider, Paper, TextareaAutosize, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

/*
  --- Analysts use this format ---
  "fds zip", "fds zip2", "fds zip3" for firm/zip
  "fds", "fds2", "fds3" for firms
*/
function formatLabel(obj) {
  if (typeof obj === "string") {
    return obj;
  }
  return Object.values(obj).join(" ");
}

/*
  --- Backend needs this format ---
  ["fds1", "fds2", "fds3"] for firms
  [{ fds_broker_id: "fds1", zip: "zip1"}, { fds_broker_id: "fds2", zip: "zip2"}] for firm/zip
*/
function reverseFormatLabel(str, properties) {
  if (str === "") return [];

  // Array of objects have a properties obj in the schema compared to simple arrays of strings
  if (properties) {
    const [key1, key2] = Object.keys(properties);
    return str.replace("   ", " ").replace("  ", " ").split(",").map(valueSpaceValue => {
      const [value1, value2] = valueSpaceValue.trimStart().split(" ");
      const outputObj = {
        [key1]: value1,
      };
      if (value2) {
        outputObj[key2] = value2;
      }
      // TODO: Minor bug where you can't delete a space without selecting it
      if (value2 === "") {
        outputObj[key2] = " ";
      }
      return outputObj;
    });
  }
  return str.split(",").map((o) => o.trim());
}

export default function CustomArray({ schema, formData, name, onChange }) {
  const [editStyle, setEditStyle] = useState("select");

  const handleEditRaw = (event, style) => {
    setEditStyle(style);
  };

  return (
    <Paper component="div">
      <div style={{ display: "flex", flexDirection: "row", padding: 20, justifyContent: "space-between" }}>
        <Typography component="h5" variant="h5" style={{}}>
          {schema.title}
        </Typography>
        <ToggleButtonGroup
          value={editStyle}
          exclusive
          onChange={handleEditRaw}
          aria-label="text alignment"
        >
          <ToggleButton value="select" aria-label="left aligned">
            <EditAttributesIcon />
          </ToggleButton>
          <ToggleButton value="raw" aria-label="centered">
            <EditIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <Divider />
      <div style={{ padding: 20 }}>
        {editStyle === "raw"
          ? (
            <TextareaAutosize
              id="outlined-textarea"
              value={formData?.map(o => formatLabel(o)) || []}
              label={name}
              placeholder="Insert or paste values here, format is enforced. E.g: LMS123,LMS456,LMS789 or LMS123 90210,LMS4569 90210"
              variant="outlined"
              style={{ width: "75%", fontSize: 15, fontFamily: "Roboto", padding: 10 }}
              minRows={5}
              onChange={(e) => {
                onChange(reverseFormatLabel(e.target.value, schema?.items?.properties));
              }}
            />
          )
          : (
            <Autocomplete
              multiple
              limitTags={3}
              id={`custom-array-${name}`}
              options={[]}
              getOptionLabel={(option) => formatLabel(option)}
              value={formData || []}
              onChange={(e, v) => {
                onChange(v);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label={name} placeholder="Add" />
              )}
              style={{ maxWidth: "75%" }}
            />
          )}
      </div>
    </Paper>
  );
}
