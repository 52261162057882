import React, { useState } from "react";
import { GraphQLObjectType, print } from "graphql";
import _ from "lodash";
import DEFAULT_DOCUMENT from "../defaultDoc";
import { defaultGetDefaultFieldNames, defaultGetDefaultScalarArgValue } from "../defaultNames";
import {
  capitalize,
  memoizeParseQuery,
} from "../util";
import { RootView } from "./rootView";

export default function Explorer({
  schema,
  query,
  makeDefaultArg,
  hideActions,
  onEdit,
  externalFragments,
  onRunOperation,
  getDefaultFieldNames,
  getDefaultScalarArgValue,
  customClassname = () => ({}),
}) {
  const [newOperationType, setNewOperationType] = useState("query");

  if (!schema) return <div>Waiting for Schema</div>;

  const queryType = schema.getQueryType();
  const mutationType = schema.getMutationType();
  const subscriptionType = schema.getSubscriptionType();
  if (!queryType && !mutationType && !subscriptionType) {
    return <div>Missing query type</div>;
  }

  const queryFields = queryType && queryType.getFields();
  const mutationFields = mutationType && mutationType.getFields();
  const subscriptionFields = subscriptionType && subscriptionType.getFields();

  const parsedQuery = memoizeParseQuery(query);
  const existingDefs = parsedQuery.definitions;

  const relevantOps = existingDefs
    .map(definition => {
      if (definition.kind === "FragmentDefinition") return definition;
      if (definition.kind === "OperationDefinition") return definition;
      return null;
    })
    .filter(Boolean);

  const relevantOperations = relevantOps.length === 0
    ? DEFAULT_DOCUMENT.definitions
    : relevantOps;

  const renameOperation = (targetOperation, name) => {
    const newName = name == null || name === ""
      ? null
      : {
        kind: "Name",
        value: name,
        loc: undefined,
      };
    const newOperation = {
      ...targetOperation,
      name: newName,
    };

    const newDefinitions = existingDefs.map(existingOperation => {
      if (targetOperation === existingOperation) return newOperation;
      return existingOperation;
    });

    return {
      ...parsedQuery,
      definitions: newDefinitions,
    };
  };

  const cloneOperation = (targetOperation) => {
    const newOperationName = `${(targetOperation.name && targetOperation.name.value) || ""}Copy`;

    const newName = {
      kind: "Name",
      value: newOperationName,
      loc: undefined,
    };

    const newOperation = {
      ...targetOperation,
      name: newName,
    };

    const newDefinitions = [...existingDefs, newOperation];

    return {
      ...parsedQuery,
      definitions: newDefinitions,
    };
  };

  const destroyOperation = targetOperation => {
    const newDefinitions = existingDefs.filter(existingOperation => targetOperation !== existingOperation);

    return {
      ...parsedQuery,
      definitions: newDefinitions,
    };
  };

  const addOperation = (kind) => {
    const viewingDefaultOperation = parsedQuery.definitions.length === 1
        && parsedQuery.definitions[0] === DEFAULT_DOCUMENT.definitions[0];

    const MySiblingDefs = viewingDefaultOperation
      ? []
      : existingDefs.filter(def => {
        if (def.kind === "OperationDefinition") {
          return def.operation === kind;
        }
        // Don't support adding fragments from explorer
        return false;
      });

    const newOperationName = `My${capitalize(kind)}${
      MySiblingDefs.length === 0 ? "" : MySiblingDefs.length + 1
    }`;

    // Add this as the default field as it guarantees a valid selectionSet
    const firstFieldName = "__typename # Placeholder value";

    const selectionSet = {
      kind: "SelectionSet",
      selections: [
        {
          kind: "Field",
          name: {
            kind: "Name",
            value: firstFieldName,
            loc: null,
          },
          arguments: [],
          directives: [],
          selectionSet: null,
          loc: null,
        },
      ],
      loc: null,
    };

    const newDefinition = {
      kind: "OperationDefinition",
      operation: kind,
      name: {
        kind: "Name",
        value: newOperationName,
      },
      variableDefinitions: [],
      directives: [],
      selectionSet,
      loc: null,
    };

    const newDefinitions = viewingDefaultOperation
      ? [newDefinition]
      : [...parsedQuery.definitions, newDefinition];

    const newOperationDef = {
      ...parsedQuery,
      definitions: newDefinitions,
    };

    onEdit(print(newOperationDef));
  };

  const actionsOptions = [
    queryFields
      ? (
        <option
          key="query"
          className="graphiql-explorer-toolbar-button"
          value="query"
        >
          Query
        </option>
      )
      : null,
    mutationFields
      ? (
        <option
          key="mutation"
          className="graphiql-explorer-toolbar-button"
          value="mutation"
        >
          Mutation
        </option>
      )
      : null,
    subscriptionFields
      ? (
        <option
          key="subscription"
          className="graphiql-explorer-toolbar-button"
          value="subscription"
        >
          Subscription
        </option>
      )
      : null,
  ].filter(Boolean);

  const actionsEl = !(actionsOptions.length === 0 || hideActions)
    ? (
      <div
        style={{
          minHeight: "50px",
          maxHeight: "50px",
          overflow: "none",
        }}
      >
        <form
          className="variable-editor-title graphiql-explorer-actions"
          onSubmit={event => event.preventDefault()}
        >
          <span
            style={{
              display: "inline-block",
              flexGrow: "0",
              textAlign: "right",
            }}
          >
            Add new
            {" "}
          </span>
          <select
            onChange={event => setNewOperationType(event.target.value)}
            value={newOperationType}
            style={{ flexGrow: "2" }}
          >
            {actionsOptions}
          </select>
          <button
            type="submit"
            className="graphiql-explorer-toolbar-button"
            onClick={() => (newOperationType ? addOperation(newOperationType) : null)}
            style={{ height: "22px", width: "22px" }}
          >
            <span>+</span>
          </button>
        </form>
      </div>
    )
    : null;

  const extFragments = externalFragments
      && externalFragments.reduce((acc, fragment) => {
        if (fragment.kind === "FragmentDefinition") {
          const fragmentTypeName = fragment.typeCondition.name.value;
          const existingFragmentsForType = acc[fragmentTypeName] || [];
          const newFragmentsForType = [
            ...existingFragmentsForType,
            fragment,
          ].sort((a, b) => a.name.value.localeCompare(b.name.value));
          return {
            ...acc,
            [fragmentTypeName]: newFragmentsForType,
          };
        }

        return acc;
      }, {});

  const documentFragments = relevantOperations.reduce(
    (acc, o) => {
      if (o.kind === "FragmentDefinition") {
        const fragmentTypeName = o.typeCondition.name.value;
        const existingFragmentsForType = acc[fragmentTypeName] || [];
        const newFragmentsForType = [
          ...existingFragmentsForType,
          o,
        ].sort((a, b) => a.name.value.localeCompare(b.name.value));
        return {
          ...acc,
          [fragmentTypeName]: newFragmentsForType,
        };
      }

      return acc;
    },
    {},
  );

  const availableFragments = { ...documentFragments, ...extFragments };

  return (
    <div className="graphiql-explorer-root">
      <div className="graphiql-explorer-root-tree">
        {relevantOperations.map((operation) => {
          const operationName = operation?.name?.value;

          const operationType = operation.kind === "FragmentDefinition"
            ? "fragment"
            : (operation?.operation) || "query";

          const onOperationRename = newName => {
            const newOperationDef = renameOperation(operation, newName);
            onEdit(print(newOperationDef));
          };

          const onOperationClone = () => {
            const newOperationDef = cloneOperation(operation);
            onEdit(print(newOperationDef));
          };

          const onOperationDestroy = () => {
            const newOperationDef = destroyOperation(operation);
            onEdit(print(newOperationDef));
          };

          const fragmentType = operation.kind === "FragmentDefinition"
              && operation.typeCondition.kind === "NamedType"
              && schema.getType(operation.typeCondition.name.value);

          const fragmentFields = fragmentType instanceof GraphQLObjectType
            ? fragmentType.getFields()
            : null;

          let fields = null;
          switch (operationType) {
          case "query":
            fields = queryFields;
            break;
          case "mutation":
            fields = mutationFields;
            break;
          case "subscription":
            fields = subscriptionFields;
            break;
          default:
            if (operation.kind === "FragmentDefinition") {
              fields = fragmentFields;
            }
            break;
          }

          const fragmentTypeName = operation.kind === "FragmentDefinition"
            ? operation.typeCondition.name.value
            : null;

          const onCommit = (parsedDocument) => {
            const textualNewDocument = print(parsedDocument);
            onEdit(textualNewDocument);
          };

          return (
            <RootView
              key={_.uniqueId()}
              fields={fields}
              operationType={operationType}
              name={operationName}
              definition={operation}
              onOperationRename={onOperationRename}
              onOperationDestroy={onOperationDestroy}
              onOperationClone={onOperationClone}
              onTypeName={fragmentTypeName}
              onCommit={onCommit}
              onEdit={(newDefinition, options) => {
                let commit = true;
                if (typeof options === "object" && typeof options.commit !== "undefined") {
                  commit = options.commit;
                }

                if (newDefinition) {
                  const newQuery = {
                    ...parsedQuery,
                    definitions: parsedQuery.definitions.map(
                      existingDefinition => (existingDefinition === operation
                        ? newDefinition
                        : existingDefinition),
                    ),
                  };

                  if (commit) onCommit(newQuery);

                  return newQuery;
                }
                return parsedQuery;
              }}
              schema={schema}
              getDefaultFieldNames={getDefaultFieldNames || defaultGetDefaultFieldNames}
              getDefaultScalarArgValue={getDefaultScalarArgValue || defaultGetDefaultScalarArgValue}
              makeDefaultArg={makeDefaultArg}
              onRunOperation={() => {
                if (onRunOperation) {
                  onRunOperation(operationName);
                }
              }}
              availableFragments={availableFragments}
              customClassname={customClassname}
            />
          );
        })}
      </div>
      {actionsEl}
    </div>
  );
}
