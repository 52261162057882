import React from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { FormControlLabel, Checkbox, Tooltip } from "@mui/material";
import { useField, useFormikContext } from "formik";

export default function LMSCheckbox({
  name,
  label,
  tooltipText,
  ...props
}) {
  const [field] = useField({ name, type: "checkbox" });
  const { setFieldValue } = useFormikContext();

  const handleChange = (event) => {
    if (!event.target.checked) {
      setFieldValue(name, false);
    } else {
      setFieldValue(name, true);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <FormControlLabel
        control={
          (
            <Checkbox
              {...field}
              {...props}
              checked={field.value ?? false}
              onChange={handleChange}
            />
          )
        }
        label={label}
      />
      {(tooltipText) ? <Tooltip title={tooltipText} placement="top" arrow><HelpOutlineIcon fontSize="small" /></Tooltip> : null }
    </div>
  );
}
