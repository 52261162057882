import React, { useEffect, useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Button, Chip, CircularProgress, DialogActions, DialogContent, DialogTitle, FormControl, InputAdornment, TextField, Autocomplete, Alert } from "@mui/material";
import { API } from "../../../utils/api";

const ErrorDisplay = () => (
  <>
    Input must contain
    <ul style={{ margin: "0" }}>
      <li>Alphanumeric characters including  _ </li>
      <li>Must not start with number or _ </li>
    </ul>
    <strong style={{ color: "green" }}> Valid Example : WEEKEND_WORKFLOW_0422</strong>
  </>
);
export function ShowWorkflowModal({ open, submitHandler, handleClose, taskConfig }) {
  const [workflowData, setWorkflowData] = useState([]);
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const checkType = (e) => { if (localStorage.getItem("form_type") === "lms_form") return submitHandler(false, e, taskConfig); return submitHandler(false, e); };
  const insertToDB = (event) => setTimeout(() => checkType(event), 0);

  const handleChange = (e, val) => {
    setValue(null);
    setTimeout(() => setValue(val), 0);
    localStorage.setItem("workflowName", val.workflowName);
    localStorage.setItem("workflowId", val.workflowId);
  };

  useEffect(() => {
    setLoading("Fetching list of workflows...");
    API.request({ url: "/workflows?limit=100", method: "GET" }).then(
      (response) => {
        if (response.data.resultCount) {
          const filteredData = response.data.results.filter(row => row.status === "DRAFT" || row.status === "CREATED");
          filteredData.sort((a, b) => a.workflowName.localeCompare(b.workflowName));
          setWorkflowData(filteredData);
          setLoading(false);
        } else {
          setWorkflowData([]);
          setLoading(false);
        }
      },
      () => {
        setLoading(false);
        return null;
      },
    );
  }, [open]);
  return (
    <div>
      <DialogTitle id="form-dialog-title">
        Select workflow
      </DialogTitle>
      <DialogContent
        style={{ maxHeight: "auto" }}
      >

        {loading
          ? <div><Alert severity="info">{`${loading}`}</Alert></div>
          : (
            <FormControl fullWidth>
              <Autocomplete
                freeSolo
                id="combo-box-demo"
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (typeof option === "object") {
                    return option?.workflowName;
                  }
                  return option;
                }}
                onChange={handleChange}
                options={workflowData}
                value={value}
                style={{ justifyContent: "center", marginBottom: "10px" }}
                renderInput={(params) => <TextField autoFocus {...params} label={workflowData.length > 0 ? "Select workflow" : "Loading Options..."} variant="outlined" />}
                renderOption={(props, option) => (
                  <div {...props} style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ width: "65%", wordWrap: "break-word", fontSize: "14px" }}>{option.workflowName}</div>
                    <div style={{ marginLeft: "auto" }}><Chip color="primary" label={(option.username.split("@")[0].replace(".", " ").toUpperCase())} style={{ fontSize: "10px", width: "130px" }} /></div>
                  </div>
                )}
              />
            </FormControl>
          )}
      </DialogContent>
      <DialogActions style={{ paddingRight: "5%" }}>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button color="primary" disabled={value === null || value === "" || loading} onClick={insertToDB}>
          Add
        </Button>
      </DialogActions>
    </div>
  );
}

export function CreateWorkflow({
  handleClose,
  clone = false,
  workflowId,
  workflowName,
  submitHandler,
  taskConfig,
  createWorkflowAndAddTask = false,
}) {
  const [value, setValue] = useState("");
  const [error, setError] = useState({ msg: "", status: false });
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const alphanumericPattern = /^[a-zA-Z][a-zA-Z0-9_]*$/;
  const [CloneWorkflowName, setCloneWorkflowName] = useState(workflowName);
  const checkType = (e) => {
    if (localStorage.getItem("form_type") === "lms_form") return submitHandler(false, e, taskConfig); return submitHandler(false, e);
  };
  const insertToDB = (event) => setTimeout(() => checkType(event), 0);

  useEffect(() => (CloneWorkflowName === "" ? setCloneWorkflowName(workflowName) : setCloneWorkflowName(CloneWorkflowName)), [CloneWorkflowName, workflowName]);

  const handleInputChange = (event) => {
    const data = event.target.value;
    setValue(null);
    setError({ msg: "", status: false });
    setValue(data.toUpperCase());
  };

  const handleCloneSubmit = () => {
    setLoading(true);
    if (!alphanumericPattern.test(value)) {
      setError({ msg: <ErrorDisplay />, status: true });
      setLoading(false);
      return;
    }
    API.request({
      url: `/workflows/${workflowId}/clone `,
      method: "POST",
      data: { workflowName: value },
    })
      .then((response) => {
        if (response.status === 201) {
          setLoading(false);
          setShowSnackbar(true);
          setError((prev) => ({
            ...prev,
            status: false,
            msg: `${value} - Workflow Created !`,
          }));
          setTimeout(() => {
            window.location.href = `/workflows/edit/${value}`;
          }, 2000);
        }
      })
      .catch((e) => {
        setLoading(false);
        setError((prev) => ({
          ...prev,
          status: true,
          msg: e.response.data.message,
        }));
        return null;
      });
  };

  const handleSubmit = () => {
    setLoading(true);
    if (!alphanumericPattern.test(value)) {
      setError({ msg: <ErrorDisplay />, status: true });
      setLoading(false);
      return;
    }
    API.request({ url: "/workflows", method: "POST", data: { workflow_name: value } })
      .then((response) => {
        if (response.status === 201) {
          setLoading(false);
          setShowSnackbar(true);
          setError(prev => ({ ...prev, status: false, msg: `${value} - Workflow Created !` }));
          if (createWorkflowAndAddTask) {
            setLoading(true);
            localStorage.setItem("workflowName", response.data.workflowName);
            localStorage.setItem("workflowId", response.data.workflowId);
            insertToDB();
          }
        }
      }).catch(e => {
        setLoading(false);
        setError(prev => ({ ...prev, status: true, msg: e.response.data.message }));
        return null;
      });
  };
  return (
    <div>
      <DialogTitle id="form-dialog-title">
        {clone ? `Clone Workflow : ${CloneWorkflowName}` : "Create workflow"}
      </DialogTitle>
      <DialogContent
        style={{ maxHeight: "auto", maxWidth: "auto" }}
      >
        <FormControl fullWidth>
          <TextField
            disabled={showSnackbar}
            autoComplete="off"
            autoFocus
            error={error.status}
            id="outlined-error-helper-text"
            label={clone ? "Enter New Workflow Name" : "Enter Workflow Name"}
            helperText={error.msg}
            variant="outlined"
            onChange={handleInputChange}
            value={value}
            InputProps={{
              endAdornment: (showSnackbar
                && (
                  <InputAdornment position="end">
                    <CheckCircleOutlineIcon color="secondary" />
                  </InputAdornment>
                )
              ),

            }}
          />
        </FormControl>

      </DialogContent>
      <DialogActions style={{ paddingRight: "5%" }}>
        {loading && <CircularProgress size="2em" />}
        <Button disabled={loading} onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          color="secondary"
          disabled={value === null || value === "" || loading}
          onClick={clone ? handleCloneSubmit : handleSubmit}
        >
          {clone ? "Clone" : "Create"}
        </Button>
      </DialogActions>
    </div>
  );
}
