import React from "react";
import { Chip, useTheme } from "@mui/material";

export default function StatusChip({ status }) {
  const theme = useTheme();

  function getStyle(statusString) {
    switch (statusString) {
    case "SUCCEEDED": {
      return theme.common.chip.successChip;
    }
    case "FAILED": {
      return theme.common.chip.errorChip;
    }
    case "ABORTED": {
      return theme.common.chip.abortedChip;
    }
    case "DRAFT": {
      return theme.common.chip.draftChip;
    }
    case "RUNNING": {
      return theme.common.chip.runningChip;
    }
    case "QUEUED": {
      return theme.common.chip.queuedChip;
    }
    case "RUNNING_QUEUE": {
      return theme.common.chip.runningQueueChip;
    }
    default:
      return theme.common.chip.baseChip;
    }
  }

  return (
    <Chip
      label={status}
      style={{ ...getStyle(status), width: 130 }}
    />
  );
}
