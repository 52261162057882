import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
  name: "csvHeaders",
  initialState: {
    data: {
      header: [],
    },
  },
  reducers: {
    setCsvHeaders: (state, action) => {
      const { csvHeaders } = action.payload;
      state.data.header = [...csvHeaders];
    },
    resetHeaders: (state) => {
      state.data.header = [];
    },
  },
});
