import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
  name: "formOptions",
  initialState: {
    quarters: ["Loading..."],
    productionCycle: ["Loading..."],
    locksMF: ["Loading..."],
    locksANN: ["Loading..."],
    locksSMA: ["Loading..."],
    territoriesVA: ["Loading..."],
    territoriesFIA: ["Loading..."],
    ddTerritoriesVA: ["Loading..."],
    ddTerritoriesFIA: ["Loading..."],
    territoryGroupsANN: ["Loading..."],
    terrAltLabelsANN: ["Loading..."],
    channelsANN: ["Loading..."],
    ddChannelsANN: ["Loading..."],
    OTTTerrGroupsANN: ["Loading..."],
    territoriesSMA: ["Loading..."],
    territoriesMF: ["Loading..."],
    territoryGroupsSMA: ["Loading..."],
    terrAltLabelsSMA: ["Loading..."],
    territoryGroupsMF: ["Loading..."],
    terrAltLabelsMF: ["Loading..."],
    channelsSMA: ["Loading..."],
    channelsMF: ["Loading..."],
    dataRevisionID: ["Loading..."],
    dataRevisionName: ["Loading..."],
    clientProfilesANN: ["Loading..."],
    clientProfilesMF: ["Loading..."],
    firmNames: ["Loading..."],
    firmNamesDD: ["Loading..."],
    usernames: ["Loading..."],
  },
  reducers: {
    setOptions: (state, action) => {
      const { option, value } = action.payload;
      state[option] = value;
    },
  },
});
