import React from "react";
import { ReportProblemOutlined } from "@mui/icons-material";
import { Card, CardContent, Typography } from "@mui/material";

function ErrorFallback() {
  return (
    <Card style={{ textAlign: "center", height: "400px", boxShadow: "none", borderRadius: 0 }}>
      <CardContent style={{ marginTop: 100 }}>
        <ReportProblemOutlined fontSize="large" />
        <Typography variant="h5" style={{ wordBreak: "break-word", marginLeft: 10, marginRight: 10 }}>
          Unable to Load Data. Please Try Again !!!
        </Typography>
      </CardContent>
    </Card>
  );
}

export default ErrorFallback;
