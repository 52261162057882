import React, { useEffect } from "react";
import { Chip, FormControl, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { useSelector } from "react-redux";

import CSVUpload from "../../components/input/CSVUpload";
import OptionSelect from "../../components/input/OptionSelect";

const tableNames = [
  // ANN
  { id: "FIA_KEY_BROKERS", label: "FIA Key Brokers", study: "ann", replace_only: true },
  { id: "VA_KEY_BROKERS", label: "VA Key Brokers", study: "ann", replace_only: true },
];
export default function LoadKeyBrokersFormDD({ children, taskConfig, setTaskConfig, csv, setCsv, submitHandler, studyType, displayHeader }) {
  const productionCycles = useSelector((state) => state.formOptions.productionCycle);

  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType, file_type: "secondary_files" }));
  }, [setTaskConfig, studyType]);

  return (
    <>
      <Typography variant="h4" style={{ backgroundColor: "orange" }}>Discovery Data</Typography>
      <Form
        schema={{}}
        onSubmit={submitHandler}
        validator={validator}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
          <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["table_name"])} />
        </div>
        <CSVUpload
          onChange={(update) => {
            setCsv({ attachment: update.csv });
          }}
          value={{ csv, ...taskConfig }}
        />
        <Typography align="right" variant="subtitle1">
          The loaded file is processed through Mr.Clean before loading
        </Typography>
        {studyType === "ann" && (
          <FormControl fullWidth margin="normal">
            <OptionSelect
              required
              style={{ width: 500, marginBottom: -10 }}
              value={taskConfig.table_name || ""}
              onChange={(tableName) => {
                setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, table_name: tableName }));
              }}
              options={tableNames.filter((o) => o.study === studyType)}
              label="Table Name"
            />
          </FormControl>
        )}
        {studyType !== "ann" && (
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel required htmlFor="inputLabel-production-cycle-select">Production Cycle</InputLabel>
            <Select
              required
              style={{ width: 500, marginBottom: 30 }}
              value={taskConfig.production_cycle || ""}
              onChange={(e) => {
                setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, production_cycle: e.target.value }));
              }}
              label="Production Cycle"
              inputProps={{
                name: "production cycle",
                id: "inputLabel-production-cycle-select",
              }}
            >
              {productionCycles.map((quarter) => (
                <MenuItem key={quarter} value={quarter}>
                  {quarter}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {children}
      </Form>

    </>
  );
}
