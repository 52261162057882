import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import NoDataFoundTile from "./NoDataFoundTile";

export default function BarChart({ title, hidetitle, inputData, xAxisTitle = null, xAxisCategories, yAxisTitle }) {
  const options = {
    accessibility: {
      enabled: false,
    },
    chart: {
      type: "bar",
    },
    title: {
      text: hidetitle ? "" : title,
      align: "center",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
        groupPadding: 0.1,
      },
    },
    xAxis: {
      categories: xAxisCategories,
      title: {
        text: xAxisTitle,
      },
      gridLineWidth: 1,
      lineWidth: 0,
    },
    yAxis: {
      min: 0,
      title: {
        useHtml: true,
        text: `<h3>${yAxisTitle}</h3>`,
      },
      labels: {
        overflow: "justify",
      },
      gridLineWidth: 0,
    },
    series: inputData,
  };
  return (
    (inputData?.length > 0) ? <HighchartsReact highcharts={Highcharts} options={options} /> : <NoDataFoundTile />
  );
}
