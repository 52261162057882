import React, { forwardRef, useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import makeStyles from "@mui/styles/makeStyles";

import { API } from "../../../../utils/api";

const useStyles = makeStyles((theme) => ({
  title: {
    position: "center",
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.primary.light,
  },
  confirmButton: {
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.secondary.light,
  },
  addIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  deleteIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
}));

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function ActivationDialog({ open, setOpen, users, refreshUsersFunction }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleClose = () => {
    setError(null);
    if (success) {
      refreshUsersFunction();
    }
    setSuccess(null);
    setOpen(false);
  };

  function activateUser(userId) {
    return API.request({ url: `/users/${encodeURIComponent(userId)}/activate`, method: "POST" })
      .then(() => {}, (e) => { setError(e); });
  }

  function submitUserActivation(usersToActivate) {
    setLoading(true);
    const requestPromiseArray = [];
    usersToActivate.forEach((user) => requestPromiseArray.push(activateUser(user.id)));
    Promise.all(requestPromiseArray).catch(() => requestPromiseArray)
      .then(() => {
        setSuccess("All users activated!");
        setLoading(false);
      }, (e) => { setError(e); setLoading(false); });
  }

  const dialogText = (
    <Typography color="textSecondary">
      {"You are about to "}
      <b>activate</b>
      {" these users, this will "}
      <b>send an email</b>
      {" notification to all of them"}
      <br />
      Please confirm this action:
    </Typography>
  );

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-slide-title" className={classes.title}>
          Confirm
        </DialogTitle>
        <DialogContent dividers>
          {dialogText}
          <br />
          {users.map((user) => (
            <Typography variant="subtitle1" key={user.id}>
              <AddOutlinedIcon className={classes.addIcon} />
              {"name: "}
              <b>{user.name}</b>
              {" email: "}
              <b>{user.email}</b>
            </Typography>
          ))}
        </DialogContent>
        {loading && <div><Alert severity="info">Activating Users...</Alert></div>}
        {error && <div><Alert severity="error">{`${error}`}</Alert></div>}
        {success
          ? (
            <div>
              <div><Alert severity="success">{`${success}`}</Alert></div>
              <DialogActions>
                <Button onClick={handleClose} className={classes.cancelButton} disabled={loading}>
                  Close
                </Button>
              </DialogActions>
            </div>
          )
          : (
            <DialogActions>
              <Button onClick={handleClose} className={classes.cancelButton} disabled={loading}>
                Cancel
              </Button>
              <Button onClick={() => submitUserActivation(users)} className={classes.confirmButton} disabled={loading}>
                Confirm
              </Button>
            </DialogActions>
          )}
      </Dialog>
    </div>
  );
}
