import React, { useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Tooltip, Button } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import IssSpinner from "./Spinner";

// Backport material-table columns to material-react-table format
function backportMaterialTable(columns) {
  return columns.map((column) => ({
    header: column?.header ?? column?.title,
    accessorKey: column?.accessorKey ?? column?.field,
    ...column.render && { Cell: ({ row }) => (column.render(row.original)) },
    ...column,
  }));
}

// BASE REUSABLE TABLE
// Minimum props are DATA and COLUMNS in this format: [{ header: "column title", accessorKey: "data key" },]
// fetchData is an optional func prop that can be passed to fetch data on pagination
// Pass any other props to the table as needed
// DOCS: https://www.material-react-table.com/

export default function Table({ data, columns, ...props }) {
  // Backport columns to material-react-table format
  // Remove when everything is ported to it
  const backportedColumns = backportMaterialTable(columns);

  // Table can fetch data with pagination if fetchData is passed as a prop
  // fetchData should be implemented by parent because often the implementation will be different
  const { fetchData, nextPaginationKey } = props;

  const table = useMaterialReactTable({
    data,
    columns: backportedColumns,
    rowCount: data?.length,
    showLoadingOverlay: true,
    muiCircularProgressProps: { Component: <IssSpinner /> },
    paginationDisplayMode: "pages",
    autoResetPageIndex: false,
    state: { isLoading: props?.isLoading, ...props.state },
    renderCaption: () => props.title,
    muiTableProps: {
      sx: {
        caption: {
          fontSize: 25,
          captionSide: "top",
        },
      },
    },
    // Backport editable props to material-react-table format
    // Remove when everything is ported to new syntax
    ...props.editable && {
      editDisplayMode: "row",
      enableEditing: true,
      renderRowActions: ({ values, row }) => (
        <Box sx={{ display: "flex", gap: "1rem" }}>
          {props.editable.onRowUpdate && (
            <Tooltip title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {props.editable.onRowDelete && (
            <Tooltip title="Delete">
              <IconButton color="error" onClick={() => { props.editable.onRowDelete({ ...row.original, ...values }); table.setEditingRow(null); }}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
      ...props.editable.onRowUpdate && { onEditingRowSave: ({ values, row, table: editingTable }) => { props.editable.onRowUpdate(values, row.original, editingTable); editingTable.setEditingRow(null); } },
      ...props.editable.onRowAdd && {
        createDisplayMode: "row",
        onCreatingRowSave: ({ values }) => { props.editable.onRowAdd(values); table.setCreatingRow(false); },
        renderTopToolbarCustomActions: () => (
          <Button
            variant="contained"
            onClick={() => {
              table.setCreatingRow(true);
            }}
          >
            Add New
          </Button>
        ),
      },
    },
    ...props,
  });

  const { getCanNextPage } = table;
  const { isLoading } = table.getState();

  useEffect(() => {
    if (fetchData && nextPaginationKey && !getCanNextPage() && !isLoading) {
      fetchData();
    }
  }, [fetchData, nextPaginationKey, getCanNextPage, isLoading]);

  return <MaterialReactTable table={table} />;
}
