import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";

export default function AnnMFClientMaterializedViewForm({ children, setTaskConfig, client, submitHandler, studyType }) {
  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType }));
  }, [setTaskConfig, studyType]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      validator={validator}
    >
      <Typography variant="h6">Client Materialized Table View</Typography>
      <Typography variant="subtitle1" style={{ margin: 15 }}>
        {"This job will create or update the "}
        <b>ANN MF</b>
        {" materialized view with territories for "}
        <b>{`${client}`}</b>
        <br />
        This table view is used to display data for that client in the MISight app.
      </Typography>
      {children}
    </Form>
  );
}
