import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ActivationDialog from "./ActivationDialog";

const useStyles = makeStyles((theme) => ({
  root: {},
  deactivateButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.error.light,
  },
  activateButton: {
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.secondary.light,
  },
}));

export default function Activation({ userData, loading, refreshUserFunction }) {
  const classes = useStyles();

  const isActivated = userData.status === "ACTIVE";
  const isIssUser = userData.client === "ISS";

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
    >
      {isActivated
        ? (
          <Button
            variant="contained"
            onClick={() => { setDialogOpen(true); }}
            className={classes.deactivateButton}
            disabled={loading || isIssUser}
          >
            Remove MISight Access
          </Button>
        )
        : (
          <Button
            color="secondary"
            variant="contained"
            onClick={() => { setDialogOpen(true); }}
            className={classes.activateButton}
            disabled={loading || isIssUser}
          >
            Activate User
          </Button>
        )}
      <ActivationDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        userData={userData}
        isActivated={isActivated}
        refreshUserFunction={refreshUserFunction}
      />
    </Box>
  );
}
