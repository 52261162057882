import React from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { useField } from "formik";

export default function LMSSwitch({
  name,
  label,
  formControlProps,
  ...props
}) {
  const [field] = useField(name);

  return (
    <FormControlLabel
      control={(
        <Switch
          checked={field?.value || false}
          {...field}
          {...props}
        />
      )}
      label={label}
      {...formControlProps}
    />
  );
}
