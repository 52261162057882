import React from "react";
import { useSelector } from "react-redux";

import { convertArrayToOptions } from "../../../utils/dataTransformations";

import LMSSelect from "./base/LMSSelect";

export default function LockToCompare({
  studyType,
  required,
  ...props
}) {
  const formOptions = useSelector((state) => state.formOptions);
  const locks = convertArrayToOptions(formOptions[`locks${studyType.toUpperCase()}`]);

  const label = required ? "Lock to Compare *" : "Lock to Compare";
  return (
    <LMSSelect
      name="lock_to_compare"
      options={locks}
      label={label}
      {...props}
    />
  );
}
