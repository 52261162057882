export function uploadFileToS3(file, presignedPost, onProgressUpdate = () => {}, onComplete = () => {}) {
  // build form data
  const formData = new FormData();
  Object.keys(presignedPost.fields).forEach(name => {
    formData.append(name, presignedPost.fields[name]);
  });
  formData.append("file", file, file.name);

  // monitor progress
  const xhr = new XMLHttpRequest();
  xhr.upload.addEventListener("progress", e => {
    if (e.lengthComputable) {
      const progress = (e.loaded / e.total) * 100;
      onProgressUpdate(progress);
    }
  });

  // monitor status
  xhr.upload.addEventListener("load", () => {
    onComplete();
  });

  // make request
  xhr.open("POST", presignedPost.url);
  xhr.send(formData);
}
