export default function flatten(object, prefix = "", delimeter = ".", maxDepth = 10, prev = null, currentDepth = 1) {
  let output = {};

  if (!object) return {};

  Object.keys(object).forEach((key) => {
    const value = object[key];
    const isArray = Array.isArray(value);
    const type = Object.prototype.toString.call(value);
    const isObject = (type === "[object Object]" || type === "[object Array]");

    const newKey = prev ? prev + delimeter + key : key;

    if (!isArray && isObject && Object.keys(value).length && (currentDepth < maxDepth)) {
      output = { ...output, ...flatten(value, prefix, delimeter, maxDepth, newKey, currentDepth + 1) };
    }

    output[prefix + newKey] = value;
  });
  return output;
}
