import React, { useState, useEffect } from "react";
import { useTheme, Button } from "@mui/material";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { qaDashboardStatus } from "../../../utils/api";
import { getStyle } from "../../tasks/dashboard_monitoring/StatusColor";

const jobStatusNotNeeded = new Set(
  ["download_lms_qa_reports"],
);

function JobStatus({ currentView, selectedTab }) {
  const productionCycle = useSelector((state) => state.formOptions.productionCycle);
  const [lastSuccessfulTask, setLastSuccessfulTask] = useState();
  const { data: qAQBITJobStatus } = useSWR(`/api/v1/qa/get_lms_qa_dashboard_reports?production_cycle=${productionCycle[1]}`, qaDashboardStatus, { revalidateOnFocus: false });
  const theme = useTheme();
  const tz = moment().tz(moment.tz.guess()).format("z");
  useEffect(() => {
    if (!jobStatusNotNeeded.has(currentView)) {
      setLastSuccessfulTask(qAQBITJobStatus?.[selectedTab]?.files.filter(job => job.reportType === currentView)[0] || { reportStatus: "NOT_ATTEMPTED", reportType: "NA", updatedAt: null });
    } else {
      setLastSuccessfulTask(null);
    }
  }, [currentView, selectedTab, qAQBITJobStatus]);
  return lastSuccessfulTask && qAQBITJobStatus ? (
    <Button
      component={Link}
      // eslint-disable-next-line no-nested-ternary
      to={lastSuccessfulTask ? (lastSuccessfulTask?.workflowId ? `/details/${lastSuccessfulTask?.workflowId}/${lastSuccessfulTask?.taskId}` : `/details/${lastSuccessfulTask?.taskId}`) : "/status"}
      target="_blank"
      disabled={lastSuccessfulTask?.reportType === "NA"}
      style={{
        marginRight: 10,
        ...getStyle(lastSuccessfulTask?.reportStatus, theme),
        color: "white",
      }}
    >
      {`JOB ${lastSuccessfulTask?.reportStatus.replaceAll("_", " ")} for ${productionCycle[1]}  ${lastSuccessfulTask?.updatedAt ? `: ${moment(moment.parseZone(lastSuccessfulTask?.updatedAt).local()).format("MMMM Do YYYY, h:mm:ss a")} ${tz}` : ""} `}
    </Button>
  ) : null;
}

export default JobStatus;
