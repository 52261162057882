import React, { useRef, useEffect } from "react";
import { isEnumType, isScalarType } from "graphql";

export default function ArgInput({ argValue, argType, setArgValue }) {
  const inputRef = useRef(null);

  useEffect(() => {
    const input = inputRef.current;
    const { activeElement } = document;
    if (
      input
      && activeElement
      && !(activeElement instanceof HTMLTextAreaElement)
    ) {
      input.focus();
    }
  }, []);

  const handleChange = (event) => {
    setArgValue(event, true);
  };

  const value = typeof argValue?.value === "string" ? argValue?.value : "";

  if (!argValue) return null;

  // Variable
  if (argValue?.kind === "Variable") {
    return (
      <span className="graphiql-explorer-input-variable">
        $
        {argValue?.name.value}
      </span>
    );
  }

  // Scalars (Boolean, String, Number)
  if (isScalarType(argType)) {
    // Boolean
    if (argType.name === "Boolean") {
      return (
        <select
          className="graphiql-explorer-input-boolean"
          onChange={setArgValue}
          value={
            argValue?.kind === "BooleanValue" ? argValue?.value : undefined
          }
        >
          <option key="true" value="true">
            true
          </option>
          <option key="false" value="false">
            false
          </option>
        </select>
      );
    }

    // String
    if (argValue?.kind === "StringValue") {
      return (
        <span className="graphiql-explorer-input-string">
          {"\""}
          <input
            ref={inputRef}
            type="text"
            onChange={handleChange}
            value={value}
          />
          {"\""}
        </span>
      );
    }

    // Number
    return (
      <span className="graphiql-explorer-input-number">
        <input
          ref={inputRef}
          type="text"
          onChange={handleChange}
          value={value}
        />
      </span>
    );
  }

  // Enums
  if (isEnumType(argType)) {
    if (argValue?.kind !== "EnumValue") {
      console.error("Expected Enum but received", argType, argValue);
    }
    return (
      <select
        className="graphiql-explorer-input-enum"
        onChange={setArgValue}
        value={argValue?.value}
      >
        {argType.getValues()
          .map(v => (
            <option key={v.name} value={v.name}>
              {v.name}
            </option>
          ))}
      </select>
    );
  }

  return null;
}
