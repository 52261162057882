import React, { createContext, useMemo } from "react";
import { useSelector } from "react-redux";

export const FormContext = createContext();

export const FormContextProvider = ({ clientMnemonic, studyType, children }) => {
  const formOptions = useSelector((state) => state.formOptions);
  const lmsBackendClient = formOptions.lms_backend_clients?.find((c) => c.mnemonic?.toUpperCase() === clientMnemonic?.toUpperCase());

  const value = useMemo(() => {
    const clientId = lmsBackendClient?.id;
    return {
      clientMnemonic,
      clientId,
      studyType,
    };
  }, [clientMnemonic, lmsBackendClient, studyType]);

  return (
    <FormContext.Provider
      value={value}
    >
      {children}
    </FormContext.Provider>
  );
};
