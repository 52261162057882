import React, { useEffect } from "react";
import { Chip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import useFormData from "../../hooks/useFormData";
import BaseForm from "./common/BaseForm";

// "ui:placeholder" doesn't work for the material-ui version...
const uiSchema = {
  lock: {
    "ui:help": "Leave unselected for Live",
  },
};

export default function SmaDataDumpForm({ children, setTaskConfig, submitHandler, formDataProp, displayHeader, setIsRequired }) {
  // Pre-populate form with previous data if available
  const formOptions = useSelector((state) => state.formOptions);
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  const smaDataDumpSchema = {
    title: "SMA Data Dump Form",
    description: "SMA Data Dump",
    type: "object",
    required: [
      "start_quarter",
      "end_quarter",
    ],
    properties: {
      start_quarter: {
        type: "string",
        title: "Start Quarter",
        enum: formOptions.quarters,
      },
      end_quarter: {
        type: "string",
        title: "End Quarter",
        enum: formOptions.quarters,
      },
      lock: {
        type: "string",
        title: "Lock (Data Set)",
        enum: formOptions.locksSMA,
      },
    },
  };

  useEffect(() => {
    if (smaDataDumpSchema.required) {
      setIsRequired((prevState) => ([...prevState, ...smaDataDumpSchema.required]));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsRequired]);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["start_quarter", "end_quarter", "lock"])} />
      </div>
      <BaseForm
        schema={smaDataDumpSchema}
        uiSchema={uiSchema}
        formData={formData}
        submitHandler={submitHandler}
        setFormData={setFormData}
      >
        {children}
      </BaseForm>
    </div>
  );
}
