import React, { useCallback } from "react";
import { Box, Button, FormControlLabel, FormGroup, Switch } from "@mui/material";
import CountDown from "../../../../components/CountDown";
import Table from "../../../../components/Table";
import Filters from "../Filters";
import DetailsColumn from "./columns/DetailsColumn";
import QuickActionsPopup from "./columns/QuickActionsPopup";
import StatusChip from "./columns/StatusChip";
import StudyColumn from "./columns/StudyColumn";
import TimeColumn from "./columns/TimeColumn";

const StatusTable = ({
  data,
  loading,
  pagination,
  setPagination,
  fetchData,
  refreshData,
  fetchQueuedData,
  onQueuePage,
  setOnQueuePage,
  timestampIconDisplay,
  setTimestampIconDisplay,
  applyFilterAndFetchData,
  resetAllFilters,
  preWarmOnly,
  ddTaskOnly,
  nextPaginationKey,
  refreshTime,
  terrClientDescriptions,
}) => {
  const columns = [
    { header: "Study", sorting: true, accessorKey: "study", Cell: useCallback(({ row }) => <StudyColumn rowData={row?.original} />, []) },
    { header: "Task", accessorKey: "details", Cell: useCallback(({ row }) => <DetailsColumn rowData={row?.original} ddTaskOnly={ddTaskOnly} />, [ddTaskOnly]) },
    { header: "Actions", accessorKey: "actions", sorting: false, Cell: useCallback(({ row }) => <QuickActionsPopup rowData={row?.original} />, []) },
    { header: "Client", accessorKey: "client", Cell: useCallback(({ row }) => (row.original.client in terrClientDescriptions ? terrClientDescriptions[row.original.client] : row.original.client), [terrClientDescriptions]) },
    { header: "Status", accessorKey: "status", Cell: useCallback(({ row }) => <StatusChip status={row.original?.status} />, []) },
    {
      header: "Timestamp",
      accessorKey: "createdDate",
      type: "datetime",
      Cell: useCallback(({ row }) => (
        <TimeColumn
          createdTime={row.original.createdDate}
          history={row.original.statusHistory}
          timestampIconDisplay={timestampIconDisplay}
        />
      ), [timestampIconDisplay]),
    },
    {
      header: "User",
      accessorKey: "username",
      Cell: ({ row }) => {
        const capitalize = (name) => name.replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase());
        if (!row.original.username.includes("_")) {
          let [first, last = ""] = row.original.username.split(".");
          first = capitalize(first.split("@")[0]);
          last = capitalize(last.split("@")[0]);
          return `${first} ${last}`;
        }
        return `${row.original.username}`;
      },
    },
  ];

  const handleToggle = (e) => {
    setOnQueuePage(e.target.checked);
    if (e.target.checked) {
      fetchQueuedData();
    } else {
      refreshData();
    }
  };

  return (
    <div>
      <Filters ddTaskOnly={ddTaskOnly} preWarmOnly={preWarmOnly}>
        <Button variant="outlined" color="secondary" onClick={applyFilterAndFetchData}>Apply Filter</Button>
        <Button variant="outlined" color="primary" onClick={resetAllFilters}>Reset Filter</Button>
        <Button variant="outlined" color="primary" onClick={() => setTimestampIconDisplay(!timestampIconDisplay)}>Toggle Timestamp Display</Button>
        {!preWarmOnly && (
          <FormGroup>
            <FormControlLabel
              control={<Switch color="primary" checked={onQueuePage} onChange={handleToggle} />}
              label="Display Queued Jobs"
            />
          </FormGroup>
        )}
      </Filters>
      <Box display="flex" flexDirection="row" justifyContent="right" style={{ marginLeft: "1em", marginRight: "1em" }}>
        <CountDown duration={refreshTime} onFinish={onQueuePage ? fetchQueuedData : refreshData} text="Refreshing in" />
      </Box>
      <Table
        data={data}
        columns={columns}
        onPaginationChange={setPagination}
        state={{ isLoading: loading, pagination }}
        fetchData={() => fetchData(pagination.pageIndex, nextPaginationKey)}
        nextPaginationKey={nextPaginationKey}
      />
    </div>
  );
};

export default StatusTable;
