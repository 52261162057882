/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from "react";
import Checkbox from "./checkbox";

export default function FragmentView({ modifySelections, selections, fragment }) {
  let previousSelection;
  const selection = selections.find(s => s.kind === "FragmentSpread" && s.name.value === fragment.name.value);

  function addFragment() {
    modifySelections([
      ...selections,
      previousSelection || {
        kind: "FragmentSpread",
        name: fragment.name,
      },
    ]);
  }

  function removeFragment() {
    previousSelection = selection;
    modifySelections(
      selections.filter(s => !(s.kind === "FragmentSpread" && s.name.value === fragment.name.value)),
    );
  }

  return (
    <div>
      <span
        style={{ cursor: "pointer" }}
        onClick={selection ? removeFragment : addFragment}
      >
        <Checkbox checked={!!selection} />
        <span style={{ color: "hsl(var(--color-tertiary))" }}>
          {fragment.name.value}
        </span>
      </span>
    </div>
  );
}
