import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import _ from "lodash";

const DO_NOT_LOG_ACTIONS = [];

const DO_NOT_LOG_STATES = [];

export const SentryErrorBoundary = Sentry.ErrorBoundary;

export function sentryInit(history) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_CUSTOM_NODE_ENV,
    normalizeDepth: 10,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
  });
}

export const SentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: action => {
    // Redux actions not to log
    if (DO_NOT_LOG_ACTIONS.includes(action.type)) return null;

    return action;
  },

  stateTransformer: state => {
    // Transform the state to remove sensitive information
    const transformedState = _.cloneDeep(state);
    DO_NOT_LOG_STATES.forEach((path) => {
      _.set(transformedState, path, "FILTERED");
    });

    return transformedState;
  },
});

export function sentrySetUsername(username) {
  Sentry.setUser({ username });
}
