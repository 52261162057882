import React from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useField } from "formik";

export default function LMSRadioGroup({
  name,
  ...props
}) {
  const [field] = useField(name);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{props.label}</FormLabel>
      <RadioGroup
        row
        aria-label={props.label}
        {...field}
        {...props}
        value={field.value || ""}
      >
        {
          props.options.map(
            (option) => {
              const { id, label } = option;
              return (
                <FormControlLabel
                  value={id}
                  control={<Radio />}
                  label={label}
                  key={id}
                />
              );
            },
          )
        }
      </RadioGroup>
    </FormControl>
  );
}
