// TODO: Allow export of second class until we can get baseURL as an overridable param in the base API class
/* eslint-disable max-classes-per-file */

import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import axiosRetry from "axios-retry";

const ALIASES = Object.freeze({
  user_acceptance: "user_acceptance",
  reporting: "reporting",
});

const ALIAS_TYPE = Object.freeze({
  [ALIASES.user_acceptance]: "UAT",
  [ALIASES.reporting]: "PROD",
});

export const baseLMSURL = process.env.REACT_APP_LMS_BACKEND_API
  || `${process.env.REACT_APP_BACKEND_API}/lms`;

async function injectIdToken(config) {
  const session = await fetchAuthSession();
  const modifiedConfig = { ...config };

  if (session?.tokens?.idToken) {
    modifiedConfig.headers.Authorization = session.tokens.idToken;
  }
  return modifiedConfig;
}

const paramsSerializer = {
  indexes: null,
};

// create new axios instance
// baseURL is always the same, so configure once
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
  // Change array serialization to match lambda backend requirement
  paramsSerializer,
});
// Cognito Management
const axiosCognitoInstance = axios.create({
  baseURL: process.env.REACT_APP_COGNITO_BACKEND_API,
  // Change array serialization to match lambda backend requirement
  paramsSerializer,
});
// LMS-Backend
const axiosLMSInstance = axios.create({
  baseURL: baseLMSURL,
  // Change array serialization to match lambda backend requirement
  paramsSerializer,
});

// create request interceptor to change config for every request
[axiosInstance, axiosCognitoInstance, axiosLMSInstance].forEach((instance) => {
  // Add id token
  instance.interceptors.request.use(injectIdToken, (e) => Promise.reject(e));
  // Add snackbars interceptors
  instance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error),
  );
  // Add retry
  axiosRetry(instance, {
    retries: 3,
    shouldResetTimeout: true,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (error) => ![200, 401, 404].includes(error?.response?.status), // Error code not in list
  });
});

export class API {
  static request({
    url,
    method,
    params = {},
    data = {},
    cancelToken,
    options = {},
    axiosConfig = {},
  }) {
    return axiosInstance({
      method,
      url,
      params,
      data,
      cancelToken,
      options,
      ...axiosConfig,
    });
  }
}

export class CognitoAPI {
  static request({
    url,
    method,
    params = {},
    data = {},
    cancelToken,
    options = {},
    axiosConfig = {},
  }) {
    return axiosCognitoInstance({
      method,
      url,
      params,
      data,
      cancelToken,
      options,
      ...axiosConfig,
    });
  }
}

export const graphQlQuery = async (query, version) => {
  const response = await axiosInstance
    .post("/graphql", query, { headers: { "x-schema": `v${version}` } })
    .catch((error) => {
      // Return even if status code is non 200, provided it has an error block
      if (error?.response?.data?.errors) return error.response;
      return error;
    });

  return response?.data;
};

// -------------------------------
// TODO: CLEANUP ALL OF THESE BELOW
// -------------------------------

export const getDataRevisionsAlias = async () => {
  const response = await axiosInstance.get("/datarevisions/alias");
  return response.data.map((item) => ({
    ...item,
    revision: [item.revision_id, ": ", item.revision_name].join(""),
    display_alias: ALIAS_TYPE[item.alias],
  }));
};

export const getDataRevisionsAliasOverride = async () => {
  const response = await axiosInstance.get("/datarevisions/aliasoverride");
  return response.data.map((item) => ({
    ...item,
    revision: [item.revision_id, ": ", item.revision_name].join(""),
  }));
};

export const getDataRevisions = async () => {
  const response = await axiosInstance.get("/datarevisions");
  return response.data.map((item) => ({
    ...item,
    revision: [item.id, ": ", item.revision_name].join(""),
  }));
};

export const updateDataRevisionAlias = async (aliasId, payload) => {
  const response = await axiosInstance.post(
    `/datarevisions/alias/${aliasId}`,
    payload,
  );
  return response.data;
};

export const updateDataRevisionAliasOverride = async (
  overrideId,
  clientId,
  payload,
) => axiosInstance.post(
  `/datarevisions/aliasoverride/${overrideId}/client/${clientId}`,
  payload,
);

export const deleteDataRevisionAliasOverride = async (overrideId, clientId) => axiosInstance.delete(
  `/datarevisions/aliasoverride/${overrideId}/client/${clientId}`,
);

export const createDataRevisionAliasOverride = async (payload) => axiosInstance.post("/datarevisions/aliasoverride", payload);

export const getMisightClients = async () => {
  const response = await axiosInstance.get("/misight/clients");
  return response.data;
};

// lms-backend APIs

export const getQaDashboardReports = async (url) => {
  const response = await axiosInstance.get(url);
  return response.data.files;
};
export const getQaDashboardQuarters = async (url, setReportsQuarter) => {
  const response = await axiosInstance.get(url);
  setReportsQuarter(response.data.quarters[0]);
  return response.data.quarters;
};
export const getClients = async () => {
  const response = await axiosInstance.get("/lms/api/v1/client/client");
  return response.data.sort((c1, c2) => c1.mnemonic.localeCompare(c2.mnemonic));
};

export const getClientProfiles = async (clientId) => {
  const response = await axiosInstance.get(
    `/lms/api/v1/client/${clientId}/profiles`,
  );
  return response.data;
};

export const getClientProfile = async (clientProfileId, study) => {
  const response = await axiosInstance.get(
    `/lms/api/v1/${study}/client/${study}_clientprofile/${clientProfileId}`,
  );
  return response.data;
};

export const deleteClientProfile = async (clientProfileId, study) => {
  const response = await axiosInstance.delete(
    `/lms/api/v1/${study}/client/${study}_clientprofile/${clientProfileId}`,
  );
  return response.data;
};

export const getClientProfileExclusions = async (clientProfileId, study) => {
  const response = await axiosInstance.get(
    `/lms/api/v1/clientprofile/${clientProfileId}/study/${study}`,
  );
  return response.data;
};

export const getSIClientProfileExclusions = async (clientProfileId, study) => {
  const response = await axiosInstance.get(
    `/lms/api/v1/si_clientprofile/${clientProfileId}/study/${study}`,
  );
  return response.data;
};

export const getClientProfileExclusionsAggregated = async (
  clientProfileId,
  study,
) => {
  const response = await axiosInstance.get(
    `/lms/api/v1/clientprofile/${clientProfileId}/study/${study}/exclusions`,
  );
  return response.data;
};

export const getSIClientProfileExclusionsAggregated = async (
  clientProfileId,
  study,
) => {
  const response = await axiosInstance.get(
    `/lms/api/v1/si_clientprofile/${clientProfileId}/study/${study}/exclusions`,
  );
  return response.data;
};

export const getClientProfileInclusionsAggregated = async (
  clientProfileId,
  study,
) => {
  const response = await axiosInstance.get(
    `/lms/api/v1/clientprofile/${clientProfileId}/study/${study}/inclusions`,
  );
  return response.data;
};

export const getSIClientProfileInclusionsAggregated = async (
  clientProfileId,
  study,
) => {
  const response = await axiosInstance.get(
    `/lms/api/v1/si_clientprofile/${clientProfileId}/study/${study}/inclusions`,
  );
  return response.data;
};

export const getTransformStudyFileType = async () => {
  const response = await axiosLMSInstance.get(
    "/api/v1/transform/study_file_type",
  );
  return response.data;
};

export const getCommonTransformMapping = async (id) => {
  const response = await axiosLMSInstance.get(
    `/api/v1/transform/common-transform/${id}`,
  );
  return response.data;
};

export const getAllHeaderTransformLogs = async () => {
  const response = await axiosLMSInstance.get(
    "/api/v1/transform/header_mapping_change_logs",
  );
  return response.data;
};

export const getAllRequiredHeaderTransformLogs = async () => {
  const response = await axiosLMSInstance.get(
    "/api/v1/transform/is_required_change_logs",
  );
  return response.data;
};
export const getCommonHeaderTransformById = async (id) => {
  const response = await axiosLMSInstance.get(
    `/api/v1/transform/common-header-transform/${id}`,
  );
  return response.data;
};

export const getAllCommonTransform = async () => {
  const response = await axiosLMSInstance.get(
    "/api/v1/transform/common_transform_file_mapping",
  );
  return response.data;
};
export const postCommonTransform = async (payload) => {
  const response = await axiosLMSInstance.post(
    "/api/v1/transform/common_transform_file_mapping",
    payload,
  );
  return response.data;
};

export const deleteCommonTransFormById = async (itemId) => {
  const response = await axiosLMSInstance.delete(
    `/api/v1/transform/common_transform_file_mapping/${itemId}`,
  );
  return response.data;
};

export const getAllCommonHeaderMapping = async () => {
  const response = await axiosLMSInstance.get(
    "/api/v1/transform/common_header_mapping",
  );
  return response.data;
};
export const postCommonHeader = async (payload) => {
  const response = await axiosLMSInstance.post(
    "/api/v1/transform/add_common_header_mapping",
    payload,
  );
  return response.data;
};

export const deleteCommonHeaderById = async (itemId) => {
  const response = await axiosLMSInstance.delete(
    `/api/v1/transform/delete_common_header_mapping/${itemId}`,
  );
  return response.data;
};

export const getAllHeaderTransform = async (studyType, fileType, clientId) => {
  const response = await axiosLMSInstance.get(
    `/api/v1/transform/all-header-transform/${studyType}/${fileType}/${clientId}`,
  );
  return response.data;
};

export const getPreprocessListById = async (clientId) => {
  if (clientId) {
    return axiosLMSInstance.get(
      `/api/v1/transform/preprocessing-files-list/${clientId}`,
    );
  }
  return [];
};

export const postPreprocessingFiles = async (payload) => {
  const response = await axiosLMSInstance.post(
    "/api/v1/transform/preprocessing_file",
    payload,
  );
  return response.data;
};

export const deletePreprocessingFiles = async (id) => {
  const response = await axiosLMSInstance.delete(
    `/api/v1/transform/preprocessing_file/${id}`,
  );
  return response.data;
};

export const getClientSpecificTransform = async (id) => {
  const response = await axiosLMSInstance.get(
    `/api/v1/transform/client-specific-transform/${id}`,
  );
  return response.data;
};

export const postClientSpecificMapping = async (payload) => {
  const response = await axiosLMSInstance.post(
    "/api/v1/transform/add_client_specific_header_mapping",
    payload,
  );
  return response.data;
};

export const deleteClientSpecificMapping = async (id) => {
  const response = await axiosLMSInstance.delete(
    `/api/v1/transform/delete_client_specific_header_mapping/${id}`,
  );
  return response.data;
};

export const editClientMapping = async (id, payload) => {
  const response = await axiosLMSInstance
    .put(
      `/api/v1/transform/update_client_specific_header_mapping/${id}`,
      payload,
    )
    .catch((error) => console.log(error));
  return response;
};

export const getStandardHeaders = async (studyType) => {
  const response = await axiosLMSInstance.get(
    `/api/v1/transform/standard-header/${studyType}`,
  );
  return response.data;
};

export const editCommonHeaderMapping = async (id, payload) => {
  const response = await axiosLMSInstance.put(
    `/api/v1/transform/update_common_header_mapping/${id}`,
    payload,
  );
  return response;
};
export const generateTableCallbacks = (
  url,
  foreignKey,
  foreignId,
  tabledata,
  callBack,
  setLoading,
  level,
) => {
  const actions = {};
  let foreignKeyParam = null;
  let parsedUrl = url;

  if (url.includes("{")) {
    const foreignKeyStart = url.indexOf("{");
    const foreignKeyEnd = url.indexOf("}");
    foreignKeyParam = url.slice(foreignKeyStart + 1, foreignKeyEnd);
    parsedUrl = {
      start: url.slice(0, foreignKeyStart),
      end: url.slice(foreignKeyEnd + 1),
    };
  }

  const isDuplicateRecord = (newData) => (level === "office"
    ? tabledata.some(
      (data) => data.dd_physical_branch_id === newData.dd_physical_branch_id,
    )
    : tabledata.some(
      (data) => data.firm_crd === newData.firm_crd
            && data.zip_code === newData.zip_code,
    ));

  const buildDeleteUrl = (oldData) => {
    if (level === "broker" && oldData.zip_code) {
      return `${url}zip/${oldData?.ids[0]}`;
    } if (level === "broker") {
      return `${url}/${oldData?.ids[0]}`;
    }
    return foreignKeyParam === null
      ? `${url}/${oldData?.id}`
      : `${parsedUrl.start}${oldData?.id}${parsedUrl.end}`;
  };

  const buildAddUrl = (newData) => {
    if (level === "broker" && newData.zip_code) {
      return `${url}zip`;
    }
    return foreignKeyParam === null
      ? url
      : `${parsedUrl.start}1${parsedUrl.end}`;
  };

  actions.onRowUpdate = async (newData, oldData, table) => {
    setLoading(true);
    const isDuplicate = isDuplicateRecord(newData);

    if (isDuplicate) {
      callBack(false, {
        response: {
          data: "Duplicate data: Another row with same values exists",
        },
      });
      setLoading(false);
      table.setEditingRow(null);
      table.setEditingCell(null);
      return;
    }

    try {
      const deleteUrl = buildDeleteUrl(oldData);
      await axiosInstance.delete(deleteUrl);

      const addUrl = buildAddUrl(newData);
      const response = await axiosInstance.post(addUrl, {
        ...newData,
        [foreignKey]: foreignId,
      });

      if (response && response.data) {
        callBack(true, response.data);
      } else {
        throw new Error("No data in response");
      }
    } catch (error) {
      console.error("Error updating row: ", error);
      callBack(false, error);
    } finally {
      table.setEditingRow(null);
      table.setEditingCell(null);
      setLoading(false);
    }
  };

  // Row delete action
  actions.onRowDelete = async (oldData) => {
    setLoading(true);
    const finalUrl = buildDeleteUrl(oldData);

    try {
      const response = await axiosInstance.delete(finalUrl, oldData);
      callBack(true, response);
    } catch (error) {
      console.error("Error deleting row: ", error);
      callBack(false, error);
    } finally {
      setLoading(false);
    }
  };

  actions.onRowAdd = async (newData) => {
    setLoading(true);
    const finalUrl = buildAddUrl(newData);

    try {
      const response = await axiosInstance.post(finalUrl, {
        ...newData,
        [foreignKey]: foreignId,
      });
      callBack(true, response);
    } catch (error) {
      console.error("Error adding row: ", error);
      callBack(false, error);
    } finally {
      setLoading(false);
    }
  };

  return actions;
};

export const qaDashboardStatus = async (url) => {
  if (url) {
    const result = {};
    const responseLmsQaDashboard = await axiosLMSInstance.request({
      url,
      params: { qa_type: "reporting_ann_lms_qa_dashboard", method: "GET" },
    });
    result.reporting_ann_lms_qa_dashboard = responseLmsQaDashboard.data;
    const responseMisightQaDashboard = await axiosLMSInstance.request({
      url,
      params: { qa_type: "reporting_ann_misight_qa_dashboard" },
      method: "GET",
    });
    result.reporting_ann_misight_qa_dashboard = responseMisightQaDashboard.data;
    return result;
  }
  return {};
};

// eslint-disable-next-line arrow-body-style
export const getClientTemplates = async (clientId, study) => {
  const response = await axiosInstance.get(
    `/lms/api/v1/reportinventory/${clientId}/${study}/templates/`,
  );
  return response.data;
};

export const updateClientTemplates = async (
  clientId,
  study,
  templateId,
  payload,
) => {
  const response = await axiosInstance.post(
    `/lms/api/v1/reportinventory/${clientId}/${study}/templates/${templateId}`,
    payload,
  );
  return response.data;
};

export const createClientTemplates = async (clientId, study, payload) => {
  const response = await axiosInstance.post(
    `/lms/api/v1/reportinventory/${clientId}/${study}/templates/`,
    payload,
  );
  return response.data;
};

export const deleteClientTemplates = async (clientId, study, templateId) => {
  const response = await axiosInstance.delete(
    `/lms/api/v1/reportinventory/${clientId}/${study}/templates/${templateId}`,
  );
  return response.data;
};

export const getFilteredAnnFirmNames = async (clientId) => {
  const response = await axiosInstance.get(`lms/api/v1/firmnames/${clientId}`);
  return response.data;
};

export const getFinalFirmNames = async () => {
  const response = await axiosInstance.get(
    "lms/api/v1/firmnames/final_firmnames",
  );
  return response.data;
};

export const updateAnnFirmNames = async (rowId, payload) => {
  const response = await axiosInstance
    .put(`lms/api/v1/ann/firmnamechange/ann_firmname/${rowId}`, payload)
    .catch((error) => error);
  return response;
};

export const addAnnFirm = async (payload) => {
  const response = await axiosInstance
    .post("lms/api/v1/ann/firmnamechange/ann_firmname", payload)
    .catch((error) => error);
  return response;
};

export const deleteMultipleAnnFirm = (payload) => API.request({
  url: `${process.env.REACT_APP_BACKEND_API}/lms/api/v1/firmnames/delete_multiple_ann_firmnames`,
  method: "DELETE",
  data: payload,
});

export const getClientDomainWhitelist = async () => {
  const response = await axiosInstance.get(
    `${baseLMSURL}/api/v1/client/clientdomainwhitelist`,
  );
  return response.data.map((item) => ({
    ...item,
    revision: [item.revision_id, ": ", item.revision_name].join(""),
  }));
};
