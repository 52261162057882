/* eslint-disable jsx-a11y/anchor-is-valid */
import "graphiql/graphiql.css";

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useTheme, ToolbarButton } from "@graphiql/react";
import { GraphiQL } from "graphiql";
import usePageTitle from "../../../hooks/usePageTitle";
import { useExplorerPlugin } from "../../../lib/graphi-explorer";
import { graphQlQuery } from "../../../utils/api";
import FileDownloader from "./fileDownloader";

const DEFAULT_QUERY = `{
  version {
    sha
    ref
    date
  }
}`;

const styles = {
  container: {
    position: "absolute",
    top: 65,
    right: 0,
    left: 74,
    bottom: 0,
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FFF",
  },
};

export default function GraphiQl() {
  usePageTitle("MI API");

  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme("light");
  }, [setTheme]);

  const [query, setQuery] = useState(null);
  const [lastResult, setLastResult] = useState(null);
  const [endpointVersion, setEndpointVersion] = useState(2);
  const otherVersion = endpointVersion === 2 ? 1 : 2;

  const runQuery = useCallback((q) => graphQlQuery(q, endpointVersion).then((result) => {
    setLastResult(result);
    return result;
  }), [endpointVersion]);

  const explorerPlugin = useExplorerPlugin({
    query,
    onEdit: setQuery,
    explorerIsOpen: true,
    title: "",
    hideActions: true,
  });
  const versionSwapper = useMemo(() => (
    <ToolbarButton
      onClick={() => { setEndpointVersion(otherVersion); }}
      label={`Swap to v${otherVersion}`}
    >
      <p className="graphiql-toolbar-icon">{`v${endpointVersion}`}</p>
    </ToolbarButton>
  ), [endpointVersion, otherVersion]);

  const downloadAction = <FileDownloader lastResult={lastResult} />;

  return (
    <div style={styles.container}>

      <GraphiQL
        fetcher={runQuery}
        defaultQuery={DEFAULT_QUERY}
        query={query}
        onEditQuery={setQuery}
        toolbar={{ additionalContent: [versionSwapper, downloadAction] }}
        plugins={[explorerPlugin]}
        visiblePlugin="GraphiQL Explorer"
        isHeadersEditorEnabled={false}
        showPersistHeadersSettings={false}
        shouldPersistHeaders={false}
      >
        <GraphiQL.Footer>
          <span>{`MI API V${endpointVersion}`}</span>
        </GraphiQL.Footer>
        <GraphiQL.Logo>{" "}</GraphiQL.Logo>
      </GraphiQL>
    </div>
  );
}
