import React from "react";

import useSWR from "swr";

import usePageTitle from "../../../hooks/usePageTitle";
import { fetcher, customRetry } from "../../../utils/swr";
import UsersTable from "./UsersTable";
import CreateUser from "./UsersTable/CreateUser";

export default function APITokens() {
  const { data, mutate, isValidating } = useSWR("/users?pool=okta_api", fetcher, customRetry);

  usePageTitle("API Users");

  return (
    <>
      <UsersTable
        users={data?.users}
        refreshUsers={mutate}
        loading={isValidating}
        title="API Tokens"
        isAPIUser
      />
      <CreateUser isAPIUser />
    </>
  );
}
