/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from "react";
import useSWR from "swr";
import MaterialTable from "../../../components/Table";
import { getDataRevisionsAlias, getDataRevisions, updateDataRevisionAlias } from "../../../utils/api";

export default function DataRevisionAlias() {
  const { data: datarevisionsAlias, error: datarevisionsAliasError, mutate } = useSWR("/datarevisions/alias", getDataRevisionsAlias);
  const { data: datarevisions, error: datarevisionsError } = useSWR("/datarevisions", getDataRevisions);

  const [loading, setLoading] = useState(false);

  if (datarevisionsAliasError || datarevisionsError) return <div>Error loading...</div>;

  const columns = [
    {
      header: "Alias",
      accessorKey: "display_alias",
      enableEditing: false,
    },
    {
      header: "Revision",
      accessorKey: "revision",
      editVariant: "select",
      editSelectOptions: datarevisions?.map((item) => item.revision),
    },
    {
      header: "Notes",
      accessorKey: "notes",
    },
    {
      header: "Updated By",
      accessorKey: "updated_by",
      enableEditing: false,
    },
    {
      header: "Updated At",
      accessorKey: "updated_at",
      type: "datetime",
      enableEditing: false,
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <MaterialTable
        isLoading={!datarevisionsAlias || !datarevisions || loading}
        columns={columns}
        data={datarevisionsAlias ?? []}
        title="Data Revision Alias"
        options={{
          search: false,
          paging: false,
        }}
        editable={{
          onRowUpdate: async (newData, oldData) => {
            setLoading(true);
            const newObj = datarevisions.find((datarev) => newData.revision === datarev.revision);
            // update data revision alias
            await updateDataRevisionAlias(oldData.id, { dataset_revision_id: newObj.id, notes: newData.notes }).finally(() => setLoading(false));
            // refetch and update UI
            mutate();
          },
        }}
      />
    </div>
  );
}
