import React from "react";

import ReportSection, { ReportSchema } from "../sections/ReportSection";
import AnnTypesSection, { AnnTypesSchema, AnnTypesInitialValues } from "./AnnTypesSection";

export const AnnReportSchema = {
  ...ReportSchema,
  ...AnnTypesSchema,
};

export const AnnReportInitialValues = {
  ...AnnTypesInitialValues,
};

export default function AnnReportSection({ isDD = false }) {
  return (
    <>
      <ReportSection isDD={isDD} />
      <AnnTypesSection />
    </>
  );
}
