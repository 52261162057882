import React, { useCallback, useContext, useState } from "react";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { Formik } from "formik";
import * as Yup from "yup";

import FormActions from "../../../components/FormActions";
import FormAlerts from "../../../components/FormAlerts";
import { API } from "../../../utils/api";
import { studyTypes } from "../../tasks/create_wizard/TaskMapping";
import { ClientProfileContext } from "./ClientProfileContext";
import PrimaryInfo, { PrimaryInfoSchema } from "./PrimaryInfo";

export const Schema = Yup.object().shape({
  ...PrimaryInfoSchema,
});

export default function PrimaryInfoCard({
  profileID,
  clientID,
  clientMnemonic,
  fullName,
  shortName,
  consortiumExclusions,
  clientExclusions,
  allowlistOnly,
  fiaAllowlistOnly,
  vaAllowlistOnly,
  territoryMap,
  channelMap,
  mutate,
}) {
  const { study } = useContext(ClientProfileContext);
  const studyInfo = studyTypes.find((o) => o.id === study);

  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [editing, setEditing] = useState(false);

  const handleSave = useCallback((profileData) => {
    setSaving(true);
    setError(null);
    setSuccess(null);
    return API.request({ url: `/lms/api/v1/${study}/client/${study}_clientprofile/${profileID}`, method: "PUT", data: profileData })
      .then(
        () => {
          setSuccess("-- Profile Successfully edited! --");
          setSaving(false);
          mutate();
        },
        (e) => {
          setError(JSON.stringify(e.response.data));
          setSaving(false);
        },
      );
  }, [profileID, study, mutate]);

  return (
    <Formik
      initialValues={{
        study,
        client_id: clientID,
        full_name: fullName,
        short_name: shortName,
        use_consortium_exclusions: consortiumExclusions,
        use_client_exclusions: clientExclusions,
        use_allowlist_only: allowlistOnly,
        use_fia_allowlist_only: fiaAllowlistOnly,
        use_va_allowlist_only: vaAllowlistOnly,
        territory_map: territoryMap,
        channel_map: channelMap,
        include_assets_bd: false,
        include_assets_ria: false,
        include_ria_fidelity: false,
      }}
      validationSchema={Schema}
      enableReinitialize
    >
      {
        (formik) => (
          <Card>
            {clientMnemonic
              ? <CardHeader title={`${studyInfo.label} Profile for ${clientMnemonic?.toUpperCase()}`} />
              : <Skeleton style={{ width: "30%", margin: "0.5em" }} />}
            <Divider />
            <CardContent>
              <PrimaryInfo clientMnemonic={clientMnemonic} disabled={!editing} study={study} />
            </CardContent>
            <Divider />
            <FormAlerts
              saving={saving}
              success={success}
              error={error}
              setSuccess={setSuccess}
              setError={setError}
            />
            <FormActions
              editing={editing}
              setEditing={setEditing}
              handleCancel={() => formik.resetForm()}
              handleSave={() => handleSave(formik.values)}
              isValid={formik.touched && (!formik.errors || Object.keys(formik.errors).length === 0)}
            />
          </Card>
        )
      }
    </Formik>
  );
}
