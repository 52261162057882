import React, { useEffect, useState } from "react";
import { Close, Done, Edit, Info } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Switch, Tab, Tabs, TextField, Tooltip, Autocomplete } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { API } from "../../utils/api";
import "../../theme/agGridThemes/statusTableStyle.css";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function UpdatePreprocessingHeaderMapping({ headerLookup, taskConfig, setTaskConfig, openEditDialog, setOpenEditDialog, setTableLoading, finalMapping, studyType, clientId, preprocessingFileType, checkRequiredMappingsPresent, checkDuplicateMappingsPresent }) {
  const [saveHeaderMappingSnackbar, setSaveHeaderMappingSnackbar] = useState(false);
  const [selectedTab, setSelectedTab] = useState("clientSpecific");
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [failedSnackbar, setFailedSnackbar] = useState(false);
  const [commonMappingData, setCommonMappingData] = useState([...finalMapping.filter(mapping => mapping.mapping_type === "common_header_mapping")]);
  const [mappingData, setMappingData] = useState([...finalMapping.filter(mapping => mapping.mapping_type !== "common_header_mapping")]);
  const defaultColDef = { sortable: true, editable: true, flex: 1, filter: true, floatingFilter: true };

  useEffect(() => {
    setMappingData([...finalMapping.filter(mapping => mapping.mapping_type !== "common_header_mapping")]);
    setCommonMappingData([...finalMapping.filter(mapping => mapping.mapping_type === "common_header_mapping")]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openEditDialog]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const SnackbarStatus = (status, message, state, setState) => (
    <Snackbar
      open={state}
      autoHideDuration={4000}
      onClose={() => { setState(false); }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <div>
        <Alert severity={status}>
          { message }
        </Alert>
      </div>
    </Snackbar>
  );

  const dropdownCellRenderer = (props) => {
    const handleClick = () => {
      props.api.startEditingCell({
        rowIndex: props.rowIndex,
        colKey: props.column.getId(),
      });
    };
    return (
      <span>
        { (props.data.is_required !== true) && (
          <Button style={{ height: "30px" }} onClick={handleClick}>
            <Edit />
          </Button>
        )}
        <span style={{ paddingLeft: "4px" }}>{props.value}</span>
      </span>
    );
  };

  const handleSaveHeaderMappingClick = () => {
    const temporaryMapping = [];
    const permanentMapping = [];
    setOpenEditDialog(false);
    // eslint-disable-next-line camelcase
    const { temporary_mapping, ...otherItems } = taskConfig;
    setTaskConfig(otherItems);

    const headerMapping = [...commonMappingData, ...mappingData].map(mapping => {
      const freshMapping = { ...mapping, isDuplicate: undefined };
      if (mapping.preview_header !== mapping.header_transform) {
        if (mapping.is_permanent === false) {
          temporaryMapping.push({
            header_name: mapping.preview_header,
            standard_header_name: mapping.header_transform,
            preprocessing_file_type: mapping.preprocessing_file_type,
            is_permanent: mapping.is_permanent,
          });
        } else if (mapping.is_permanent === true && mapping.mapping_type !== "common_header_mapping") {
          permanentMapping.push({
            is_required: mapping.is_required || false,
            header_name: mapping.preview_header,
            standard_header_name: mapping.header_transform,
            preprocessing_file_type: mapping.preprocessing_file_type,
          });
        }
      }
      return freshMapping;
    });

    const saveTemporaryMapping = new Promise((resolve, reject) => {
      if (temporaryMapping.length > 0) {
        if (setTaskConfig === undefined) {
          setTimeout(() => {
            reject();
          }, 0);
        }
        setTaskConfig(prevTaskConfig => ({ ...prevTaskConfig, temporary_mapping: temporaryMapping }));
      }
      setTimeout(() => {
        resolve();
      }, 0);
    });

    const savePermanentMapping = new Promise((resolve, reject) => {
      if (permanentMapping.length > 0) {
        API.request({
          url: "/lms/api/v1/transform/set-client-specific-transform",
          method: "POST",
          data: {
            study: studyType?.toUpperCase(),
            file_type: taskConfig.file_type?.toUpperCase(),
            client_id: clientId,
            preprocessing_file_type: preprocessingFileType || null,
            header_mappings: permanentMapping,
          },
        }).then(response => {
          if (response.status === 204) {
            setTimeout(() => {
              resolve();
            }, 0);
            setOpenEditDialog(false);
          }
        }).catch((error) => {
          if (error.response?.status === 404) {
            setFailedSnackbar(true);
            setOpenEditDialog(false);
          } else if (error.response?.status === 500) {
            setErrorSnackbar(true);
            setTableLoading(false);
          }
          setTimeout(() => {
            reject();
          }, 0);
        });
      } else {
        setTimeout(() => {
          resolve();
        }, 0);
      }
    });

    setTableLoading(true);
    Promise.all([savePermanentMapping, saveTemporaryMapping]).then(() => {
      setTaskConfig(prevConfig => ({ ...prevConfig, use_v2: true }));
      checkDuplicateMappingsPresent(headerMapping);
      checkRequiredMappingsPresent(headerMapping);
      setTimeout(() => {
        setSaveHeaderMappingSnackbar(true);
        setTableLoading(false);
      }, 0);
    }).catch(() => {
      setTableLoading(false);
      setErrorSnackbar(true);
    });
  };

  const handleOptionSelect = (event, newValue, rowIndex, state, setState) => {
    if (newValue?.label !== undefined) {
      const updatedRowData = [...state];
      updatedRowData[rowIndex].header_transform = newValue.label;
      updatedRowData[rowIndex].isDuplicate = undefined;
      if (updatedRowData[rowIndex].mapping_type === "common_header_mapping") {
        updatedRowData[rowIndex].is_permanent = false;
      }
      setState([...updatedRowData]);
    }
  };

  const columns = [
    { headerName: "Preview Header", field: "preview_header", editable: false, cellStyle: { fontSize: "18px", display: "flex", alignItems: "center" } },
    {
      headerName: "Header Transform",
      field: "header_transform",
      editable: true,
      minWidth: 300,
      cellStyle: { fontSize: "18px", width: 320, display: "flex", alignItems: "center" },
      cellRenderer: (props) => dropdownCellRenderer(props),
      // eslint-disable-next-line react/no-unstable-nested-components
      cellEditor: (props) => (
        <Autocomplete
          freeSolo
          open
          onChange={(event, newValue) => (
            props.data.mapping_type !== "common_header_mapping"
              ? handleOptionSelect(event, newValue, props.rowIndex, mappingData, setMappingData)
              : handleOptionSelect(event, newValue, props.rowIndex, commonMappingData, setCommonMappingData)
          )}
          options={[{ id: props.data.preview_header, label: props.data.preview_header.toString().toUpperCase() }, ...headerLookup]}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Standard Headers"
              variant="outlined"
              margin="normal"
            />
          )}
          getOptionLabel={(option) => {
            if (option.label !== undefined) return option.label;
            return "";
          }}
          value={props.value}
          style={{ width: 300, paddingLeft: "10px" }}
        />
      ),
    },
    {
      headerName: "Is Required",
      field: "is_required",
      editable: true,
      floatingFilter: false,
      cellStyle: { display: "flex", alignItems: "center" },
      // eslint-disable-next-line react/no-unstable-nested-components, no-nested-ternary
      cellRenderer: (props) => (props.data.is_permanent === true ? (props.data.is_required === true ? <Done /> : <Close />) : ""),
    },
    {
      headerName: "Permanent Mapping",
      field: "is_permanent",
      editable: true,
      cellStyle: { display: "flex", alignItems: "center" },
      floatingFilter: false,
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: (props) => ((props.data.mapping_type !== "common_header_mapping")
        ? (
          <Switch
            color="primary"
            checked={props.value || false}
            onChange={e => {
              const updatedRowData = [...mappingData];
              updatedRowData[props.rowIndex].is_permanent = e.target.checked;
              if (updatedRowData[props.rowIndex].is_required === undefined) {
                updatedRowData[props.rowIndex].is_required = false;
              }
              setMappingData(updatedRowData);
            }}
          />
        ) : <Tooltip title="This is Common Header Mapping. Changes to this mapping is always temporary."><Info /></Tooltip>),
    },
  ];

  return (
    <div>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        maxWidth=""
        style={{ overflowX: "visible" }}
        fullWidth
      >
        <DialogTitle>Edit Header Mapping</DialogTitle>
        <DialogContent>
          <div>
            <Box style={{ width: "100%", marginBottom: "15px" }}>
              <Tabs
                value={selectedTab}
                onChange={(e, tabIndex) => { setSelectedTab(tabIndex); }}
                textColor="secondary"
                indicatorColor="secondary"
              >
                <Tab value="clientSpecific" label="Client Specific Mapping" />
                <Tab value="commonHeader" label="Common Header Mapping" />
              </Tabs>
            </Box>
            <Box style={{ width: "100%", marginLeft: 10 }}>
              <div className="ag-theme-alpine ag-theme-status-table" style={{ height: "600px" }}>
                <AgGridReact
                  columnDefs={columns}
                  gridOptions={{ rowHeight: 80 }}
                  rowData={selectedTab === "clientSpecific" ? mappingData : commonMappingData}
                  defaultColDef={defaultColDef}
                  enableCellEditing
                  suppressClickEdit
                />
              </div>
            </Box>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => handleSaveHeaderMappingClick()} style={{ float: "right" }}>
            Save
          </Button>
          <Button variant="outlined" onClick={() => setOpenEditDialog(false)} style={{ color: "red" }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {SnackbarStatus("success", "Header Mapping Saved Successfully!", saveHeaderMappingSnackbar, setSaveHeaderMappingSnackbar)}
      {SnackbarStatus("error", "Save Header Mapping Failed!", errorSnackbar, setErrorSnackbar)}
      {SnackbarStatus("error", "Failed. Preprocessing File Type Doesnot Exists!", failedSnackbar, setFailedSnackbar)}
    </div>
  );
}
export default UpdatePreprocessingHeaderMapping;
