import React, { useEffect } from "react";
import { FormControl, InputAdornment, TextField, Typography } from "@mui/material";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import CSVUpload from "../../components/input/CSVUpload";
import OptionSelect from "../../components/input/OptionSelect";
import { fileType } from "./common/BaseForm";

const insertTypes = [
  { id: "supplement", label: "Supplement" },
  { id: "replacement", label: "Replacement" },
];
export default function LoadZoneMapForms({ children, taskConfig, setTaskConfig, client, csv, setCsv, submitHandler, studyType, setIsRequired }) {
  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType, file_type: fileType.SECONDARY_FILES }));
    setIsRequired((prevState) => ([...prevState, "zone_map_name", "insertion_type"]));
  }, [setTaskConfig, studyType, setIsRequired]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      validator={validator}
    >
      <Typography variant="h6">
        {studyType.toUpperCase()}
        {" Load Zone Map "}
      </Typography>
      <CSVUpload
        onChange={(update) => {
          setCsv({ attachment: update.csv });
        }}
        value={{ csv, ...taskConfig }}
      />
      <FormControl fullWidth margin="normal">
        <TextField
          style={{ width: 500, marginBottom: -10 }}
          label="Custom Client (Add suffix)"
          value={taskConfig.client_suffix || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, client_suffix: e.target.value }));
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">{client.concat("_")}</InputAdornment>,
          }}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField
          required
          style={{ width: 500, marginBottom: -25 }}
          label="Zone Map Name"
          value={taskConfig.zone_map_name || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, zone_map_name: e.target.value }));
          }}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <OptionSelect
          required
          style={{ width: 500, marginBottom: -10 }}
          value={taskConfig.insertion_type || ""}
          onChange={(insertionType) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, insertion_type: insertionType }));
          }}
          options={insertTypes}
          label="Insertion Type"
        />
      </FormControl>
      {children}
    </Form>
  );
}
