import React, { useCallback, useState } from "react";
import { NavigateNext as NavigateNextIcon, NavigateBefore as NavigateBeforeIcon } from "@mui/icons-material";
import { Button, Box, IconButton, Typography } from "@mui/material";

import TabPanel from "../../../../components/TabPanel";
import SearchBar from "../../../../components/input/SearchBar";
import usePageTitle from "../../../../hooks/usePageTitle";
import MisightProfileList from "./MisightProfileList";

export default function MisightClientProfileGroupingConfig({ isDD = false }) {
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState("");

  const handleSearch = useCallback((val) => {
    setSearch(val);
  }, []);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  usePageTitle("Misight Profiles");

  return (
    <Box sx={{ width: "100%" }}>
      <Box justifyContent="center" sx={{ width: "100%", marginBottom: 5, marginLeft: 2 }}>
        <Button
          variant={value === 0 ? "contained" : "outlined"}
          onClick={() => setValue(0)}
          sx={{ mr: 2 }}
        >
          ANN
        </Button>
        <Button
          variant={value === 1 ? "contained" : "outlined"}
          onClick={() => setValue(1)}
          sx={{ mr: 2 }}
        >
          MF
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <SearchBar
          onChange={handleSearch}
          value={search}
          name="misight-profile-list"
          formControlProps={{ style: { width: "50%" } }}
        />
        <Box
          display="flex"
          style={{ marginRight: 10 }}
        >
          <IconButton
            onClick={() => setPage(page - 1)}
            disabled={page === 0}
            style={{ marginBottom: 15 }}
            size="large"
          >
            <NavigateBeforeIcon />
          </IconButton>
          <Typography color="primary" style={{ marginTop: 20 }}>
            {`Page ${page + 1} of ${Math.ceil(count / 8)}`}
          </Typography>
          <IconButton
            onClick={() => setPage(page + 1)}
            disabled={page >= Math.ceil(count / 8) - 1}
            style={{ marginBottom: 15 }}
            size="large"
          >
            <NavigateNextIcon />
          </IconButton>
        </Box>
      </Box>
      <div style={{ marginTop: 80 }}>
        <TabPanel value={value} index={0}>
          <MisightProfileList study="ann" search={search} page={page} setCount={setCount} setPage={setPage} isDD={isDD} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MisightProfileList study="mf" search={search} page={page} setCount={setCount} setPage={setPage} />
        </TabPanel>

      </div>
    </Box>
  );
}
