export const authConfig = {
  Cognito: {
    userPoolClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    signUpVerificationMethod: "code",
    allowGuestAccess: false,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    mandatorySignIn: true,
    authenticationFlowType: process.env.REACT_APP_COGNITO_AUTHENTICATION_FLOW_TYPE,
    loginWith: {
      oauth: {
        scopes: ["openid", "profile", "email"],
        identityProvider: process.env.REACT_APP_COGNITO_OAUTH_IDENTITY_PROVIDER,
        domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
        redirectSignIn: [process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNIN, process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNIN],
        redirectSignOut: [process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNOUT, process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNOUT],
        responseType: "code",
      },
    },
  },
};
