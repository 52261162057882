import { useEffect } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import { useDispatch } from "react-redux";
import { removeGroup, setPrivilege } from "../state";

export default function usePrivilege() {
  const dispatch = useDispatch();

  useEffect(() => {
    async function loadPrivilege() {
      try {
        const session = await fetchAuthSession({ forceRefresh: true });
        const cognitoGroups = session?.tokens?.accessToken?.payload["cognito:groups"].filter((group) => !group.endsWith("ISS-Okta-Internal"));
        if (localStorage.getItem("groups")) {
          const storedGroups = JSON.parse(localStorage.getItem("groups"));
          storedGroups.forEach((group) => {
            if (group !== "base" && !cognitoGroups.includes(group)) {
              dispatch(removeGroup(group));
            }
          });
        }
        localStorage.setItem("cognitoGroups", JSON.stringify(cognitoGroups ?? []));
        dispatch(setPrivilege({
          cognitoGroups,
        }));
      } catch (e) {
        console.log(e);
      }
    }
    loadPrivilege();
  }, [dispatch]);
}
