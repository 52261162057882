import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CardHeader, Divider } from "@mui/material";
import Alert from "@mui/material/Alert";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

import { API } from "../../../../utils/api";
import UserForm from "../UsersTable/UserForm";

const useStyles = makeStyles((theme) => ({
  root: {},
  cancelButton: {
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.primary.light,
  },
  confirmButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.secondary.light,
  },
  alerts: {
    maxHeight: 100,
    maxWidth: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    overflowWrap: "break-word",
  },
}));

export default function AccountDetails({ className, userData, setUserData, misightUserData, loading, isAPIUser, ...rest }) {
  const classes = useStyles();

  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [editing, setEditing] = useState(false);

  // Separate state so that the user can cancel an edit
  const [editableUserData, setEditableUserData] = useState({});

  const isIssUser = userData.client === "ISS";

  const handleSave = () => {
    setSaving(true);
    setSuccess("");
    setError("");
    return API.request({ url: `users/${encodeURIComponent(userData.id)}`, data: editableUserData, method: "POST" })
      .then((response) => {
        setSuccess(prevMsg => prevMsg.concat(`-- User ${userData.email} successfully updated --`));
        setUserData(response.data);
        setSaving(false);
        setEditing(false);
      }, (e) => {
        setError(e.response.data);
        setSaving(false);
      });
  };

  useEffect(() => {
    setEditableUserData(userData);
  }, [userData]);

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader title="Profile" />
        <Divider />
        {!loading && (
          <CardContent>
            <UserForm
              userData={editableUserData}
              setUserData={setEditableUserData}
              disabled={!editing}
              isAPIUser={isAPIUser}
            />
          </CardContent>
        )}
        <Divider />
        {saving && <div><Alert severity="info" className={classes.alerts}>Updating profile...</Alert></div>}
        {success && <div><Alert severity="success" className={classes.alerts}>{`${success}`}</Alert></div>}
        {error && <div><Alert severity="error" className={classes.alerts}>{`${error}`}</Alert></div>}
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          {editing
            ? (
              <div>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleSave}
                  className={classes.confirmButton}
                  disabled={saving}
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    setEditing(false);
                    setEditableUserData(userData);
                  }}
                  className={classes.cancelButton}
                  disabled={saving}
                >
                  Cancel
                </Button>
              </div>
            )
            : (
              <Button
                color="primary"
                variant="contained"
                onClick={() => setEditing(!editing)}
                disabled={isIssUser}
              >
                Edit profile
              </Button>
            )}
        </Box>
      </Card>
    </form>
  );
}
