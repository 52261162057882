import React from "react";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";

function quarterToInt(Qstr) {
  return parseInt(Qstr.split("Q").join(""), 10);
}

export const fileType = {
  OFFICE_MAP: "office_map",
  // Misc
  STRATEGY_MAP: "strategy_map",
  // Annuities
  FIA: "fia",
  VA: "va",
  FIA_AND_VA: "fia_and_va",
  // MFs
  BD_TRANSACTIONS: "bd_transactions",
  BD_AUM: "bd_aum",
  RIA_TRANSACTIONS: "ria_transactions",
  RIA_AUM: "ria_aum",
  BD_AND_RIA_TRANSACTIONS: "bd_and_ria_transactions",
  BD_AND_RIA_AUM: "bd_and_ria_aum",
  BD_AND_RIA_TRANSACTIONS_AND_AUM: "bd_and_ria_transactions_and_aum",
  FIRST_CLEARING: "first_clearing",
  // ALL
  SECONDARY_FILES: "secondary_files",
};

export default function BaseForm({ children, schema, submitHandler, taskConfig }) {
  // Add any general validation rules here
  function validate(data, errors) {
    if (taskConfig && taskConfig.start_quarter && taskConfig.end_quarter) {
      if (quarterToInt(taskConfig.start_quarter) > quarterToInt(taskConfig.end_quarter)) errors.start_quarter.addError("Start quarter cannot be higher than end quarter");
    } else if (taskConfig && taskConfig.delete_start_quarter && taskConfig.delete_end_quarter) {
      if (quarterToInt(taskConfig.delete_start_quarter) > quarterToInt(taskConfig.delete_end_quarter)) errors.delete_start_quarter.addError("Delete Start quarter cannot be higher than end quarter");
    }
    return errors;
  }

  return (
    <Form
      customValidate={validate}
      schema={schema}
      onSubmit={submitHandler}
      validator={validator}
    >
      {children}
    </Form>
  );
}
