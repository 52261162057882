import LZString from "lz-string";

// eslint-disable-next-line no-warning-comments
// TODO: Use redux-toolkit, axios wrapper, swr or any mainstream solutions
// But beware of 5MB storage limit that we'll likely always hit
// Hence the compression with LZString

export function getFromStorage(key) {
  const JSONItems = localStorage.getItem(key);

  // if the item doesn't exist, return null
  if (!JSONItems) {
    return null;
  }

  const item = JSON.parse(LZString.decompress(JSONItems));
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // let consummer handle refetching/saving implementation
    console.log("Storage item expired");
    return null;
  }
  console.log("Returning item from storage");
  return item.value;
}
export function saveToStorage(key, value, toExpireInMilliseconds) {
  const now = new Date();

  const item = {
    value,
    expiry: now.getTime() + toExpireInMilliseconds,
  };
  localStorage.setItem(key, LZString.compress(JSON.stringify(item)));
  console.log("Saved to storage");
}
