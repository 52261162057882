import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useFormData from "../../hooks/useFormData";
import BaseForm from "./common/BaseForm";

const uiSchema = {
  file_type_inclusions: {
    "ui:widget": "checkboxes",
  },
};

export default function LMSVettingReportForm({ children, setTaskConfig, submitHandler, formDataProp, setIsRequired }) {
  const formOptions = useSelector((state) => state.formOptions);
  // Pre-populate form with previous data if available
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  const LMSVettingReportSchema = {
    title: "LMS Vetting Report Form",
    type: "object",
    required: ["start_quarter", "end_quarter", "lock"],
    properties: {
      start_quarter: {
        type: "string",
        title: "Start Quarter",
        enum: formOptions.quarters,
      },
      end_quarter: {
        type: "string",
        title: "End Quarter",
        enum: formOptions.quarters,
      },
      lock: {
        type: "string",
        title: "Lock to Compare",
        enum: formOptions.locksMF,
      },
      file_type_inclusions: {
        type: "array",
        title: "File Type Inclusions",
        items: {
          type: "string",
          enum: [
            "BD Transactions",
            "RIA Transactions",
            "BD AUM",
            "RIA AUM",
            "UNKNOWN Transactions",
            "UNKNOWN AUM"],
        },
        default: [
          "BD Transactions",
          "RIA Transactions",
          "BD AUM",
          "RIA AUM",
          "UNKNOWN Transactions",
          "UNKNOWN AUM"],
        uniqueItems: true,
        minItems: 1,
      },
      remove_line_of_business: {
        type: "boolean",
        title: "Remove Line of Business Column",
        default: false,
      },
    },
  };

  useEffect(() => {
    if (LMSVettingReportSchema.required) {
      setIsRequired((prevState) => ([...prevState, ...LMSVettingReportSchema.required]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsRequired]);

  return (
    <BaseForm
      schema={LMSVettingReportSchema}
      uiSchema={uiSchema}
      formData={formData}
      submitHandler={submitHandler}
      setFormData={setFormData}
    >
      {children}
    </BaseForm>
  );
}
