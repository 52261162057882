/* eslint-disable no-restricted-syntax */
import { isLeafType, isNonNullType, isScalarType, isWrappingType, parse } from "graphql";
import DEFAULT_DOCUMENT from "./defaultDoc";

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export function isRequiredArgument(arg) {
  return isNonNullType(arg.type) && arg.defaultValue === undefined;
}

export function unwrapOutputType(outputType) {
  let unwrappedType = outputType;
  while (isWrappingType(unwrappedType)) {
    unwrappedType = unwrappedType.ofType;
  }
  return unwrappedType;
}

export function unwrapInputType(inputType) {
  let unwrappedType = inputType;
  while (isWrappingType(unwrappedType)) {
    unwrappedType = unwrappedType.ofType;
  }
  return unwrappedType;
}

export function coerceArgValue(argType, value) {
  // Handle the case where we're setting a variable as the value
  if (typeof value !== "string" && value.kind === "VariableDefinition") {
    return value.variable;
  }
  if (isScalarType(argType)) {
    try {
      switch (argType.name) {
      case "String":
        return {
          kind: "StringValue",
          value: String(argType.parseValue(value)),
        };
      case "Float":
        return {
          kind: "FloatValue",
          value: String(argType.parseValue(parseFloat(value))),
        };
      case "Int":
        return {
          kind: "IntValue",
          value: String(argType.parseValue(parseInt(value, 10))),
        };
      case "Boolean":
        try {
          const parsed = JSON.parse(value);
          if (typeof parsed === "boolean") {
            return {
              kind: "BooleanValue",
              value: parsed,
            };
          }
          return {
            kind: "BooleanValue",
            value: false,
          };
        } catch (e) {
          return {
            kind: "BooleanValue",
            value: false,
          };
        }
      default:
        return {
          kind: "StringValue",
          value: String(argType.parseValue(value)),
        };
      }
    } catch (e) {
      console.error("error coercing arg value", e, value);
      return {
        kind: "StringValue",
        value,
      };
    }
  } else {
    try {
      const parsedValue = argType.parseValue(value);
      if (parsedValue) {
        return {
          kind: "EnumValue",
          value: String(parsedValue),
        };
      }
      return {
        kind: "EnumValue",
        value: argType.getValues()[0].name,
      };
    } catch (e) {
      return {
        kind: "EnumValue",
        value: argType.getValues()[0].name,
      };
    }
  }
}

function parseQuery(text) {
  try {
    if (!text.trim()) return null;

    return parse(
      text,
      // Tell graphql to not bother track locations when parsing, we don't need
      // it, and it's a tiny bit more expensive.
      { noLocation: true },
    );
  } catch (e) {
    return new Error(e);
  }
}

let parseQueryMemoize = null;

export function memoizeParseQuery(query) {
  if (parseQueryMemoize && parseQueryMemoize[0] === query) {
    return parseQueryMemoize[1];
  }
  const result = parseQuery(query);
  if (!result) {
    return DEFAULT_DOCUMENT;
  }
  if (result instanceof Error) {
    if (parseQueryMemoize) {
      // Most likely a temporarily invalid query while they type
      return parseQueryMemoize[1];
    }
    return DEFAULT_DOCUMENT;
  }
  parseQueryMemoize = [query, result];
  return result;
}

export function isHandleable(event, argSelection, argType) {
  let settingToNull = false;
  let settingToVariable = false;
  let settingToLiteralValue = false;
  try {
    if (event.kind === "VariableDefinition") {
      settingToVariable = true;
    } else if (typeof event === "undefined") {
      settingToNull = true;
    } else if (typeof event.kind === "string") {
      settingToLiteralValue = true;
    }
  } catch (e) {
    console.error(e);
  }

  if (!argSelection && !settingToVariable) {
    console.error("missing arg selection when setting arg value");
    return null;
  }

  return isLeafType(argType)
      || settingToVariable
      || settingToNull
      || settingToLiteralValue;
}
