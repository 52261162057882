import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useFormData from "../../hooks/useFormData";
import BaseForm from "./common/BaseForm";

export default function AnomalyDetectionForm({ children, setTaskConfig, submitHandler, formDataProp, setIsRequired }) {
  const formOptions = useSelector((state) => state.formOptions);
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  const anomalyDetectionSchema = {
    title: "Anomaly Detection Report",
    type: "object",
    required: [
      "end_quarter",
    ],
    properties: {
      end_quarter: {
        type: "string",
        title: "End Quarter",
        enum: formOptions.quarters,
      },
      custom_file_name: {
        type: "string",
        title: "Custom File Name",
        description: "Use a custom file name for the Anomaly Detection Report other than the default template",
        minLength: 1,
      },
      lock: {
        type: "string",
        title: "Lock (Data Set)",
        enum: formOptions.locksMF,
      },
      stdev: {
        type: "number",
        title: "Standard Deviations",
        description: "Number of Standard Deviations for Anomaly Detection Report",
        default: 3,
      },
      turn_off_validation: {
        type: "boolean",
        title: "Turn Off Validation",
        description: "Turn off Anomaly Detection Report validation",
      },
      version: {
        type: "string",
        title: "Version",
        description: "Version of file",
      },
    },
  };

  useEffect(() => {
    if (anomalyDetectionSchema.required) {
      setIsRequired((prevState) => ([...prevState, ...anomalyDetectionSchema.required]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsRequired]);

  return (
    <BaseForm
      schema={anomalyDetectionSchema}
      formData={formData}
      submitHandler={submitHandler}
      setFormData={setFormData}
    >
      {children}
    </BaseForm>
  );
}
