import React from "react";

import { alpha, useTheme } from "@mui/material/styles";

import CircularProgress from "./Spinner";

export default function TableLoadingOverlay() {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "table",
        width: "100%",
        height: "100%",
        backgroundColor: alpha(theme.palette.background.paper, 0.7),
      }}
    >
      <div
        style={{
          display: "table-cell",
          width: "100%",
          height: "100%",
          verticalAlign: "middle",
          textAlign: "center",
        }}
      >
        <CircularProgress style={{ fill: theme.palette.primary.main, width: "100px", height: "100px" }} />
      </div>
    </div>
  );
}
