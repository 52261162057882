import React, { useState, useEffect } from "react";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import Table from "./Table";

export function TableWithToggle({
  inputData,
  formattingOptions,
}) {
  const [toggle, setToggle] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData({
      data: inputData?.data?.filter((row) => row.is_default === toggle),
    });
  }, [toggle, inputData]);
  return (
    <div>
      {filteredData?.data && inputData && (
        <>
          <FormGroup>
            <FormControlLabel
              control={(
                <Switch
                  color="primary"
                  onChange={(e) => setToggle(e.target.checked)}
                />
              )}
              label={
                toggle
                  ? "Users with default dashboard"
                  : "Users without default dashboard"
              }
            />
          </FormGroup>
          <Table
            inputData={filteredData}
            formattingOptions={formattingOptions}
            columnDefs={inputData.columns}
          />
        </>
      )}
    </div>
  );
}
