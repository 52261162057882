import React, { useEffect, useState } from "react";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ButtonGroup, Chip, Button } from "@mui/material";
import Table from "../../components/Table";
import { API } from "../../utils/api";
import AddUserDialog from "./AddUserDialog";
import RemoveUserDialog from "./RemoveUserDialog";
import UpdateUserSnackbar from "./UpdateUserSnackbar";

export default function GroupTable({ group }) {
  const [refreshTable, setRefreshTable] = useState(false);
  const [openAddUserDialog, setAddUserOpenDialog] = useState(false);
  const [openRemoveUserDialog, setRemoveUserOpenDialog] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [groupTableData, setGroupTableData] = useState([]);
  const [userUnderRemoval, setUserUnderDeletion] = useState({ name: "", userId: "" });
  const [addUserSnackbarStatus, setAddUserSnackbarStatus] = useState({ status: "", message: "" });
  const [removeUserSnackbarStatus, setRemoveUserSnackbarStatus] = useState({ status: "", message: "" });
  const [tableKey, setTableKey] = useState(0);

  const headerStyle = {
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  };

  const columns = [
    {
      header: "Name",
      accessorKey: "name",
      Cell: ({ cell }) => (
        <Chip
          variant="outlined"
          style={{
            width: 300,
            textAlign: "center",
            backgroundColor: "white",
            color: "black",
          }}
          label={cell.getValue().toUpperCase()}
        />
      ),
      ...headerStyle,
    },
    {
      header: "Email ID",
      accessorKey: "email",
      size: 300,
      ...headerStyle,
    },
  ];

  useEffect(() => {
    setLoadingData(true);
    API.request({
      url: "/users",
      method: "GET",
      params: { pool: "cognito_group", group_name: group.cognitoName },
    }).then((response) => {
      setLoadingData(false);
      setGroupTableData(response.data.users);
      if (response.data.users.length === 0) {
        setRemoveUserSnackbarStatus({ status: "error", message: `No Users found for ${group.name} !` });
      }
      setTableKey(tableKey + 1);
    }).catch((error) => {
      console.error(error);
      setTimeout(() => setGroupTableData([]), 0);
      setLoadingData(false);
      setRemoveUserSnackbarStatus({ status: "error", message: `Failed to get users for ${group.name} !` });
      setTableKey(tableKey + 1);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group.name, refreshTable]);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Table
        columns={columns}
        data={groupTableData}
        isLoading={loadingData}
        enableRowActions
        key={tableKey}
        title={`${group.name} Group`}
        rowAddButtonName="Add Users"
        positionActionsColumn="last"
        initialState={{
          pagination: {
            pageSize: 20,
            pageIndex: 0,
          },
        }}
        sx={{
          width: 800,
        }}
        editable={{
          onRowDelete: (rowData) => {
            setRemoveUserOpenDialog(true);
            setUserUnderDeletion({ name: rowData.name, userId: rowData.id });
          },
        }}
        renderTopToolbarCustomActions={
          () => (
            <ButtonGroup>
              <Button
                variant="contained"
                onClick={() => {
                  setAddUserOpenDialog(true);
                }}
                style={{ marginRight: 10 }}
                endIcon={<LibraryAddIcon />}
              >
                Add Users
              </Button>
              <Button
                variant="contained"
                endIcon={<RefreshIcon />}
                onClick={() => { setRefreshTable(!refreshTable); }}
              >
                Refresh Table
              </Button>
            </ButtonGroup>
          )

        }
      />
      {openAddUserDialog
                && (
                  <AddUserDialog
                    openDialog={openAddUserDialog}
                    setOpenDialog={setAddUserOpenDialog}
                    setSnackbarStatus={setAddUserSnackbarStatus}
                    groupTableData={groupTableData}
                    group={group}
                    refreshTable={refreshTable}
                    setRefreshTable={setRefreshTable}
                  />
                )}
      {openRemoveUserDialog
                && (
                  <RemoveUserDialog
                    openDialog={openRemoveUserDialog}
                    setOpenDialog={setRemoveUserOpenDialog}
                    group={group}
                    setSnackbarStatus={setRemoveUserSnackbarStatus}
                    userUnderRemoval={userUnderRemoval}
                    refreshTable={refreshTable}
                    setRefreshTable={setRefreshTable}
                  />
                )}
      {addUserSnackbarStatus.status.length > 0
                && (
                  <UpdateUserSnackbar
                    status={addUserSnackbarStatus.status}
                    message={addUserSnackbarStatus.message}
                    state={addUserSnackbarStatus}
                    setState={setAddUserSnackbarStatus}
                    downloadingType={addUserSnackbarStatus.status === "info"}
                  />
                )}
      {removeUserSnackbarStatus.status.length > 0
                && (
                  <UpdateUserSnackbar
                    status={removeUserSnackbarStatus.status}
                    message={removeUserSnackbarStatus.message}
                    state={removeUserSnackbarStatus}
                    setState={setRemoveUserSnackbarStatus}
                    downloadingType={removeUserSnackbarStatus.status === "info"}
                  />
                )}
    </div>
  );
}
