import React from "react";
import { useSelector } from "react-redux";

import { convertArrayToOptions } from "../../../utils/dataTransformations";

import LMSSelect from "./base/LMSSelect";

export default function Lock({
  studyType,
  ...props
}) {
  const formOptions = useSelector((state) => state.formOptions);
  const locks = convertArrayToOptions(formOptions[`locks${studyType.toUpperCase()}`]);

  return (
    <LMSSelect
      name="lock"
      options={locks}
      label="Lock (Data Set)"
      {...props}
    />
  );
}
