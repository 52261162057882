/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import {
  PlayCircleFilledSharp as RunButtonIcon,
  DeleteForever as DeleteButtonIcon,
  CheckCircleSharp as CheckIcon,
  CancelSharp as CloseIcon,
  FileCopyTwoTone as FileCopyIcon,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  ButtonGroup,
  Snackbar,
  Chip,
  Button,
  Dialog,
  DialogContent,
  Tooltip,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import CountDown from "../../components/CountDown";
import DisplayFlow from "../../components/DisplayFlow";
import CircularProgress from "../../components/Spinner";
import Table from "../../components/Table";
import usePageTitle from "../../hooks/usePageTitle";
import { resetFilters } from "../../state";
import { API } from "../../utils/api";
import { CreateWorkflow } from "../tasks/create_wizard/WorkflowModal";
import StatusChip from "../tasks/status/table/columns/StatusChip";
import TimeColumn from "../tasks/status/table/columns/TimeColumn";
import WorkflowFilters from "./WorkflowFilters";
import WorkflowNameColumn from "./WorkflowNameColumn";

const refreshTime = 30;
const pageSizeLimit = 10;
const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
  },
  runButton: {
    color: theme.palette.secondary.dark,
  },
}));

function WorkflowRun({ rowData, refreshData }) {
  const { status, workflowId, workflowName } = rowData;
  const classes = useStyles();
  const [deleteWorkflow, setDeleteWorkflow] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [showRunWorkflowConfirm, setShowRunWorkflowConfirm] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const theme = useTheme();

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = (id) => {
    setDeleteWorkflow(workflowName);
    API.request({ url: `/delete-workflow/${id}`, method: "DELETE" }).then(
      () => {
        setLoading(false);
        refreshData();
        setShowSnackbar(true);
        setShowConfirm(false);
      },
      (e) => {
        console.error(e);
      },
    );
  };

  const handleRunWorkflow = () => {
    API.request({
      url: `/workflow/${workflowName}/${workflowId}/publish`,
      method: "POST",
    })
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          refreshData();
          setShowSnackbar(true);
          setShowRunWorkflowConfirm(false);
          setDeleteWorkflow("");
        }, 3000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCloneDialog = () => {
    setOpenDialog(true);
  };

  const isDisabled = status === "DRAFT" || status === "CREATED";
  return (
    <>
      {openDialog && (
        <Dialog
          open={openDialog}
          fullWidth
          maxWidth="xs"
          classes={{
            paper: classes.dialogPaper,
          }}
        >
          <Box style={{ margin: 8, height: "50%" }}>
            <CreateWorkflow
              handleClose={handleClose}
              clone
              workflowId={workflowId}
              workflowName={workflowName}
            />

            <Dialog
              open={loading}
              PaperProps={{
                style: { backgroundColor: "transparent", boxShadow: "none" },
              }}
              maxWidth="xs"
            >
              <DialogContent>
                <CircularProgress style={{ width: "50px", height: "50px" }} />
              </DialogContent>
            </Dialog>
          </Box>
        </Dialog>
      )}
      {!showConfirm && !showRunWorkflowConfirm && (
        <ButtonGroup variant="outlined">
          <Button
            aria-label="run"
            className={classes.runButton}
            disabled={status !== "DRAFT"}
            onClick={() => setShowRunWorkflowConfirm(true)}
          >
            <Tooltip title="Run" arrow>
              <RunButtonIcon />
            </Tooltip>
          </Button>
          <Button
            aria-label="clone"
            color="primary"
            onClick={handleCloneDialog}
          >
            <Tooltip title="Clone" arrow>
              <FileCopyIcon variant="outlined" />
            </Tooltip>
          </Button>
          <Button
            aria-label="delete"
            className={classes.deleteButton}
            disabled={!isDisabled}
            onClick={() => setShowConfirm(true)}
          >
            <Tooltip title="Delete" arrow>
              <DeleteButtonIcon />
            </Tooltip>
          </Button>
        </ButtonGroup>
      )}
      {showConfirm && (
        <div>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <Chip
                label="Confirm delete ?"
                style={{ backgroundColor: "#d85f5f", color: "white" }}
              />
              <br />
              <IconButton
                color="primary"
                disabled={loading}
                onClick={() => {
                  setLoading(true);
                  handleDelete(workflowId);
                }}
                size="large"
              >
                <CheckIcon />
              </IconButton>
              <IconButton
                color="default"
                onClick={() => {
                  setShowConfirm(false);
                  setLoading(false);
                }}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </>
          )}
        </div>
      )}
      {showRunWorkflowConfirm && (
        <div>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <Chip
                label="Run workflow ?"
                style={{
                  backgroundColor: theme.palette.success.main,
                  color: theme.palette.background.paper,
                }}
              />
              <br />
              <IconButton
                color="primary"
                disabled={loading}
                onClick={() => {
                  setLoading(true);
                  handleRunWorkflow();
                }}
                size="large"
              >
                <CheckIcon />
              </IconButton>
              <IconButton
                color="default"
                onClick={() => {
                  setShowRunWorkflowConfirm(false);
                  setLoading(false);
                }}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </>
          )}
        </div>
      )}
      <Snackbar
        open={showSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        {deleteWorkflow ? (
          <MuiAlert onClose={handleSnackbarClose} severity="warning">
            Workflow
            {" "}
            {deleteWorkflow}
            {" "}
            deleted!
          </MuiAlert>
        ) : (
          <MuiAlert onClose={handleSnackbarClose}>
            Workflow
            {" "}
            {workflowName}
            {" "}
            submitted !!!
          </MuiAlert>
        )}
      </Snackbar>
    </>
  );
}

function AnalystWorkflowStatus() {
  const dispatch = useDispatch();
  const [timestampIconDisplay, setTimestampIconDisplay] = useState(true);
  const filters = useSelector((state) => state.filters.available);
  const [loading, setLoading] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});

  usePageTitle("Workflow Status");

  const [data, setData] = useState([]);
  const [nextPaginationKey, setNextPaginationKey] = useState(false);
  const [loadedPaginationKeys, setLoadedPaginationKeys] = useState([]);
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const url = "/workflows";

  const fetchData = useCallback(
    (page, paginationKeyParam) => {
      if (!pages.includes(page)) {
        setLoading(true);
        setLoadedPaginationKeys((prevKeys) => prevKeys.concat(paginationKeyParam));
        const params = paginationKeyParam
          ? {
            ...appliedFilters,
            limit: pageSizeLimit,
            paginationKey: paginationKeyParam,
          }
          : { ...appliedFilters, limit: pageSizeLimit + 1 };
        API.request({ url, method: "GET", params }).then(
          (response) => {
            response.data.results.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1));
            setData((PrevData) => PrevData.concat(response.data.results));
            setNextPaginationKey(response.data.paginationKey);
            setPages((prevPages) => prevPages.concat(page));
            setLoading(false);
          },
          () => {
            setLoading(false);
            return null;
          },
        );
      }
    },
    [pages, appliedFilters],
  );

  const refreshData = useCallback(() => {
    setLoading(true);
    let newData = [];
    const refreshRequests = [];

    loadedPaginationKeys.forEach((paginationKey) => {
      const params = paginationKey
        ? { ...appliedFilters, limit: pageSizeLimit + 1, paginationKey }
        : { ...appliedFilters, limit: pageSizeLimit + 1 };
      refreshRequests.push(
        API.request({ url, method: "GET", params }).then((response) => {
          newData = newData.concat(response.data.results);
        }),
      );
    });
    Promise.all(refreshRequests).then(() => {
      newData.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1));
      setData(newData);
      setLoading(false);
    });
  }, [appliedFilters, loadedPaginationKeys]);

  const applyFilterAndFetchData = useCallback(() => {
    setLoading(true);
    // Clean up state before sending
    const flattenedFilters = {
      ...filters,
      username: filters.username.map((user) => user.email),
      workflow_name: filters.workflow_name.map(
        (workflowName) => workflowName.label,
      ),
    };
    const noBlankFilters = {};
    Object.keys(flattenedFilters).forEach((key) => {
      if (
        flattenedFilters[key]
        && flattenedFilters[key] !== ""
        && Object.keys(flattenedFilters[key]).length !== 0
      ) {
        noBlankFilters[key] = flattenedFilters[key];
      }
    });
    setAppliedFilters(noBlankFilters);
    const params = { ...noBlankFilters, limit: pageSizeLimit + 1 };
    API.request({ url, method: "GET", params }).then(
      (response) => {
        // Reset all the data with the new filters
        if (response.data.results.length > 0) {
          response.data.results.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1));
          if (params.orderBy === "asc") {
            response.data.results.sort((a, b) => (a.createdDate < b.createdDate ? -1 : 1));
          }
          setData(response.data.results);
          setNextPaginationKey(response.data.paginationKey);
          setLoadedPaginationKeys([""]);
          setPages([0]);
        } else {
          setData([]);
        }
        setLoading(false);
      },
      () => {
        setLoading(false);
        return null;
      },
    );
  }, [filters]);

  const onPageChange = (page) => {
    setCurrentPage(page);
    if (nextPaginationKey) {
      fetchData(page, nextPaginationKey);
    }
  };

  const columns = [
    {
      header: "Display Flow",
      sorting: false,
      accessorKey: "DisplayFlow",
      render: (row) => <DisplayFlow rowData={row} />,
    },
    {
      header: "Workflow Name",
      accessorKey: "workflowName",
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      render: (row) => <WorkflowNameColumn rowData={row} />,
    },
    {
      header: "Status",
      accessorKey: "status",
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      render: (row) => <StatusChip status={row.status} />,
    },
    {
      header: "Timestamp",
      accessorKey: "createdDate",
      type: "datetime",
      render: (row) => (
        <TimeColumn
          createdTime={row.createdDate}
          history={row.statusHistory}
          timestampIconDisplay={timestampIconDisplay}
        />
      ),
    },
    {
      header: "User",
      accessorKey: "username",
      render: (row) => {
        const capitalize = (name) => name.replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase());
        if (!row.username.includes("_")) {
          let [first, last = ""] = row.username.split(".");
          first = capitalize(first.split("@")[0]);
          last = capitalize(last.split("@")[0]);
          return `${first} ${last}`;
        }
        return `${row.username}`;
      },
    },
    {
      header: "Actions",
      accessorKey: "actions",
      sorting: false,
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      render: (row) => (
        <WorkflowRun rowData={row} refreshData={refreshData} />
      ),
    },
  ];

  useEffect(() => {
    dispatch(resetFilters());
    fetchData(0, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <WorkflowFilters>
        <Button
          variant="outlined"
          color="secondary"
          onClick={applyFilterAndFetchData}
        >
          Apply Filter
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            dispatch(resetFilters());
            setAppliedFilters({});
          }}
        >
          Reset Filter
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setTimestampIconDisplay(!timestampIconDisplay);
          }}
        >
          Toggle Timestamp Display
        </Button>
      </WorkflowFilters>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="right"
        style={{ marginLeft: "1em", marginRight: "1em" }}
      >
        {!currentPage && (
          <CountDown
            duration={refreshTime}
            onFinish={refreshData}
            text="Refreshing in"
          />
        )}
      </Box>
      <Table
        style={{ marginTop: "1.5em" }}
        columns={columns}
        data={data ?? []}
        onPageChange={onPageChange}
        state={{
          isLoading: loading,
        }}
      />
    </div>
  );
}

export default AnalystWorkflowStatus;
