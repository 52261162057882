import React, { useEffect, useMemo, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { FormControl, Grid, TextField, Chip, Divider, Tooltip, useTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import APISelect from "../../../components/input/APISelect";
import OptionSelect from "../../../components/input/OptionSelect";
import TerrClientSelect from "../../../components/input/TerrClientSelect";
import usePageTitle from "../../../hooks/usePageTitle";
import { backendType } from "../../../state/application";
import { textPerPalette } from "../../../theme/common";
import { actions, annLmsTaskTypes, annStudyTypes, diStudyTypes, diTaskTypes, lmsPreWarmStudyType, ddLmsTaskTypes, reportTypes, lmsPreWarmTaskType, preWarmreportType } from "./TaskMapping";
import WizardButtons from "./WizardButtons";

const ALL_CLIENTS_ENTRY = { id: "ALL_CLIENTS", label: "All Clients" };
const MULTIPLE_CLIENTS_ENTRY = {
  id: "MULTIPLE_CLIENTS",
  label: "Multiple Clients",
};

export default function TaskStep1({
  step,
  setStep,
  action,
  setAction,
  taskType,
  setTaskType,
  studyType,
  setStudyType,
  client,
  setClient,
  setTitle,
  isDDTask,
  isPrewarmTask = false,
  globalSearch = true,
}) {
  const cognitoGroups = useSelector(state => state.application.cognitoGroups);
  const isSuperAdmin = cognitoGroups.includes("super-admin");
  const [currentTaskType, setCurrentTaskType] = useState(isPrewarmTask ? lmsPreWarmTaskType[0] : {});
  const [inputValue, setInputValue] = useState(isPrewarmTask ? "ANN MISight Prewarm Cache" : "");
  const [inputValueClient, setInputValueClient] = useState("");
  const [reportType, setReportType] = useState(isPrewarmTask ? "misight_reports" : "internal_reports");
  const filters = useSelector((state) => state.filters.available);
  const theme = useTheme();
  let displayTaskTypes;
  if (backendType === "LMS") {
    if (isDDTask) {
      displayTaskTypes = ddLmsTaskTypes;
    } else if (isPrewarmTask) {
      displayTaskTypes = lmsPreWarmTaskType;
    } else {
      displayTaskTypes = annLmsTaskTypes;
    }
  } else {
    displayTaskTypes = diTaskTypes;
  }

  const selectedTaskObj = useMemo(
    () => displayTaskTypes
      .filter((task) => task.study === studyType)
      .filter((task) => task.id === taskType)[0] || {},
    [studyType, taskType, displayTaskTypes],
  );
  // Apply title
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let pageTitle = "Create Task";
  if (!globalSearch) { pageTitle = "Run Pre-Warm"; }

  usePageTitle(pageTitle);
  useEffect(() => {
    localStorage.removeItem("form_type");
    localStorage.removeItem("workflowId");
    if (!isPrewarmTask) setTitle(selectedTaskObj.label);
    setClient(client);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTaskObj, setTitle]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Reset task if selection changes
    if (!isPrewarmTask) {
      setTaskType("");
      setCurrentTaskType({});
      setInputValue("");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, studyType, setTaskType, setInputValue]);

  useEffect(() => {
    setTimeout(() => setClient(""), setInputValueClient(""), 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskType]);
  // Accommodate DI and Multiple Options
  let clientSelect;
  if (studyType === "territory_service") {
    if (selectedTaskObj.multiple_clients) {
      clientSelect = (
        <APISelect
          label="Multiple Territory Service Client"
          url="/di/territory/clients"
          value={client || []}
          valueKey="pk"
          optionKey="client_description"
          onChange={setClient}
          showError={false}
          multiple
        />
      );
    } else {
      clientSelect = (
        <TerrClientSelect
          label="Territory Service Client"
          url="/di/territory/clients"
          value={client}
          onChange={setClient}
          showError={false}
          filterValue={studyType}
          sort
          valueKey="pk"
          labelKey="client_description"
        />
      );
    }
  } else {
    clientSelect = (
      <APISelect
        label="Client"
        inputValueClient={inputValueClient}
        setInputValueClient={setInputValueClient}
        url="/clients"
        value={client || ""}
        optionKey="id"
        onChange={setClient}
        showError={false}
        filter="type" // Name for study in API
        filterValue={studyType}
        sort
        sortValue="id"
        forClients
        additionalOptions={selectedTaskObj.allow_all_clients ? [ALL_CLIENTS_ENTRY] : []}
      />
    );
  }
  // Jobs that are locked to run for everyone
  if (selectedTaskObj.all_clients_only) {
    setClient(ALL_CLIENTS_ENTRY.id);
    clientSelect = (
      <OptionSelect
        value={ALL_CLIENTS_ENTRY.id}
        disabled
        onChange={setClient}
        options={[ALL_CLIENTS_ENTRY]}
        label="Clients"
        style={{ display: "flex" }}
      />
    );
  }
  // Jobs that are locked to run for one hardcoded client only
  if (selectedTaskObj.one_client_only) {
    setClient(selectedTaskObj.one_client_only);
    clientSelect = (
      <OptionSelect
        value={selectedTaskObj.one_client_only}
        disabled
        onChange={setClient}
        options={[{ id: selectedTaskObj.one_client_only, label: selectedTaskObj.one_client_only }]}
        label="Clients"
        style={{ display: "flex" }}
      />
    );
  }
  // Jobs with multiple clients to select on another step
  // TODO: Refactor this so that it's just a multiple select on this step
  if (selectedTaskObj.multiple_clients_selection) {
    setClient(MULTIPLE_CLIENTS_ENTRY.id);
    clientSelect = (
      <OptionSelect
        value={MULTIPLE_CLIENTS_ENTRY.id}
        disabled
        onChange={setClient}
        options={[MULTIPLE_CLIENTS_ENTRY]}
        label="Clients"
        style={{ display: "flex" }}
      />
    );
  }

  const handleChange = (e, val) => {
    if (
      typeof val === "object"
      && val !== null
      && Object.keys(val).length !== 0
    ) {
      setCurrentTaskType(val);
      setTaskType(val.id);
      setTimeout(() => {
        if (!selectedTaskObj.all_clients_only) setClient(client);
      }, 0);
    }
  };
  const getTaskList = (taskList) => {
    const { action: actionFromList, label, id, study, ALL_CLIENTS_ONLY, reportType: reportTypeFromList } = taskList;

    setAction(actionFromList);
    setStudyType(study);
    setReportType(reportTypeFromList);
    setTimeout(() => {
      setCurrentTaskType(taskList);
      setTaskType(id);
      setInputValue(label);
      if (ALL_CLIENTS_ONLY) {
        setClient(ALL_CLIENTS_ENTRY.id);
      }
    }, 0);
  };

  const getValues = () => (action === "reporting"
    ? displayTaskTypes
      .filter(
        (task) => task.action === action
          && task.study === studyType
          && task.reportType === reportType
          && (!task.dev_only_task || isSuperAdmin),
      )
      .sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      })
    : displayTaskTypes
      .filter(
        (task) => task.action === action && task.study === studyType && (!task.dev_only_task || isSuperAdmin),
      )
      .sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      }));
  const icon = () => (
    <Tooltip disableFocusListener title="General Search for Task type" placement="top-end">
      <InfoIcon style={{ color: textPerPalette(theme) }} />
    </Tooltip>
  );
  const useStyles = makeStyles({
    chipRoot: {
      "& .MuiChip-icon": {
        order: 1,
        marginRight: "10px",
        cursor: "pointer",
      },
      "& .MuiChip-deleteIcon": {
        order: 2,
      },
    },
  });

  const getStudyType = () => {
    let studyTypes;
    if (backendType === "LMS") {
      if (isPrewarmTask) {
        studyTypes = lmsPreWarmStudyType;
      } else {
        studyTypes = annStudyTypes;
      }
    } else {
      studyTypes = diStudyTypes;
    }
    return studyTypes;
  };

  const classes = useStyles();
  return (
    <>
      {globalSearch
      && (
        <FormControl fullWidth>
          <Chip
            classes={{ root: classes.chipRoot }}
            size="medium"
            style={{ fontSize: "large", color: textPerPalette(theme), backgroundColor: theme.palette.primary.main }}
            label="Global Task Search"
            icon={icon()}
          />
          <Autocomplete
            fullWidth
            sx={{ width: "600px" }}
            clearOnBlur={false}
            freeSolo
            value={filters.taskType}
            onChange={(e, taskList) => {
              if (e.target.value !== "") {
                e.preventDefault();
                getTaskList(taskList ?? {});
              }
            }}
            options={displayTaskTypes ?? []}
            groupBy={(option) => option.action}
            getOptionLabel={(option) => option.label || ""}
            renderInput={(params) => (
              <TextField {...params} autoFocus label="Enter Task type" variant="outlined" margin="normal" />
            )}
            isOptionEqualToValue={(val, option) => option.id === val.id}
          />
          <Divider
            style={{ backgroundColor: "light-gray", paddingBottom: "4px", marginBottom: "1%", marginTop: "1%" }}
          />
          <Chip
            size="medium"
            style={{ fontSize: "large", color: textPerPalette(theme), backgroundColor: theme.palette.primary.main }}
            label="Search by Action and Task Type"
          />

        </FormControl>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <OptionSelect value={action} onChange={setAction} options={actions} label="Action" disabled={isPrewarmTask} />
          </FormControl>
        </Grid>
        {action === "reporting" && (
          <Grid item xs={10} md={1}>
            <FormControl fullWidth>
              <OptionSelect value={reportType} onChange={setReportType} options={isPrewarmTask ? preWarmreportType : reportTypes} label="Report Type" disabled={isPrewarmTask} />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} md={1}>
          <FormControl fullWidth>
            <OptionSelect value={studyType} onChange={setStudyType} options={getStudyType()} label="Study" disabled={isPrewarmTask} />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Autocomplete
              disableClearable
              openOnFocus
              inputValue={inputValue}
              clearOnBlur={false}
              options={getValues()}
              getOptionLabel={(option) => {
                if (option.label !== undefined) return option.label;
                return "";
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Task Type"
                  variant="outlined"
                  margin="normal"
                />
              )}
              onInputChange={(event, newInputValue) => {
                if (!isPrewarmTask) setInputValue(newInputValue);
              }}
              onChange={handleChange}
              value={currentTaskType.label}
              disabled={isPrewarmTask}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>{clientSelect}</FormControl>
        </Grid>
      </Grid>
      <WizardButtons
        step={step}
        onChange={setStep}
        hasNext={step < 2}
        validated={
          client !== null
        && client !== ""
        && action !== ""
        && taskType !== ""
        && studyType !== ""
        }
      />
    </>
  );
}
