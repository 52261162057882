/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/jsx-no-bind,no-unused-vars */
import React, { useState } from "react";
import { isInputObjectType, isLeafType, parseType, visit } from "graphql";
import { defaultInputObjectFields } from "../defaultNames";
import { coerceArgValue, isHandleable, isRequiredArgument, unwrapInputType } from "../util";
import ArgInput from "./argInput";
import Arrow from "./arrow";
import Checkbox from "./checkbox";

function InputArgView({
  selection,
  arg,
  modifyFields,
  getDefaultScalarArgValue,
  parentField,
  onRunOperation,
  onCommit,
  definition,
  makeDefaultArg,
}) {
  let previousArgSelection;

  const argType = unwrapInputType(arg.type);

  const argSelection = selection.fields.find(
    field => field.name.value === arg.name,
  );

  function removeArg() {
    previousArgSelection = argSelection;
    modifyFields(
      selection.fields.filter(field => field !== argSelection),
      true,
    );
  }

  function addArg() {
    let argSel = null;
    if (previousArgSelection) {
      argSel = previousArgSelection;
    } else if (isInputObjectType(argType)) {
      const fields = argType.getFields();
      argSel = {
        kind: "ObjectField",
        name: {
          kind: "Name",
          value: arg.name,
        },
        value: {
          kind: "ObjectValue",
          fields: defaultInputObjectFields(
            getDefaultScalarArgValue,
            makeDefaultArg,
            parentField,
            Object.keys(fields)
              .map(k => fields[k]),
          ),
        },
      };
    } else if (isLeafType(argType)) {
      argSel = {
        kind: "ObjectField",
        name: {
          kind: "Name",
          value: arg.name,
        },
        value: getDefaultScalarArgValue(parentField, arg, argType),
      };
    }

    if (!argSel) {
      console.error("Unable to add arg for argType", argType);
    } else {
      return modifyFields(
        [...(selection.fields || []), argSel],
        true,
      );
    }

    return null;
  }

  function setArgValue(event, options) {
    if (!isHandleable(event, argSelection, argType)) {
      console.warn(
        "Unable to handle non leaf types in InputArgView.setArgValue",
        event,
      );
      return null;
    }
    let targetValue;
    let value;

    if (event === null || typeof event === "undefined") {
      value = null;
    } else if (
      !event.target
      && !!event.kind
      && event.kind === "VariableDefinition"
    ) {
      targetValue = event;
      value = targetValue.variable;
    } else if (typeof event.kind === "string") {
      value = event;
    } else if (event.target && typeof event.target.value === "string") {
      targetValue = event.target.value;
      value = coerceArgValue(argType, targetValue);
    }

    return modifyFields(
      (selection.fields || []).map(field => (field === argSelection
        ? {
          ...field,
          value,
        }
        : field)),
      options,
    );
  }

  function modifyChildFields(fields) {
    return modifyFields(
      selection.fields.map(field => (field.name.value === arg.name
        ? {
          ...field,
          value: {
            kind: "ObjectValue",
            fields,
          },
        }
        : field)),
      true,
    );
  }
  return (
    <AbstractArgView
      argValue={argSelection ? argSelection.value : null}
      arg={arg}
      parentField={parentField}
      addArg={addArg}
      removeArg={removeArg}
      setArgFields={modifyChildFields}
      setArgValue={setArgValue}
      getDefaultScalarArgValue={getDefaultScalarArgValue}
      makeDefaultArg={makeDefaultArg}
      onRunOperation={onRunOperation}
      onCommit={onCommit}
      definition={definition}
    />
  );
}

function AbstractArgView({
  argValue,
  addArg,
  removeArg,
  parentField,
  makeDefaultArg,
  onRunOperation,
  setArgFields,
  getDefaultScalarArgValue,
  arg,
  definition,
  onCommit,
  setArgValue,
}) {
  const [displayArgActions, setDisplayArgActions] = useState(false);

  const argType = unwrapInputType(arg.type);
  const isArgValueVariable = argValue?.kind === "Variable";

  function variablize() {
    /**
     1. Find current operation variables
     2. Find current arg value
     3. Create a new variable
     4. Replace current arg value with variable
     5. Add variable to operation
     */

    const baseVariableName = arg.name;
    const conflictingNameCount = (
      definition.variableDefinitions || []
    ).filter(varDef => varDef.variable.name.value.startsWith(baseVariableName)).length;

    const variableName = (conflictingNameCount > 0) ? `${baseVariableName}${conflictingNameCount}` : baseVariableName;

    // To get an AST definition of our variable from the instantiated arg,
    // we print it to a string, then parseType to get our AST.
    const argPrintedType = arg.type.toString();
    const argumentType = parseType(argPrintedType);

    const base = {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: variableName,
        },
      },
      type: argumentType,
      directives: [],
    };

    const variableDefinitionByName = name => (definition.variableDefinitions || []).find(
      varDef => varDef.variable.name.value === name,
    );

    let variable;
    let subVariableUsageCountByName;

    if (typeof argValue !== "undefined" && argValue !== null) {
      /** In the process of devariabilizing descendent selections,
       * we may have caused their variable definitions to become unused.
       * Keep track and remove any variable definitions with 1 or fewer usages.
       * */
      const cleanedDefaultValue = visit(argValue, {
        Variable(node) {
          const varName = node.name.value;
          const varDef = variableDefinitionByName(varName);

          subVariableUsageCountByName[varName] = subVariableUsageCountByName[varName] + 1 || 1;

          return varDef?.defaultValue;
        },
      });

      const isNonNullable = base.type.kind === "NonNullType";

      // We're going to give the variable definition a default value, so we must make its type nullable
      const unwrappedBase = isNonNullable
        ? {
          ...base,
          type: base.type.type,
        }
        : base;

      variable = {
        ...unwrappedBase,
        defaultValue: cleanedDefaultValue,
      };
    } else {
      variable = base;
    }

    const newlyUnusedVariables = subVariableUsageCountByName
      ? Object.entries(subVariableUsageCountByName)
        .filter(([_, usageCount]) => usageCount < 2)
        .map(([varName, _]) => varName)
      : null;

    if (variable) {
      const newDoc = setArgValue(variable, false);

      if (newDoc) {
        const targetOperation = newDoc.definitions.find(d => d.operation && d?.name?.value === definition?.name?.value);
        const newVariableDefinitions = [
          ...(targetOperation.variableDefinitions || []),
          variable,
        ].filter(
          varDef => newlyUnusedVariables.indexOf(varDef.variable.name.value) === -1,
        );
        const newOperation = {
          ...targetOperation,
          variableDefinitions: newVariableDefinitions,
        };

        const existingDefs = newDoc.definitions;

        const newDefinitions = existingDefs.map(existingOperation => {
          if (targetOperation === existingOperation) {
            return newOperation;
          }
          return existingOperation;
        });

        const finalDoc = {
          ...newDoc,
          definitions: newDefinitions,
        };

        onCommit(finalDoc);
      }
    }
  }

  function devariablize() {
    /**
     * 1. Find the current variable definition in the operation def
     * 2. Extract its value
     * 3. Replace the current arg value
     * 4. Visit the resulting operation to see if there are any other usages of the variable
     * 5. If not, remove the variableDefinition
     */
    if (!argValue?.name?.value) {
      return;
    }

    const variableName = argValue.name.value;
    const variableDefinition = (
      definition.variableDefinitions || []
    ).find(varDef => varDef.variable.name.value === variableName);

    if (!variableDefinition) return;

    const { defaultValue } = variableDefinition;

    const newDoc = setArgValue(defaultValue, { commit: false });

    if (newDoc) {
      const targetOperation = newDoc.definitions.find(d => d.name.value === definition.name.value);
      if (!targetOperation) return;

      // After de-variabilizing, see if the variable is still in use. If not, remove it.
      let variableUseCount = 0;

      visit(targetOperation, {
        Variable(node) {
          if (node.name.value === variableName) {
            variableUseCount += 1;
          }
        },
      });

      let newVariableDefinitions = targetOperation.variableDefinitions || [];

      // A variable is in use if it shows up at least twice (once in the definition, once in the selection)
      if (variableUseCount < 2) {
        newVariableDefinitions = newVariableDefinitions.filter(
          varDef => varDef.variable.name.value !== variableName,
        );
      }

      const newOperation = {
        ...targetOperation,
        variableDefinitions: newVariableDefinitions,
      };

      const existingDefs = newDoc.definitions;

      const newDefinitions = existingDefs.map(existingOperation => {
        if (targetOperation === existingOperation) {
          return newOperation;
        }
        return existingOperation;
      });

      const finalDoc = {
        ...newDoc,
        definitions: newDefinitions,
      };

      onCommit(finalDoc);
    }
  }

  function makeInput() {
    if (argValue && isInputObjectType(argType)) {
      if (argValue?.kind !== "ObjectValue") {
        console.error("Expected Object but received", argType, argValue);
      }

      if (argValue?.kind === "ObjectValue") {
        const fields = argType.getFields();
        return (
          <div style={{ marginLeft: 16 }}>
            {
              Object.keys(fields)
                .sort()
                .map(fieldName => (
                  <InputArgView
                    key={fieldName}
                    arg={fields[fieldName]}
                    parentField={parentField}
                    selection={argValue}
                    modifyFields={setArgFields}
                    getDefaultScalarArgValue={getDefaultScalarArgValue}
                    makeDefaultArg={makeDefaultArg}
                    onRunOperation={onRunOperation}
                    onCommit={onCommit}
                    definition={definition}
                  />
                ))
            }
          </div>
        );
      }
    }
    return <ArgInput argValue={argValue} argType={argType} setArgValue={setArgValue} />;
  }

  const variablizeActionButton = displayArgActions
    ? (
      <button
        type="submit"
        className="graphiql-explorer-action-button"
        title={
          isArgValueVariable
            ? "Remove the variable"
            : "Extract the current value into a GraphQL variable"
        }
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();

          if (isArgValueVariable) {
            devariablize();
          } else {
            variablize();
          }
        }}
      >
        <span className="graphiql-explorer-variable-action">$</span>
      </button>
    )
    : null;

  return (
    <div
      data-arg-name={arg.name}
      data-arg-type={argType.name}
      className="graphiql-explorer-arg"
    >
      <span
        onClick={() => {
          const shouldAdd = !argValue;
          if (shouldAdd) {
            addArg(true);
          } else {
            removeArg(true);
          }
          setDisplayArgActions(shouldAdd);
        }}
      >
        {isInputObjectType(argType)
          ? <Arrow open={!!argValue} />
          : <Checkbox checked={!!argValue} />}
        <span
          className="graphiql-explorer-arg-name"
          title={arg.description}
          onMouseEnter={() => {
            // Make implementation a bit easier and only show 'variablize' action if arg is already added
            if (argValue !== null && typeof argValue !== "undefined") {
              setDisplayArgActions(true);
            }
          }}
          onMouseLeave={() => setDisplayArgActions(false)}
        >
          {arg.name}
          {isRequiredArgument(arg) ? "*" : ""}
          :
          {variablizeActionButton}
          {" "}
        </span>
        {" "}
      </span>
      {makeInput() || <span />}
      {" "}
    </div>
  );
}

export default function ArgView({
  selection,
  getDefaultScalarArgValue,
  makeDefaultArg,
  parentField,
  arg,
  modifyArguments,
  onRunOperation,
  onCommit,
  definition,
}) {
  let previousArgSelection;

  const argSelection = (selection.arguments || []).find(
    a => a.name.value === arg.name,
  );
  const argType = unwrapInputType(arg.type);

  function removeArg(commit) {
    previousArgSelection = argSelection;
    return modifyArguments(
      (selection.arguments || []).filter(s => s !== argSelection),
      commit,
    );
  }

  function addArg(commit) {
    let argSel;
    if (previousArgSelection) {
      argSel = previousArgSelection;
    } else if (isInputObjectType(argType)) {
      const fields = argType.getFields();
      argSel = {
        kind: "Argument",
        name: {
          kind: "Name",
          value: arg.name,
        },
        value: {
          kind: "ObjectValue",
          fields: defaultInputObjectFields(
            getDefaultScalarArgValue,
            makeDefaultArg,
            parentField,
            Object.keys(fields)
              .map(k => fields[k]),
          ),
        },
      };
    } else if (isLeafType(argType)) {
      argSel = {
        kind: "Argument",
        name: {
          kind: "Name",
          value: arg.name,
        },
        value: getDefaultScalarArgValue(parentField, arg, argType),
      };
    }

    if (!argSel) {
      console.error("Unable to add arg for argType", argType);
      return null;
    }

    return modifyArguments(
      [...(selection.arguments || []), argSel],
      commit,
    );
  }

  function setArgValue(event, options) {
    if (!isHandleable(event, argSelection, argType)) {
      console.warn("Unable to handle non leaf types in ArgView._setArgValue");
      return null;
    }

    let targetValue;
    let value;

    if (event === null || typeof event === "undefined") {
      value = null;
    } else if (event.target && typeof event.target.value === "string") {
      targetValue = event.target.value;
      value = coerceArgValue(argType, targetValue);
    } else if (!event.target && event.kind === "VariableDefinition") {
      targetValue = event;
      value = targetValue.variable;
    } else if (typeof event.kind === "string") {
      value = event;
    }

    return modifyArguments(
      (selection.arguments || []).map(a => (a === argSelection
        ? {
          ...a,
          value,
        }
        : a)),
      options,
    );
  }

  function setArgFields(fields, commit) {
    if (!argSelection) {
      console.error("missing arg selection when setting arg value");
      return null;
    }

    return modifyArguments(
      (selection.arguments || []).map(a => (a === argSelection
        ? {
          ...a,
          value: {
            kind: "ObjectValue",
            fields,
          },
        }
        : a)),
      commit,
    );
  }

  return (
    <AbstractArgView
      argValue={argSelection ? argSelection.value : null}
      arg={arg}
      parentField={parentField}
      addArg={addArg}
      removeArg={removeArg}
      setArgFields={setArgFields}
      setArgValue={setArgValue}
      getDefaultScalarArgValue={getDefaultScalarArgValue}
      makeDefaultArg={makeDefaultArg}
      onRunOperation={onRunOperation}
      onCommit={onCommit}
      definition={definition}
    />
  );
}
