import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useClientConfiguration from "../../hooks/useClientConfiguration";
import useFormData from "../../hooks/useFormData";
import BaseForm from "./common/BaseForm";

const uiSchema = {
  lock: {
    "ui:help": "Leave unselected for Live",
  },
};

export default function PublishANNLegacyForm({ children, studyType, client, setTaskConfig, submitHandler, formDataProp, setIsRequired }) {
  const dispatch = useDispatch();
  const formOptions = useSelector((state) => state.formOptions);
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  useClientConfiguration(
    setFormData,
    studyType,
    client,
    dispatch,
  );

  const annLegacySchema = {
    title: "Publish Legacy",
    description: "Create or update consortium data to athena",
    type: "object",
    required: [
      "start_quarter",
      "end_quarter",
    ],
    properties: {
      start_quarter: {
        type: "string",
        title: "Start Quarter",
        enum: formOptions.quarters,
      },
      end_quarter: {
        type: "string",
        title: "End Quarter",
        enum: formOptions.quarters,
      },
      lock: {
        type: "string",
        title: "Lock (Data Set)",
        enum: formOptions.locksANN,
      },
    },
  };

  useEffect(() => {
    if (annLegacySchema.required) {
      setIsRequired((prevState) => ([...prevState, ...annLegacySchema.required]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsRequired]);

  return (
    <BaseForm
      schema={annLegacySchema}
      uiSchema={uiSchema}
      formData={formData}
      submitHandler={submitHandler}
      setFormData={setFormData}
    >
      {children}
    </BaseForm>
  );
}
