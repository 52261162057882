const currencyFormatter = (currency, sign) => {
  const currencyDec = parseFloat(currency)?.toFixed(2);
  const formatted = currencyDec?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return formatted?.startsWith("-") ? `${sign} (${formatted?.replace("-", "")})` : `${sign} ${formatted}`;
};
const applyCellStyle = (cellOfComment) => {
  if (cellOfComment?.toLowerCase()?.includes("confirmed")) {
    return {
      boxShadow: "0px 0px 5px rgba(0,0,125,3)",
      backgroundColor: "#70AD47",
    };
  }
  if (cellOfComment?.toLowerCase()?.includes("pending")) {
    return {
      boxShadow: "0px 0px 5px rgba(0,0,125,3)",
      backgroundColor: "#FFFF00",
    };
  }
  return {
    boxShadow: "0px 0px 5px rgba(0,0,125,3)",
  };
};

const percentageFormatter = (value, sign) => {
  if (value) { return `${value?.toFixed(2)} ${sign}`; }

  return "";
};

const removeCellStyle = (cellOfComment) => {
  if (cellOfComment?.toLowerCase()?.includes("confirmed")) {
    return {
      backgroundColor: "#70AD47",
    };
  }
  if (cellOfComment?.toLowerCase()?.includes("pending")) {
    return {
      backgroundColor: "#FFFF00",
    };
  }
  return {};
};

export function applyFormatting(inputData, isEditable) {
  const formattedColumns = inputData?.columns?.map((column) => {
    if (column.headerName.includes("$")) {
      return { ...column, valueFormatter: params => currencyFormatter(params.data[column.field], "$") };
    }
    if (column.headerName.includes("%")) {
      return { ...column, valueFormatter: params => percentageFormatter(params.data[column.field], "%") };
    }
    if (column.headerName === "Comments") {
      if (isEditable) {
        return { ...column,
          cellStyle: params => applyCellStyle(params.data[column.field]),
          type: "editableColumn",
        };
      }

      return {
        ...column,
        cellStyle: params => removeCellStyle(params.data[column.field]),
        type: "editableColumn",
      };
    }
    return { ...column };
  });
  return formattedColumns;
}
