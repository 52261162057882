import React, { useMemo, useState, useEffect } from "react";
import {
  ListAlt as TaskStatusIcon,
  Equalizer as FileMonitorIcon,
  AccountTree as AnalystWorkflowStatusIcon,
} from "@mui/icons-material";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useLocation } from "react-router-dom";
import AnalystWorkflowStatus from "../../analysts_workflow/AnalystWorkflowStatus";
import FileLoadMonitor from "../dashboard_monitoring/FileLoadMonitor";
import StatusTable from "./table/index";

function TabSelectorView({ ddTaskOnly = false }) {
  const [selectedTab, setSelectedTab] = useState("task");
  const location = useLocation();

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    (location.state
    && location.state.selectedTab) && setSelectedTab(location.state.selectedTab);
  }, [location.state]);

  const tabContents = useMemo(() => {
    if (selectedTab === "task") {
      return <StatusTable ddTaskOnly={ddTaskOnly} />;
    }
    if (selectedTab === "analystWorkflow") {
      return <AnalystWorkflowStatus />;
    }
    if (selectedTab === "fileLoadMonitor") {
      return <FileLoadMonitor />;
    }
    return <StatusTable ddTaskOnly={ddTaskOnly} />;
  }, [selectedTab, ddTaskOnly]);
  return (
    <Grid container spacing={2}>
      <Box style={{ width: "100%", marginBottom: "15px" }}>
        <Tabs
          value={selectedTab}
          onChange={(e, tabIndex) => { setSelectedTab(tabIndex); }}
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab value="task" label="Task Status" icon={<TaskStatusIcon />} />
          <Tab value="analystWorkflow" label="Workflow Status" icon={<AnalystWorkflowStatusIcon />} />
          <Tab value="fileLoadMonitor" label="File Load Monitor" icon={<FileMonitorIcon />} />
        </Tabs>
      </Box>
      <Box style={{ width: "100%", marginLeft: 10 }}>
        {tabContents}
      </Box>
    </Grid>
  );
}

export default TabSelectorView;
