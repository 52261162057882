import { API } from "./api";

export const fetcher = url => API.request({ url, method: "GET" }).then(res => res.data);
export const poster = (url, payload) => API.request({ url, data: payload, method: "POST" }).then((res) => res.data);
export const updater = (url, payload) => API.request({ url, data: payload, method: "PUT" }).then((res) => res.data);
export const deleter = (url, payload) => API.request({ url, data: payload, method: "DELETE" }).then((res) => res.data);

export const customRetry = {
  onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
    // Never retry for a specific key.
    if (key.includes("//") || key.includes("/?")) return;

    // Only retry up to 3 times.
    if (retryCount >= 3) return;

    // Retry after 5 seconds.
    setTimeout(() => revalidate({ retryCount }), 5000);
  },
};
