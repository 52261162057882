import React, { useEffect, useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Dialog,
  DialogContent,
  Box,
  MenuItem,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import moment from "moment-timezone";
import { nanoid } from "nanoid";
import { useParams } from "react-router-dom";
import CountDown from "../../components/CountDown";
import { WorkflowDiagram } from "../../components/DisplayFlow";
import CircularProgress from "../../components/Spinner";
import usePageTitle from "../../hooks/usePageTitle";
import { API } from "../../utils/api";
import { CreateWorkflow } from "../tasks/create_wizard/WorkflowModal";

const tz = moment().tz(moment.tz.guess()).format("z");

const useStyles = makeStyles((theme) => ({
  cloneButton: {
    width: "fit-content",
    margin: "1.5em",
    backgroundColor: theme.palette.secondary.main,
  },
  root: {
    minWidth: 275,
    margin: "1.5em",
  },
  title: {
    fontSize: 14,
  },
}));

export default function WorkflowDetails() {
  const classes = useStyles();
  const [workflowData, setWorkflowData] = useState(null);
  const { workflowNameParam } = useParams();
  const [refresh, setRefresh] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  usePageTitle("Workflow Details");
  const handleRefresh = () => {
    setRefresh(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  useEffect(() => {
    const url = "/workflow-tasks?limit=100";
    API.request({
      url,
      method: "GET",
      params: { workflow_name: workflowNameParam },
    }).then(
      (response) => {
        setWorkflowData(response.data.workflowDetails);
      },
      (error) => {
        console.error(error);
        setErrorMessage("Invalid Workflow Name");
      },
    );
  }, [workflowNameParam]);

  if (workflowData) {
    return (
      <Grid container>
        <Grid item xs={12}>
          {openDialog ? (
            <Dialog
              open={openDialog}
              fullWidth
              maxWidth="xs"
              classes={{
                paper: classes.dialogPaper,
              }}
            >
              <Box style={{ margin: 8, height: "50%" }}>
                <CreateWorkflow
                  handleClose={handleClose}
                  clone
                  workflowId={workflowData.workflowId}
                  workflowName={workflowData.workflowName}
                />

                <Dialog
                  // open={loading}
                  PaperProps={{
                    style: {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    },
                  }}
                  maxWidth="xs"
                >
                  <DialogContent>
                    <CircularProgress
                      style={{ width: "50px", height: "50px" }}
                    />
                  </DialogContent>
                </Dialog>
              </Box>
            </Dialog>
          ) : (
            ""
          )}
          <div>
            <MenuItem
              style={{
                width: "fit-content",
                background: "#1dbfa3",
                borderRadius: "8px",
                marginLeft: "25px",
              }}
              onClick={() => setOpenDialog(true)}
            >
              Clone
            </MenuItem>
          </div>
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Display Flow
              </Typography>
            </CardContent>
            <Divider />
            <CardActions style={{ padding: "15px" }}>
              {workflowData?.status === "RUNNING" && <CountDown duration={10} onFinish={handleRefresh} text="Refreshing in" />}
            </CardActions>
            <CardActions>
              <Container fullWidth maxWidth={false} style={{ overflow: "scroll" }}>
                <div style={{ width: "max-content", maxHeight: "200px", margin: "5px auto 35px" }}>
                  <WorkflowDiagram workflowName={workflowNameParam} refresh={refresh} setRefresh={setRefresh} />
                </div>
              </Container>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* SUMMARY */}
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Summary
              </Typography>
            </CardContent>
            <List>
              <Divider component="li" />
              <ListItem key="workflowname">
                <ListItemText
                  primary="Workflow Name"
                  secondary={workflowData.workflowName}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem key="workflowid">
                <ListItemText
                  primary="Workflow ID"
                  secondary={workflowData.workflowId}
                />
              </ListItem>
              {workflowData.cloned_from ? (
                <>
                  <Divider component="li" />
                  <ListItem key="cloned_from">
                    <ListItemText primary="Cloned From" secondary={workflowData.cloned_from} />
                  </ListItem>
                </>
              ) : null}
              <Divider component="li" />
              <ListItem key="username">
                <ListItemText
                  primary="Created By"
                  secondary={workflowData.username}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem key="runBy">
                <ListItemText
                  primary="Run By"
                  secondary={workflowData.run_by}
                />
              </ListItem>
            </List>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* HISTORY */}
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Status History
              </Typography>
            </CardContent>
            <List>
              {workflowData.statusHistory.map((statusHistory) => (
                <div key={statusHistory.date}>
                  <Divider component="li" />
                  <ListItem key={nanoid()}>
                    <ListItemText
                      primary={statusHistory.status}
                      secondary={`${moment(moment.parseZone(statusHistory?.date).local()).format("MMMM Do YYYY, h:mm:ss a")} ${tz}`}
                    />
                  </ListItem>
                </div>
              ))}
            </List>
          </Card>
        </Grid>
      </Grid>
    );
  }

  return (
    <Card>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {errorMessage || "Loading..."}
        </Typography>
      </CardContent>
    </Card>
  );
}
