import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
  name: "firmInclusions",
  initialState: {
    exclude_fidelity: false,
  },
  reducers: {
    setExcludeFidelity: (state, action) => {
      state.exclude_fidelity = action.payload;
    },
    setExcludeBD: (state, action) => {
      state.exclude_bd = action.payload;
    },
    setExcludeRIA: (state, action) => {
      state.exclude_ria = action.payload;
    },
  },
});
