import React from "react";
import { Typography } from "@mui/material";
import * as Yup from "yup";

import Lock from "../../../components/input/Formik/Lock";
import TimePeriod from "../../../components/input/Formik/TimePeriod";
import LMSCheckbox from "../../../components/input/Formik/base/LMSCheckBox";
import { LMSForm } from "../common/LMSForm";

export const AnnSiIdentifierMappingSchema = Yup.object().shape({
  time_period: Yup.string().required("Required"),
  start_quarter: Yup.string().required("Required"),
  end_quarter: Yup.string().required("Required"),
});

export default function AnnSiIdentifierMappingForm({
  title,
  client,
  studyType,
  step,
  setStep,
  submitHandler,
  taskConfig,
}) {
  return (
    <LMSForm
      client={client}
      studyType={studyType}
      step={step}
      setStep={setStep}
      submitHandler={submitHandler}
      taskConfig={taskConfig}
      validationSchema={AnnSiIdentifierMappingSchema}
      initialValues={{
        time_period: 8,
      }}
      title={title}
    >
      <Typography variant="h4" style={{ backgroundColor: "orange" }}>Discovery Data</Typography>
      <TimePeriod required />
      <Lock studyType="ann" helperText="Leave unselected for Live" />
      <LMSCheckbox label="Exclude broker id column" name="exclude_broker_id_column" />
      <LMSCheckbox label="Add Sales_team column" name="add_sales_team_column" tooltipText="This option is specific to AIG" />
    </LMSForm>
  );
}
