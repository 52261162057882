/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from "react";
import { Select, MenuItem } from "@mui/material";
import useSWR from "swr";
import MaterialTable from "../../../components/Table";
import { getDataRevisionsAliasOverride, getDataRevisions, updateDataRevisionAliasOverride, deleteDataRevisionAliasOverride, createDataRevisionAliasOverride, getMisightClients, getDataRevisionsAlias } from "../../../utils/api";

export default function DataRevisionAliasOverrides() {
  const { data, error, mutate } = useSWR("/datarevisions/aliasoverride", getDataRevisionsAliasOverride);
  const { data: datarevisions, error: datarevisionsError } = useSWR("/datarevisions", getDataRevisions);
  const { data: clients, error: clientsError } = useSWR("/misight/clients", getMisightClients);
  const { data: datarevisionsAlias, error: datarevisionsAliasError } = useSWR("/datarevisions/alias", getDataRevisionsAlias);

  const [editValue, setEditValue] = useState({ client_mnemonic: "", alias: "", revision: "" });
  const [loading, setLoading] = useState(false);

  if (error || datarevisionsError || clientsError || datarevisionsAliasError) return <div>Error loading...</div>;

  const columns = [
    {
      header: "Client",
      accessorKey: "client_mnemonic",
      Edit: () => (
        <Select
          value={editValue.client_mnemonic}
          onChange={(event) => setEditValue({ ...editValue, client_mnemonic: event.target.value })}
        >
          {clients.map(s => (
            <MenuItem
              key={s.id}
              value={s.mnemonic}
            >
              {s.mnemonic}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      header: "Alias",
      accessorKey: "alias",
      Edit: () => (
        <Select
          value={editValue.alias}
          onChange={(event) => setEditValue({ ...editValue, alias: event.target.value })}
        >
          {datarevisionsAlias.map(s => (
            <MenuItem
              key={s.id}
              value={s.alias}
            >
              {s.display_alias}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      header: "Revision",
      accessorKey: "revision",
      enableEditing: true,
      Edit: () => (
        <Select
          value={editValue.revision}
          onChange={(event) => setEditValue({ ...editValue, revision: event.target.value })}
        >
          {datarevisions.map(s => (
            <MenuItem
              key={s.id}
              value={s.revision}
            >
              {s.revision}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      header: "Notes",
      accessorKey: "notes",
    },
    {
      header: "Updated By",
      accessorKey: "updated_by",
      enableEditing: false,
    },
    {
      header: "Updated At",
      accessorKey: "updated_at",
      enableEditing: false,
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <MaterialTable
        isLoading={!(data && datarevisions && clients && datarevisionsAlias) || loading}
        columns={columns}
        data={data ?? []}
        title="Client Overrides"
        editable={{
          onRowUpdate: async (newData, oldData) => {
            setLoading(true);
            const selectedClient = clients.find((client) => newData.client_mnemonic === client.mnemonic);
            const newObj = datarevisions.find((datarev) => newData.revision === datarev.revision);
            await updateDataRevisionAliasOverride(oldData.id, selectedClient.id, { dataset_revision_id: newObj.id, notes: newData.notes }).finally(() => setLoading(false));
            mutate();
          },
          onRowDelete: async (oldData) => {
            setLoading(true);
            await deleteDataRevisionAliasOverride(oldData.id, oldData.client_id).finally(() => setLoading(false));
            mutate();
          },
          onRowAdd: async (newData) => {
            setLoading(true);
            const selectedClient = clients.find((client) => editValue.client_mnemonic === client.mnemonic);
            const selectedAlias = datarevisionsAlias.find((dra) => editValue.alias === dra.alias);
            const selectedDataRevision = datarevisions.find((dr) => editValue.revision === dr.revision);
            await createDataRevisionAliasOverride({ client_id: selectedClient?.id, alias: selectedAlias?.alias, dataset_revision_id: selectedDataRevision?.id, notes: newData.notes }).finally(() => setLoading(false));
            mutate();
          },
        }}
      />
    </div>
  );
}
