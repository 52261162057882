import React from "react";
import { useSelector } from "react-redux";

import useClientConfiguration from "../../hooks/useClientConfiguration";
import useFormData from "../../hooks/useFormData";
import * as SharedDefinitions from "../../utils/SharedDefinitions";
import BaseForm from "./common/BaseForm";

const uiSchema = {
  lock: {
    "ui:help": "Leave unselected for Live",
  },
  line_of_business: {
    "ui:help": "Leave unselected for both",
  },
};

export default function AnnOTTForm({ children, studyType, client, setTaskConfig, submitHandler, formDataProp }) {
  const formOptions = useSelector((state) => state.formOptions);
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  useClientConfiguration(setFormData, studyType, client);

  const annOTTSchema = {
    title: "ANN OTT Form",
    description: "ANN OTT",
    type: "object",
    required: [
      "start_quarter",
      "end_quarter",
      "ott_type_settings",
      "territory_settings"],
    properties: {
      start_quarter: {
        type: "string",
        title: "Start Quarter",
        enum: formOptions.quarters,
      },
      end_quarter: {
        type: "string",
        title: "End Quarter",
        enum: formOptions.quarters,
      },
      lock: {
        type: "string",
        title: "Lock (Data Set)",
        enum: formOptions.locksANN,
      },
      production_cycle: {
        type: "string",
        title: "Production Cycle",
        enum: formOptions.quarters,
      },
      annuity_types: SharedDefinitions.annuityTypes,
      include_indirect_transactions: {
        type: "boolean",
        title: "Include Indirect Transactions (Previously misnamed Sales)",
      },
      ott_type_settings: {
        type: "object",
        title: "OTT Type Settings",
        required: [
          "ott_type",
        ],
        properties: {
          ott_type: {
            type: "string",
            title: "OTT Type",
            oneOf: [
              {
                const: "bd",
                title: "BD",
              },
              {
                const: "bd_territory",
                title: "BD/Territory",
              },
              {
                const: "bd_territory_per_file",
                title: "BD/Territory per File",
              },
              {
                const: "bd_zip",
                title: "BD/Zip",
              },
              {
                const: "territory",
                title: "Territory",
              },
              {
                const: "territory_per_file",
                title: "Territory per File",
              },
              {
                const: "zip",
                title: "Zip",
              },
              {
                const: "zip_territory",
                title: "Zip/Territory",
              },
              {
                const: "zip_territory_per_file",
                title: "Zip/Territory per File",
              },
            ],
          },
          display_territory_per_territory_file: {
            type: "boolean",
            title: "Include Territory Column in Each Per Territory File",
            default: false,
          },
          per_file_terr_map_as_group: {
            type: "string",
            title: "Territory Grouping Per File",
            enum: SharedDefinitions.getOptionsByClient(formOptions.territoryGroupsANN, client),
          },
        },
      },
      broker_zip_exclusions_settings: {
        type: "object",
        title: "Broker/Zip Exclusions Settings",
        properties: {
          fia_exclusion_settings: {
            type: "object",
            title: "FIA Broker/Zip Exclusions",
            properties: SharedDefinitions.brokerZipExclusions,
          },
          va_exclusion_settings: {
            type: "object",
            title: "VA Broker/Zip Exclusions",
            properties: SharedDefinitions.brokerZipExclusions,
          },
        },
      },
      verified_address_exclusions: SharedDefinitions.verifiedAddressExclusions,
      include_mf_data: {
        type: "boolean",
        title: "Include MF Data",
      },
      include_top_categories: {
        type: "boolean",
        title: "Include Top Category Sections",
      },
      include_all_mstar_categories: {
        type: "boolean",
        title: "Include All Category Sections from Morningstar",
      },
      territory_settings: {
        type: "object",
        title: "Territories",
        required: ["territory_mode"],
        properties: {
          territory_mode: {
            type: "string",
            title: "Territory Mode",
            enum: ["combined", "split"],
            default: "combined",
          },
        },
        dependencies: {
          territory_mode: SharedDefinitions.getTerritoryModeVA(formOptions, client),
        },
      },
      omit_territory_column: {
        type: "boolean",
        title: "Omit Territory Column (Previously Exclude Territory Column)",
        default: false,
      },
      omit_metro_column: {
        type: "boolean",
        title: "Omit Metro Column (Previously Exclude Metro Column)",
        default: false,
      },
      omit_zone_column: {
        type: "boolean",
        title: "Omit Zone Column (Previously Exclude Zone Column)",
        description: "Omit zone column from report",
      },
      disable_phantom_exclusions: {
        type: "boolean",
        title: "Disable Phantom Exclusions",
      },
      combine_clients: {
        type: "array",
        title: "Combine Clients",
        items: {
          type: "string",
        },
      },
      separate_client_columns: {
        type: "boolean",
        title: "Separate client columns",
      },
      use_va_alternate_broker_names: {
        type: "boolean",
        title: "Use VA Alternate Broker Names",
      },
      initial_only: {
        type: "boolean",
        title: "Initial Only Sales Deposit Type Types (Leave both this and below blank to keep including both)",
        description: "Limit the report to only records with a initial type (DEPOSIT_TYPE of 1)",
      },
      subsequent_only: {
        type: "boolean",
        title: "Subsequent Only Sales Deposit Type (Leave both this and above blank to keep including both)",
        description: "Limit the report to only records with a subsequent type (DEPOSIT_TYPE of 0)",
      },
      include_other_share_class: {
        type: "boolean",
        title: "Include Other Share Class",
        description: "Include other share class in report",
      },
      sales_channels_brokers_only: {
        type: "boolean",
        title: "Client Brokers in Channel Map Only",
        description: "Limit report to only the brokers the client reported sales for and those in their channel map",
      },
      exclude_channel_col: {
        type: "boolean",
        title: "Exclude Channel Column",
      },
      rebalance: {
        type: "boolean",
        title: "Include Rebalances",
      },
      va_product_type: SharedDefinitions.vaProductType,
    },
  };

  return (
    <BaseForm
      schema={annOTTSchema}
      uiSchema={uiSchema}
      formData={formData}
      submitHandler={submitHandler}
      setFormData={setFormData}
    >
      {children}
    </BaseForm>
  );
}
