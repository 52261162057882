import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useFormData from "../../hooks/useFormData";
import BaseForm from "./common/BaseForm";

const uiSchema = {
  lock: {
    "ui:help": "Leave unselected for Live",
  },
};

export default function MfAnalyticsFeedForm({ children, setTaskConfig, submitHandler, formDataProp, setIsRequired }) {
  const formOptions = useSelector((state) => state.formOptions);
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  const mfAnalyticsFeedSchema = {
    title: "MF Analytics Feed Form",
    description: "MF Analytics Feed",
    type: "object",
    required: ["end_quarter"],
    properties: {
      end_quarter: {
        type: "string",
        title: "End Quarter",
        enum: formOptions.quarters,
      },
      lock: {
        type: "string",
        title: "Lock (Data Set)",
        enum: formOptions.locksMF,
      },
      version: {
        type: "string",
        title: "Feed Version",
      },
    },
  };
  useEffect(() => {
    if (mfAnalyticsFeedSchema.required) {
      setIsRequired((prevState) => ([...prevState, ...mfAnalyticsFeedSchema.required]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsRequired]);

  return (
    <BaseForm
      schema={mfAnalyticsFeedSchema}
      uiSchema={uiSchema}
      formData={formData}
      submitHandler={submitHandler}
      setFormData={setFormData}
    >
      {children}
    </BaseForm>
  );
}
