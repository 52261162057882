import React, { useState } from "react";
import {
  Box,
  Button,
  CardContent,
  Divider,
  List,
  ListItem,
  MenuItem,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import { nanoid } from "nanoid";
import CircularProgress from "../../../components/Spinner";
import { API } from "../../../utils/api";

async function getLogs(url, token, setJobLogs, setFetching) {
  return API.request({
    url,
    method: "GET",
    params: { token },
  }).then((response) => {
    const displayedEvents = response.data.events.length ? response.data.events : [];
    const displayedLogs = {
      displayedEvents,
      nextBackwardToken: response.data.nextBackwardToken,
      nextForwardToken: response.data.nextForwardToken,
    };
    return displayedLogs;
  }, (e) => {
    console.error(e);
    const errorMsg = [{ message: "An error occurred when trying to retrieving logs" }];
    setJobLogs({ displayedEvents: errorMsg });
    setFetching(false);
    return null;
  });
}

export default function TaskLogDetails({ taskId, logsData }) {
  const [jobLogs, setJobLogs] = useState({});
  const [value, setValue] = useState(0);
  const [cache, setCache] = useState({});
  const [fetching, setFetching] = useState();
  const [currLogParams, setCurrLogParams] = useState({});

  async function fetchLogs(group, stream, step, awsRequestId, token = null) {
    setCurrLogParams({ group, stream, step, awsRequestId, token });
    if (cache[`${step}-${token}`]) {
      setJobLogs(cache[`${step}-${token}`]);
    } else {
      setFetching(true);
      let url = `/tasks/${taskId}/logs/events/${group}/${stream}`;
      if (awsRequestId) {
        url += `?AWSRequestId=${awsRequestId}`;
      }
      let displayedLogs = await getLogs(url, token, setJobLogs, setFetching);
      if (displayedLogs.displayedEvents && displayedLogs.displayedEvents.length === 0 && !token) {
        // Fetch logs from head if no logs found fetching from bottom
        url += "?startFromHead=1";
        displayedLogs = await getLogs(url, token, setJobLogs, setFetching);
      }
      if (displayedLogs.displayedEvents && displayedLogs.displayedEvents.length === 0) {
        // If call is successful but there are no logs found, still display a message
        displayedLogs.displayedEvents = [{ message: "No logs found" }];
      }
      setCache((prevCache) => ({ ...prevCache, [`${step}-${token}`]: displayedLogs }));
      setJobLogs(displayedLogs);
      setFetching(false);
    }
  }

  const logPanel = (
    <List>
      <Paper elevation={3}>
        {jobLogs.displayedEvents?.map((index) => (
          <ListItem key={nanoid()} style={{ display: "inline-block", wordBreak: "break-word" }}>
            {index.message}
          </ListItem>
        ))}
      </Paper>
      {jobLogs && Object.keys(jobLogs).length > 0
        ? (
          <Box flexDirection="row">
            <Button
              disabled={currLogParams.token === jobLogs.nextBackwardToken}
              onClick={() => (
                fetchLogs(
                  currLogParams.group,
                  currLogParams.stream,
                  currLogParams.step,
                  currLogParams.awsRequestId,
                  jobLogs.nextBackwardToken,
                )
              )}
            >
              PREVIOUS
            </Button>
            <Button
              disabled={currLogParams.token === jobLogs.nextForwardToken}
              onClick={() => (
                fetchLogs(
                  currLogParams.group,
                  currLogParams.stream,
                  currLogParams.step,
                  currLogParams.awsRequestId,
                  jobLogs.nextForwardToken,
                )
              )}
            >
              NEXT
            </Button>
          </Box>
        )
        : null}
    </List>
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (logsData.length) {
    return (
      <>
        <CardContent>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {logsData.map((log) => (
              <Tab
                key={nanoid()}
                wrapped
                onClick={() => fetchLogs(log.group, log.stream, log.step_type, log.aws_request_id)}
                label={log.step_type}
                style={{ maxWidth: "max-content" }}
              />
            ))}
          </Tabs>
          <Divider />
        </CardContent>

        <CardContent>
          {fetching
            ? <CircularProgress />
            : logPanel}
        </CardContent>
      </>

    );
  }
  return (
    <MenuItem key="logDisplay" disabled>
      No Logs
    </MenuItem>
  );
}
