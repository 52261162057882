import React from "react";

export default function LMSErrorMessage({ error, name }) {
  if (!error) return null;
  return (
    <div
      id={`LMS-ERROR-MESSAGE-${name}`}
      style={{ color: "#d85f5f" }}
    >
      {error}
    </div>
  );
}
