// SettingsPage.js

import React, { useMemo } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import usePageTitle from "../../hooks/usePageTitle";
import { setTheme, addGroup, removeGroup } from "../../state";

const SettingsPage = () => {
  const dispatch = useDispatch();
  usePageTitle("Settings");
  // Theme the user chose
  const theme = useSelector((state) => state.userSettings.theme);
  // Groups the user chose to subscribe to
  const groups = useSelector((state) => state.userSettings.groups);
  // User privileges from Cognito
  const { cognitoGroups } = useSelector((state) => state.application);

  const groupOptions = useMemo(
    () => [
      ...cognitoGroups,
    ],
    [cognitoGroups],
  );

  const handleSetTheme = (selectedTheme) => {
    dispatch(setTheme(selectedTheme));
  };

  const handleToggleGroup = (group) => {
    if (groups.includes(group)) {
      dispatch(removeGroup(group));
    } else {
      dispatch(addGroup(group));
    }
  };

  return (
    <>
      <Box sx={{ textAlign: "center", marginTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          Theme
        </Typography>
        <Button
          variant={theme === "light" ? "contained" : "outlined"}
          onClick={() => handleSetTheme("light")}
          sx={{ mr: 2 }}
        >
          Light Theme
        </Button>
        <Button
          variant={theme === "dark" ? "contained" : "outlined"}
          onClick={() => handleSetTheme("dark")}
          sx={{ mr: 2 }}
        >
          Dark Theme
        </Button>
        <Button
          variant={theme === "legacy" ? "contained" : "outlined"}
          onClick={() => handleSetTheme("legacy")}
        >
          Legacy Theme
        </Button>
      </Box>
      <Box sx={{ textAlign: "center", marginTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          Groups
        </Typography>
        {groupOptions.map((group) => (
          <Button
            variant={groups.includes(group) ? "contained" : "outlined"}
            onClick={() => handleToggleGroup(group)}
            sx={{ mr: 2 }}
            key={group}
          >
            {group}
          </Button>
        ))}
      </Box>
    </>
  );
};

export default SettingsPage;
