import React from "react";
import ExclusionsTable from "./ExclusionsTable";
import LimitationsTable from "./LimitationsTable";

export default function Exclusions() {
  return (
    <div>
      <div style={{ padding: 20 }}>
        <ExclusionsTable />
      </div>
      <div style={{ padding: 20 }}>
        <LimitationsTable />
      </div>
    </div>
  );
}
