import React, { useMemo, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import StatusTable from "../../../../tasks/status/table/index";

export default function TabSelectorViewPreWarm({ ddTaskOnly = false }) {
  const location = useLocation();

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    (location.state
      && location.state.selectedTab);
  }, [location.state]);

  const tabContents = useMemo(() => <StatusTable ddTaskOnly={ddTaskOnly} pageTitle="Pre-Warm Status" preWarmOnly />, [ddTaskOnly]);
  return (
    <Grid>
      <Box style={{ width: "100%", marginBottom: "15px" }} />
      <Box style={{ width: "100%", marginLeft: 10 }}>
        {tabContents}
      </Box>
    </Grid>
  );
}
