import React from "react";
import { Add as AddIcon } from "@mui/icons-material";
import { Fab as FabMI } from "@mui/material";

export default function Fab({ onClick, color = "primary", offset = 0, children }) {
  const style = {
    margin: 0,
    top: "auto",
    right: 20 + (65 * offset),
    bottom: 80,
    left: "auto",
    zIndex: 500,
    position: "fixed",
  };

  return (
    <FabMI style={style} color={color} onClick={onClick}>
      {children || <AddIcon />}
    </FabMI>
  );
}
