import React from "react";
import { Button, Card, CardActions, CardContent, Grid, TextField } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  column: {
    flexBasis: "50%",
    margin: theme.spacing(1),
  },
  action: {
    justifyContent: "left",
  },
}));

export default function OfficeFilters({ filters, setFilters, getData, handleResetFilters }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="DD Physical Branch ID"
                name="dd_physical_branch_id"
                onChange={(event) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    dd_physical_branch_id: event.target.value,
                  }));
                }}
                value={filters?.dd_physical_branch_id}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button variant="outlined" color="secondary" onClick={getData}>Apply Filter</Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleResetFilters}
          >
            Reset Filter
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
