import { createTheme } from "@mui/material/styles";

import darkPalette from "./darkPalette";
import legacyPalette from "./legacyPalette";
import lightPalette from "./lightPalette";

const paletteMapping = {
  light: lightPalette,
  dark: darkPalette,
  legacy: legacyPalette,
};

export function createBraavosTheme(theme = "dark") {
  const palette = paletteMapping[theme] ?? darkPalette;

  return createTheme({
    palette,
    common: {
      tableHeader: {
        background: palette.background.nav,
        "& *": {
          color: palette.text.shaded,
        },
      },
      shadedPaper: {
        backgroundColor: palette.background.paperShaded,
      },
      chip: {
        baseChip: {},
        successChip: {
          color: palette.text.onBackground,
          backgroundColor: palette.success.secondary,
        },
        errorChip: {
          color: palette.text.onBackground,
          backgroundColor: palette.error.main,
        },
        abortedChip: {
          color: palette.text.onBackground,
          backgroundColor: palette.aborted.main,
        },
        runningChip: {
          color: palette.text.onBackground,
          backgroundColor: palette.running.main,
        },
        draftChip: {
          color: palette.text.onBackground,
          backgroundColor: palette.warning.main,
        },
        territoryChip: {
          minWidth: "200px",
        },
        queuedChip: {
          color: palette.text.onBackground,
          backgroundColor: palette.queued.main,
        },
        runningQueueChip: {
          color: palette.text.onBackground,
          backgroundColor: palette.running_queue.main,
        },
        notAttemptedChip: {
          color: palette.text.onBackground,
          backgroundColor: palette.not_attempted.main,
        },
        defaultTerritoryChip: {
          color: palette.text.onBackground,
          backgroundColor: palette.running.main,
          minWidth: "200px",
        },
      },
      cancelButton: {
        color: palette.text.onBackground,
        backgroundColor: palette.primary.light,
      },
      confirmButton: {
        marginRight: 10,
        color: palette.text.onBackground,
        backgroundColor: palette.secondary.light,
      },
      alerts: {
        maxHeight: 100,
        maxWidth: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        overflowWrap: "break-word",
      },
    },
    overrides: {
      MuiTableSortLabel: {
        root: {
          color: "#97a9bc",
          "&:hover": {
            color: palette.text.link,
          },
          "&:active": {
            color: palette.text.link,
          },
          "&:focus": {
            color: palette.text.link,
          },
          "&.MuiTableSortLabel-active": {
            color: palette.text.link,
          },
        },
      },
      MuiInputBase: {
        root: {
          maxWidth: "500px",
          minWidth: "fit-content",
        },
      },
      MuiOutlinedInput: {
        root: {
          "&:hover": {
            transform: "scale(1.01)",
          },
        },
      },
      MuiAlert: {
        standardSuccess: {
          backgroundColor: palette.success.main,
          color: palette.text.onBackground,
        },
        standardError: {
          backgroundColor: palette.error.main,
          color: palette.text.onBackground,
        },
        standardWarning: {
          backgroundColor: palette.warning.main,
          color: palette.text.onBackground,
        },
        standardInfo: {
          backgroundColor: palette.info.main,
          color: palette.text.onBackground,
        },
      },
    },
    props: {
      MuiTextField: {
        variant: "outlined",
      },
    },
    shape: {
      borderRadius: 10,
    },
  });
}
