import React, { useCallback, useEffect, useRef, useState } from "react";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { Checkbox, Fab, FormControlLabel, Typography } from "@mui/material";
import APISelectCreatable from "../../components/input/APISelectCreatable";
import OptionSelect from "../../components/input/OptionSelect";
import useTerrClientDescriptions from "../../hooks/useTerrClientDescriptions";

export const mapStudyTypeOptions = [
  { id: "BrightScope", label: "BrightScope" },
  { id: "MF LMS", label: "MF LMS" },
  { id: "ANN LMS", label: "ANN LMS" },
];

export default function TerrServiceTerritoryMapsForm({ setTaskConfig, setCsv, client, submitHandler, children }) {
  // State
  const [territoryMap, setTerritoryMap] = useState("");
  const [territoryMapName, setTerritoryMapName] = useState("");
  const [terrClientDescriptions, setTerrClientDescriptions] = useState({});
  const [loadingType, setLoadingType] = useState("REPLACEMENT");
  const [mapStudyType, setMapStudyType] = useState("");
  const [brightscopeDefault, setBrightscopeDefault] = useState(false);
  const [fileInputs, setFileInputs] = useState({
    territory_map_file: undefined,
    channel_map_file: undefined,
    territory_exceptions_file: undefined,
    sales_structure_file: undefined,
  });

  // Refs
  const fileRefs = {
    territory_map_file: useRef(),
    channel_map_file: useRef(),
    territory_exceptions_file: useRef(),
    sales_structure_file: useRef(),
  };

  const handleFormChange = useCallback(
    () => {
      setCsv(fileInputs);
      setTaskConfig((prevTaskConfig) => ({
        ...prevTaskConfig,
        territory_map_entity_id: territoryMap,
        territory_map_name: territoryMapName,
        load_type: loadingType,
        use_brightscope_default: brightscopeDefault,
      }));
    },
    [setCsv, fileInputs, setTaskConfig, territoryMap, territoryMapName, loadingType, brightscopeDefault],
  );

  useTerrClientDescriptions(setTerrClientDescriptions);

  useEffect(() => {
    handleFormChange();
  }, [territoryMap, territoryMapName, loadingType, fileInputs, brightscopeDefault, handleFormChange]);

  return (
    <form schema={{}} onSubmit={(e) => { e.preventDefault(); submitHandler(true, e); }} noValidate autoComplete="off" onChange={handleFormChange}>
      <Typography gutterBottom variant="h5" component="h2">
        Territory Map Version Upload Tool
      </Typography>
      <Typography variant="body2" color="textSecondary" component="h3">
        {`Client: ${terrClientDescriptions[client]}`}
      </Typography>
      <br />
      {/* --- TERRITORY MAP --- */}
      {Object.keys(fileRefs).map(fileKey => (
        <>
          <label htmlFor={fileKey}>
            <input
              required
              style={{ display: "none" }}
              id={fileKey}
              onChange={(e) => {
                setFileInputs({
                  ...fileInputs,
                  [fileKey]: e.target.files[0] });
              }}
              ref={fileRefs[fileKey]}
              type="file"
              accept="text/csv,.txt,.xlsx,.xls"
            />
            <Fab
              color={fileInputs[fileKey] ? "default" : "primary"}
              size="small"
              component="span"
              aria-label="add"
              variant="extended"
            >
              {fileInputs[fileKey] ? <DoneAllIcon /> : <PostAddIcon />}
              {" "}
              {fileInputs[fileKey] ? fileInputs[fileKey].name : fileKey}
            </Fab>
            {"  "}
          </label>
          <br />
        </>
      ))}
      <OptionSelect
        required
        style={{ width: 1000 }}
        value={mapStudyType}
        onChange={setMapStudyType}
        options={mapStudyTypeOptions}
        label="Map Study"
      />
      <APISelectCreatable
        required
        label="Territory Map"
        url={`/di/territory/clients/${encodeURIComponent(client)}/maps`}
        value={territoryMap}
        onChange={setTerritoryMap}
        showError={false}
        sort
        valueKey="sk"
        labelKey="territory_map_name"
        filter="study"
        filterValue={mapStudyType}
        createInputFields={["client_id", "territory_map_name"]}
        createOptionSelectFields={["study"]}
        createOptionSelectOptions={{ study: mapStudyTypeOptions }}
        createUrl={`/di/territory/clients/${encodeURIComponent(client)}/maps`}
        createInitialState={{ client_id: client, territory_map_name: "", study: mapStudyType }}
        additionalOnChange={setTerritoryMapName}
      />
      <OptionSelect
        required
        style={{ width: 1000 }}
        value={loadingType}
        onChange={setLoadingType}
        options={[
          { id: "REPLACEMENT", label: "Replacement" },
          { id: "SUPPLACEMENT", label: "Supplacement" },
          { id: "SUPPLEMENT", label: "Supplement" },
        ]}
        label="Loading Type"
      />
      <br />
      {mapStudyType === "BrightScope"
        ? <FormControlLabel control={(<Checkbox checked={brightscopeDefault} onChange={(e) => setBrightscopeDefault(e.target.checked)} inputProps={{ "aria-label": "primary checkbox" }} />)} label="Use Brightscope Default Territory Map" />
        : null}
      {children}
    </form>
  );
}
