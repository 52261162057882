import React, { useEffect, useState } from "react";
import { FormControl, FormControlLabel, InputLabel, Select, Snackbar, MenuItem, TextField, Typography, Checkbox } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import CSVUpload from "../../components/input/CSVUpload";
import OptionSelect from "../../components/input/OptionSelect";
import { fetching, fetchingDone } from "../../state";
import { API } from "../../utils/api";
import BaseForm from "./common/BaseForm";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const insertTypes = [
  { id: "append", label: "Supplement" },
  { id: "replace", label: "Replacement" },
];

const annTypes = [
  { id: "fia", label: "FIA" },
  { id: "va", label: "VA" },
];
const annSalesLoaderSchema = {
  type: "object",
  properties: {
    delete_start_quarter: {
      type: "object",
      title: "ANN Sales Loader",
    },
  },
};
export default function LoadAnnSalesForm({ children, taskConfig, setTaskConfig, csv, setCsv, submitHandler, studyType, setIsRequired }) {
  const formOptions = useSelector((state) => state.formOptions);
  const [jobRunFlag, setjobRunFlag] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsRequired([]);
    setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, study: studyType }));
    if (taskConfig.append_replace === "replace") {
      setIsRequired((prevState) => ([...prevState, "ann_type", "append_replace", "delete_start_quarter", "delete_end_quarter"]));
    } else {
      setIsRequired((prevState) => ([...prevState, "ann_type", "append_replace"]));
    }
    dispatch(fetching({ element: "selectAPI" }));
    API.request({
      url: "/tasks",
      method: "GET",
      params: { taskType: ["preprocessing_load_sales_ann"], status: "RUNNING" },
    })
      .then((response) => {
        const tasks = response.data.results;
        if (tasks.length !== 0) {
          console.log("Previous Job running, Submission denied");
          setjobRunFlag(false);
        } else {
          setjobRunFlag(true);
        }
        dispatch(fetchingDone({ element: "selectAPI" }));
      })
      .catch((e) => {
        console.error(e);
        dispatch(fetchingDone({ element: "selectAPI" }));
      });
  }, [dispatch, setTaskConfig, studyType, setIsRequired, taskConfig.append_replace]);

  return (
    <BaseForm
      schema={annSalesLoaderSchema}
      submitHandler={submitHandler}
      taskConfig={taskConfig}
    >
      <CSVUpload
        onChange={(update) => { setCsv({ attachment: update.csv }); }}
        value={{ csv, ...taskConfig }}
      />
      <FormControl fullWidth margin="normal">
        <OptionSelect
          required
          style={{ width: 500 }}
          value={taskConfig.append_replace || ""}
          onChange={(insertionType) => {
            /* eslint-disable no-param-reassign */
            if (taskConfig && taskConfig.delete_start_quarter) delete taskConfig.delete_start_quarter;
            if (taskConfig && taskConfig.delete_end_quarter) delete taskConfig.delete_end_quarter;
            /* eslint-disable no-param-reassign */
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, append_replace: insertionType }));
          }}
          options={insertTypes}
          label="Insertion Type"
        />
      </FormControl>
      {taskConfig.append_replace === "replace" && (
        <>
          <Typography variant="body1">
            {" Replacement selected for. Please specify which previous quarters to delete:"}
          </Typography>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel required htmlFor="inputLabel-delete-start-quarter-select">From</InputLabel>
            <Select
              required
              style={{ width: 500, marginBottom: 5 }}
              value={taskConfig.delete_start_quarter || ""}
              onChange={(e) => {
                setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, delete_start_quarter: e.target.value }));
              }}
              label="Delete Start Quarter"
              inputProps={{
                name: "delete start quarter",
                id: "inputLabel-delete-start-quarter-select",
              }}
            >
              {formOptions.quarters.map((quarter) => (
                <MenuItem key={quarter} value={quarter}>
                  {quarter}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel required htmlFor="inputLabel-delete-end-quarter-select">To</InputLabel>
            <Select
              required
              style={{ width: 500, marginBottom: 20 }}
              value={taskConfig.delete_end_quarter || ""}
              onChange={(e) => {
                setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, delete_end_quarter: e.target.value }));
              }}
              label="Delete End Quarter"
              inputProps={{
                name: "delete end quarter",
                id: "inputLabel-delete-end-quarter-select",
              }}
            >
              {formOptions.quarters.map((quarter) => (
                <MenuItem key={quarter} value={quarter}>
                  {quarter}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
      <FormControl fullWidth margin="normal">
        <OptionSelect
          required
          style={{ width: 500, marginBottom: -5, marginTop: -5 }}
          value={taskConfig.ann_type || ""}
          onChange={(annType) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, ann_type: annType }));
          }}
          options={annTypes}
          label="Annuity Type"
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField
          style={{ width: 500, marginBottom: -10 }}
          label="Load all sales with this date (YYYYMMDD)"
          value={taskConfig.default_date || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, default_date: e.target.value }));
          }}
        />
      </FormControl>
      <FormControl component="fieldset" fullWidth margin="normal">
        <FormControlLabel
          style={{ marginBottom: 10 }}
          value={taskConfig.broker_id_7_char || ""}
          control={<Checkbox checked={taskConfig.broker_id_7_char} />}
          label="Broker IDs are DBRs"
          onChange={(e) => setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, broker_id_7_char: e.target.checked }))}
        />
      </FormControl>
      {jobRunFlag ? children : null}
      <Snackbar
        open={!jobRunFlag}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div>
          <div>
            <Alert severity="error">
              Only one ANN Sales Loader job can be run at a time!
            </Alert>
          </div>
        </div>
      </Snackbar>
    </BaseForm>
  );
}
