import React from "react";
import { useSelector } from "react-redux";

import CircularProgress from "./Spinner";

export default function FirmNameColumnDD({ value }) {
  const fetchingFirmNames = useSelector((state) => state.application.fetchingBackground.firmNamesDD);

  if (fetchingFirmNames) return <CircularProgress size="2em" />;
  return value || null;
}
