import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Chip, Autocomplete } from "@mui/material";
import { useSelector } from "react-redux";
import { API } from "../../utils/api";

export default function AddUserDialog({ openDialog, setOpenDialog, group, groupTableData, setSnackbarStatus, refreshTable, setRefreshTable }) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const userOptions = useSelector(state => state.formOptions.usernames);

  const handleDialogSave = () => {
    if (selectedUsers.length === 0) {
      setSnackbarStatus({ status: "error", message: "You need to add at least one user . Please Try Again !" });
      return;
    }
    setSnackbarStatus({ status: "info", message: `Adding users into ${group.name}` });
    setButtonsDisabled(true);
    const oktaUsernames = selectedUsers.map((user) => `ISS-Okta-Internal_${user.id}`);
    API.request({
      url: "/cognito/users",
      method: "POST",
      data: { users: oktaUsernames },
      params: { group_name: group.cognitoName },
    }).then(() => {
      setSnackbarStatus({ status: "success", message: "Users added successfully !" });
      setOpenDialog(false);
      setRefreshTable(!refreshTable);
      setButtonsDisabled(false);
    }).catch((error) => {
      console.error(error);
      setSnackbarStatus({ status: "error", message: "Users could not be added. Please Try Again !" });
      setButtonsDisabled(false);
    });
  };

  const getOptions = () => {
    if (userOptions[0] === "Loading...") {
      return [];
    }

    return userOptions.filter((user) => !groupTableData.map((row) => row.name).includes(user.name));
  };

  return (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>
        {"Add Users into "}
        <Chip label={group.name} variant="outlined" />
        {" Group"}
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          value={selectedUsers}
          options={getOptions()}
          loading={userOptions[0] === "Loading..."}
          multiple
          getOptionLabel={(option) => option.name}
          onChange={(e, addUsers) => { setSelectedUsers(addUsers); }}
          getOptionSelected={(val, option) => val.name === option.name}
          renderInput={(params) => <TextField {...params} autoFocus label="Usernames" variant="outlined" margin="normal" />}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="primary"
          onClick={() => { setOpenDialog(false); }}
          disabled={buttonsDisabled}
        >
          Close
        </Button>
        <Button
          variant="text"
          color="secondary"
          onClick={handleDialogSave}
          disabled={buttonsDisabled}
        >
          Add Users
        </Button>
      </DialogActions>
    </Dialog>
  );
}
