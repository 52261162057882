import flatten from "./flatten";

export function heapInit() {
  window.heap.load(process.env.REACT_APP_HEAP_ID);
}

export function heapTrack(eventName, properties) {
  window.heap.track(`BV - ${eventName}`, flatten(properties, "BV - "));
}

export function heapSetUser(userId, properties) {
  window.heap.identify(userId);
  window.heap.addUserProperties(flatten(properties, "BV - "));
}

export function heapResetIdentity() {
  window.heap.resetIdentity();
}

export function heapSetGlobals(properties) {
  window.heap.clearEventProperties();
  window.heap.addEventProperties(flatten(properties, "BV - "));
}

export const heapNavigationAutotrack = (component) => component;

export const heapMiddleware = () => next => action => {
  // Ignore certain payloads
  let payload = action?.payload;
  if (action.type.endsWith("fulfilled")) payload = {};
  if (action.type.endsWith("REHYDRATE")) payload = {};

  heapTrack(`Action: ${action.type}`, {
    action: action.meta,
    error: action.error,
    meta: action?.payload?.meta,
    payload,
  });
  return next(action);
};
