import React, { useEffect } from "react";
import { ThemeProvider, styled } from "@mui/material";
import {
  SnackbarProvider,
  MaterialDesignContent,
} from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { setEnvironment } from "../state";
import { getOptionsData } from "../state/getOptions";
import { createBraavosTheme } from "../theme/theme";
// This is the main wrapper for initializing the app's data so that we don't reload it on every page change.
// The data is preloaded for both LMS and DD pages, not DI.

const baseTheme = createBraavosTheme("dark");

export default function SetupWrapper({ children }) {
  const dispatch = useDispatch();

  const userTheme = useSelector((state) => state.userSettings.theme);

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    "&.notistack-MuiContent-success": {
      backgroundColor: baseTheme.palette.success.main,
    },
    "&.notistack-MuiContent-error": {
      backgroundColor: baseTheme.palette.error.main,
    },
  }));

  const backendType = process.env.REACT_APP_BACKEND_API.includes(
    "marketmetrics",
  )
    ? "LMS"
    : "DI";

  useEffect(() => {
    // DI doesn't need to preload data right now
    if (backendType === "LMS") {
      // Preload all the data we need for the app
      dispatch(getOptionsData());
      // Set the environment
      const env = process.env.REACT_APP_BACKEND_API.split(".")[1];
      dispatch(setEnvironment({ environment: env }));
    }
  }, [backendType, dispatch]);

  return (
    <ThemeProvider theme={createBraavosTheme(userTheme)}>
      <SnackbarProvider
        dense
        preventDuplicate
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
      >
        {children}
      </SnackbarProvider>
    </ThemeProvider>
  );
}
