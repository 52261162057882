import React from "react";
import Alert from "@mui/material/Alert";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  alerts: {
    maxHeight: 100,
    maxWidth: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    overflowWrap: "break-word",
  },
}));

export default function FormAlerts({
  saving,
  success,
  error,
  setSuccess,
  setError,
}) {
  const classes = useStyles();
  return (
    <>
      {saving && <div><Alert severity="info" className={classes.alerts}>Saving...</Alert></div>}
      {success && <div><Alert onClose={() => setSuccess(false)} severity="success" className={classes.alerts}>{`${success}`}</Alert></div>}
      {error && <div><Alert onClose={() => setError("")} severity="error" className={classes.alerts}>{`${error}`}</Alert></div>}
    </>
  );
}
