import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
  name: "filters",
  initialState: {
    users: [],
    misightUsers: [],
  },
  reducers: {
    setUsers: (state, action) => {
      const { users } = action.payload;
      state.users = users;
    },
    setMisightUsers: (state, action) => {
      const { users } = action.payload;
      state.misightUsers = users;
    },
  },
});
