import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  theme: localStorage.getItem("theme") ?? "dark",
  groups: JSON.parse(localStorage.getItem("groups")) ?? ["base"],
};

export default createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
      localStorage.setItem("theme", action.payload);
    },
    addGroup: (state, action) => {
      const group = action.payload;
      if (!state.groups.includes(group)) {
        state.groups.push(group);
      }
      localStorage.setItem("groups", JSON.stringify(state.groups));
    },
    removeGroup: (state, action) => {
      const group = action.payload;
      state.groups = state.groups.filter((g) => g !== group);
      localStorage.setItem("groups", JSON.stringify(state.groups));
    },
  },
});
