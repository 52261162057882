import _ from "lodash";

export const convertArrayToOptions = (arr) => (
  arr.map((el) => (
    { id: el, label: el }
  ))
);

export const processFlattenedData = (data) => {
  if (!data || !data.keys || !data.results) {
    return [];
  }
  const processedData = [];
  const { keys } = data;
  data.results.forEach((result) => {
    const asDict = {};
    keys.forEach((key, idx) => {
      asDict[key] = result[idx];
    });
    processedData.push(asDict);
  });
  return processedData;
};

export const combineArrays = (array1, array2, matchKey) => {
  if (!array1 || !array2 || !matchKey) {
    return [];
  }
  return array1.map((array1Row) => {
    const matchValue = array1Row[matchKey];
    const array2Row = array2.find((row) => row[matchKey] === matchValue);
    return {
      ...array1Row,
      ...array2Row,
    };
  });
};

// convert object with snake-case keys to camel-case keys
// {example_key1: val1, example_key2: val2} => {exampleKey1: val1, exampleKey2: val2}
export const camelCaseObject = (object) => _.mapKeys(object, (val, key) => _.camelCase(key));

// other direction
export const snakeCaseObject = (object) => _.mapKeys(object, (val, key) => _.snakeCase(key));
