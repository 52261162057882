/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Checkbox } from "@mui/material";

import FirmNameColumn from "../../../../components/FirmNameColumn";
import FirmNameAutoComplete from "../../../../components/input/FirmNameAutoComplete";
import { channels, filterOptions } from "../../../../utils/SharedDefinitions";
import { API } from "../../../../utils/api";

export const generateSIFirmColumns = () => (
  [
    {
      header: "Firm Name",
      accessorKey: "firm_name",
      filterFn: "startsWith",
      columnFilterModeOptions: filterOptions,
      Cell: ({ renderedCellValue }) => <FirmNameColumn value={renderedCellValue} />,
      Edit: ({ row, column, table, cell }) => (
        <FirmNameAutoComplete
          defaultValue={row.original}
          onChange={(event, newValue) => {
            row._valuesCache[column.id] = event.target.innerHTML.split(" (")[0];
            row._valuesCache.firm_crd = newValue?.firm_crd;
            row._valuesCache.si_firm_id = newValue?.si_firm_id;
            table.setEditingCell(cell);
          }}
        />
      ),
    },
    { header: "Firm CRD", accessorKey: "firm_crd", filterFn: "startsWith", columnFilterModeOptions: filterOptions, enableEditing: false },
    { header: "SI Firm ID", accessorKey: "si_firm_id", filterFn: "startsWith", columnFilterModeOptions: filterOptions, enableEditing: false },
  ]
);

export const generateCommonColumns = () => (
  [
    { header: "Created At", accessorKey: "created_at", enableEditing: false, type: "datetime" },
    { header: "Created By", accessorKey: "created_by", enableEditing: false },
    { header: "Updated By", accessorKey: "updated_by", enableEditing: false },
    { header: "Updated At", accessorKey: "updated_at", enableEditing: false, type: "datetime" },
  ]
);

export const generateCommonExclusionColumns = () => (
  [
    ...generateSIFirmColumns(),
    { header: "Zip Code", accessorKey: "zip_code", filterFn: "startsWith", columnFilterModeOptions: filterOptions },
  ]
);

export const generateStudySpecificColumns = (study) => {
  const columnMap = {
    ann: [
      {
        header: "Channel",
        accessorKey: "channel",
        editVariant: "select",
        editSelectOptions: channels,
        filterFn: "startsWith",
        columnFilterModeOptions: filterOptions,
      },
      {
        header: "VA",
        accessorKey: "va",
        filterVariant: "checkbox",
        filterFn: "equalsString",
        Edit: ({ row, column }) => {
          const [value, setValue] = useState(row.original.va === "" ? true : row.original.va);
          setTimeout(() => {
            if (row._valuesCache[column.id] === "") row._valuesCache[column.id] = true;
          }, 0);
          return (
            <Checkbox
              checked={value}
              onChange={(event) => {
                row._valuesCache[column.id] = event.target.checked;
                setValue(event.target.checked);
              }}
            />
          );
        },
        Cell: ({ cell }) => {
          if (cell.getValue() === true) { return <CheckIcon />; }
          if (cell.getValue() === false) { return <ClearIcon />; }
          return "";
        },
      },
      {
        header: "FIA",
        accessorKey: "fia",
        filterVariant: "checkbox",
        filterFn: "equalsString",
        Edit: ({ row, column }) => {
          const [value, setValue] = useState(row.original.fia === "" ? true : row.original.fia);
          setTimeout(() => {
            if (row._valuesCache[column.id] === "") row._valuesCache[column.id] = true;
          }, 0);
          return (
            <Checkbox
              checked={value}
              onChange={(event) => {
                row._valuesCache[column.id] = event.target.checked;
                setValue(event.target.checked);
              }}
            />
          );
        },
        Cell: ({ cell }) => {
          if (cell.getValue() === true) { return <CheckIcon />; }
          if (cell.getValue() === false) { return <ClearIcon />; }
          return "";
        },
      },
    ],
    mf: [],
    sma: [],
  };
  return columnMap[study];
};

const mapPayloadToUrl = (payload, direction, study) => {
  const hasAnnuityType = payload.fia || payload.va;

  if (payload.si_firm_id && !payload.zip_code && !payload.channel && !hasAnnuityType) {
    return `/lms/api/v1/${study}/si_exclusion/si_${study}_exclusion_clientprofile${direction}firm`;
  }
  if (payload.si_firm_id && !payload.zip_code && payload.channel && !hasAnnuityType) {
    return `/lms/api/v1/${study}/si_exclusion/si_${study}_exclusion_clientprofile${direction}firmchannel`;
  }
  if (payload.si_firm_id && !payload.zip_code && hasAnnuityType) {
    return `/lms/api/v1/ann/si_exclusion/si_ann_exclusion_clientprofile${direction}firmannuitytype`;
  }
  if (payload.si_firm_id && payload.zip_code && !payload.channel && !hasAnnuityType) {
    return `/lms/api/v1/${study}/si_exclusion/si_${study}_exclusion_clientprofile${direction}firmzip`;
  }
  if (payload.si_firm_id && payload.zip_code && payload.channel && !hasAnnuityType) {
    return `/lms/api/v1/${study}/si_exclusion/si_${study}_exclusion_clientprofile${direction}firmzipchannel`;
  }
  if (payload.si_firm_id && payload.zip_code && hasAnnuityType) {
    return `/lms/api/v1/ann/si_exclusion/si_ann_exclusion_clientprofile${direction}firmzipannuitytype`;
  }
  return null;
};

const checks = (data, study) => {
  const hasAnnuityType = data.fia || data.va;
  if (study === "ann") {
    if (!data.channel && !hasAnnuityType) {
      return "Channel or FIA or VA is a required field";
    }
    if (data.channel && hasAnnuityType) {
      return "Channel and Annuity Type are mutually exclusive";
    }
  }
  return null;
};

export const generateExclusionTableCallbacks = (
  url,
  callBack,
  extraCallBack,
  direction,
  study,
  tableData,
  setLoading,
) => {
  const actions = {};
  actions.onRowAdd = async (data) => {
    const annReponse = checks(data, study);
    if (annReponse) {
      callBack(false, { response: { data: annReponse } });
      return;
    }
    setLoading(true);
    API.request({ url, method: "POST", data })
      .then((response) => {
        callBack(true, response);
        extraCallBack();
      })
      .catch((e) => {
        callBack(false, e);
      }).finally(() => {
        setLoading(false);
      });
  };

  actions.onRowUpdate = async (newData, data, table) => {
    let dupsPresent = false;
    const compareValues = (preValue, newValue) => ((preValue === null && newValue === "") || (preValue === newValue));
    const baseURL = mapPayloadToUrl(data, direction, study);
    const annReponse = checks(newData, study);
    if (annReponse) {
      callBack(false, { response: { data: annReponse } });
      return;
    }
    tableData.forEach((prevData) => {
      if (prevData.si_firm_id === newData.si_firm_id
        && compareValues(prevData.zip_code, newData.zip_code)
        && compareValues(prevData.channel, newData.channel)
        && compareValues(prevData.fia, newData.fia)
        && compareValues(prevData.va, newData.va)) {
        dupsPresent = true;
        callBack(false, { response: { data: "Duplicate data: Another row with same values existing" } });
      }
    });
    if (!dupsPresent) {
      setLoading(true);
      data.ids.forEach((id) => {
        API.request({ url: `${baseURL}/${id}`, method: "DELETE" })
          .then((response) => {
            callBack(true, response);
            extraCallBack();
          })
          .catch((e) => {
            callBack(false, e);
          })
          .finally(() => {
            table.setEditingRow(null);
            table.setEditingCell(null);
          });
      });
      API.request({ url, method: "POST", data: newData })
        .then((response) => {
          callBack(true, response);
          extraCallBack();
        })
        .catch((e) => {
          callBack(false, e);
        })
        .finally(() => {
          table.setEditingRow(null);
          table.setEditingCell(null);
          setLoading(false);
        });
    }
  };
  actions.onRowDelete = async (data) => {
    setLoading(true);
    const baseURL = mapPayloadToUrl(data, direction, study);
    data.ids.forEach((id) => {
      API.request({ url: `${baseURL}/${id}`, method: "DELETE" })
        .then((response) => {
          callBack(true, response);
          extraCallBack();
        })
        .catch((e) => {
          callBack(false, e);
        }).finally(() => {
          setLoading(false);
        });
    });
  };
  return actions;
};
