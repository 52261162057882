export const taskTypesWithRequiredAttachment = new Set([
  "preprocessing_mf",
  "preprocessing_ann",
  "preprocessing_sma",
  "preprocessing_channel_loader",
  "preprocessing_generic_loader",
  "preprocessing_load_key_broker",
  "preprocessing_load_key_funds",
  "preprocessing_load_territory_groups",
  "preprocessing_tag_large_negative_sales",
  "preprocessing_tag_manual_warnings",
  "preprocessing_load_territory_map",
  "preprocessing_ann_load_va_product_types",
  "preprocessing_share_class",
  "preprocessing_load_mf_zip_code_data",
  "preprocessing_load_verified_address",
  "preprocessing_broker_standardization",
  "preprocessing_load_sales_ann",
  "preprocessing_load_sales_mf",
  "preprocessing_sma_sales_loader",
  "preprocessing_morningstar_funds_stage_loader",
]);

export const tasksThatNeedsQueuing = new Set([
  "preprocessing_tag_large_negative_sales",
  "preprocessing_tag_manual_warnings",
  "preprocessing_rebalance_tagger",
  "preprocessing_rebalance_tagger_client_specific",
  "preprocessing_add_new_client",
  "preprocessing_mf_delete_sales_data_by_run_date",
  "preprocessing_populate_sales_totals",
  "preprocessing_populate_sales_totals_all_clients",
  "preprocessing_load_sales_mf",
  "preprocessing_load_sales_ann",
  "preprocessing_ann_populate_sales_totals",
  "preprocessing_ann_populate_sales_totals_all_clients",
  "preprocessing_load_sales_ann",
  "preprocessing_locked_dataset",
  "preprocessing_sma_sales_loader",
]);

export const preWarmTask = "misight_prewarm_cache";
