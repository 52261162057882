import React, { useEffect } from "react";
import { FormControl, Typography } from "@mui/material";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import APISelect from "../../components/input/APISelect";

export default function AnnVABDChannelConflictsForm({ children, taskConfig, setTaskConfig, submitHandler, studyType, setIsRequired }) {
  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType }));
    setIsRequired((prevState) => ([...prevState, "multiple_client_ids"]));
  }, [setTaskConfig, studyType, setIsRequired]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      validator={validator}
    >
      <Typography variant="h6">
        ANN VA BD Channel Conflicts Report
      </Typography>
      <Typography variant="caption"> Select single or mulitple clients </Typography>
      <FormControl required fullWidth margin="normal" variant="outlined">
        <APISelect
          required
          label="Client Selection"
          style={{ width: 500, marginBottom: 20 }}
          url="/clients"
          value={taskConfig.multiple_client_ids || []}
          optionKey="id"
          onChange={(multipleClients) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, multiple_client_ids: multipleClients }));
          }}
          filter="type"
          filterValue={studyType}
          multiple
        />
      </FormControl>
      {children}
    </Form>
  );
}
