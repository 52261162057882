import React, { useEffect, useState } from "react";
import { Card, CardContent, Divider, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

import { API } from "../../../utils/api";
import TaskLogDetails from "./TaskLogDetails";
import TaskLogUrls from "./TaskLogUrls";

export default function TaskLogs({ classes, taskId, workflowId }) {
  const [logsData, setLogsData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const url = workflowId ? `/tasks/${workflowId}/${taskId}/logs` : `/tasks/${taskId}/logs`;
    API.request({ url, method: "GET" }).then(
      (response) => {
        setLogsData(response.data);
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLogsData([]);
        setLoading(false);
      },
    );
  }, [taskId, workflowId]);

  if (loading) {
    return <Skeleton style={{ margin: "0.5em" }} />;
  }

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Logs
          </Typography>
        </CardContent>
        <Divider />
        <TaskLogUrls logsData={logsData} />
      </Card>
      <Card className={classes.root}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Log Details
          </Typography>
        </CardContent>
        <Divider />
        <TaskLogDetails taskId={taskId} logsData={logsData} />
      </Card>
    </>
  );
}
