import React from "react";
import useFormData from "../../hooks/useFormData";
import BaseForm from "./common/BaseForm";

export default function AnnGPSForm({ children, setTaskConfig, submitHandler, formDataProp }) {
  // Pre-populate form with previous data if available
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  const annGPSSchema = {
    title: "ANN Growth Potential Score Prediction",
    type: "object",
    properties: {},
  };

  return (
    <BaseForm
      schema={annGPSSchema}
      formData={formData}
      submitHandler={submitHandler}
      setFormData={setFormData}
    >
      {children}
    </BaseForm>
  );
}
