import { useEffect } from "react";
import { useSelector } from "react-redux";
import { API } from "../utils/api";

export default function useTerrClientDescriptions(setClientDescriptions) {
  const backendType = useSelector(state => state.application.backendType);

  useEffect(() => {
    if (backendType === "LMS") return;

    API.request({ url: "/di/territory/clients", method: "GET" }).then((response) => {
      setClientDescriptions(Object.assign({}, ...response.data.map((obj) => ({ [obj.pk]: obj.client_description }))));
    });
  }, [backendType, setClientDescriptions]);
}
