import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
  name: "filters",
  initialState: {
    available: {
      // Multiple selects
      client: [],
      taskType: [],
      username: [],
      workflow_name: [],
      // Single selects
      status: "",
      orderBy: "",
      startDate: "",
      endDate: "",
    },
    applied: {},
  },
  reducers: {
    setFilters: (state, action) => {
      const { key, value } = action.payload;
      state.available[key] = value;
    },
    setAppliedFilters: (state, action) => {
      const { filters } = action.payload;
      state.applied = filters;
    },
    resetFilters: (state) => {
      state.applied = {};
      state.available = {
        client: [],
        taskType: [],
        username: [],
        workflow_name: [],
        // Single selects
        status: "",
        orderBy: "",
        startDate: "",
        endDate: "",
      };
    },
  },
});
