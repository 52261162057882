import React from "react";
import { Divider, List, ListItem, ListItemText, Card,
  CardContent,
  Button,
  Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import ReactJson from "react-json-view";
import { Link } from "react-router-dom";
import useSWR from "swr";

import { getClientProfile, getClientProfileExclusions } from "../../../utils/api";

export default function TaskClientProfile({ profileId, study, isDD = false }) {
  const { data: primaryData, error: primaryDataError } = useSWR(`/lms/api/v1/${study}/client/${study}_clientprofile/${profileId}`, () => getClientProfile(profileId, study));
  const { data: exclusions, error: exclusionsError } = useSWR(`/lms/api/v1/clientprofile/${profileId}/study/${study}`, () => getClientProfileExclusions(profileId, study));
  const prefix = isDD ? "/dd" : "";

  if (!primaryData && !exclusions && !primaryDataError && !exclusionsError) {
    return (
      <div>
        <Skeleton style={{ margin: "0.5em" }} />
        <Skeleton style={{ margin: "0.5em" }} />
        <Skeleton style={{ margin: "0.5em" }} />
        <Skeleton style={{ margin: "0.5em" }} />
      </div>
    );
  }

  if (exclusions && Object.keys(exclusions).length) {
    return (
      <Card>
        <CardContent>
          <Typography
            color="textSecondary"
            gutterBottom
          >
            Client Profile Summary
          </Typography>
          <List>
            <Divider component="li" />
            <ListItem key="name">
              <ListItemText primary="Profile Name" secondary={primaryData?.full_name} />
              <Button
                component={Link}
                to={`${prefix}/client-profile/details/${profileId}/study/${study}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Profile
              </Button>
            </ListItem>
            <Divider component="li" />
            <ListItem key="by">
              <ListItemText primary="By" secondary={primaryData?.created_by} />
            </ListItem>
            <Divider component="li" />
            <ListItem key="updated">
              <ListItemText primary="Updated" secondary={primaryData?.updated_at} />
            </ListItem>
            <Divider component="li" />
            <ListItem key="notes">
              <ListItemText
                primary="Notes"
                secondary={primaryData?.notes}
              />
            </ListItem>
          </List>
          <Typography
            color="textSecondary"
            gutterBottom
          >
            Client Profile Exclusions
          </Typography>
          <ReactJson
            src={exclusions}
            name={false}
            groupArraysAfterLength={1000}
            displayDataTypes={false}
            displayObjectSize={false}
            displayArrayKey={false}
            collapsed
            theme="ocean"
          />
        </CardContent>
      </Card>
    );
  }

  return (
    <List>
      <Divider component="li" />
      <ListItem key="no-exclusions">
        <ListItemText secondary="No Exclusions" />
      </ListItem>
    </List>
  );
}
