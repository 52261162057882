export const isGroup = (field) => field && Object.keys(field?.fields)?.length;

export function recursiveAllFields(fieldObj, fieldKey, allFields = []) {
  let updatedAllFields = [...allFields];
  // Add the actual group key
  updatedAllFields.push(fieldKey);
  // Recursively add any subfields and subgroups
  Object.keys(fieldObj?.fields).forEach((k) => {
    if (isGroup(fieldObj?.fields[k])) {
      updatedAllFields = [
        ...updatedAllFields,
        ...recursiveAllFields(
          fieldObj?.fields[k],
          `${fieldKey}.${k}`,
          updatedAllFields,
        ),
      ];
    } else {
      updatedAllFields.push(`${fieldKey}.${k}`);
    }
  });
  return updatedAllFields;
}

// "foo.bar.baz" --> ["foo", "foo.bar", "foo.bar.baz"]
export function addParentNodes(fieldKey) {
  const subFields = fieldKey.split(".");
  let fullFieldKey = "";
  const fieldKeysWithParentNodes = [];
  subFields.forEach((field, index) => {
    fullFieldKey = fullFieldKey.concat(
      `${index === 0 ? "" : "."}${field}`,
    );
    fieldKeysWithParentNodes.push(fullFieldKey);
  });
  return fieldKeysWithParentNodes;
}

export function removeLonelyParentNodes(fieldKey, selectedFields) {
  // First get all nodes associated with the key
  const allParentNodes = addParentNodes(fieldKey);
  // Then remove them from the selected fields
  const cleanedSelectedFields = selectedFields.filter(f => !allParentNodes.includes(f));
  // Add them back if they are still relevant to another subnode
  const relevantParent = new Set();
  allParentNodes.forEach(parent => {
    cleanedSelectedFields.forEach(f => {
      if (f.includes(parent)) {
        relevantParent.add(parent);
      }
    });
  });
  return [...cleanedSelectedFields, ...relevantParent];
}
