import React, { useEffect } from "react";
import { Checkbox, FormControl, Typography, FormControlLabel } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import APISelect from "../../components/input/APISelect";
import OptionSelect from "../../components/input/OptionSelect";
import BaseForm from "./common/BaseForm";

const annTypes = [
  { id: "FIA", label: "FIA" },
  { id: "VA", label: "VA" },
  { id: "all", label: "ALL" },
];

const annProductNameSchema = {
  type: "object",
  properties: {
    start_quarter: {
      type: "object",
      title: "ANN Product Name Breakdown",
    },
  },
};

export default function ANNProductNameBreakdownForm({ children, taskConfig, setTaskConfig, submitHandler, studyType, setIsRequired }) {
  const formOptions = useSelector((state) => state.formOptions);

  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType }));
    setIsRequired((prevState) => ([...prevState, "multiple_client_ids", "start_quarter", "end_quarter", "annuity_type"]));
  }, [setTaskConfig, studyType, setIsRequired]);

  return (
    <BaseForm
      schema={annProductNameSchema}
      submitHandler={submitHandler}
      taskConfig={taskConfig}
    >
      <Typography variant="caption"> Select single or mulitple clients </Typography>
      <FormControl required fullWidth margin="normal" variant="outlined">
        <APISelect
          required
          label="Client Selection"
          style={{ width: 500, marginBottom: 20 }}
          url="/clients"
          value={taskConfig.multiple_client_ids || []}
          optionKey="id"
          onChange={(multipleClients) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, multiple_client_ids: multipleClients }));
          }}
          filter="type"
          filterValue={studyType}
          multiple
        />
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <InputLabel required htmlFor="inputLabel-start-quarter-select">Start Quarter</InputLabel>
        <Select
          required
          style={{ width: 500, marginBottom: 20 }}
          value={taskConfig.start_quarter || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, start_quarter: e.target.value }));
          }}
          label="Start Quarter"
          inputProps={{
            name: "start quarter",
            id: "inputLabel-start-quarter-select",
          }}
        >
          {formOptions.quarters.map((quarter) => (
            <MenuItem key={quarter} value={quarter}>
              {quarter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <InputLabel required htmlFor="inputLabel-end-quarter-select">End Quarter</InputLabel>
        <Select
          required
          style={{ width: 500, marginBottom: 20 }}
          value={taskConfig.end_quarter || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, end_quarter: e.target.value }));
          }}
          label="End Quarter"
          inputProps={{
            name: "end quarter",
            id: "inputLabel-end-quarter-select",
          }}
        >
          {formOptions.quarters.map((quarter) => (
            <MenuItem key={quarter} value={quarter}>
              {quarter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="inputLabel-lock-select">Lock (Data Set)</InputLabel>
        <Select
          style={{ width: 500, marginBottom: -10 }}
          value={taskConfig.locks || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, lock: e.target.value }));
          }}
          label="Lock"
          inputProps={{
            name: "lock",
            id: "inputLabel-lock-select",
          }}
        >
          {formOptions.locksANN.map((lock) => (
            <MenuItem key={lock} value={lock}>
              {lock}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="outlined">
        <OptionSelect
          required
          style={{ width: 500, marginBottom: 10 }}
          value={taskConfig.annuity_type || ""}
          onChange={(annType) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, annuity_type: annType }));
          }}
          options={annTypes}
          label="Annuity Type"
        />
      </FormControl>
      <FormControl>
        <FormControlLabel
          label="Exclude Warnings"
          control={(
            <Checkbox
              checked={taskConfig.exclude_warnings}
              onChange={(e) => setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, exclude_warnings: e.target.checked }))}
            />
          )}
        />
      </FormControl>

      {children}
    </BaseForm>
  );
}
