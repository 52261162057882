/* eslint-disable react/no-this-in-sfc */
import React from "react";
import * as Highcharts from "highcharts";

import ChartCard from "../../../components/ChartCard";

export default function GpsR2Trend({ data, tooltipMap }) {
  const options = {
    title: {
      text: "R2 Trend",
    },
    chart: {
      type: "spline",
      zoomType: "x",
    },
    xAxis: {
      allowDecimals: false,
      title: {
        text: "Run On",
        scalable: false,
      },
      type: "datetime",
      labels: {
        formatter() {
          return Highcharts.dateFormat("%d-%b-%y", this.value);
        },
      },
      tickPixelInterval: 100,
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: "r2",
      },
      max: 1,
      min: -1,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter() {
        return `<b>${this.series.name}</b><br/><b>r2:</b> ${this.y}<br/>${tooltipMap[this.x]}`;
      },
    },
    series: [
      {
        name: "Max",
        data: data?.map(r => [r.timestamp, r.max_r2]),
        color: "#007236",
      },
      {
        name: "Average",
        data: data?.map(r => [r.timestamp, r.mean_r2]),
        color: "#50759B",
      },
      {
        name: "Min",
        data: data?.map(r => [r.timestamp, r.min_r2]),
        color: "#9b1d21",
      },
    ],
  };

  return (
    <ChartCard options={options} />
  );
}
