import React from "react";
import { ReportProblemOutlined } from "@mui/icons-material";
import { Card, CardContent, Typography, useTheme } from "@mui/material";

function NoDataFoundTile() {
  const theme = useTheme();
  return (
    <Card style={{ textAlign: "center", backgroundColor: theme.palette.background.paper, height: "400px", boxShadow: "none", borderRadius: 0 }}>
      <CardContent style={{ marginTop: 100 }}>
        <ReportProblemOutlined fontSize="large" />
        <Typography variant="h5" style={{ wordBreak: "break-word", marginLeft: 10, marginRight: 10 }}>
          No Data Found !!!
        </Typography>
      </CardContent>
    </Card>
  );
}

export default NoDataFoundTile;
