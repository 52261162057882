import React from "react";

import CalendarToday from "@mui/icons-material/CalendarToday";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import { Box, Tooltip } from "@mui/material";
import moment from "moment-timezone";

export default function TimeColumn({ createdTime, history, timestampIconDisplay }) {
  const startTime = history.filter(o => o.status === "RUNNING" || o.status === "RUNNING_QUEUE")[0];
  const endTime = history.filter(o => o.status === "SUCCEEDED" || o.status === "FAILED")[0];
  const tz = moment().tz(moment.tz.guess()).format("z");
  const createdLocal = moment.parseZone(createdTime).local();
  const startLocal = startTime ? moment.parseZone(startTime?.date).local() : "";
  const endLocal = endTime ? moment.parseZone(endTime?.date).local() : "";

  return (
    <Tooltip
      title={
        (
          (timestampIconDisplay)
            ? (
              <div style={{ whiteSpace: "pre-line" }}>
                {createdLocal ? `Created at: ${moment(createdLocal).format("MMMM Do YYYY, h:mm:ss a")} ${tz}` : ""}
                {startLocal ? (`\nStarted at: ${moment(startLocal).format("MMMM Do YYYY, h:mm:ss a")} ${tz}`) : ""}
                {endLocal ? (`\nFinished at: ${moment(endLocal).format("MMMM Do YYYY, h:mm:ss a")} ${tz}`) : ""}
              </div>
            )
            : (
              <div style={{ whiteSpace: "pre-line" }}>
                {createdLocal ? `When: ${moment(createdLocal).fromNow()}` : ""}
                {startTime ? (`\nDuration: ${moment(endTime?.date).from(startTime?.date, true)}`) : ""}
              </div>
            )
        )
      }
    >
      {
        (timestampIconDisplay)
          ? (
            <Box
              alignItems="flex-start"
              display="flex"
              flexDirection="column"
              style={{ width: "inherit" }}
            >
              <Box
                alignItems="left"
                display="flex"
                flexDirection="row"
                justifyContent="center"
              >
                <CalendarToday style={{ marginRight: 5 }} />
                {moment(createdLocal).fromNow()}
              </Box>
              {endTime
                && (
                  <Box
                    alignItems="left"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                  >
                    <TimelapseIcon style={{ marginRight: 5 }} />
                    {moment(endTime?.date).from(startTime?.date, true)}
                  </Box>
                )}
            </Box>
          )
          : (
            <Box
              alignItems="flex-start"
              display="flex"
              flexDirection="column"
              style={{ width: "inherit", fontSize: "0.9em" }}
            >
              <Box
                alignItems="left"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                style={{ marginBottom: 2 }}
              >
                {`Created: ${moment(createdLocal).format("MMMM Do YYYY, h:mm:ss a")} ${tz}`}
              </Box>
              <Box
                alignItems="left"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                style={{ marginBottom: 2 }}
              >
                {startLocal
                  ? (
                    `\nStarted: ${moment(startLocal).format("MMMM Do YYYY, h:mm:ss a")} ${tz}`
                  )
                  : ""}

              </Box>
              <Box
                alignItems="left"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                style={{ marginBottom: 5 }}
              >
                {endLocal
                  ? (
                    `\nFinished: ${moment(endLocal).format("MMMM Do YYYY, h:mm:ss a")} ${tz}`
                  )
                  : ""}
              </Box>
            </Box>
          )
      }
    </Tooltip>
  );
}
