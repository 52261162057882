import React, { forwardRef } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useConfirmDialogStyles = makeStyles((theme) => ({
  title: {
    position: "center",
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.primary.light,
  },
  confirmButton: {
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.secondary.light,
  },
  addIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  deleteIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
}));

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function ConfirmDialog({ open, setOpen, confirmFunction, loading, children }) {
  const classes = useConfirmDialogStyles();

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-slide-title" className={classes.title}>
          Confirm
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} className={classes.cancelButton} disabled={loading}>
            Cancel
          </Button>
          <Button onClick={() => { confirmFunction(); setOpen(false); }} className={classes.confirmButton} disabled={loading}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
