import React from "react";
import { useSelector } from "react-redux";

import CircularProgress from "./Spinner";

export default function FirmNameColumn({ value }) {
  const fetchingFirmNames = useSelector((state) => state.application.fetchingBackground.firmNames);

  if (fetchingFirmNames) return <CircularProgress size="2em" />;
  return value || null;
}
