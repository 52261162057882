import React, { useEffect } from "react";
import { Chip, FormControl, InputAdornment, TextField, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { useSelector } from "react-redux";
import CSVUpload from "../../components/input/CSVUpload";
import OptionSelect from "../../components/input/OptionSelect";

const insertTypes = [
  { id: "append", label: "Supplement" },
  { id: "replace", label: "Replacement" },
];

export default function ANNLoadChannelsFormDD({ children, taskConfig, setTaskConfig, client, csv, setCsv, submitHandler, studyType, displayHeader }) {
  const productionCycles = useSelector((state) => state.formOptions.productionCycle);
  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType, file_type: "secondary_files" }));
  }, [setTaskConfig, studyType]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      validator={validator}
    >
      <Typography variant="h4" style={{ backgroundColor: "orange" }}>Discovery Data</Typography>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["client_suffix", "append_replace", "production_cycle"])} />
      </div>
      <Typography variant="h6">
        {studyType.toUpperCase()}
        {" Channel Loader "}
      </Typography>
      <CSVUpload
        onChange={(update) => { setCsv({ attachment: update.csv }); }}
        value={{ csv, ...taskConfig }}
      />
      <Typography align="right" variant="subtitle1">
        The loaded file is processed through Mr.Clean before loading
      </Typography>
      <FormControl fullWidth margin="normal">
        <TextField
          style={{ width: 500, marginBottom: -25 }}
          label="Custom Client (Add suffix)"
          value={taskConfig.client_suffix || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, client_suffix: e.target.value }));
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">{client.concat("_")}</InputAdornment>,
          }}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <OptionSelect
          required
          style={{ width: 500, marginBottom: 10 }}
          value={taskConfig.append_replace || ""}
          onChange={(insertionType) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, append_replace: insertionType }));
          }}
          options={insertTypes}
          label="Insertion Type"
        />
      </FormControl>
      {studyType !== "ann" && (
        <FormControl fullWidth variant="outlined">
          <InputLabel required htmlFor="inputLabel-production-cycle-select">Production Cycle</InputLabel>
          <Select
            required
            style={{ width: 500, marginBottom: 30 }}
            value={taskConfig.production_cycle || ""}
            onChange={(e) => {
              setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, production_cycle: e.target.value }));
            }}
            label="Production Cycle"
            inputProps={{
              name: "production cycle",
              id: "inputLabel-production-cycle-select",
            }}
          >
            {productionCycles.map((quarter) => (
              <MenuItem key={quarter} value={quarter}>
                {quarter}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {children}
    </Form>
  );
}
