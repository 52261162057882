export function objectToArray(o) {
  if (!o) {
    return [];
  }
  return Object.keys(o)?.map(k => ({ key: k, ...o[k] }));
}

export function userDisplay(userId, users) {
  const userObj = users?.find(u => u?.id === userId);
  return `${userObj?.okta_first_name} ${userObj?.okta_last_name} - ${userObj?.username} - ${userObj?.okta_role}`;
}
