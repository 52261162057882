import React, { useMemo } from "react";
import { useTheme } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "../../../theme/agGridThemes/qaDashboardTableStyle.css";
import { applyFormatting } from "../../../utils/lms_qa_dashboard_utils/TableFormatter";

export default function Table({ inputData, formattingOptions, columnDefs, isEditable, gridRef, cellEditCallback = null, ...otherProps }) {
  const theme = useTheme();
  const formattedColumns = (columnDefs) || (formattingOptions ? applyFormatting(inputData, isEditable) : inputData?.columns);
  const ROWS_PER_PAGE = 20;
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      flex: 1,
      editable: false,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    }),
    [],
  );

  const columnTypes = useMemo(() => ({
    editableColumn: {
      editable: isEditable,
      singleClickEdit: isEditable,
    },
  }), [isEditable]);

  const getRowStyle = (params) => {
    if (params.data?.notes?.toLowerCase()?.includes("pending")) {
      return { background: theme.palette.warning.main };
    }
    if (params.data?.notes?.toLowerCase()?.includes("confirmed")) {
      return { background: theme.palette.success.main };
    }
    if (params.node.rowPinned === "bottom") {
      return { fontWeight: "bold" };
    }
    return null;
  };
  return (
    <div className={`ag-theme-material ag-theme-qa-dashboard-${theme.palette.themeName}`} style={{ height: inputData?.data?.length < ROWS_PER_PAGE ? "100%" : "800px", width: "100%" }}>
      <AgGridReact
        enableCellTextSelection
        style={{ width: "100%", height: "100%" }}
        columnDefs={formattedColumns}
        columnTypes={columnTypes}
        defaultColDef={defaultColDef}
        rowData={formattingOptions?.bottomRowPinned ? inputData?.data?.slice(0, -1) : inputData?.data}
        onCellValueChanged={cellEditCallback}
        rowBuffer={100}
        domLayout={inputData?.data?.length < ROWS_PER_PAGE ? "autoHeight" : "normal"}
        pinnedBottomRowData={formattingOptions?.bottomRowPinned ? inputData?.data?.slice(-1) : []}
        getRowStyle={getRowStyle}
        ref={gridRef}
        {...otherProps}
      />
    </div>
  );
}
