import React from "react";
import * as Yup from "yup";

import LockToCompare from "../../../components/input/Formik/LockToCompare";
import TimePeriod from "../../../components/input/Formik/TimePeriod";
import { LMSForm } from "../common/LMSForm";

export const AnnVettingReportSchema = Yup.object().shape({
  time_period: Yup.string().required("Required"),
  start_quarter: Yup.string().required("Required"),
  end_quarter: Yup.string().required("Required"),
  lock_to_compare: Yup.string().required("Required"),
});

export default function AnnVettingReportForm({
  title,
  client,
  studyType,
  step,
  setStep,
  submitHandler,
  taskConfig,
}) {
  return (
    <LMSForm
      client={client}
      studyType={studyType}
      step={step}
      setStep={setStep}
      submitHandler={submitHandler}
      taskConfig={taskConfig}
      validationSchema={AnnVettingReportSchema}
      title={title}
      requiredInputAdded
    >
      <TimePeriod required />
      <LockToCompare studyType="ann" required />
    </LMSForm>
  );
}
