const DEFAULT_OPERATION = {
  kind: "OperationDefinition",
  operation: "query",
  variableDefinitions: [],
  name: {
    kind: "Name",
    value: "MyQuery",
  },
  directives: [],
  selectionSet: {
    kind: "SelectionSet",
    selections: [],
  },
};

export default {
  kind: "Document",
  definitions: [DEFAULT_OPERATION],
};
