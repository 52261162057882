import React, { useState } from "react";
import { Dialog, DialogTitle, DialogActions, Button, Chip } from "@mui/material";
import { API } from "../../utils/api";

export default function RemoveUserDialog({ openDialog, setOpenDialog, group, setSnackbarStatus, userUnderRemoval, refreshTable, setRefreshTable }) {
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const handleDialogSave = () => {
    setSnackbarStatus({ status: "info", message: `Removing ${userUnderRemoval.name} from ${group.name}` });
    setButtonsDisabled(true);
    API.request({
      url: `/cognito/users/ISS-Okta-Internal_${userUnderRemoval.userId}`,
      method: "DELETE",
      params: { group_name: group.cognitoName },
    }).then(() => {
      setButtonsDisabled(false);
      setSnackbarStatus({ status: "success", message: "User removed successfully !" });
      setRefreshTable(!refreshTable);
      setOpenDialog(false);
    }).catch((error) => {
      console.error(error);
      setButtonsDisabled(false);
      setSnackbarStatus({ status: "error", message: "User could not be removed. Please Try Again !" });
    });
  };

  return (
    <div>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          {"Remove "}
          <Chip label={userUnderRemoval.name} variant="outlined" />
          {" from "}
          <Chip label={group.name} variant="outlined" />
          {" ?"}
        </DialogTitle>
        <DialogActions>
          <Button
            variant="text"
            color="primary"
            onClick={() => setOpenDialog(false)}
            disabled={buttonsDisabled}
          >
            Close
          </Button>
          <Button
            variant="text"
            style={{
              color: (!buttonsDisabled) ? "red" : "grey",
              opacity: 0.6,
            }}
            onClick={handleDialogSave}
            disabled={buttonsDisabled}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
