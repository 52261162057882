import React, { useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import usePageTitle from "../../hooks/usePageTitle";
import GroupTable from "./GroupTable";

export default function UserGroups() {
  const groupList = {
    "super-admin": "Super Admins",
    "data-ops": "Data Ops",
    "data-ops-management": "Data Ops Management",
    "client-success": "Client Success",
  };
  const [currentGroup, setCurrentGroup] = useState({ name: "Super Admins", cognitoName: "super-admin" });
  const theme = useTheme();

  usePageTitle("User Groups Management");
  return (
    <>
      <div style={{ display: "flex", flexFlow: "row", justifyContent: "center" }}>
        {
          Object.entries(groupList).map(([label, name]) => (
            <Card
              style={{
                marginRight: 20,
                width: "fit-content",
                height: 55,
                backgroundColor: (currentGroup.name === name) ? theme.palette.primary.main : "white",
                cursor: "pointer",
                marginBottom: 15,
              }}
              key={label}
              onClick={() => {
                setCurrentGroup({ name, cognitoName: label });
              }}
            >
              <CardContent>
                <Typography style={{ fontWeight: "bold", color: (currentGroup.name === name && theme.palette.themeName !== "dark") ? "white" : "black" }}>
                  {`${name}`}
                </Typography>
              </CardContent>
            </Card>
          ))
        }
      </div>
      <GroupTable group={currentGroup} />
    </>
  );
}
