import React from "react";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

import useClientConfiguration from "../../hooks/useClientConfiguration";
import useFormData from "../../hooks/useFormData";
import BaseForm from "./common/BaseForm";

const uiSchema = {
  lock: {
    "ui:help": "Leave unselected for Live",
  },
};

export default function PublishAthenaTerritoryFormDD({ children, studyType, client, setTaskConfig, submitHandler, formDataProp }) {
  const formOptions = useSelector((state) => state.formOptions);
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  useClientConfiguration(setFormData, studyType, client);

  const publishAthenaTerritortySchema = {
    title: "Publish Territory Data",
    description: "Create or update territory data to athena",
    type: "object",
    properties: {
      lock: {
        type: "string",
        title: "Lock (Data Set)",
        enum: formOptions.locksANN,
      },
    },
  };

  return (
    <div>
      <Typography variant="h4" style={{ backgroundColor: "orange" }}>Discovery Data</Typography>
      <BaseForm
        schema={publishAthenaTerritortySchema}
        uiSchema={uiSchema}
        formData={formData}
        submitHandler={submitHandler}
        setFormData={setFormData}
      >
        {children}
      </BaseForm>
    </div>
  );
}
