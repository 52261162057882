import React, { useState } from "react";
import { Button, useTheme } from "@mui/material";
import Alert from "@mui/material/Alert";
import tableIcons from "../../../../components/MaterialTableIcons";
import MaterialTable from "../../../../components/Table";

import { API } from "../../../../utils/api";

export default function AddTerritoriesStep({ columns, csvData, setCsvData, removeFile, setErrorPerRow, client, setNextStep, studyType }) {
  const theme = useTheme();

  const [loading, setLoading] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  function submitUsers() {
    setLoading(true);
    const requestPromiseArray = [];
    csvData.forEach(row => {
      const clientData = { first_name: row.first_name, last_name: row.last_name, role: row.role, email: row.email, client };
      requestPromiseArray.push(API.request({ url: "/users", method: "POST", data: clientData })
        .then(
          (response) => {
            setSuccess(prevStr => prevStr.concat(`-- User ${row.email} Successfully Created! --`));
            if (row.territories) {
              row.territories.forEach((resource) => {
                if (resource) {
                  API.request(
                    { url: `misight/users/${response.data.misight_user_id}/resources`, method: "POST", data: { resource_type: "wholesaler", resource_value: resource, study: studyType, is_default: (resource === row.defaultTerritory) } },
                  ).then(
                    () => { setSuccess(prevStr => prevStr.concat(`-- Resource ${resource.resource_value} Successfully added! --`)); },
                    (e) => { setError(prevStr => prevStr.concat(`-- Failed to add ${resource.resource_value} as a resource --`)); setErrorPerRow(prevObj => ({ ...prevObj, [row.email]: e.response.data })); },
                  );
                }
              });
            }
          },
          (e) => {
            setError(prevStr => prevStr.concat(`--  Failed to create user: ${row.email} --`));
            setErrorPerRow(prevObj => ({ ...prevObj, [row.email]: e.response.data }));
            console.log(e);
          },
        ));
    });
    Promise.all(requestPromiseArray)
      .then(() => {
        setLoading(false);
      }).catch((err) => {
        console.log(err.message);
      });
  }

  function handleCancel() {
    removeFile();
    setCsvData();
    setError("");
    setErrorPerRow({});
    setSuccess("");
    setNextStep(false);
  }

  return (
    <div>
      <MaterialTable
        style={{ marginTop: "1.5em" }}
        title={`Importing ${client} Users`}
        columns={columns}
        data={csvData}
        icons={tableIcons}
        options={{
          pageSize: 20,
          pageSizeOptions: [20],
          headerStyle: {
            backgroundColor: theme.palette.background.nav,
            color: theme.palette.text.shaded,
          },
          search: false,
          filtering: true,
        }}
      />
      {success && <div><Alert severity="success">{`${success}`}</Alert></div>}
      {loading && <div><Alert severity="info">Creating Users...</Alert></div>}
      {error && <div><Alert severity="error">{`${error}`}</Alert></div>}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="outlined" onClick={handleCancel} style={{ width: "20%", marginTop: "10px", marginRight: "10px" }}>Cancel</Button>
        <Button variant="outlined" onClick={() => submitUsers()} style={{ width: "20%", marginTop: "10px" }} disabled={error}>Submit</Button>
      </div>
    </div>
  );
}
