import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { useDispatch } from "react-redux";

import { fetching, fetchingDone } from "../../../state";
import { API } from "../../../utils/api";

export default function CreationDialog({
  onChange,
  clientData,
  setClientData,
  allOptions,
  setAllOptions,
  label,
  valueKey,
  open,
  handleClose,
}) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  async function handleSubmit() {
    dispatch(fetching({ element: "selectAPI" }));
    setLoading("Creating new client...");
    await API.request({
      url: "/di/territory/clients",
      method: "POST",
      data: clientData,
    }).then(
      (r) => {
        if (!r.data) return;

        // Internal state
        setClientData(r.data);
        setAllOptions(allOptions.concat(r.data));
        // Lift up value
        onChange(r.data[valueKey]);
        // Loading
        setLoading(false);
        dispatch(fetchingDone({ element: "selectAPI" }));
        setSuccess("Your client was created!");
      },
      (e) => {
        setLoading(false);
        setError(`${e}`);
        dispatch(fetchingDone({ element: "selectAPI" }));
        return null;
      },
    );
  }

  const formFields = ["client_description", "lms_client_id", "brightscope_label"];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <form>
        <DialogTitle id="form-dialog-title">
          {`Add a new ${label}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Create a new ${label}`}
          </DialogContentText>
          <FormControl fullWidth>
            {formFields.map((field) => (
              <FormControl margin="normal" key={[field]}>
                <TextField
                  autoFocus
                  value={clientData[[field]]}
                  onChange={(event) => setClientData({
                    ...clientData,
                    [field]: event.target.value,
                  })}
                  label={[field]}
                  type="text"
                  fullWidth
                />
              </FormControl>
            ))}
          </FormControl>
        </DialogContent>
        {loading && <div><Alert severity="info">{`${loading}`}</Alert></div>}
        {error && <div><Alert severity="error">{`${error}`}</Alert></div>}
        {success && <div><Alert severity="success">{`${success}`}</Alert></div>}
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
