import * as React from "react";

export default function Checkbox({ checked }) {
  if (checked) {
    return (
      <svg
        viewBox="0 0 15 15"
        style={{
          color: "hsl(var(--color-info))",
          marginRight: "var(--px-4)",
          height: "var(--px-16)",
          width: "var(--px-16)",
        }}
      >
        <circle cx="7.5" cy="7.5" r="7.5" fill="currentColor" />
        <path
          d="M4.64641 7.00106L6.8801 9.23256L10.5017 5.61325"
          fill="none"
          stroke="white"
          strokeWidth="1.5"
        />
      </svg>
    );
  }

  return (
    <svg
      viewBox="0 0 15 15"
      style={{
        color: "hsla(var(--color-neutral), var(--alpha-tertiary, 0.4))",
        marginRight: "var(--px-4)",
        height: "var(--px-16)",
        width: "var(--px-16)",
      }}
    >
      <circle cx="7.5" cy="7.5" r="6" stroke="currentColor" fill="none" />
    </svg>
  );
}
