import React, { useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { Chip, useTheme } from "@mui/material";

export default function TerritoryChip({ label, index, getTagProps, defaultTerr, handleChipClick }) {
  const theme = useTheme();

  const [hover, setHover] = useState(false);

  const isDefault = index === defaultTerr;

  return (
    <Chip
      label={hover ? "Set as default" : label}
      style={isDefault ? theme.common.chip.defaultTerritoryChip : theme.common.chip.territoryChip}
      icon={isDefault ? <DoneIcon /> : null}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={() => handleChipClick(index)}
      {...getTagProps({ index })}
    />
  );
}
