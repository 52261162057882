import React from "react";
import { LinearProgress, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function UpdateUserSnackbar({ status, message, state, setState, downloadingType }) {
  return (
    <Snackbar
      open={state?.status?.length > 0}
      autoHideDuration={downloadingType ? undefined : 4000}
      onClose={() => { setState({ status: "", message: "" }); }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <div>
        <Alert severity={status}>
          {downloadingType && <LinearProgress />}
          {message}
        </Alert>
      </div>
    </Snackbar>
  );
}
