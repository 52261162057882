import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import exportingModule from "highcharts/modules/exporting";
import light from "highcharts/themes/brand-light";
import HighchartsReact from "highcharts-react-official";
import NoDataFoundTile from "./NoDataFoundTile";

light(Highcharts);
exportingModule(Highcharts);

export default function PieChart({ title, hidetitle, inputData, legendName }) {
  const [chartDataPresent, setChartDataPresent] = useState(false);
  const chartData = inputData?.map((item) => ({
    name: item.name,
    y: item.count,
  }));

  const options = {
    accessibility: {
      enabled: false,
    },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: hidetitle ? "" : title,
    },
    plotOptions: {
      pie: {
        innerSize: 200,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.y}",
        },
        showInLegend: false,
      },
    },
    series: [{
      name: legendName,
      colorByPoint: true,
      data: chartData,
    }],
    credits: {
      enabled: false,
    },
  };

  useEffect(() => {
    setChartDataPresent(chartData?.reduce((accumulator, currentValue) => accumulator || currentValue.y !== 0, false));
  }, [chartData]);

  return (
    chartDataPresent ? <HighchartsReact highcharts={Highcharts} options={options} /> : <NoDataFoundTile />
  );
}
