/* eslint-disable jsx-a11y/no-static-element-interactions,react/jsx-no-bind */
import React, { useState } from "react";
import { capitalize } from "../util";
import { FieldView } from "./fieldView";

export function RootView({
  schema,
  fields = {},
  operationType,
  name,
  onTypeName,
  definition,
  onEdit,
  onCommit,
  onOperationRename,
  onOperationDestroy,
  onOperationClone,
  onRunOperation,
  getDefaultFieldNames,
  getDefaultScalarArgValue,
  makeDefaultArg,
  availableFragments,
  customClassname,
}) {
  const [displayTitleActions, setDisplayTitleActions] = useState(false);

  let previousOperationDef;

  function modifySelections(selections, options) {
    let operationDef = definition;

    if (operationDef.selectionSet.selections.length === 0 && previousOperationDef) {
      operationDef = previousOperationDef;
    }

    let newOperationDef;

    if (operationDef.kind === "FragmentDefinition") {
      newOperationDef = {
        ...operationDef,
        selectionSet: {
          ...operationDef.selectionSet,
          selections,
        },
      };
    } else if (operationDef.kind === "OperationDefinition") {
      let cleanedSelections = selections.filter(
        (selection) => !(selection.kind === "Field" && selection.name.value === "__typename"),
      );

      if (cleanedSelections.length === 0) {
        cleanedSelections = [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "__typename ## Placeholder value",
            },
          },
        ];
      }

      newOperationDef = {
        ...operationDef,
        selectionSet: {
          ...operationDef.selectionSet,
          selections: cleanedSelections,
        },
      };
    }

    onEdit(newOperationDef, options);
  }

  function handlePotentialRun(event) {
    if (event.ctrlKey && event.key === "Enter" && definition.kind !== "FragmentDefinition") {
      onRunOperation(name);
    }
  }

  return (
    <div
      onKeyDown={handlePotentialRun}
      className="graphiql-explorer-operation"
    >
      <div
        className="graphiql-explorer-operation-title-bar"
        onMouseEnter={() => setDisplayTitleActions(true)}
        onMouseLeave={() => setDisplayTitleActions(false)}
      >
        {operationType}
        {" "}
        <input
          autoComplete="false"
          placeholder={`${capitalize(operationType)} Name`}
          value={name}
          onKeyDown={handlePotentialRun}
          onChange={(event) => onOperationRename(event.target.value)}
        />
        {onTypeName
          ? (
            <span>
              <br />
              {`on ${onTypeName}`}
            </span>
          )
          : ""}
        {displayTitleActions
          ? (
            <>
              <button
                type="submit"
                className="graphiql-explorer-action-button"
                onClick={() => onOperationDestroy()}
              >
                <span>{"\u2715"}</span>
              </button>
              <button
                type="submit"
                className="graphiql-explorer-action-button"
                onClick={() => onOperationClone()}
              >
                <span>⎘</span>
              </button>
            </>
          )
          : (""
          )}
      </div>

      {
        Object.keys(fields)
          .sort()
          .map((fieldName) => (
            <FieldView
              key={fieldName}
              field={fields[fieldName]}
              selections={definition.selectionSet.selections}
              modifySelections={modifySelections}
              schema={schema}
              getDefaultFieldNames={getDefaultFieldNames}
              getDefaultScalarArgValue={getDefaultScalarArgValue}
              makeDefaultArg={makeDefaultArg}
              onRunOperation={onRunOperation}
              onCommit={onCommit}
              definition={definition}
              availableFragments={availableFragments}
              customClassname={customClassname}
            />
          ))
      }
    </div>
  );
}
