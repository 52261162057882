import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Card, CardContent, Divider, Grid, Typography, CardHeader, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SimpleTreeView } from "@mui/x-tree-view";
import ReactJson from "react-json-view";
import { useSWRConfig } from "swr";

import ConfirmDialog from "../../../components/ConfirmDialog";
import FormAlerts from "../../../components/FormAlerts";
import usePageTitle from "../../../hooks/usePageTitle";
import { jsonSchema } from "../../../utils/graphqlDecoder";
import { poster, updater, deleter } from "../../../utils/swr";
import { GQlTreeItem, BundlesList } from "./components";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
    color: theme.palette.textSecondary,
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  labelIconSelected: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  labelIconNotSelected: {
    marginRight: theme.spacing(1),
    color: theme.palette.warning.main,
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
  addIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  deleteIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
}));

function treeItems(fields, isSelected, setSelected, prefix, classes) {
  const fieldKey = (fieldName, pre) => (pre ? `${pre}.${fieldName}` : fieldName);

  return Object.keys(fields)?.map((fieldName) => (
    <GQlTreeItem
      fieldName={fieldName}
      fieldObj={fields[fieldName]}
      fieldKey={fieldKey(fieldName, prefix)}
      isSelected={isSelected}
      setSelected={setSelected}
      classes={classes}
      key={fieldName}
    >
      {fields[fieldName]?.fields && treeItems(fields[fieldName]?.fields, isSelected, setSelected, fieldKey(fieldName, prefix), classes)}
    </GQlTreeItem>
  ));
}

export default function Bundles({ bundles, error }) {
  const { mutate } = useSWRConfig();
  const classes = useStyles();
  usePageTitle("API Access Control");

  // --- INTERNAL STATE ---
  const [selectedBundle, setSelectedBundle] = useState();
  // Selected fields state
  const [selected, setSelected] = useState([]);
  // Used to compare changes to selected fields before saving
  const [savedSelected, setSavedSelected] = useState([]);
  const [itemId, setItemId] = useState();
  const [fieldOptions, setFieldOptions] = useState([]);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [success, setSuccess] = useState("");

  async function saveBundle(title, fields) {
    mutate(
      "lms/api/v1/misight/accessbundles",
      async () => {
        const updatedList = await poster(
          "lms/api/v1/misight/accessbundles",
          {
            name: title,
            fields: `${JSON.stringify(fields)}`,
          },
        );
        setSuccess("Update successfull");
        return [updatedList];
      },
      { revalidate: true },
    );
  }

  async function updateBundle(id) {
    mutate(
      "lms/api/v1/misight/accessbundles",
      async () => {
        const updatedList = await updater(
          `lms/api/v1/misight/accessbundles/${id}`,
          {
            name: selectedBundle,
            fields: `${JSON.stringify(selected)}`,
          },
        );
        setSuccess("Update successfull");
        return [updatedList];
      },
      { revalidate: true },
    );
  }

  async function deleteBundle(id) {
    mutate(
      "lms/api/v1/misight/accessbundles",
      async () => {
        const updatedList = await deleter(
          `lms/api/v1/misight/accessbundles/${id}`,
        );
        setSuccess("Bundle Successfully Deleted");
        return [updatedList];
      },
      { revalidate: true },
    );
  }

  useEffect(() => {
    // Reset client vals
    setItemId(null);
    setSelected([]);
    // Apply saved data
    if (bundles) {
      const savedAccess = bundles?.find(d => d.name === selectedBundle);
      if (savedAccess) {
        const fields = JSON.parse(savedAccess?.fields);
        setSelected(fields);
        setSavedSelected(fields);
        setItemId(savedAccess?.id);
      }
    }
  }, [bundles, selectedBundle]);

  useEffect(() => {
    try {
      jsonSchema().then((result) => {
        setFieldOptions({ ...result?.queries?.fields, ...result?.mutations?.fields });
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const isSelected = (field) => selected?.some((v) => v === field);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4">
          List of bundles
        </Typography>
        <BundlesList bundles={bundles} selectedBundle={selectedBundle} setSelectedBundle={setSelectedBundle} saveBundle={saveBundle} creatable />
        <Divider style={{ marginBlock: 30 }} />
      </Grid>
      {selectedBundle && (
        <>
          <Grid item xs={12}>
            <Typography variant="h4">
              Bundle:
              {" "}
              {selectedBundle}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ minHeight: 300 }}>
            <Card>
              <CardHeader title="Key Management" style={{ textAlign: "center" }} />
              <CardContent>
                <SimpleTreeView
                  className={classes.root}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                >
                  {treeItems(fieldOptions, isSelected, setSelected, "", classes)}
                </SimpleTreeView>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} style={{ minHeight: 300 }}>
            <Card style={{ marginBottom: 10 }}>
              <CardHeader>Save</CardHeader>
              <CardContent style={{ display: "flex", justifyContent: "space-evenly" }}>
                <Button onClick={() => setSaveDialogOpen(true)}>Save</Button>
                <ConfirmDialog open={saveDialogOpen} setOpen={setSaveDialogOpen} confirmFunction={itemId !== null ? () => updateBundle(itemId) : () => saveBundle(selectedBundle, selected)}>
                  <Typography variant="subtitle1">Adding:</Typography>
                  <Typography variant="caption">
                    Please confirm fields here should be accessed by
                    {" "}
                    <b>any</b>
                    {" "}
                    user or client tied to this bundle
                  </Typography>
                  {selected.filter(f => !savedSelected.includes(f)).map((field) => (
                    <Typography variant="overline" key={field} className={classes.labelRoot}>
                      <AddIcon className={classes.addIcon} />
                      <b>{field}</b>
                    </Typography>
                  ))}
                  <Typography variant="subtitle1">Removing:</Typography>
                  <Typography variant="caption">
                    Please confirm fields removed are not critical to
                    {" "}
                    <b>API</b>
                    {" "}
                    users or
                    {" "}
                    <b>MISIGHT</b>
                    {" "}
                    users tied to this bundle
                  </Typography>
                  {savedSelected.filter(f => !selected.includes(f)).map((field) => (
                    <Typography variant="overline" key={field} className={classes.labelRoot}>
                      <AddIcon className={classes.deleteIcon} />
                      <b>{field}</b>
                    </Typography>
                  ))}
                </ConfirmDialog>
                <Button onClick={() => setDeleteDialogOpen(true)}>Delete Bundle</Button>
                <ConfirmDialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen} confirmFunction={() => deleteBundle(itemId)} />
              </CardContent>
              <FormAlerts
                success={success}
                error={error}
                setSuccess={setSuccess}
              />
            </Card>
            <Card style={{ background: "#272822" }}>
              <CardContent>
                <p style={{ color: "white" }}>Raw Access Fields</p>
                <ReactJson
                  src={{ fields: selected }}
                  name={false}
                  groupArraysAfterLength={1000}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  displayArrayKey={false}
                  shouldCollapse={(field) => field.type === "array" && field.src.length > 100}
                  theme="ocean"
                />
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
    </>
  );
}
