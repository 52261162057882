import React from "react";
import { useSelector } from "react-redux";

import useClientConfiguration from "../../hooks/useClientConfiguration";
import useFormData from "../../hooks/useFormData";
import * as SharedDefinitions from "../../utils/SharedDefinitions";
import BaseForm from "./common/BaseForm";

// "ui:placeholder" doesn't work for the material-ui version...
const uiSchema = {
  lock: {
    "ui:help": "Leave unselected for Live",
  },
};

function transformErrors(errors) {
  return errors.map(error => {
    if (error.name === "pattern") {
      const myError = error;
      myError.message = "DO NOT include file extension in custom file name";
      return myError;
    }
    return error;
  });
}

export default function ANNStandardFeedForm({ children, studyType, client, setTaskConfig, submitHandler, formDataProp }) {
  const formOptions = useSelector((state) => state.formOptions);
  // Pre-populate form with previous data if available
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  useClientConfiguration(setFormData, studyType, client);

  const annStandardFeedSchema = {
    title: "ANN Standard Feed Form",
    description: "ANN Standard Feed",
    type: "object",
    required: ["start_quarter", "end_quarter", "territory_settings"],
    properties: {
      start_quarter: {
        type: "string",
        title: "Start Quarter",
        enum: formOptions.quarters,
      },
      end_quarter: {
        type: "string",
        title: "End Quarter",
        enum: formOptions.quarters,
      },
      production_cycle: {
        type: "string",
        title: "Production Cycle",
        enum: formOptions.quarters,
      },
      lock: {
        type: "string",
        title: "Lock (Data Set)",
        enum: formOptions.locksANN,
      },
      annuity_types: SharedDefinitions.annuityTypes,
      va_product_type: SharedDefinitions.vaProductType,
      territory_settings: {
        type: "object",
        title: "Territories",
        required: ["territory_mode"],
        properties: {
          territory_mode: {
            type: "string",
            title: "Territory Mode",
            enum: ["combined", "split"],
            default: "combined",
          },
          omit_territory_column: {
            type: "boolean",
            title: "Omit Territory Column (Previously Exclude Territory Column)",
            default: false,
          },
          omit_metro_column: {
            type: "boolean",
            title: "Omit Metro Column (Previously Exclude Metro Column)",
            default: false,
          },
        },
        dependencies: {
          territory_mode: SharedDefinitions.getTerritoryModeVA(formOptions, client),
        },
      },
      broker_zip_exclusions_settings: {
        type: "object",
        title: "Broker/Zip Exclusions Settings",
        properties: {
          fia_exclusion_settings: {
            type: "object",
            title: "FIA Broker/Zip Exclusions",
            properties: SharedDefinitions.brokerZipExclusions,
          },
          va_exclusion_settings: {
            type: "object",
            title: "VA Broker/Zip Exclusions",
            properties: SharedDefinitions.brokerZipExclusions,
          },
        },
      },
      verified_address_exclusions: SharedDefinitions.verifiedAddressExclusions,
      disable_phantom_exclusions: {
        type: "boolean",
        title: "Disable Phantom Exclusions",
      },
      include_indirect_transactions: {
        type: "boolean",
        title: "Include Indirect Transactions (Previously misnamed Sales)",
      },
    },
  };

  return (
    <BaseForm
      schema={annStandardFeedSchema}
      transformErrors={transformErrors}
      uiSchema={uiSchema}
      formData={formData}
      submitHandler={submitHandler}
      setFormData={setFormData}
    >
      {children}
    </BaseForm>
  );
}
