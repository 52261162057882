import { useCallback, useState } from "react";
import { API } from "../../../../utils/api";

const useFetchData = (url, appliedFilters, preWarmOnly) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextPaginationKey, setNextPaginationKey] = useState(null);
  const [loadedPaginationKeys, setLoadedPaginationKeys] = useState([]);

  const fetchData = useCallback((page, paginationKeyParam) => {
    setLoading(true);
    setLoadedPaginationKeys(prevKeys => prevKeys.concat(paginationKeyParam));

    const params = paginationKeyParam ? { ...appliedFilters, limit: 500, paginationKey: paginationKeyParam } : { ...appliedFilters, limit: 500 };
    if (preWarmOnly) { params.preWarmOnly = preWarmOnly; }
    API.request({ url, method: "GET", params }).then(
      (response) => {
        response.data.results.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1));
        setData(prevData => [...prevData, ...response.data.results]);
        setNextPaginationKey(response.data.paginationKey);
        setLoading(false);
      },
      () => {
        setLoading(false);
      },
    );
  }, [url, appliedFilters, preWarmOnly]);

  const refreshData = useCallback(() => {
    setLoading(true);
    let newData = [];
    const refreshRequests = [];

    loadedPaginationKeys?.forEach((paginationKey) => {
      const params = paginationKey ? { ...appliedFilters, limit: 500, paginationKey } : { ...appliedFilters, limit: 500 };
      if (preWarmOnly) { params.preWarmOnly = preWarmOnly; }
      refreshRequests.push(
        API.request({ url, method: "GET", params }).then((response) => {
          newData = newData.concat(response.data.results);
        }),
      );
    });
    Promise.all(refreshRequests).then(() => {
      newData.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1));
      setData(newData);
      setLoading(false);
    });
  }, [url, appliedFilters, loadedPaginationKeys, preWarmOnly]);

  const fetchQueuedData = useCallback(() => {
    setLoading(true);
    let fetchedData = [];
    const promisedRequest = [];
    const queuedStatus = ["RUNNING_QUEUE", "QUEUED"];
    queuedStatus?.forEach((status) => {
      const params = { ...appliedFilters, status };
      promisedRequest.push(
        API.request({ url, method: "GET", params }).then((response) => {
          fetchedData = fetchedData.concat(response.data.results);
        }),
      );
    });
    Promise.all(promisedRequest).then(() => {
      let finalFetch = [];
      fetchedData = fetchedData.sort((a, b) => b.createdDate < a.createdDate);
      const fetchRunnning = fetchedData.filter(a => a?.status === "RUNNING_QUEUE");
      finalFetch = finalFetch.concat(fetchRunnning);
      let fetchQueued = fetchedData.filter(a => a?.status === "QUEUED");
      /* eslint-disable*/
      fetchQueued = fetchQueued.sort((a, b) => ((a.createdDate > b.createdDate) ? 1 : ((b.createdDate > a.createdDate) ? -1 : 0)));
      finalFetch = finalFetch.concat(fetchQueued);
      setLoading(false);
      finalFetch.sort((a, b) => a.createdDate > b.createdDate ? -1 : 1);
      setData(finalFetch);
    });
  }, [url, appliedFilters]);

  const resetData = useCallback(() => {
    setData([]);
    setNextPaginationKey(null);
    setLoadedPaginationKeys([]);
  }, []);

  return { data, loading, nextPaginationKey, fetchData, refreshData, fetchQueuedData, resetData };
};

export default useFetchData;
