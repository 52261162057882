import React, { useEffect } from "react";
import { Chip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import useFormData from "../../hooks/useFormData";
import BaseForm from "./common/BaseForm";

export default function RebalanceTaggerForm({ children, setTaskConfig, submitHandler, formDataProp, client, displayHeader, setIsRequired }) {
  const formOptions = useSelector((state) => state.formOptions);
  // Pre-populate form with previous data if available
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);
  const clientDetails = `Client : ${client}`;
  const BasicFormSchema = {
    title: "Tag Rebalances",
    description: clientDetails,
    type: "object",
    required: [
      "start_quarter",
      "end_quarter",
    ],
    properties: {
      start_quarter: {
        type: "string",
        title: "Start Quarter",
        enum: formOptions.quarters,
      },
      end_quarter: {
        type: "string",
        title: "End Quarter",
        enum: formOptions.quarters,
      },
    },
  };

  useEffect(() => {
    setIsRequired((prevState) => ([...prevState, "start_quarter", "end_quarter"]));
  }, [setIsRequired]);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["start_quarter", "end_quarter"])} />
      </div>
      <BaseForm
        schema={BasicFormSchema}
        formData={formData}
        submitHandler={submitHandler}
        setFormData={setFormData}
      >

        {children}
      </BaseForm>
    </div>
  );
}
