import React, { useState } from "react";
import {
  Chip,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import useSWR from "swr";
import { getMisightClients } from "../../utils/api";

export default function ClientSelect({ client, setClient }) {
  // --- USERS ---
  const users = useSelector((state) => state.users.misightUsers);

  // --- CLIENTS ---
  const { data: clients } = useSWR("/misight/clients", getMisightClients);
  const allClient = clients ? [...clients, { display_name: "API ISS", mnemonic: "ISS" }] : [];
  const [clientSearch, setClientSearch] = useState("");
  const filteredClients = allClient?.filter(
    (c) => !clientSearch
      || (c.mnemonic.toLowerCase())?.includes(clientSearch)
      || (c.display_name.toLowerCase())?.includes(clientSearch),
  );

  return (
    <div>
      <Typography
        variant="h3"
        style={{ display: "flex", alignItems: "flex-end" }}
      >
        Please select a
        {" "}
        <TextField
          id="standard-full-width"
          style={{ marginLeft: 20 }}
          label={client ? "CLIENT" : ""}
          placeholder={client || "CLIENT"}
          variant="standard"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setClientSearch(e.target.value.toLowerCase())}
        />
      </Typography>
      {filteredClients?.slice(0, 10)?.map((c) => (
        <Chip
          key={c?.mnemonic}
          label={`${c.display_name} - ${
            users?.filter(
              (u) => u?.client_mnemonic === c?.mnemonic?.toUpperCase(),
            )?.length
          } users`}
          color={c.mnemonic.toUpperCase() === client ? "primary" : "default"}
          onClick={() => setClient(c.mnemonic.toUpperCase())}
        />
      ))}
      {filteredClients?.length >= 10 && <Chip label="...and more" disabled />}
    </div>
  );
}
