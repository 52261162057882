import React from "react";
import { Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import { Link, useParams } from "react-router-dom";
import useSWR from "swr";

import usePageTitle from "../../../hooks/usePageTitle";
import { getClientProfile, getSIClientProfileExclusions } from "../../../utils/api";
import { ClientProfileContextProvider } from "./ClientProfileContext";
import PrimaryInfoCard from "./PrimaryInfoCard";
import Exclusions from "./exclusions/Exclusions";

export default function ProfileDetails() {
  const { profileId, study } = useParams();
  const { data: primaryData, error: primaryDataError, mutate: primaryMutate } = useSWR(`/lms/api/v1/${study}/client/${study}_clientprofile/${profileId}`, () => getClientProfile(profileId, study));
  const { data: exclusions, error: exclusionsError, mutate } = useSWR(`/lms/api/v1/si_clientprofile/${profileId}/study/${study}`, () => getSIClientProfileExclusions(profileId, study));

  usePageTitle("Client Profile Details");

  return (
    <ClientProfileContextProvider
      mutate={mutate}
      clientProfileID={primaryData?.id}
      clientMnemonic={exclusions?.client_mnemonic}
      study={study}
    >
      {!primaryData && <div><Alert severity="info">Loading Client Profile Primary Data...</Alert></div>}
      {primaryDataError && <div><Alert severity="error">{`Error getting Client Profile Primary Data: ${primaryDataError}`}</Alert></div>}
      <PrimaryInfoCard
        profileID={primaryData?.id}
        clientID={primaryData?.client_id}
        clientMnemonic={exclusions?.client_mnemonic}
        fullName={primaryData?.full_name}
        shortName={primaryData?.short_name}
        consortiumExclusions={primaryData?.use_consortium_exclusions}
        clientExclusions={primaryData?.use_client_exclusions}
        allowlistOnly={primaryData?.use_allowlist_only}
        fiaAllowlistOnly={primaryData?.use_fia_allowlist_only}
        vaAllowlistOnly={primaryData?.use_va_allowlist_only}
        territoryMap={primaryData?.territory_map}
        channelMap={primaryData?.channel_map}
        mutate={primaryMutate}
      />
      {exclusionsError && <div><Alert severity="error">{`Error getting Exclusions: ${exclusionsError}`}</Alert></div>}
      <Exclusions exclusions={exclusions} />
      <Button variant="contained" color="primary" href="/client-profiles">
        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/client-profiles"
          state={{ clientId: primaryData?.client_id }}
        >
          Done
        </Link>
      </Button>
    </ClientProfileContextProvider>
  );
}
