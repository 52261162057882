import React from "react";
import useSWR from "swr";
import MaterialTable from "../../../components/Table";
import { getDataRevisions } from "../../../utils/api";

export default function DataRevisions() {
  const { data, error } = useSWR("/datarevisions", getDataRevisions);
  if (error) return <div>Error loading...</div>;

  return (
    <div style={{ width: "100%" }}>
      <MaterialTable
        isLoading={!data}
        columns={[
          { header: "Revision", accessorKey: "revision" },
          { header: "Notes", accessorKey: "notes" },
          { header: "Created At", accessorKey: "created_at", type: "datetime" },
          { header: "Created By", accessorKey: "created_by" },
        ]}
        data={data ?? []}
        title="Data Revisions"
      />
    </div>
  );
}
