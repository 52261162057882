import React from "react";
import { Chip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import useFormData from "../../../hooks/useFormData";
import BaseForm from "../../JsonSchema/common/BaseForm";

export default function AnnMissingZipsForm({ children, setTaskConfig, submitHandler, formDataProp, displayHeader }) {
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);
  const formOptions = useSelector((state) => state.formOptions);
  // Pre-populate form with previous data if available
  const annMissingZipsFormSchema = {
    title: "ANN Missing Zips",
    description: "",
    type: "object",
    required: [
      "start_quarter",
      "end_quarter",
    ],
    properties: {
      start_quarter: {
        type: "string",
        title: "Start Quarter",
        enum: formOptions.quarters,
      },
      end_quarter: {
        type: "string",
        title: "End Quarter",
        enum: formOptions.quarters,
      },
      lock: {
        type: "string",
        title: "Lock (Data Set)",
        enum: formOptions.locksANN,
      },
      alternate_client_ids: {
        type: "string",
        title: "Alternate Client_ID",
      },
    },
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["ann_type"])} />
      </div>
      <BaseForm
        schema={annMissingZipsFormSchema}
        formData={formData}
        submitHandler={submitHandler}
        setFormData={setFormData}
      >
        {children}
      </BaseForm>
    </div>
  );
}
