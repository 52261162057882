import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";

export default function LMSDDCrossReference({ children, setTaskConfig, submitHandler, studyType }) {
  useEffect(() => {
    setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, study: studyType }));
  }, [setTaskConfig, studyType]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      validator={validator}
    >
      <Typography variant="h6">Generate LMS-DD Cross Reference File</Typography>
      <Typography variant="subtitle1" style={{ margin: 15 }}>
        This job will generate a CSV file that maps SI Office IDs to Discovery Physical Branch IDs.
      </Typography>
      {children}
    </Form>
  );
}
