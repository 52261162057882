import React, { useEffect } from "react";
import { Typography, FormHelperText, FormControl } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { useSelector } from "react-redux";

export default function PublishToRedshift({ children, setTaskConfig, taskConfig, submitHandler, studyType }) {
  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType, file_type: "secondary_files" }));
  }, [setTaskConfig, studyType]);
  const locksMF = useSelector((state) => state.formOptions.locksMF);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      validator={validator}
    >
      <Typography variant="h6">
        {studyType.toUpperCase()}
        {" Publish To Redshift "}
      </Typography>
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel htmlFor="inputLabel-lock-select">Select lock to publish</InputLabel>
        <Select
          style={{ width: 500, marginBottom: 0 }}
          value={taskConfig.lock || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, lock: e.target.value }));
          }}
          label="Select lock to publish"
          inputProps={{
            name: "Select lock to publish",
            id: "inputLabel-lock-select",
          }}
        >
          {locksMF.map((quarter) => (
            <MenuItem key={quarter} value={quarter}>
              {quarter}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Leave unselected for Live</FormHelperText>
      </FormControl>
      {children}
    </Form>
  );
}
