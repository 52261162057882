import { useEffect, useState } from "react";

export default function useFormData(setTaskConfig, initialFormData = null) {
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    setTaskConfig(formData);
  }, [formData, setTaskConfig]);
  return [formData, setFormData];
}
