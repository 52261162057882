import React, { useEffect } from "react";
import { Chip, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import useClientConfiguration from "../../hooks/useClientConfiguration";
import useFormData from "../../hooks/useFormData";
import BaseForm from "./common/BaseForm";

const uiSchema = {
  lock: {
    "ui:help": "Leave unselected for Live",
  },
};

export default function PublishAthenaConsortiumForm({ children, studyType, client, setTaskConfig, submitHandler, formDataProp, displayHeader, setIsRequired }) {
  const formOptions = useSelector((state) => state.formOptions);
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  useClientConfiguration(setFormData, studyType, client);

  const annOTTSchema = {
    title: "Publish Consortium",
    description: "Create or update consortium data to athena",
    type: "object",
    required: [
      "start_quarter",
      "end_quarter",
    ],
    properties: {
      start_quarter: {
        type: "string",
        title: "Start Quarter",
        enum: formOptions.quarters,
      },
      end_quarter: {
        type: "string",
        title: "End Quarter",
        enum: formOptions.quarters,
      },
      lock: {
        type: "string",
        title: "Lock (Data Set)",
        enum: formOptions.locksANN,
      },
      production_cycle: {
        type: "string",
        title: "Production Cycle",
        enum: formOptions.quarters,
      },
    },
  };

  useEffect(() => {
    if (annOTTSchema.required) {
      setIsRequired((prevState) => ([...prevState, ...annOTTSchema.required]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsRequired]);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["lock"])} />
      </div>
      <BaseForm
        schema={annOTTSchema}
        uiSchema={uiSchema}
        formData={formData}
        submitHandler={submitHandler}
        setFormData={setFormData}
      >
        {children}
      </BaseForm>
    </div>
  );
}
