import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useFormData from "../../hooks/useFormData";
import BaseForm from "./common/BaseForm";

export default function MFSalesRecords({ children, setTaskConfig, submitHandler, formDataProp, setIsRequired }) {
  const formOptions = useSelector((state) => state.formOptions);
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  const mfSalesRecordsSchema = {
    title: "MF Sales and Records QA Report",
    description: "MF Sales and Records QA Report",
    type: "object",
    required: ["lock_required", "start_quarter", "end_quarter"],
    properties: {
      start_quarter: {
        type: "string",
        title: "Start Quarter",
        enum: formOptions.quarters,
      },
      end_quarter: {
        type: "string",
        title: "End Quarter",
        enum: formOptions.quarters,
      },
      lock_required: {
        type: "string",
        title: "Lock (Data Set)",
        enum: formOptions.locksMF,
      },
    },
  };

  useEffect(() => {
    if (mfSalesRecordsSchema.required) {
      setIsRequired((prevState) => ([...prevState, ...mfSalesRecordsSchema.required]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsRequired]);

  return (
    <BaseForm
      schema={mfSalesRecordsSchema}
      formData={formData}
      submitHandler={submitHandler}
      setFormData={setFormData}
    >
      {children}
    </BaseForm>
  );
}
