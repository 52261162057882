import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import CSVUpload from "../../components/input/CSVUpload";
import { fileType } from "./common/BaseForm";

export default function ANNLoadOfficeMapFormDD({ children, taskConfig, setTaskConfig, csv, setCsv, submitHandler, studyType }) {
  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType, file_type: fileType.OFFICE_MAP }));
  }, [setTaskConfig, studyType]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      validator={validator}
    >
      <Typography variant="h4" style={{ backgroundColor: "orange" }}>Discovery Data</Typography>
      <CSVUpload
        onChange={(update) => {
          setCsv({ attachment: update.csv });
          setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig }));
        }}
        value={{ csv, ...taskConfig }}
      />
      <Typography align="right" variant="subtitle1">
        The loaded file is processed through Mr.Clean before loading
      </Typography>
      {children}
    </Form>
  );
}
