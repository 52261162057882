/* eslint-disable react/jsx-no-bind,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,no-unused-vars */
import React, { useState } from "react";

import { getNamedType, isInterfaceType, isObjectType, isUnionType } from "graphql";

import { defaultArgs } from "../defaultNames";
import { unwrapOutputType } from "../util";
import ArgView from "./argView";
import Arrow from "./arrow";
import Checkbox from "./checkbox";
import FragmentView from "./fragmentView";

function AbstractView({
  implementingType,
  schema,
  getDefaultFieldNames,
  selections,
  modifySelections,
  makeDefaultArg,
  onRunOperation,
  getDefaultScalarArgValue,
  onCommit,
  definition,
  availableFragments,
  parentFieldName,
  customClassname,
}) {
  let previousSelection;
  const selection = selections.find(s => s.kind === "InlineFragment" && implementingType.name === s?.typeCondition?.name?.value);
  const fields = implementingType.getFields();
  const childSelections = selection?.selectionSet?.selections || [];

  const addFragment = () => {
    modifySelections([
      ...selections,
      previousSelection || {
        kind: "InlineFragment",
        typeCondition: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: implementingType.name,
          },
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: getDefaultFieldNames(implementingType).map(fieldName => ({
            kind: "Field",
            name: {
              kind: "Name",
              value: fieldName,
            },
          })),
        },
      },
    ]);
  };

  const removeFragment = () => {
    previousSelection = selection;
    modifySelections(selections.filter(s => s !== selection));
  };

  const modifyChildSelections = (selectionsToModify, options) => modifySelections(
    selection.map(s => {
      if (s === selection) {
        return {
          directives: s.directives,
          kind: "InlineFragment",
          typeCondition: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: implementingType.name,
            },
          },
          selectionSet: {
            kind: "SelectionSet",
            selections: selectionsToModify,
          },
        };
      }
      return selection;
    }),
    options,
  );

  return (
    <div className={`graphiql-explorer-${implementingType.name}`}>
      <span style={{ cursor: "pointer" }} onClick={selection ? removeFragment : addFragment}>
        <Checkbox checked={!!selection} />
        <span style={{ color: "hsl(var(--color-tertiary))" }}>{implementingType.name}</span>
      </span>
      {
        selection
          ? (
            <div style={{ marginLeft: 16 }}>
              {
                Object.keys(fields)
                  .sort()
                  .map(fieldName => (
                    <FieldView
                      key={fieldName}
                      field={fields[fieldName]}
                      selections={childSelections}
                      modifySelections={modifyChildSelections}
                      schema={schema}
                      getDefaultFieldNames={getDefaultFieldNames}
                      getDefaultScalarArgValue={getDefaultScalarArgValue}
                      makeDefaultArg={makeDefaultArg}
                      onRunOperation={onRunOperation}
                      onCommit={onCommit}
                      definition={definition}
                      availableFragments={availableFragments}
                      parentFieldName={parentFieldName}
                      customClassname={customClassname}
                    />
                  ))
              }
            </div>
          )
          : null
      }
    </div>
  );
}

export function FieldView({
  field,
  schema,
  getDefaultFieldNames,
  selections,
  modifySelections,
  getDefaultScalarArgValue,
  makeDefaultArg,
  availableFragments,
  onRunOperation,
  onCommit,
  definition,
  parentFieldName,
  customClassname,
}) {
  const [displayFieldActions, setDisplayFieldActions] = useState(false);

  let previousSelection;
  const selection = selections.find(s => s.kind === "Field" && field.name === s.name.value);

  const type = unwrapOutputType(field.type);
  const args = field.args.sort((a, b) => a.name.localeCompare(b.name));
  const applicableFragments = (isObjectType(type) || isInterfaceType(type) || isUnionType(type)
    ? availableFragments?.[type.name]
    : null) || [];

  const path = parentFieldName ? `${parentFieldName}.${field.name}` : field.name;

  function addAllFieldsToSelections(rawSubfields) {
    const subFields = rawSubfields
      ? Object.keys(rawSubfields)
        .map((fieldName) => ({
          kind: "Field",
          name: {
            kind: "Name",
            value: fieldName,
          },
          arguments: [],
        }))
      : [];

    const nextSelections = [
      ...selections.filter((s) => {
        if (s.kind === "InlineFragment") return true;
        // Remove the current selection set for the target field
        return s.name.value !== field.name;
      }),
      {
        kind: "Field",
        name: {
          kind: "Name",
          value: field.name,
        },
        arguments: defaultArgs(
          getDefaultScalarArgValue,
          makeDefaultArg,
          field,
        ),
        selectionSet: {
          kind: "SelectionSet",
          selections: subFields,
        },
      },
    ];

    modifySelections(nextSelections);
  }

  function addFieldToSelections() {
    const nextSelections = [
      ...selections,
      previousSelection || {
        kind: "Field",
        name: {
          kind: "Name",
          value: field.name,
        },
        arguments: defaultArgs(
          getDefaultScalarArgValue,
          makeDefaultArg,
          field,
        ),
      },
    ];

    modifySelections(nextSelections);
  }

  function removeFieldFromSelections() {
    previousSelection = selection;
    modifySelections(selections.filter((s) => s !== selection));
  }

  function handleUpdateSelections(event) {
    if (selection && !event.altKey) {
      removeFieldFromSelections();
    } else {
      const fieldType = getNamedType(field.type);
      const rawSubfields = isObjectType(fieldType) && fieldType.getFields();

      // Select all subfields (on alt-click)
      if (!!rawSubfields && event.altKey) {
        addAllFieldsToSelections(rawSubfields);
      } else {
        addFieldToSelections(rawSubfields);
      }
    }
  }

  function setArguments(argumentNodes, options) {
    if (!selection) {
      console.error("Missing selection when setting arguments", argumentNodes);
      return null;
    }
    return modifySelections(
      selections.map((s) => (s === selection
        ? {
          alias: selection.alias,
          arguments: argumentNodes,
          directives: selection.directives,
          kind: "Field",
          name: selection.name,
          selectionSet: selection.selectionSet,
        }
        : s)),
      options,
    );
  }

  const modifyChildSelections = (selectionsToModify, options) => modifySelections(
    selections.map((s) => {
      if (s.kind === "Field" && field.name === s.name.value) {
        if (s.kind !== "Field") {
          throw new Error("invalid selection");
        }
        return {
          alias: s.alias,
          arguments: s.arguments,
          directives: s.directives,
          kind: "Field",
          name: s.name,
          selectionSet: {
            kind: "SelectionSet",
            selections: selectionsToModify,
          },
        };
      }
      return s;
    }),
    options,
  );

  let className = "graphiql-explorer-node";
  const customFieldClass = customClassname({ type: "field", path, field });
  if (customFieldClass?.field) className += ` ${customFieldClass?.field}`;
  if (field.isDeprecated) className += " graphiql-explorer-deprecated";

  const node = (
    <div className={className}>
      <span
        title={field.description}
        className="graphiql-explorer-field"
        data-field-name={field.name}
        data-field-type={type.name}
        onClick={handleUpdateSelections}
        onMouseEnter={() => {
          const containsMeaningfulSubselection = isObjectType(type)
              && selection?.selectionSet?.selections
              && selection.selectionSet.selections.filter(
                s => s.kind !== "FragmentSpread",
              ).length > 0;

          if (containsMeaningfulSubselection) {
            setDisplayFieldActions(true);
          }
        }}
        onMouseLeave={() => setDisplayFieldActions(false)}
      >
        {
          isObjectType(type)
            ? <Arrow open={!!selection} />
            : <Checkbox checked={!!selection} />
        }
        <span className="graphiql-explorer-field-name">
          {field.name}
        </span>
        {
          displayFieldActions
            ? (
              <button
                type="submit"
                className="graphiql-explorer-action-button"
                title="Extract selections into a new reusable fragment"
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  // 1. Create a fragment spread node
                  // 2. Copy selections from this object to fragment
                  // 3. Replace selections in this object with fragment spread
                  // 4. Add fragment to document
                  const typeName = type.name;
                  let newFragmentName = `${typeName}Fragment`;

                  const conflictingNameCount = applicableFragments.filter(
                    (fragment) => fragment.name.value.startsWith(newFragmentName),
                  ).length;

                  if (conflictingNameCount > 0) {
                    newFragmentName = `${newFragmentName}${conflictingNameCount}`;
                  }

                  const childSelections = selection?.selectionSet?.selections || [];

                  const nextSelections = [
                    {
                      kind: "FragmentSpread",
                      name: {
                        kind: "Name",
                        value: newFragmentName,
                      },
                      directives: [],
                    },
                  ];

                  const newFragmentDefinition = {
                    kind: "FragmentDefinition",
                    name: {
                      kind: "Name",
                      value: newFragmentName,
                    },
                    typeCondition: {
                      kind: "NamedType",
                      name: {
                        kind: "Name",
                        value: type.name,
                      },
                    },
                    directives: [],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: childSelections,
                    },
                  };

                  const newDoc = modifyChildSelections(nextSelections, false);

                  if (newDoc) {
                    const newDocWithFragment = {
                      ...newDoc,
                      definitions: [...newDoc.definitions, newFragmentDefinition],
                    };

                    onCommit(newDocWithFragment);
                  } else {
                    console.warn("Unable to complete extractFragment operation");
                  }
                }}
              >
                <span>…</span>
              </button>
            )
            : null
        }
      </span>
      {
        selection && args.length
          ? (
            <div
              style={{ marginLeft: 16 }}
              className="graphiql-explorer-graphql-arguments"
            >
              {args.map(arg => (
                <ArgView
                  key={arg.name}
                  parentField={field}
                  arg={arg}
                  selection={selection}
                  modifyArguments={setArguments}
                  getDefaultScalarArgValue={getDefaultScalarArgValue}
                  makeDefaultArg={makeDefaultArg}
                  onRunOperation={onRunOperation}
                  onCommit={onCommit}
                  definition={definition}
                />
              ))}
            </div>
          )
          : null
      }
    </div>
  );

  if (selection && (isObjectType(type) || isInterfaceType(type) || isUnionType(type))) {
    const fields = isUnionType(type) ? {} : type.getFields();
    const childSelections = selection?.selectionSet?.selections || [];
    return (
      <div>
        {node}
        <div style={{ marginLeft: 16 }}>
          {
            applicableFragments.map(fragment => (
              schema.getType(fragment.typeCondition.name.value)
                ? (
                  <FragmentView
                    key={fragment.name.value}
                    fragment={fragment}
                    selections={childSelections}
                    modifySelections={modifyChildSelections}
                    schema={schema}
                    onCommit={onCommit}
                  />
                )
                : null
            ))
          }
          {
            Object.keys(fields)
              .sort()
              .map(fieldName => (
                <FieldView
                  key={fieldName}
                  field={fields[fieldName]}
                  selections={childSelections}
                  modifySelections={modifyChildSelections}
                  schema={schema}
                  getDefaultFieldNames={getDefaultFieldNames}
                  getDefaultScalarArgValue={getDefaultScalarArgValue}
                  makeDefaultArg={makeDefaultArg}
                  onRunOperation={onRunOperation}
                  onCommit={onCommit}
                  definition={definition}
                  availableFragments={availableFragments}
                  parentFieldName={path}
                  customClassname={customClassname}
                />
              ))
          }
          {
            isInterfaceType(type) || isUnionType(type)
              ? schema
                .getPossibleTypes(type)
                .map(t => (
                  <AbstractView
                    key={type.name}
                    implementingType={t}
                    selections={childSelections}
                    modifySelections={modifyChildSelections}
                    schema={schema}
                    getDefaultFieldNames={getDefaultFieldNames}
                    getDefaultScalarArgValue={getDefaultScalarArgValue}
                    makeDefaultArg={makeDefaultArg}
                    onRunOperation={onRunOperation}
                    onCommit={onCommit}
                    definition={definition}
                    parentFieldName={path}
                    customClassname={customClassname}
                  />
                ))
              : null
          }
        </div>
      </div>
    );
  }
  return node;
}
