/* eslint-disable no-restricted-syntax */
import { isEnumType, isInputObjectType, isLeafType, isRequiredInputField } from "graphql";
import { isRequiredArgument, unwrapInputType } from "./util";

export function defaultGetDefaultFieldNames(type) {
  const fields = type.getFields();

  // Is there an `id` field?
  if (fields.id) {
    const res = ["id"];
    if (fields.email) {
      res.push("email");
    } else if (fields.name) {
      res.push("name");
    }
    return res;
  }

  // Is there an `edges` field?
  if (fields.edges) return ["edges"];

  // Is there an `node` field?
  if (fields.node) return ["node"];
  if (fields.nodes) return ["nodes"];

  // Include all leaf-type fields.
  const leafFieldNames = [];
  Object.keys(fields)
    .forEach(fieldName => {
      if (isLeafType(fields[fieldName].type)) {
        leafFieldNames.push(fieldName);
      }
    });

  if (!leafFieldNames.length) {
    // No leaf fields, add typename so that the query stays valid
    return ["__typename"];
  }
  return leafFieldNames.slice(0, 2); // Prevent too many fields from being added
}

export function defaultValue(argType) {
  if (isEnumType(argType)) {
    return {
      kind: "EnumValue",
      value: argType.getValues()[0].name,
    };
  }
  switch (argType.name) {
  case "String":
    return {
      kind: "StringValue",
      value: "",
    };
  case "Float":
    return {
      kind: "FloatValue",
      value: "1.5",
    };
  case "Int":
    return {
      kind: "IntValue",
      value: "10",
    };
  case "Boolean":
    return {
      kind: "BooleanValue",
      value: false,
    };
  default:
    return {
      kind: "StringValue",
      value: "",
    };
  }
}

export const defaultGetDefaultScalarArgValue = (parentField, arg, argType) => defaultValue(argType);

export function defaultInputObjectFields(
  getDefaultScalarArgValue,
  makeDefaultArg,
  parentField,
  fields,
) {
  const nodes = [];
  for (const field of fields) {
    if (isRequiredInputField(field) || (makeDefaultArg && makeDefaultArg(parentField, field))) {
      const fieldType = unwrapInputType(field.type);
      if (isInputObjectType(fieldType)) {
        const fs = fieldType.getFields();
        nodes.push({
          kind: "ObjectField",
          name: {
            kind: "Name",
            value: field.name,
          },
          value: {
            kind: "ObjectValue",
            fields: defaultInputObjectFields(
              getDefaultScalarArgValue,
              makeDefaultArg,
              parentField,
              Object.keys(fs)
                .map(k => fs[k]),
            ),
          },
        });
      } else if (isLeafType(fieldType)) {
        nodes.push({
          kind: "ObjectField",
          name: {
            kind: "Name",
            value: field.name,
          },
          value: getDefaultScalarArgValue(parentField, field, fieldType),
        });
      }
    }
  }
  return nodes;
}

export function defaultArgs(
  getDefaultScalarArgValue,
  makeDefaultArg,
  field,
) {
  const args = [];
  for (const arg of field.args) {
    if (isRequiredArgument(arg) || (makeDefaultArg && makeDefaultArg(field, arg))) {
      const argType = unwrapInputType(arg.type);
      if (isInputObjectType(argType)) {
        const fields = argType.getFields();
        args.push({
          kind: "Argument",
          name: {
            kind: "Name",
            value: arg.name,
          },
          value: {
            kind: "ObjectValue",
            fields: defaultInputObjectFields(
              getDefaultScalarArgValue,
              makeDefaultArg,
              field,
              Object.keys(fields)
                .map(k => fields[k]),
            ),
          },
        });
      } else if (isLeafType(argType)) {
        args.push({
          kind: "Argument",
          name: {
            kind: "Name",
            value: arg.name,
          },
          value: getDefaultScalarArgValue(field, arg, argType),
        });
      }
    }
  }
  return args;
}
