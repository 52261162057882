import React from "react";
import { Avatar, Box, Card, CardActions, CardContent, Divider, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import moment from "moment-timezone";
import { nanoid } from "nanoid";

import CircularProgress from "../../../../components/Spinner";
import GroupsChip from "../GroupsChip";
import UserStatusChip from "../UserStatusChip";
import Activation from "./Activation";
import PasswordReset from "./PasswordReset";

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100,
  },
}));

export default function Profile({ className, userData, loading, refreshUserFunction }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          padding={2}
        >
          {!loading
            ? <Avatar className={classes.avatar} />
            : <CircularProgress style={{ width: "100px", height: "100px" }} />}
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h4"
          >
            {userData.name}
          </Typography>
          <div>
            <UserStatusChip status={userData.status || "Unknown"} />
          </div>
          <Divider style={{ marginBlock: 5, width: "80%" }} />
          <Typography
            color="textSecondary"
            gutterBottom
            variant="subtitle1"
          >
            {`Username: ${userData.id || "Unknown"}`}
          </Typography>
          <Divider style={{ marginBlock: 5, width: "80%" }} />
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body1"
          >
            Groups:
            {" "}
            {userData?.groups?.map(g => <GroupsChip key={nanoid()} label={g} group={g} />) || "Unknown"}
          </Typography>
          <Divider style={{ marginBlock: 5, width: "80%" }} />
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {"Last login: "}
            {
              userData.last_login
                ? moment(userData.last_login).format("MMMM Do YYYY, h:mm:ss a")
                : <span style={{ color: theme.palette.error.main }}>Never logged in</span>
            }
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        <CardActions>
          <Activation userData={userData} loading={loading} refreshUserFunction={refreshUserFunction} />
          <PasswordReset userData={userData} loading={loading} refreshUserFunction={refreshUserFunction} />
        </CardActions>
      </Box>
    </Card>
  );
}
