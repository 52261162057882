/* eslint-disable react/destructuring-assignment */
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Card, Typography, Tooltip, CardContent, Grid, Input, IconButton, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TreeItem } from "@mui/x-tree-view";
import moment from "moment-timezone";

import { isGroup, recursiveAllFields, addParentNodes, removeLonelyParentNodes } from "./utils";

function getIcon(fieldKey, fieldObj, isSelected, classes) {
  if (
    isGroup(fieldObj)
    && Object.keys(fieldObj?.fields)?.every((f) => isSelected(`${fieldKey}.${f}`))
  ) {
    return <CheckCircleIcon className={classes.labelIconSelected} />;
  }
  if (
    isSelected(fieldKey)
    || Object.keys(fieldObj?.fields)?.some((f) => isSelected(`${fieldKey}.${f}`))
  ) {
    return <CheckIcon className={classes.labelIconSelected} />;
  }
  return <ClearIcon className={classes.labelIconNotSelected} />;
}

export function GQlTreeItem({
  fieldName,
  fieldObj,
  fieldKey,
  isSelected,
  setSelected,
  children,
  classes,
}) {
  return (
    <TreeItem
      id={fieldKey}
      itemId={fieldKey}
      key={fieldKey}
      label={(
        <div className={classes.labelRoot}>
          {getIcon(fieldKey, fieldObj, isSelected, classes)}
          <Typography variant="body2" className={classes.labelText}>
            {fieldName}
          </Typography>
          {!!fieldObj?.description && (
            <Tooltip title={fieldObj?.description} placement="right-start">
              <HelpOutlineIcon />
            </Tooltip>
          )}
          {!!isGroup(fieldObj) && (
            <ClearAllIcon
              onClick={() => setSelected((prevSelected) => [
                ...new Set([
                  ...prevSelected,
                  ...recursiveAllFields(fieldObj, fieldKey, []),
                ]),
              ])}
            />
          )}
        </div>
      )}
      // labelIcon={ClearIcon}
      onClick={() => {
        if (!isGroup(fieldObj)) {
          if (isSelected(fieldKey)) {
            // Remove field and make sure we remove any parents that are no longer needed
            setSelected((prevSelected) => [...new Set(removeLonelyParentNodes(fieldKey, prevSelected))]);
          } else {
            // Add field and make sure we add any parents
            setSelected((prevSelected) => [
              ...new Set([...prevSelected, ...addParentNodes(fieldKey)]),
            ]);
          }
        }
      }}
    >
      {children}
    </TreeItem>
  );
}

const useStyles = makeStyles((theme) => ({
  selectedCard: {
    backgroundColor: theme.palette.success.main,
  },
  hoveredCard: {
    backgroundColor: "#edecec",
  },
}));

export function HoverCard(props) {
  const classes = useStyles();

  const [hover, setHover] = useState(false);

  return (
    <Card
      {...props}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      className={[props.className, hover ? classes.hoveredCard : ""]}
      style={{ ...props.style, margin: "1em" }}
    >
      {props.children}
    </Card>
  );
}

export function BundlesList({
  bundles,
  selectedBundle,
  setSelectedBundle,
  saveBundle,
  client,
  assignBundle,
  unassignBundle,
  creatable,
}) {
  const classes = useStyles();
  const [createNew, setCreateNew] = useState(false);
  const [newBundleName, setNewBundleName] = useState();

  return (
    <Grid item xs={12} style={{ display: "flex", flexWrap: "wrap" }}>
      {bundles?.map((bundle) => (
        <Grid item xs={3} key={bundle?.id}>
          <HoverCard
            onClick={() => {
              if (client && assignBundle) {
                assignBundle(client, bundle.id);
              } else if (unassignBundle) {
                unassignBundle(bundle.assignment_id);
              } else {
                setSelectedBundle(bundle.name);
              }
            }}
            className={
              bundle.name === selectedBundle ? classes.selectedCard : ""
            }
          >
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h4">{bundle.name}</Typography>
              <Typography color="textSecondary">
                {bundle && `${JSON.parse(bundle?.fields)?.length} fields`}
              </Typography>
              <Typography color="textSecondary">
                {`Updated: ${moment(bundle?.updated_at)?.fromNow()}`}
              </Typography>
              {bundle?.assignment_id && (
                <>
                  <Divider style={{ width: "100%", margin: "1em" }} />
                  <Typography color="textSecondary">
                    {`Assigned to ${client} ${moment(bundle?.assignment_updated_at)?.fromNow()} by ${bundle?.assignment_updated_by}`}
                  </Typography>
                </>
              )}
            </CardContent>
          </HoverCard>
        </Grid>
      ))}
      {creatable && (
        <Grid item xs={3}>
          <HoverCard>
            <CardContent
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                minWidth: 300,
              }}
            >
              {createNew ? (
                <>
                  <Input
                    placeholder="Bundle Name"
                    onChange={(e) => setNewBundleName(e.target.value)}
                  />
                  <IconButton
                    onClick={() => {
                      saveBundle(newBundleName, []);
                      setNewBundleName();
                      setCreateNew(false);
                    }}
                    size="large"
                  >
                    <SaveAltIcon />
                  </IconButton>
                </>
              ) : (
                <IconButton onClick={() => setCreateNew(true)} size="large">
                  <AddIcon />
                </IconButton>
              )}
            </CardContent>
          </HoverCard>
        </Grid>
      )}
    </Grid>
  );
}
