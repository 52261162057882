import React, { useEffect } from "react";
import { Checkbox, FormControl, FormControlLabel, InputLabel, Select, MenuItem, Typography } from "@mui/material";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { useSelector } from "react-redux";
import OptionSelect from "../../components/input/OptionSelect";

const aggregateFields = [
  { id: "id", label: "id" },
  { id: "client_id", label: "client_id" },
  { id: "firm_crd", label: "firm_crd" },
  { id: "dd_physical_branch_id", label: "dd_physical_branch_id" },
  { id: "split_broker_designation", label: "split_broker_designation" },
  { id: "dd_address", label: "dd_address" },
  { id: "dd_city", label: "dd_city" },
  { id: "dd_state", label: "dd_state" },
  { id: "dd_zip", label: "dd_zip" },
  { id: "deposit_type", label: "deposit_type" },
  { id: "direct", label: "direct" },
  { id: "ann_product_type", label: "ann_product_type" },
  { id: "va_prod_type", label: "va_prod_type" },
  { id: "product_name", label: "product_name" },
  { id: "ann_dist_type", label: "ann_dist_type" },
  { id: "sale", label: "sale" },
  { id: "firm_name", label: "firm_name" },
  { id: "share_class", label: "share_class" },
];

export default function AnnGeneralDataDumpDD({ children, taskConfig, setTaskConfig, submitHandler, studyType }) {
  const formOptions = useSelector((state) => state.formOptions);
  useEffect(() => {
    setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, study: studyType }));
  }, [setTaskConfig, studyType]);
  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      validator={validator}
    >
      <Typography variant="h4" style={{ backgroundColor: "orange" }}>Discovery Data</Typography>
      <FormControl fullWidth margin="normal" variant="outlined">
        <InputLabel required htmlFor="inputLabel-start-quarter-select">Start Quarter</InputLabel>
        <Select
          required
          style={{ width: 500, marginBottom: 20 }}
          value={taskConfig.start_quarter || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, start_quarter: e.target.value }));
          }}
          label="Start Quarter"
          inputProps={{
            name: "start quarter",
            id: "inputLabel-start-quarter-select",
          }}
        >
          {formOptions.quarters.map((quarter) => (
            <MenuItem key={quarter} value={quarter}>
              {quarter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal" variant="outlined">
        <InputLabel required htmlFor="inputLabel-end-quarter-select">End Quarter</InputLabel>
        <Select
          required
          style={{ width: 500, marginBottom: 20 }}
          value={taskConfig.end_quarter || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, end_quarter: e.target.value }));
          }}
          label="End Quarter"
          inputProps={{
            name: "end quarter",
            id: "inputLabel-end-quarter-select",
          }}
        >
          {formOptions.quarters.map((quarter) => (
            <MenuItem key={quarter} value={quarter}>
              {quarter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="inputLabel-lock-select">Lock (Data Set)</InputLabel>
        <Select
          style={{ width: 500, marginBottom: -10 }}
          value={taskConfig.lock || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, lock: e.target.value }));
          }}
          label="Lock"
          inputProps={{
            name: "lock",
            id: "inputLabel-lock-select",
          }}
        >
          {formOptions.locksANN.map((lock) => (
            <MenuItem key={lock} value={lock}>
              {lock}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl margin="normal" fullWidth variant="outlined">
        <OptionSelect
          multiple
          options={aggregateFields}
          onChange={(fields) => setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, aggregate_fields: fields }))}
          label="Data Dump Fields"
          value={taskConfig.aggregate_fields || []}
          required
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <FormControlLabel
          label="Turn Off Phantom Exclusions"
          control={(
            <Checkbox
              checked={taskConfig.turn_off_phantom}
              onChange={(e) => setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, turn_off_phantom: e.target.checked }))}
            />
          )}
        />
      </FormControl>
      <FormControl>
        <FormControlLabel
          label="Invert Phantom Exclusions"
          control={(
            <Checkbox
              checked={taskConfig.invert_phantom}
              onChange={(e) => setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, invert_phantom: e.target.checked }))}
            />
          )}
        />
      </FormControl>
      {children}
    </Form>
  );
}
