import React, { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Card, Collapse, Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import { textPerPalette } from "../../../theme/common";
import { reportMappings } from "../constants";

const useStyles = makeStyles((theme) => ({
  gridCards: {
    margin: "5px 8px",
  },
  sideBarTitle: {
    width: "100%",
    backgroundColor: theme.palette.info.main,
    color: textPerPalette(theme),
    padding: "4px 8px",
    textAlign: "center",
    fontSize: "1rem",
  },
  sideBarContent: {
    padding: "4px 8px",
  },
}));

export default function SideBarView({
  selectedTab,
  currentSheet,
  setCurrentSheet,
  currentView,
  setCurrentView,
  currentSheetRef,
}) {
  const [openItems, setOpenItems] = useState([currentView]);
  const theme = useTheme();
  const classes = useStyles(theme);
  const handleClick = (itemId) => {
    setCurrentView(itemId);
    setCurrentSheet(reportMappings[selectedTab].filter(item => item.id === itemId)?.[0].items?.[0].id);
    // eslint-disable-next-line no-param-reassign
    currentSheetRef.current = reportMappings[selectedTab].filter(item => item.id === itemId)?.[0].items?.[0].id;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (openItems?.includes(itemId)) {
      setOpenItems(openItems?.filter((id) => id !== itemId));
    } else {
      setOpenItems([itemId]);
    }
  };

  const handleSubItemClick = (subItemId) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setCurrentSheet(subItemId);
    // eslint-disable-next-line no-param-reassign
    currentSheetRef.current = subItemId;
  };

  const listLabelColor = theme.palette.themeName === "dark" ? "white" : "black";

  return (
    <Card
      className={classes.gridCards}
    >
      <Typography variant="h6" className={classes.sideBarTitle}>
        List of Views
      </Typography>
      {reportMappings[selectedTab].map((item, key) => (
        <List
          key={item.id}
          className={classes.sideBarContent}
        >
          <ListItem button style={{ padding: "8px 5px" }} onClick={() => handleClick(item.id)}>
            <ListItemText primary={<Typography variant="body1" style={{ fontWeight: "bold" }}>{item.label}</Typography>} />
            {openItems?.includes(item.id) ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openItems?.includes(item.id)} timeout="auto" unmountOnExit>
            {item.items?.map((subItem) => (
              <List component="div" disablePadding key={subItem.id}>
                <ListItem button style={{ paddingLeft: "15px" }} onClick={() => handleSubItemClick(subItem.id)}>
                  <ListItemText>
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: subItem.id === currentSheet ? "bold" : "normal",
                        fontStyle: subItem.id === currentSheet ? "oblique" : "normal",
                        color: subItem.id === currentSheet ? "#397FCA" : listLabelColor,
                      }}
                    >
                      {subItem.label}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            ))}
          </Collapse>
          {(reportMappings[selectedTab].length - 1) === key ? "" : <Divider style={{ margin: "1em 0em", border: "0.25px solid rgba(0,0,0,0.10)" }} />}
        </List>
      ))}
    </Card>
  );
}
