import React, { useEffect } from "react";
import { FormControl, Typography } from "@mui/material";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";

import CSVUpload from "../../components/input/CSVUpload";
import OptionSelect from "../../components/input/OptionSelect";
import { fileType } from "./common/BaseForm";

const insertTypes = [
  { id: "append", label: "Supplement" },
  { id: "replace", label: "Replacement" },
  { id: "supplace", label: "Supplacement" },
];

export default function AnnLoadVAProductTypesDD({ children, taskConfig, setTaskConfig, csv, setCsv, submitHandler, studyType }) {
  useEffect(
    () => {
      setTaskConfig((preConfig) => ({ ...preConfig, study: studyType, file_type: fileType.SECONDARY_FILES }));
    },
    [setTaskConfig, studyType],
  );
  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      validator={validator}
    >
      <Typography variant="h4" style={{ backgroundColor: "orange" }}>Discovery Data</Typography>
      <CSVUpload
        onChange={(update) => {
          setCsv({ attachment: update.csv });
        }}
        value={{ csv, ...taskConfig }}
      />
      <FormControl fullWidth margin="normal">
        <OptionSelect
          required
          style={{ width: 500, marginBottom: 10 }}
          value={taskConfig.append_replace || ""}
          onChange={(insertionType) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, append_replace: insertionType }));
          }}
          options={insertTypes}
          label="Insertion Type"
        />
      </FormControl>
      {children}
    </Form>
  );
}
