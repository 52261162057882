import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  TextField,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import moment from "moment-timezone";

import { API } from "../../../utils/api";

export default function UpdateDialog({
  clientData,
  setClientData,
  open,
  handleClose,
  reloadFunction,
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const formFields = ["client_description", "lms_client_id", "brightscope_label", "default_territory_map", "default_wholesaler_mapping", "default_division_mapping", "default_channel_mapping"];

  async function updateClient() {
    setLoading("Updating client data...");
    const clientFormData = {};
    formFields.forEach((field) => {
      clientFormData[field] = clientData[field];
    });
    await API.request({
      url: `/di/territory/clients/${encodeURIComponent(clientData.pk)}`,
      method: "PUT",
      data: clientFormData,
    }).then(
      () => {
        setLoading(false);
        setSuccess("Your client was updated!");
      },
      (e) => {
        setLoading(false);
        setError(`${e}`);
      },
    );
  }

  async function handleSubmit() {
    await updateClient();
    reloadFunction();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <form>
        <DialogTitle id="form-dialog-title">
          {`Update ${clientData?.client_description}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Created: ${clientData?.created ? moment(clientData.created).format("MMMM Do YYYY, h:mm:ss a") : "Unknown"}`}
          </DialogContentText>
          <Divider />
          <FormControl fullWidth>
            {formFields.map((field) => (
              <FormControl margin="normal" key={[field]}>
                <TextField
                  autoFocus
                  value={clientData ? clientData[[field]] : ""}
                  onChange={(event) => setClientData({
                    ...clientData,
                    [field]: event.target.value,
                  })}
                  label={[field]}
                  type="text"
                  fullWidth
                />
              </FormControl>
            ))}
          </FormControl>
        </DialogContent>
        {loading && <div><Alert severity="info">{`${loading}`}</Alert></div>}
        {error && <div><Alert severity="error">{`${error}`}</Alert></div>}
        {success && <div><Alert severity="success">{`${success}`}</Alert></div>}
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
