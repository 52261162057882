import React, { useEffect } from "react";
import { Checkbox, FormControl, FormControlLabel, InputLabel, Select, MenuItem, TextField, Tooltip } from "@mui/material";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { useSelector } from "react-redux";
import APISelect from "../../components/input/APISelect";

export default function AddNewClient({ children, taskConfig, setTaskConfig, submitHandler, studyType, setIsRequired }) {
  const formOptions = useSelector((state) => state.formOptions);
  useEffect(() => {
    setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, study: studyType }));
    setIsRequired((prevState) => ([...prevState, "new_client_id", "new_client_name", "new_client_abbreviation"]));
  }, [setTaskConfig, studyType, setIsRequired]);
  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      validator={validator}
    >
      <FormControl fullWidth margin="normal" variant="outlined">
        <TextField
          label="New Client ID"
          value={taskConfig.new_client_id || ""}
          onChange={(e) => setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, new_client_id: e.target.value }))}
          required
        />
      </FormControl>
      <FormControl fullWidth margin="normal" variant="outlined">
        <TextField
          label="New Client Name"
          value={taskConfig.new_client_name || ""}
          onChange={(e) => setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, new_client_name: e.target.value }))}
          required
        />
      </FormControl>
      <FormControl fullWidth margin="normal" variant="outlined">
        <TextField
          label="New Client Abbreviation"
          value={taskConfig.new_client_abbreviation || ""}
          onChange={(e) => setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, new_client_abbreviation: e.target.value }))}
          required
        />
      </FormControl>
      <FormControl margin="normal" variant="outlined">
        <Tooltip title="New Client" placement="right-end">
          <FormControlLabel
            control={(
              <Checkbox
                checked={taskConfig.is_new_client}
                onChange={(e) => setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, is_new_client: e.target.checked }))}
              />
            )}
            label="Deactivate Client"
          />
        </Tooltip>
      </FormControl>
      <FormControl fullWidth margin="normal" variant="outlined">
        <FormControlLabel
          control={(
            <Checkbox
              checked={taskConfig.update_old_client}
              onChange={(e) => setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, update_old_client: e.target.checked }))}
            />
          )}
          label="Rename Old Client"
        />
      </FormControl>
      {taskConfig.update_old_client && (
        <>
          <FormControl fullWidth margin="normal" variant="outlined">
            <APISelect
              value={taskConfig.old_client_id || ""}
              required
              url="/clients"
              optionKey="id"
              onChange={(clientId) => setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, old_client_id: clientId }))}
              label="Client to be updated"
            />
          </FormControl>
          <FormControl fullWidth margin="normal" variant="outlined">
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel required htmlFor="inputLabel-quarter-select">Valid Quarter</InputLabel>
              <Select
                required
                style={{ width: 500, marginBottom: 20 }}
                value={taskConfig.quarter || ""}
                onChange={(e) => {
                  setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, quarter: e.target.value }));
                }}
                label="Start Quarter"
                inputProps={{
                  name: "quarter",
                  id: "inputLabel-quarter-select",
                }}
              >
                {formOptions.quarters.map((quarter) => (
                  <MenuItem key={quarter} value={quarter}>
                    {quarter}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormControl>
        </>
      )}
      {children}
    </Form>
  );
}
