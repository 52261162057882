import React from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";

export default function LMSTextField({
  name,
  ...props
}) {
  const [field, meta] = useField(name);

  const error = meta?.touched && meta?.error;

  return (
    <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      {...field}
      {...props}
      error={!!error}
      value={(field.value !== undefined) ? field.value : ""}
      helperText={error || props.helperText || ""}
    />
  );
}
