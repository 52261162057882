import React, { useEffect, useState } from "react";
import { FormControl, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";

import CSVUpload from "../../components/input/CSVUpload";
import BaseForm from "./common/BaseForm";

export default function ApplyCusipRecode({ children, taskConfig, setTaskConfig, csv, setCsv, submitHandler, studyType, setIsRequired }) {
  const formOptions = useSelector((state) => state.formOptions);
  const [attachedFiles, setAttachedFiles] = useState({ attachment_1: false, attachment_2: false });
  const applyCusipRecode = {
    type: "object",
    properties: {
      start_quarter: {
        type: "object",
        title: "Apply Cusip Recode",
      },
    },
  };
  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType, file_type: "secondary_files" }));
    setIsRequired((prevState) => ([...prevState, "start_quarter", "end_quarter"]));
  }, [setTaskConfig, studyType, setIsRequired]);
  return (
    <BaseForm
      schema={applyCusipRecode}
      submitHandler={submitHandler}
      taskConfig={taskConfig}
    >
      <Typography variant="h6">
        {" Previous Quarter's Cusip FIle "}
      </Typography>
      <CSVUpload
        onChange={(update) => {
          setCsv((preConfig) => ({ ...preConfig, attachment_1: update.csv }));
          setAttachedFiles((prev) => ({ ...prev, attachment_1: true }));
        }}
        value={{ csv, ...taskConfig }}
        attachmentKey={Object.keys(attachedFiles)[0]}
        showAttachment={attachedFiles.attachment_1}
      />
      <Typography variant="h6">
        {" Current Quarter's Cusip FIle "}
      </Typography>
      <CSVUpload
        onChange={(update) => {
          setCsv((preConfig) => ({ ...preConfig, attachment_2: update.csv }));
          setAttachedFiles((prev) => ({ ...prev, attachment_2: true }));
        }}
        value={{ csv, ...taskConfig }}
        attachmentKey={Object.keys(attachedFiles)[1]}
        showAttachment={attachedFiles.attachment_2}
      />
      <br />
      <FormControl fullWidth variant="outlined">
        <InputLabel required htmlFor="inputLabel-start-quarter-select">Start Quarter</InputLabel>
        <Select
          required
          style={{ width: 500, marginBottom: 20 }}
          value={taskConfig.start_quarter || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, start_quarter: e.target.value }));
          }}
          label="Start Quarter"
          inputProps={{
            name: "start quarter",
            id: "inputLabel-start-quarter-select",
          }}
        >
          {formOptions.quarters.map((quarter) => (
            <MenuItem key={quarter} value={quarter}>
              {quarter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <InputLabel required htmlFor="inputLabel-end-quarter-select">End Quarter</InputLabel>
        <Select
          required
          style={{ width: 500, marginBottom: 20 }}
          value={taskConfig.end_quarter || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, end_quarter: e.target.value }));
          }}
          label="End Quarter"
          inputProps={{
            name: "end quarter",
            id: "inputLabel-end-quarter-select",
          }}
        >
          {formOptions.quarters.map((quarter) => (
            <MenuItem key={quarter} value={quarter}>
              {quarter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {children}
    </BaseForm>
  );
}
