import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useFormikContext } from "formik";

import LMSSelect from "./base/LMSSelect";
import LMSTextField from "./base/LMSTextField";

const numberOfQuartersOptions = [
  { id: 4, label: "4" },
  { id: 8, label: "8" },
  { id: "custom", label: "Custom" },
];

export default function NumPastQuarters() {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values?.num_past_quarters_select && values?.num_past_quarters_select !== "custom") {
      setFieldValue("num_past_quarters", values.num_past_quarters_select);
    }
  }, [values?.num_past_quarters_select, setFieldValue]);

  return (
    <>
      <LMSSelect
        name="num_past_quarters_select"
        label="Number of Past Quarters *"
        options={numberOfQuartersOptions}
      />
      {values?.num_past_quarters_select === "custom"
        ? (
          <Grid container>
            <Grid item xs={12} md={6}>
              <LMSTextField name="num_past_quarters" label="Custom Number of Past Quarters" type="number" required />
            </Grid>
          </Grid>
        )
        : null}
    </>
  );
}
