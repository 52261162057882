import React from "react";
import ExclusionsTableDD from "./ExclusionsTableDD";
import LimitationsTableDD from "./LimitationsTableDD";

export default function ExclusionsDD() {
  return (
    <div>
      <div style={{ padding: 20 }}>
        <ExclusionsTableDD />
      </div>
      <div style={{ padding: 20 }}>
        <LimitationsTableDD />
      </div>
    </div>
  );
}
