import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import useClientConfiguration from "../../hooks/useClientConfiguration";
import useFormData from "../../hooks/useFormData";
import * as SharedDefinitions from "../../utils/SharedDefinitions";
import BaseForm from "./common/BaseForm";

const BrokerCategory = {
  fds_broker_id: SharedDefinitions.FDSBrokerId,
  fund_category: {
    type: "string",
    title: "Fund Category",
    description: "Morningstar fund category",
  },
};

export default function MFLiteStandardFeedForm({ children, studyType, client, setTaskConfig, submitHandler, formDataProp, setIsRequired }) {
  const formOptions = useSelector((state) => state.formOptions);
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  useClientConfiguration(setFormData, studyType, client);

  const LiteStandardFeedTerritoryConfiguration = {
    territory_name: {
      type: "string",
      title: "Territory Map",
      description: "The name of the territory map to be used for this report",
      enum: formOptions.territoriesVA,
    },
    channel_map: {
      type: "string",
      title: "Channel Map",
      description: "The name of the channel map to be used for this report",
      enum: formOptions.channelsANN,
    },
    territory_map_as_group: {
      type: "string",
      title: "Alternate Territory Grouping",
      description: "Group the territories by another group other than the channel",
      enum: formOptions.territoryGroupsANN,
    },
  };

  const MFLiteStandardFeedSchema = {
    title: "MF Lite Standard Feed",
    type: "object",
    required: [
      "start_quarter",
      "end_quarter",
      "territory_settings_1",
    ],
    properties: {
      start_quarter: {
        type: "string",
        title: "Start Quarter",
        enum: formOptions.quarters,
      },
      end_quarter: {
        type: "string",
        title: "End Quarter",
        enum: formOptions.quarters,
      },
      lock: {
        type: "string",
        title: "Lock",
        enum: formOptions.locksMF,
      },
      territory_settings_1: {
        type: "object",
        required: ["territory_name"],
        properties: LiteStandardFeedTerritoryConfiguration,
        title: "Territory Settings 1",
        description: "The territories to be outputed for this report",
      },
      exclude_client_firm_id: {
        type: "boolean",
        title: "Exclude Broker Identifiers",
        description: "Exclude client broker identifiers",
      },
      exclude_client_office_id: {
        type: "boolean",
        title: "Exclude Office Identifiers",
        description: "Exclude client office identifiers",
      },
      exclude_sales_fields: {
        type: "boolean",
        title: "Exclude Sales Fields",
        description: "Blank out sales fields",
      },
      custom_inclusions_settings: {
        type: "object",
        title: "Custom Limitations",
        properties: {
          broker_limitation: SharedDefinitions.brokerZipExclusions.broker_limitation,
          broker_zip_limitation: SharedDefinitions.brokerZipExclusions.broker_zip_limitation,
          cusip_limitation: {
            type: "array",
            items: {
              type: "string",
            },
            title: "Cusip Limitation",
            description: "Limit the feed to only these cusips",
          },
          broker_category_limitation: {
            type: "array",
            required: [
              "fds_broker_id",
              "fund_category",
            ],
            items: {
              properties: BrokerCategory,
            },
            title: "Broker Category Limitation",
            description: "Limit  the feed to only these broker and category combinations",
          },
        },
      },
      filter_code_override_settings: {
        type: "object",
        title: "Filter code overrides",
        properties: {
          broker_overrides: {
            type: "array",
            items: {
              type: "string",
              pattern: "^LMS.+$",
              title: "FDS Broker ID",
              description: "The LMS identifier for a broker",
              examples: [
                "LMS46910",
                "LMS35420",
              ],
            },
            title: "Broker Filter Code Override",
            description: "Removes 'LMS_EXCLUSION' label from brokers tied to Phantom logic",
          },
          client_specific_broker_overrides: {
            type: "array",
            items: SharedDefinitions.FDSBrokerId,
            title: "Client Specific Broker Filter Code Override",
            description: "Removes 'LMS_EXCLUSION' label from brokers tied to Phantom logic for this client",
          },
          client_data_exclusions: {
            type: "array",
            items: {
              type: "string",
            },
            title: "Client Data Exclusions",
            description: "Apply 'LMS_EXCLUSION' filter code to the selected client",
          },
        },
      },
      ria_inclusions: {
        type: "array",
        minItems: 1,
        items: SharedDefinitions.FilingType,
        title: "Firm Type Inclusions *",
        description: "Finra filing types and RIA custodian types inclusions",
      },
    },
  };

  useEffect(() => {
    if (MFLiteStandardFeedSchema.required) {
      setIsRequired((prevState) => ([...prevState, ...MFLiteStandardFeedSchema.required]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsRequired]);

  return (
    <BaseForm
      schema={MFLiteStandardFeedSchema}
      formData={formData}
      submitHandler={submitHandler}
      setFormData={setFormData}
    >
      {children}
    </BaseForm>
  );
}
