import React, { useContext } from "react";
import { Typography } from "@mui/material";
import * as Yup from "yup";

import ClientProfileDropdown from "../../../components/input/Formik/ClientProfileDropdown";
import Lock from "../../../components/input/Formik/Lock";
import TimePeriod from "../../../components/input/Formik/TimePeriod";
import LMSSelect from "../../../components/input/Formik/base/LMSSelect";
import { FormContext } from "../common/FormContext";
import { LMSForm } from "../common/LMSForm";

export const AnnUnclassifiedBDsSchema = Yup.object().shape({
  time_period: Yup.string().required("Required"),
  start_quarter: Yup.string().required("Required"),
  end_quarter: Yup.string().required("Required"),
  ann_type: Yup.string().required("Required"),
  client_profile_id: Yup.number().integer(),
});

export function ClientProfileSection() {
  const { clientId, studyType } = useContext(FormContext);

  return (
    <ClientProfileDropdown
      clientId={clientId}
      studyType={studyType}
      isDD
    />
  );
}

export default function AnnUnclassifiedBDsDDForm({
  title,
  client,
  studyType,
  step,
  setStep,
  submitHandler,
  taskConfig,
}) {
  return (
    <LMSForm
      client={client}
      studyType={studyType}
      step={step}
      setStep={setStep}
      submitHandler={submitHandler}
      taskConfig={taskConfig}
      validationSchema={AnnUnclassifiedBDsSchema}
      title={title}
      requiredInputAdded
    >
      <Typography variant="h4" style={{ backgroundColor: "orange" }}>Discovery Data</Typography>
      <TimePeriod required />
      <Lock studyType="ann" />
      <LMSSelect
        label="Annuity Type *"
        name="ann_type"
        options={[
          { id: "va", label: "VA" },
          { id: "fia", label: "FIA" },
          { id: "all", label: "ALL" },
        ]}
      />
      <ClientProfileSection />
    </LMSForm>
  );
}
