import React from "react";
import { mdiGraphql, mdiDatabaseSync, mdiTableEye } from "@mdi/js";
import { SvgIcon } from "@mui/material";

export const GraphQlIcon = (props) => (
  <SvgIcon {...props}>
    <path d={mdiGraphql} />
  </SvgIcon>
);

export const DatabaseSyncIcon = (props) => (
  <SvgIcon {...props}>
    <path d={mdiDatabaseSync} />
  </SvgIcon>
);

export const AccessControlIcon = (props) => (
  <SvgIcon {...props}>
    <path d={mdiTableEye} />
  </SvgIcon>
);
