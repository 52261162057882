import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetFilters } from "../../../../state";

const useFilters = () => {
  const dispatch = useDispatch();
  const filters = useSelector(state => state.filters.available);
  const [appliedFilters, setAppliedFilters] = useState({});

  useEffect(() => {
    const flattenedFilters = {
      ...filters,
      taskType: filters.taskType.map((task) => task.id),
      client: filters.client.map((clt) => clt.id),
      username: filters.username.map((user) => user.email),
    };
    const noBlankFilters = {};
    Object.keys(flattenedFilters)?.forEach((key) => {
      if (flattenedFilters[key] && flattenedFilters[key] !== "" && Object.keys(flattenedFilters[key]).length !== 0) {
        noBlankFilters[key] = flattenedFilters[key];
      }
    });
    setAppliedFilters(noBlankFilters);
  }, [filters]);

  const resetAllFilters = () => {
    dispatch(resetFilters());
    setAppliedFilters({});
  };

  return { appliedFilters, resetAllFilters };
};

export default useFilters;
