import React, { forwardRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import makeStyles from "@mui/styles/makeStyles";

import GenericExporterForm from "../../../forms/Regular/GenericExporterForm";
import useSubmitTaskHandler from "../../../hooks/useSubmitTaskHandler";
import { lmsTaskTypes } from "../../tasks/create_wizard/TaskMapping";

const useStyles = makeStyles((theme) => ({
  title: {
    position: "center",
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.primary.light,
  },
  confirmButton: {
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.secondary.light,
  },
}));

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function ConsortiumExportDialog({
  open,
  setOpen,
  study,
  setSubmitMessage,
}) {
  const classes = useStyles();
  const [taskId, setTaskId] = useState("");
  const [taskConfig, setTaskConfig] = useState({ study });
  const exportTaskID = "reporting_generic_exporter";
  const exportTask = lmsTaskTypes.find((task) => task.id === exportTaskID && task.study === study);

  const submitHandler = useSubmitTaskHandler({
    id: taskId,
    setTaskId,
    client: "ALL CLIENTS",
    action: exportTask.action,
    studyType: study,
    taskType: exportTask.id,
    taskConfig,
    title: exportTask.label,
    setSubmitMessage,
    redirectToDetails: true,
  });

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="alert-dialog-slide-title" className={classes.title}>
        Export Table
      </DialogTitle>
      <DialogContent dividers>
        <Alert severity="info" style={{ marginBottom: 20 }}>
          Choose the Table you want to export
        </Alert>

        <GenericExporterForm
          taskConfig={taskConfig}
          setTaskConfig={setTaskConfig}
          submitHandler={(form) => submitHandler(true, { setSubmitMessage }, form)}
          studyType={study}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            submitHandler(true, { setSubmitMessage }, taskConfig);
            setOpen(false);
          }}
        >
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
}
