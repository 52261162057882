import React, { useEffect } from "react";
import useFormData from "../../hooks/useFormData";
import BaseForm from "./common/BaseForm";

export default function CreateAthenaLockPartitionForm({ children, setTaskConfig, submitHandler, formDataProp, setIsRequired }) {
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  const createAthenaLockPartitionSchema = {
    title: "Athena Lock Partition Creation Form",
    description: "Create Athena Lock Partition",
    type: "object",
    required: ["dataset_revision_name"],
    properties: {
      dataset_revision_name: {
        type: "string",
        title: "Dataset Revision Name (Lock Name)",
        description: "A name for this dataset revision (lock)",
      },
      notes: {
        type: "string",
        title: "Notes",
        description: "Notes on the dataset revision partition to be created",
      },
    },
  };

  useEffect(() => {
    if (createAthenaLockPartitionSchema.required) {
      setIsRequired((prevState) => ([...prevState, ...createAthenaLockPartitionSchema.required]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsRequired]);

  return (
    <BaseForm
      schema={createAthenaLockPartitionSchema}
      formData={formData}
      submitHandler={submitHandler}
      setFormData={setFormData}
    >
      {children}
    </BaseForm>
  );
}
