import React from "react";
import {
  Checkbox,
  FormControl,
  Typography,
} from "@mui/material";
import { useField, useFormikContext } from "formik";
import * as Yup from "yup";

import LMSErrorMessage from "../../../../components/input/Formik/base/LMSErrorMessage";
import LMSSelect from "../../../../components/input/Formik/base/LMSSelect";

export const AnnTypesSchema = {
  annuity_types: Yup.array().min(1).required(),
};

export const AnnTypesInitialValues = {
  annuity_types: ["FA", "IA", "VA"],
};

const annuityTypes = [
  { id: "FA", label: "FA" },
  { id: "IA", label: "IA" },
  { id: "VA", label: "VA" },
];

const vaProductTypes = [
  {
    id: "",
    label: "",
  },
  {
    id: "IOVA",
    label: "IOVA: Investment Only Variable Annuity",
  },
  {
    id: "TRVA",
    label: "TRVA: Traditional Variable Annuity",
  },
  {
    id: "RILA",
    label: "RILA: Registered Index-Linked Annuity",
  },
];

export default function AnnTypesSection() {
  const { setFieldValue } = useFormikContext();
  const [annTypesField, annTypesMeta, annTypesHelpers] = useField({ name: "annuity_types", type: "checkbox" });
  const error = (annTypesMeta.touched && annTypesMeta.error);

  return (
    <FormControl
      fullWidth
      error={!!error}
      margin="normal"
    >
      <Typography>
        Ann Types *
      </Typography>
      <div
        role="group"
        aria-labelledby="checkbox-group"
        style={{ flexDirection: "column" }}
      >
        {
          annuityTypes.map(({ id, label: optionLabel }) => (
            <div
              key={id}
            >
              <label
                htmlFor={`${id}-checkbox`}
              >
                <Checkbox
                  name={annTypesField.name}
                  checked={annTypesField.value?.includes(id)}
                  onBlur={annTypesField.onBlur}
                  value={id}
                  id={`${id}-checkbox`}
                  onClick={(event) => {
                    if (id === "VA" && !event.target.checked) {
                      setFieldValue("va_product_type", undefined);
                    }
                    if (event.target.checked) {
                      annTypesHelpers.setValue(annTypesField.value.concat(id));
                    } else {
                      annTypesHelpers.setValue(
                        annTypesField.value.filter(annType => annType !== id),
                      );
                    }
                  }}
                />
                {optionLabel}
              </label>
            </div>
          ))
        }
      </div>
      <div
        style={{
          paddingLeft: 20,
          pointerEvents: annTypesField.value.includes("VA") ? "all" : "none",
          opacity: annTypesField.value.includes("VA") ? 1 : 0.4,
        }}
      >
        <LMSSelect
          name="va_product_type"
          label="VA Product Type"
          options={vaProductTypes}
        />
      </div>
      <LMSErrorMessage error={error} />
    </FormControl>
  );
}
