import React from "react";
import { useSelector } from "react-redux";
import useFormData from "../../hooks/useFormData";
import BaseForm from "./common/BaseForm";

// "ui:placeholder" doesn't work for the material-ui version...
const uiSchema = {
  lock: {
    "ui:help": "Leave unselected for Live",
  },
  add_sales_team_column: {
    "ui:help": "This is a custom request by AIG to include their SALES_TEAM information from their channel map",
  },
};

export default function AnnSiIdentifierMappingForm({ children, setTaskConfig, submitHandler, formDataProp }) {
  // Pre-populate form with previous data if available
  const formOptions = useSelector((state) => state.formOptions);
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  const AnnSiIdentifierMappingSchema = {
    title: "ANN SI Identifier Mapping",
    description: "Form to generate the ANN SI Identifier Mapping",
    type: "object",
    required: [
      "start_quarter",
      "end_quarter",
    ],
    properties: {
      start_quarter: {
        type: "string",
        title: "Start Quarter",
        enum: formOptions.quarters,
      },
      end_quarter: {
        type: "string",
        title: "End Quarter",
        enum: formOptions.quarters,
      },
      lock: {
        type: "string",
        title: "Lock (Data Set)",
        enum: formOptions.locksANN,
      },
      exclude_broker_id_column: {
        type: "boolean",
        title: "Exclude broker id column",
        default: false,
      },
      include_office_map_offices: {
        type: "boolean",
        title: "Include Office Map Data",
        default: true,
      },
      include_transaction_offices: {
        type: "boolean",
        title: "Include Transaction Data",
        default: false,
      },
      add_sales_team_column: {
        type: "boolean",
        title: "Add SALES_TEAM column",
        default: false,
      },
    },
  };

  return (
    <BaseForm
      schema={AnnSiIdentifierMappingSchema}
      uiSchema={uiSchema}
      formData={formData}
      submitHandler={submitHandler}
      setFormData={setFormData}
    >
      {children}
    </BaseForm>
  );
}
