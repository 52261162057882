import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Card,
  Grid,
  TableHead,
  TableRow,
} from "@mui/material";
import _ from "lodash";
import { nanoid } from "nanoid";

export default function GpsStats({ data }) {
  // Create a list of all clients
  const clients = _.chain(data).map("client").uniq().value();

  // Create a list of all Products
  const products = _.chain(data).map("prod_type").uniq().value();

  function getCell(client, product) {
    const runs = data.filter(
      (r) => r.client === client && r.prod_type === product,
    );

    if (runs.length === 0) {
      return "";
    }

    const r2 = Math.round(_.meanBy(runs, "r2") * 1e2) / 1e2;

    let color;
    if (r2 > 0.5) {
      color = "#3A94CA";
    } else if (r2 > 0.15) {
      color = "#007236";
    } else if (r2 > 0) {
      color = "#b76c00";
    } else {
      color = "#9b1d21";
    }
    return <strong><span style={{ color }}>{r2}</span></strong>;
  }

  return (
    <Grid item xs={12} style={{ marginBottom: 10 }}>
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                {products.map((product) => (
                  <TableCell key={nanoid()}>{product}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {clients.map((client) => (
                <TableRow key={nanoid()}>
                  <TableCell key={nanoid()}>
                    {client}
                  </TableCell>
                  {products.map((product) => (
                    <TableCell key={nanoid()}>
                      {getCell(client, product)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Grid>
  );
}
