import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import NoDataFoundTile from "./NoDataFoundTile";

export function ColumnChart({ title, hidetitle, isStacked, inputData, yAxisTitle }) {
  const [series, setSeries] = useState();
  const [categories, setCategoies] = useState();

  useEffect(() => {
    if (inputData && isStacked) {
      setSeries(inputData?.series);
      setCategoies(inputData?.categories);
    }
  }, [inputData, isStacked]);

  const options = {
    accessibility: {
      enabled: false,
    },
    credits: { enabled: false },
    chart: {
      type: "column",
    },
    title: {
      text: hidetitle ? "" : title,
    },
    xAxis: isStacked ? { categories } : {
      type: "category",
      labels: {
        rotation: -30,
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: yAxisTitle,
      },
      stackLabels: {
        enabled: true,
      },
    },
    legend: {
      enabled: !!isStacked,
    },
    plotOptions: {
      column: {
        stacking: "normal",
      },
    },
    series: isStacked ? series : ([
      {
        colorByPoint: true,
        data: inputData?.map((item) => ({
          name: item.name,
          y: item.count })),
      },
    ]),
  };
  return (
    (inputData?.length > 0) ? <HighchartsReact highcharts={Highcharts} options={options} /> : <NoDataFoundTile />
  );
}
