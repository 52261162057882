import React from "react";
import { Grid, Typography } from "@mui/material";
import StatusColor from "./StatusColor";

export default function ColorStatus() {
  const statusColor = [
    { status: "FAILED" },
    { status: "ABORTED" },
    { status: "NOT_ATTEMPTED" },
    { status: "QUEUED" },
    { status: "RUNNING" },
    { status: "RUNNING_QUEUE" },
    { status: "SUCCEEDED" },
    { status: "NOT_EXPECTED" },
  ];
  return (
    <div style={{ flexDirection: "row", width: "100%" }}>
      <Typography align="center" variant="h5" component="div" style={{ marginBottom: 20, marginTop: 30 }}>
        File Load Monitor Legend
      </Typography>
      <Grid container justifyContent="center" style={{ marginTop: 10 }}>
        {statusColor.map((item) => (
          <Grid item xs={1}>
            <div key={item.status} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <StatusColor status={item.status} />
              <Typography align="center" style={{ marginTop: 10, marginBottom: 5, marginLeft: 5 }}>
                {item.status.replace("_", " ")}
              </Typography>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
