import React, { useEffect, useState } from "react";
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";

import DDSyncIDsInput from "../../components/input/DDSyncIDsInput";
import OptionSelect from "../../components/input/OptionSelect";

const syncTypes = [
  { id: "platform_sync", label: "DD Firm Office Sync" },
  { id: "office_map_sync", label: "DD Office Map Sync" },
  { id: "manual_review_sync", label: "DD Manual Review Sync" },
  { id: "firm_only_sync", label: "DD Firm Only Sync" },
];

export default function DDSyncForm({ children, taskConfig, setTaskConfig, csv, setCsv, submitHandler, studyType, setIsRequired }) {
  const [syncSpecificIds, setSyncSpecificIds] = useState(false);
  const [firmOfficeIds, setFirmOfficeIds] = useState("");
  const [hasIdsFromFile, setHasIdsFromFile] = useState(true);

  useEffect(() => {
    setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, study: studyType }));
    setIsRequired((prevState) => ([...prevState, "sync"]));
  }, [setTaskConfig, studyType, setIsRequired]);

  useEffect(() => {
    if (syncSpecificIds) {
      setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, has_ids_from_file: hasIdsFromFile }));
    }
  }, [setTaskConfig, hasIdsFromFile, syncSpecificIds]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      validator={validator}
    >
      <FormControl fullWidth margin="normal">
        <OptionSelect
          required
          style={{ width: 500 }}
          value={taskConfig.sync || ""}
          onChange={(sync) => {
            if (sync !== "platform_sync" && ("firm_office_ids" in taskConfig || "has_ids_from_file" in taskConfig)) {
              setSyncSpecificIds(false);
              setFirmOfficeIds("");
              /* eslint-disable no-param-reassign */
              delete taskConfig.firm_office_ids;
              delete taskConfig.has_ids_from_file;
              setCsv({});
              /* eslint-disable no-param-reassign */
            }
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, sync }));
          }}
          options={syncTypes}
          label="Select Sync"
        />
        <br />
        {
          taskConfig.sync === "platform_sync"
            ? (
              <FormControlLabel
                control={
                  (
                    <Checkbox
                      checked={syncSpecificIds}
                      onChange={(e) => {
                        setSyncSpecificIds(e.target.checked);
                        if (!e.target.checked) {
                          setFirmOfficeIds("");
                          /* eslint-disable no-param-reassign */
                          delete taskConfig.firm_office_ids;
                          /* eslint-disable no-param-reassign */
                        }
                      }}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  )
                }
                label="Sync Specific IDs Only"
              />
            )
            : null
        }
        <br />
        {
          syncSpecificIds && taskConfig.sync === "platform_sync"
            ? (
              <DDSyncIDsInput
                taskConfig={taskConfig}
                setTaskConfig={setTaskConfig}
                csv={csv}
                setCsv={setCsv}
                firmOfficeIds={firmOfficeIds}
                setFirmOfficeIds={setFirmOfficeIds}
                hasIdsFromFile={hasIdsFromFile}
                setHasIdsFromFile={setHasIdsFromFile}
              />
            )
            : null
        }
      </FormControl>
      {children}
    </Form>
  );
}
