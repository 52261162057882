import React, { useState } from "react";
import { Button } from "@mui/material";
import AddToWorkflow from "../../../components/AddToWorkflow";
import useSubmitTaskHandler from "../../../hooks/useSubmitTaskHandler";
import TaskMapping from "./TaskMapping";
import WizardButtons from "./WizardButtons";
import { taskTypesWithRequiredAttachment, tasksThatNeedsQueuing, preWarmTask } from "./constants";

export default function TaskStep2({
  step,
  setStep,
  action,
  taskType,
  studyType,
  client,
  setClient,
  setSubmitMessage,
  title,
  displayHeader,
  taskConfig,
  setTaskConfig,
  isRequired,
  setIsRequired,
  isDDTask = false,
}) {
  const [csv, setCsv] = useState({});
  const [taskId, setTaskId] = useState(null);
  const CurrentForm = TaskMapping[taskType];
  const queuedTask = !!tasksThatNeedsQueuing.has(taskType);
  const [loading, setLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const requiredInputAdded = taskTypesWithRequiredAttachment.has(taskType) ? csv.attachment : true;
  const submitHandler = useSubmitTaskHandler({
    id: taskId,
    setTaskId,
    action,
    client,
    studyType,
    taskType: taskType.indexOf("v2_") === 0 ? taskType.slice(3) : taskType,
    taskConfig,
    title,
    csv,
    setSubmitMessage,
    redirectToDetails: false,
    queuedTask,
    setLoading,
    setShowDialog: () => {},
    isDDTask,
  });

  const submitWithDebounce = (runtTask, event, payload) => {
    submitHandler(runtTask, event, payload);
    setDisableSubmit(true);
    setTimeout(() => setDisableSubmit(false), 10000);
  };
  const isPreWarmTask = taskType === preWarmTask;

  return (
    <CurrentForm
      taskConfig={taskConfig}
      setTaskConfig={setTaskConfig}
      studyType={studyType}
      csv={csv}
      setCsv={setCsv}
      client={client}
      submitHandler={submitWithDebounce}
      formDataProp={{ study: studyType }}
      setClient={setClient}
      title={title}
      step={step}
      setStep={setStep}
      displayHeader={displayHeader}
      setIsRequired={setIsRequired}
    >
      <WizardButtons
        step={step}
        onChange={setStep}
        hasNext={step < 2}
        hasPrev={!isPreWarmTask}
        onSave={(event) => submitWithDebounce(false, event)}
        validated={requiredInputAdded}
        // TODO: Look at why and fix
        // Passing taskConfig just to force a rerender
        // Otherwise the onSubmit/onSave state is sometimes out of sync
        // Weird react behavior?
        // TODO: Conditional Rendering of AddToWorkflow component be handled further by usegroups
        taskConfig={taskConfig}
      >
        {!queuedTask
          ? (
            <Button type="submit" disabled={!requiredInputAdded || disableSubmit}>
              Run
            </Button>
          )
          : (
            <Button type="submit" disabled={!requiredInputAdded || disableSubmit}>
              Submit to Queue
            </Button>
          )}
        {action !== "preprocessing" && !isPreWarmTask && (
          <AddToWorkflow
            loading={loading}
            isRequired={isRequired}
            requiredInputAdded={requiredInputAdded}
            submitHandler={submitHandler}
            taskConfig={taskConfig}
            taskType={taskType}
          />
        )}
      </WizardButtons>
    </CurrentForm>
  );
}
