import React, { useEffect } from "react";
import { FormControl } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import BaseForm from "./common/BaseForm";

export default function ANNSalesTotalsSelectively({ children, taskConfig, setTaskConfig, submitHandler, studyType, setIsRequired }) {
  const formOptions = useSelector((state) => state.formOptions);

  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType }));
    setIsRequired((prevState) => ([...prevState, "start_quarter", "end_quarter"]));
  }, [setIsRequired, setTaskConfig, studyType]);

  const annPopulateSalesSchema = {
    type: "object",

    properties: {
      start_quarter: {
        type: "object",
        title: "ANN Populate Sales Totals",
      },
    },
  };

  return (
    <BaseForm
      schema={annPopulateSalesSchema}
      submitHandler={submitHandler}
      taskConfig={taskConfig}
    >
      <FormControl fullWidth variant="outlined">
        <InputLabel required htmlFor="inputLabel-start-quarter-select">Start Quarter</InputLabel>
        <Select
          required
          style={{ width: 500, marginBottom: 20 }}
          value={taskConfig.start_quarter || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, start_quarter: e.target.value }));
          }}
          label="Start Quarter"
          inputProps={{
            name: "start quarter",
            id: "inputLabel-start-quarter-select",
          }}
        >
          {formOptions.quarters.map((quarter) => (
            <MenuItem key={quarter} value={quarter}>
              {quarter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <InputLabel required htmlFor="inputLabel-end-quarter-select">End Quarter</InputLabel>
        <Select
          required
          style={{ width: 500, marginBottom: 20 }}
          value={taskConfig.end_quarter || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, end_quarter: e.target.value }));
          }}
          label="End Quarter"
          inputProps={{
            name: "end quarter",
            id: "inputLabel-end-quarter-select",
          }}
        >
          {formOptions.quarters.map((quarter) => (
            <MenuItem key={quarter} value={quarter}>
              {quarter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {children}
    </BaseForm>
  );
}
