import React from "react";
import { Button, Chip, Link, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import moment from "moment-timezone";

import { objectToArray } from "./utils";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export default function JobSummaryUser({ userName, data }) {
  const classes = useStyles();

  if (!data?.length) {
    return null;
  }

  const userJob = data?.find(x => x !== undefined);
  const numberOfQueries = objectToArray(userJob?.stats)?.length;
  const uniqueQueriesWithEmptyRows = objectToArray(userJob?.stats)?.filter(stat => stat?.records === 0);

  return (
    <>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        Latest Job Summary
      </Typography>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        See Job:
        <Link href={`/details/${userJob?.task_id}`} color="inherit">
          {userJob?.task_id}
        </Link>
      </Typography>
      <Typography variant="h5">
        The latest
        {" "}
        <b>
          <Link href={`/user/${userName}`} color="inherit">
            {userName}
          </Link>
        </b>
        {" "}
        prewarming job on
        {" "}
        <b>{moment(userJob?.created_at).format("MMMM Do YYYY, h:mm:ss a")}</b>
        {" "}
        ran for
        {" "}
        <b>{numberOfQueries}</b>
        {" "}
        queries
        {" "}
        . Out of that number,
        {" "}
        <HtmlTooltip
          interactive
          title={(
            <>
              <Typography color="inherit">Queries</Typography>
              <Typography className={classes.typography}>{uniqueQueriesWithEmptyRows?.map(o => o?.key).map(q => <Chip label={q} />)}</Typography>
            </>
          )}
        >
          <Button color="primary"><b>{uniqueQueriesWithEmptyRows?.length}</b></Button>
        </HtmlTooltip>
        {" "}
        queries returned no data.
      </Typography>
    </>
  );
}
