import React from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import ClientDomainWhitelist from "./ClientDomainWhitelist";
import DataRevisionAlias from "./DataRevisionAlias";
import DataRevisionAliasOverrides from "./DataRevisionAliasOverrides";
import DataRevisions from "./DataRevisions";

export default function MisightData() {
  usePageTitle("LMS MISight Data");

  return (
    <div>
      <div style={{ padding: 20 }}>
        <DataRevisionAlias />
      </div>
      <div style={{ padding: 20 }}>
        <DataRevisionAliasOverrides />
      </div>
      <div style={{ padding: 20 }}>
        <DataRevisions />
      </div>
      <div style={{ padding: 20 }}>
        <ClientDomainWhitelist />
      </div>
    </div>
  );
}
