import React from "react";
import { Typography } from "@mui/material";
import * as Yup from "yup";

import NumPastQuarters from "../../../components/input/Formik/NumPastQuarters";
import NumRollingQuarters from "../../../components/input/Formik/NumRollingQuarters";
import LMSCheckbox from "../../../components/input/Formik/base/LMSCheckBox";
import LMSSelect from "../../../components/input/Formik/base/LMSSelect";
import { LMSForm } from "../common/LMSForm";

import AnnReportSection, { AnnReportSchema, AnnReportInitialValues } from "../common/ann/AnnReportSection";
import TerritorySection from "../common/sections/TerritorySection";

export const AnnDashBoardSchema = Yup.object().shape({
  ...AnnReportSchema,
  num_past_quarters: Yup.number().required().positive().integer(),
  num_rolling_quarters: Yup.number().required().positive().integer(),
  metric_display_type: Yup.string().required("Required"),
});

const AnnDashBoardInitialValues = {
  time_period: 24,
  ...AnnReportInitialValues,
};

const metricDisplayTypeOptions = [
  {
    id: "client_rank",
    label: "Client Market Share Rank",
  },
  {
    id: "market_share",
    label: "Market Share",
  },
  {
    id: "client_gross_sales",
    label: "Client Gross Sales",
  },
  {
    id: "total_opportunity",
    label: "Total Opportunity",
  },
];

export default function AnnDashboardFormDD({
  title,
  client,
  studyType,
  step,
  setStep,
  submitHandler,
  taskConfig,
}) {
  return (
    <LMSForm
      title={title}
      client={client}
      studyType={studyType}
      step={step}
      setStep={setStep}
      submitHandler={submitHandler}
      taskConfig={taskConfig}
      validationSchema={AnnDashBoardSchema}
      initialValues={AnnDashBoardInitialValues}
    >
      <Typography variant="h4" style={{ backgroundColor: "orange" }}>Discovery Data</Typography>
      <AnnReportSection isDD />
      <TerritorySection
        territorySettingsName="territory_settings"
        alternateTerrLabel1="Alternate Territory Name (Column A)"
        alternateTerrLabel2="Alternate Territory Name (Column B)"
      />
      <NumPastQuarters />
      <NumRollingQuarters />
      <LMSSelect
        name="metric_display_type"
        options={metricDisplayTypeOptions}
        label="Metric Display Type (Previously named Cell Contents) *"
      />
      <LMSCheckbox
        name="include_indirect_transactions"
        label="Include Indirect Transactions (Previously misnamed Sales)"
      />
      <LMSCheckbox
        name="generate_raw_data_file"
        label="Generate Raw Data File"
      />
      <LMSCheckbox
        name="raw_data_include_channel_field"
        label="Include Channel in Raw Data File"
      />
    </LMSForm>
  );
}
