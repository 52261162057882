import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetching, fetchingDone } from "../state";
import { API } from "../utils/api";

export default function useClientConfiguration(setFormData, study, client) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!study || !client) return;

    dispatch(fetching({ element: "clientConfig" }));
    const url = `/clients/${client}/${study}/config`;
    API.request({ url, method: "GET", data: { study, client } }).then(
      (response) => {
        setFormData((prevFormData) => ({ ...prevFormData, ...response.data }));
        dispatch(fetchingDone({ element: "clientConfig" }));
      },
      (error) => {
        dispatch(fetchingDone({ element: "clientConfig" }));
        console.error(error);
      },
    );
  }, [setFormData, study, client, dispatch]);
}
