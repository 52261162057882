import React from "react";
import { Card, CardContent, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { HighchartsColors } from "../constants";

export default function DataGrid({ inputData }) {
  const useStyles = makeStyles(() => ({
    gridcard: {
      color: "#ffffff",
      textAlign: "center",
      fontSize: "22px",
      fontWeight: "500",
      fontFamily: "IBM Plex Sans,sans-serif",
      margin: "0.3em",
    },

  }));

  const classes = useStyles();

  return (
    <Grid container>
      {inputData?.map((item, index) => (
        <Grid item xs key={[index]}>
          <Card className={classes.gridcard} style={{ backgroundColor: HighchartsColors[index] }}>
            <CardContent style={{ padding: "10px" }}>
              <div style={{ fontWeight: "bold" }}>
                {item.name}
              </div>
              <div>
                {item.count}
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
