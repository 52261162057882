import React from "react";
import { Typography } from "@mui/material";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";

export default function UpdatePhantomZipExclusions({ children, submitHandler }) {
  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      validator={validator}
    >
      <Typography variant="h5" style={{ marginBottom: 20 }}>
        Update Phantom Zip Exclusions
      </Typography>
      {children}
    </Form>
  );
}
