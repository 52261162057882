import React from "react";
import { Grid, Button } from "@mui/material";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import TaskClientProfile from "../../../views/tasks/status/TaskClientProfile";
import LMSSelect from "./base/LMSSelect";

export default function ClientProfileDropdown({
  clientId,
  studyType,
  name = "client_profile_id",
  label = "Client Profile *",
  showSummary = false,
  isDD = false,
  ...props
}) {
  const formOptions = useSelector((state) => state.formOptions);
  const { values } = useFormikContext();
  const prefix = isDD ? "/dd" : "";

  let clientProfiles = formOptions[`clientProfiles${studyType.toUpperCase()}`]?.filter(
    (profile) => profile.client_id === clientId,
  );

  clientProfiles = clientProfiles?.map((profile) => {
    const prof = profile;
    return { ...prof, label: `${profile.full_name} (${profile.short_name})` };
  });

  return (
    <>
      <Grid container alignItems="center">
        <Grid item md={5}>
          <LMSSelect
            name={name}
            options={clientProfiles}
            label={label}
            {...props}
          />
        </Grid>
        <Grid item>
          {
            values[name]
            && !showSummary && (
              <Button
                component={Link}
                to={`${prefix}/client-profile/details/${values[name]}/study/${studyType}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Profile
              </Button>
            )
          }
        </Grid>
      </Grid>
      {values[name]
          && showSummary && <TaskClientProfile profileId={values[name]} study={studyType} isDD={isDD} />}

    </>
  );
}
