/* eslint-disable react/no-this-in-sfc */
import React from "react";
import * as Highcharts from "highcharts";
import _ from "lodash";
import ChartCard from "../../../components/ChartCard";

export default function GpsR2Distribution({ data, tooltipMap }) {
  const colorMap = {
    FA: "#1ABFA3",
    IA: "#50759B",
    TRVA: "#DEBE52",
    RILA: "#EF8C57",
    IOVA: "#9CAEC1",
  };

  const options = {
    title: {
      text: "R2 Distribution",
    },
    chart: {
      type: "scatter",
      zoomType: "xy",
    },
    xAxis: {
      allowDecimals: false,
      title: {
        text: "Run On",
        scalable: false,
      },
      type: "datetime",
      labels: {
        formatter() {
          return Highcharts.dateFormat("%d-%b-%y", this.value);
        },
      },
      tickPixelInterval: 100,
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: "r2",
      },
      max: 1,
      min: -1,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter() {
        return `<b>${this.series.name}</b><br/><b>r2:</b> ${this.y}<br/>${tooltipMap[this.x]}`;
      },
    },
    series: _.chain(data)
      .groupBy("prod_type")
      .map((value, key) => ({
        name: key,
        data: value?.map(r => [r.timestamp, r.r2]),
        color: colorMap[key],
      }))
      .value(),
  };

  return (
    <ChartCard options={options} />
  );
}
