import React from "react";
import { useSelector } from "react-redux";

import { convertArrayToOptions } from "../../../utils/dataTransformations";

import LMSSelect from "./base/LMSSelect";

export default function StartQuarter({
  required,
}) {
  const formOptions = useSelector((state) => state.formOptions);
  const quarters = convertArrayToOptions(formOptions.quarters);

  const label = required ? "Start Quarter *" : "Start Quarter";
  return (
    <LMSSelect
      name="start_quarter"
      options={quarters}
      label={label}
    />
  );
}
