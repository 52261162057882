import React, { useCallback, useEffect, useState } from "react";

import FileUploadIcon from "@mui/icons-material/CloudUpload";
import { useDispatch, useSelector } from "react-redux";

import Fab from "../../../components/Fab";
import usePageTitle from "../../../hooks/usePageTitle";
import { setUsers } from "../../../state";
import { API } from "../../../utils/api";
import OktaUsersTable from "./UsersTable";
import CreateUser from "./UsersTable/CreateUser";

export default function UsersList() {
  const dispatch = useDispatch();
  const oktaUsersList = useSelector((state) => state.users.users);

  const [loading, setLoading] = useState(false);

  usePageTitle("MISight Users");

  const getOktaUsers = useCallback(() => {
    function getUsers(data = [], nextToken = null) {
      return API.request({
        url: "/users",
        method: "GET",
        params: { pool: "okta", next_token: nextToken },
      })
        .then((response) => {
          const appendedData = data.concat(response.data.users);
          dispatch(setUsers({ users: appendedData }));
          if (response.data.next_token) {
            getUsers(appendedData, response.data.next_token);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
    setLoading(true);
    getUsers();
  }, [dispatch]);

  useEffect(() => {
    if (!oktaUsersList.length) {
      setLoading(true);
      getOktaUsers();
    }
  }, [oktaUsersList, getOktaUsers]);

  return (
    <>
      <OktaUsersTable
        users={oktaUsersList}
        refreshUsers={getOktaUsers}
        loading={loading}
        title="MISight Users"
      />
      <CreateUser />
      <Fab
        offset={1}
        onClick={() => { window.location.href = "/importUsers"; }}
        color="default"
      >
        <FileUploadIcon />
      </Fab>
    </>
  );
}
