import React from "react";
import { lmsTaskTypes } from "../../views/tasks/create_wizard/TaskMapping";
import NavigationButton from "./NavigationButton";

export default function BulkUploadButton({ tableName, studyType = "ann", client = "ALL_CLIENTS" }) {
  const bulkUploadTask = lmsTaskTypes.find((task) => task.id === "preprocessing_generic_loader" && task.study === studyType);

  return (
    <NavigationButton
      url="/create"
      text="Bulk Upload"
      state={
        {
          action: "loading",
          studyType,
          taskType: "preprocessing_generic_loader",
          client,
          title: bulkUploadTask?.label,
          step: 2,
          taskConfig: {
            table_name: tableName,
          },
        }
      }
    />
  );
}
