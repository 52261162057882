import React, { useEffect, useState } from "react";
import { CloseSharp as CloseSharpIcon, Search as SearchIcon } from "@mui/icons-material";
import { IconButton, FormControl, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";

export default function SearchBar({ onChange, value, name, formControlProps = {} }) {
  const [inputVal, setInputVal] = useState(value);

  useEffect(() => {
    const timeOutId = setTimeout(() => onChange(inputVal), 500);
    return () => clearTimeout(timeOutId);
  }, [inputVal, onChange]);
  return (
    <FormControl sx={{ m: 1 }} variant="outlined" {...formControlProps}>
      <InputLabel htmlFor={`outlined-adornment-search-${name}`}>
        Search
      </InputLabel>
      <OutlinedInput
        id={`outlined-adornment-search-${name}`}
        type="text"
        onChange={(e) => setInputVal(e.target.value)}
        value={inputVal}
        startAdornment={(
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton onClick={() => { setInputVal(""); onChange(""); }} size="large">
              <CloseSharpIcon />
            </IconButton>
          </InputAdornment>
        )}
        style={{ maxWidth: 1000 }}
        label="Search"
      />
    </FormControl>
  );
}
