import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as IssLogo } from "../assets/iss-logo.svg";

const useStyles = makeStyles(() => ({
  "@keyframes spinner": {
    "0%": { opacity: 1, transform: "rotate(0deg)" },
    "80%": { opacity: 0.01, transform: "rotate(360deg)" },
    "100%": { opacity: 0, transform: "rotate(360deg)" },
  },
  logo: {
    animationName: "$spinner",
    animationDuration: "1s",
    animationTimingFunction: "ease",
    animationIterationCount: "infinite",
    width: "35px",
    height: "35px",
  },
}));

export default function Spinner({ style }) {
  const classes = useStyles();

  return (
    <IssLogo className={classes.logo} style={style} alt="spinning-logo" />
  );
}
