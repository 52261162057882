import React, { useEffect, useState } from "react";
import {
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { useDispatch, useSelector } from "react-redux";
import APISelect from "../../components/input/APISelect";
import { fetching, fetchingDone } from "../../state";
import { API } from "../../utils/api";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function MFTerritoryExport({ children, taskConfig, setTaskConfig, submitHandler, studyType, client, displayHeader, setIsRequired }) {
  const productionCycles = useSelector((state) => state.formOptions.productionCycle);
  const [jobRunFlag, setjobRunFlag] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, study: studyType }));
    // eslint-disable-next-line no-unused-expressions
    client === "MULTIPLE_CLIENTS"
      ? setIsRequired((prevState) => ([...prevState, ...["multiple_client_ids", "production_cycle"]]))
      : setIsRequired((prevState) => ([...prevState, ...["production_cycle"]]));
    dispatch(fetching({ element: "selectAPI" }));
    API.request({
      url: "/tasks",
      method: "GET",
      params: { taskType: ["reporting_mf_territory_export_all_clients", "reporting_mf_territory_export"], status: "RUNNING" },
    })
      .then((response) => {
        const tasks = response.data.results;
        if (tasks.length !== 0) {
          console.log("Previous Job runnig, Submission denied");
          setjobRunFlag(false);
        } else {
          setjobRunFlag(true);
        }
        dispatch(fetchingDone({ element: "selectAPI" }));
      })
      .catch((e) => {
        console.error(e);
        dispatch(fetchingDone({ element: "selectAPI" }));
      });
  }, [dispatch, setTaskConfig, studyType, setIsRequired, client]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      validator={validator}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader([""])} />
      </div>
      {client === "MULTIPLE_CLIENTS" && (
        <FormControl required fullWidth margin="normal" variant="outlined">
          <APISelect
            disabled={!jobRunFlag}
            autoInit
            required
            label="Client Selection"
            style={{ width: 500, marginBottom: 20 }}
            url="/clients"
            value={taskConfig.multiple_client_ids || []}
            optionKey="id"
            onChange={(multipleClients) => {
              setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, multiple_client_ids: multipleClients }));
            }}
            filter="type"
            filterValue={studyType}
            multiple
          />
        </FormControl>
      )}
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="inputLabel-production-cycle-select" required>Production Cycle</InputLabel>
        <Select
          disabled={!jobRunFlag}
          style={{ width: 500 }}
          value={taskConfig.production_cycle || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, production_cycle: e.target.value }));
          }}
          label="Production Cycle"
          inputProps={{
            name: "production cycle",
            id: "inputLabel-production-cycle-select",
          }}
          required
        >
          {productionCycles.map((quarter) => (
            <MenuItem key={quarter} value={quarter}>
              {quarter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {jobRunFlag ? children : null}
      <Snackbar
        open={!jobRunFlag}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div>
          <Alert severity="error">
            Only one Territory Export job can be run at a time!
          </Alert>
        </div>
      </Snackbar>
    </Form>
  );
}
