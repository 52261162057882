import { colors } from "@mui/material";

export default {
  themeName: "legacy",
  primary: { main: "#30455c" },
  secondary: { main: "#1dbfa3" },
  success: {
    main: "#75D6C4",
    secondary: "#4c9e55",
  },
  info: { main: "#30455c" },
  error: { main: "#d85f5f" },
  aborted: { main: "#fa9e25" },
  warning: { main: "#debe52" },
  running: { main: "#736dbf" },
  queued: { main: "#3399ff" },
  running_queue: { main: "#ac00e6" },
  not_attempted: { main: "#808080" },
  background: {
    default: "#f5f5f5",
    paper: colors.common.white,
    paperShaded: "#eef3f7",
    nav: "#30455c",
  },
  text: {
    shaded: "#97a9bc",
    onBackground: colors.common.white,
    link: "#1dbfa3",
  },
};
