import React, { useContext } from "react";
import * as Yup from "yup";

import ClientProfileDropdown from "../../../../components/input/Formik/ClientProfileDropdown";
import Lock from "../../../../components/input/Formik/Lock";
import ProductionCycle from "../../../../components/input/Formik/ProductionCycle";
import TimePeriod from "../../../../components/input/Formik/TimePeriod";
import { FormContext } from "../FormContext";

export const ReportSchema = {
  time_period: Yup.string().required("Required"),
  start_quarter: Yup.string().required("Required"),
  end_quarter: Yup.string().required("Required"),
  production_cycle: Yup.string().required("Required"),
  client_profile_id: Yup.number().integer().required("Required"),
};

export default function ReportSection({ isDD = false }) {
  const { clientId, studyType } = useContext(FormContext);

  return (
    <>
      <Lock studyType={studyType} />
      <TimePeriod required />
      <ProductionCycle required />
      <ClientProfileDropdown
        clientId={clientId}
        studyType={studyType}
        isDD={isDD}
      />
    </>
  );
}
