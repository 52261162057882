import { getIntrospectionQuery } from "graphql";
import _ from "lodash";
import { graphQlQuery } from "./api";

// Navigate through hierarchical type chain, to get to root type.
// Returns a name, description tuple
// name: The root type as it appears in the astTypes dicy
// desc: Human readable name that appends modifiers such as required "!" and list "[]"
function getNodeType(node) {
  let name; let
    desc;

  if (node?.ofType) {
    [name, desc] = getNodeType(node?.ofType);
  } else if (node.kind === "SCALAR") {
    [name, desc] = [node.name, node.name];
  } else if (node.kind === "ENUM") {
    [name, desc] = [node.name, "ENUM"];
  } else {
    [name, desc] = [node.name, ""];
  }

  if (node.kind === "NON_NULL" && desc) {
    desc += "!";
  }

  if (node.kind === "LIST" && desc) {
    desc = `[${desc}]`;
  }

  return [name, desc];
}

// Decodes a GraphQL node of an AST and transforms it into a plain JS Dict
// Flattens down complex structure into simple tree
function decode(astTypes, node, depth = 0) {
  const structure = {};
  structure.description = node?.description;
  structure.deprecationReason = node?.deprecationReason;

  structure.fields = {};

  if (node?.fields) {
    node?.fields.forEach((field) => {
      let name;
      let desc;
      if (field?.type) {
        [name, desc] = getNodeType(field?.type);
        structure.fields[field.name] = decode(astTypes, astTypes[name], depth + 1);
        structure.fields[field.name].type = desc;
      } else {
        structure.fields[field.name] = decode(astTypes, field, depth + 1);
      }

      structure.fields[field.name].description = field?.description;

      if (field?.args) {
        structure.fields[field.name].args = {};
        field.args.forEach((a) => {
          [, desc] = getNodeType(a?.type);

          structure.fields[field.name].args[a.name] = {};
          structure.fields[field.name].args[a.name].type = desc;

          structure.fields[field.name].args[a.name].description = a?.description;
        });
      }
    });
  }

  structure.inputFields = {};

  // Note Input fields aren't named
  let n = 0;

  if (node?.inputFields) {
    node?.inputFields.forEach((field) => {
      const [name, desc] = getNodeType(field.type);
      structure.inputFields[n] = decode(astTypes, astTypes[name], depth + 1);
      structure.inputFields[n].type = desc;
      n += 1;
    });
  }

  return structure;
}

// Converts the streamlined JSON tree into a human readable form, suitable for documentation
function toHuman(name, node, depth = 0) {
  let human = ("  ".repeat(depth) + name).padEnd(35);
  if (!node) return human;

  human += (node?.type || "").padEnd(13);
  human += node?.description || "";

  human += "\n";

  _.map(node.fields, (v, k) => {
    human += toHuman(k, v, depth + 1);
  });

  _.map(node.inputFields, (v, k) => {
    human += toHuman(k, v, depth + 1);
  });

  return human;
}

export async function jsonSchema() {
  const introspectionResult = await graphQlQuery({ query: getIntrospectionQuery() });
  const astTypes = _.keyBy(introspectionResult?.data.__schema.types, "name");

  return {
    queries: decode(astTypes, astTypes.Query),
    mutations: decode(astTypes, astTypes.Mutation),
  };
}

export async function humanSchema() {
  const doc = await jsonSchema();
  return toHuman("Queries", doc.queries) + toHuman("Mutations", doc.mutations);
}
