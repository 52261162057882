import React, { useEffect, useState } from "react";
import { Button, FormControl, TextField, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Papa from "papaparse";
import { DropzoneArea } from "react-mui-dropzone";
import { useSelector } from "react-redux";

import OptionSelect from "../../../../components/input/OptionSelect";
import { studyTypes } from "../../../tasks/create_wizard/TaskMapping";

export default function AddFileStep({
  columns, csvData, setCsvData, client, setNextStep, fileUpload, setFileUpload, studyType, setStudyType, setClient,
}) {
  const [error, setError] = useState("");
  const formOptions = useSelector((state) => state.formOptions);

  useEffect(() => {
    setError("");
    if (fileUpload) {
      Papa.parse(fileUpload, {
        header: true,
        transformHeader(header) {
          const colObj = columns.find(col => col.header.toLowerCase() === header.toLowerCase());
          if (colObj) {
            return colObj.accessorKey;
          }
          return header;
        },
        complete(results) {
          const requiredColumns = columns.filter(colObj => colObj.required).map(colObj => colObj.accessorKey);
          requiredColumns.forEach(col => {
            if (!results.meta.fields.includes(col)) {
              setError(`Error: Missing required header '${col}'`);
            }
          });
          setCsvData(results.data.map(row => ({ ...row, role: "Wholesaler" })));
        },
        error: (e) => setError(`${e}`),
      });
    }
  }, [columns, fileUpload, setCsvData]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {error && <div><Alert severity="error">{`${error}`}</Alert></div>}
      <DropzoneArea
        onDrop={(files) => setFileUpload(files[0])}
        acceptedFiles={["text/plain, text/csv"]}
        filesLimit={1}
        maxFileSize={1024 * 1024 * 1024 * 500} // 500GB
        clearOnUnmount={false}
        showPreviews={false}
      />
      <Typography style={{ margin: "2em" }} variant="subtitle1">Required fields: First Name, Last Name, Email </Typography>
      <FormControl style={{ width: 500 }}>
        <OptionSelect
          onChange={setStudyType}
          options={studyTypes.filter(o => !o.internalOnly)}
          label="Study"
        />
      </FormControl>
      <FormControl style={{ width: 500 }} margin="normal">
        <Autocomplete
          onChange={(e, v) => setClient(v.id)}
          options={formOptions.clients || []}
          groupBy={(option) => option.type}
          getOptionLabel={(option) => option.value}
          style={{ width: "100%" }}
          renderInput={(params) => <TextField {...params} label="Clients" variant="outlined" margin="normal" />}
          isOptionEqualToValue={(val, option) => option.id === val.id}
        />
      </FormControl>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="outlined"
          style={{ width: "20%", marginTop: "10px" }}
          onClick={() => setNextStep(true)}
          disabled={error || !csvData || !client || !studyType}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
