import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import useFormData from "../../hooks/useFormData";
import BaseForm from "./common/BaseForm";

const uiSchema = {
  dataset_revision_id: {
    "ui:help": "dataset_revision_id: revision_name",
  },
};

export default function AnnMisightQAReportForm({ children, setTaskConfig, submitHandler, formDataProp, setIsRequired }) {
  const formOptions = useSelector((state) => state.formOptions);
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  const annMisightQaReportSchema = {
    title: "ANN Misight QA Report Form",
    description: "ANN Misight By Wholesaler QA Report",
    type: "object",
    required: ["dataset_revision_id"],
    properties: {
      dataset_revision_id: {
        type: "integer",
        title: "Dataset Revision ID",
        enum: formOptions.dataRevisionID,
        enumNames: formOptions.dataRevisionName,
      },
    },
  };

  useEffect(() => {
    if (annMisightQaReportSchema.required) {
      setIsRequired((prevState) => ([...prevState, ...annMisightQaReportSchema.required]));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsRequired]);

  return (
    <BaseForm
      schema={annMisightQaReportSchema}
      uiSchema={uiSchema}
      formData={formData}
      submitHandler={submitHandler}
      setFormData={setFormData}
    >
      {children}
    </BaseForm>
  );
}
