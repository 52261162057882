import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { useDispatch, useSelector } from "react-redux";
import OptionSelect from "../../components/input/OptionSelect";
import { setFilters } from "../../state";
import { API } from "../../utils/api";
import Chips from "../tasks/status/FilterChip";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  column: {
    flexBasis: "50%",
    margin: theme.spacing(1),
  },
  action: {
    justifyContent: "left",
  },
}));

const StatusOptions = [
  { id: "SUCCEEDED", label: "SUCCEEDED" },
  { id: "DRAFT", label: "DRAFT" },
  { id: "FAILED", label: "FAILED" },
  { id: "RUNNING", label: "RUNNING" },
  { id: "CREATED", label: "CREATED" },
  { id: "ABORTED", label: "ABORTED" },
];

const OrderOptions = [
  { id: "desc", label: "Descending" },
  { id: "asc", label: "Ascending" },
];

const twoWeeksAgo = new Date();
twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

export default function Filters({ children }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filters = useSelector(state => state.filters.available);
  const usernames = useSelector(state => state.formOptions.usernames);
  const [workflowNames, setWorkflowNames] = useState([]);
  const [workflowNamesOptionsLoading, setWorkflowNamesOptionsLoading] = useState(false);

  const [accordionExpanded, setAccordionExpanded] = useState(false);

  useEffect(() => {
    function getWorkflowNames() {
      setWorkflowNamesOptionsLoading(true);
      setWorkflowNames([]);
      return (
        API.request({ url: "/workflows", method: "GET", params: { limit: 1000 } }).then((response) => {
          response.data.results.forEach(element => {
            setWorkflowNames(prevNames => [...prevNames, { id: element.workflowName, label: element.workflowName }]);
          });
          setWorkflowNamesOptionsLoading(false);
        }).catch((error) => {
          console.error(error);
        })
      );
    }
    getWorkflowNames();
  }, []);

  return (
    <div className={classes.root}>
      <Accordion expanded={accordionExpanded} onChange={() => setAccordionExpanded(!accordionExpanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Search Filters</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Autocomplete
                value={filters.workflow_name}
                onChange={(e, workflowName) => dispatch(setFilters({ value: workflowName, key: "workflow_name" }))}
                options={workflowNames}
                getOptionLabel={(option) => option.label}
                style={{ width: "100%" }}
                renderInput={(params) => <TextField {...params} label="Workflow Names" variant="outlined" margin="normal" />}
                isOptionEqualToValue={(val, option) => option.label === val.label}
                multiple
                loading={workflowNamesOptionsLoading}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                value={filters.username}
                onChange={(e, users) => dispatch(setFilters({ value: users, key: "username" }))}
                options={usernames[0] !== "Loading..." ? usernames : []}
                getOptionLabel={(option) => option.name}
                style={{ width: "100%" }}
                renderInput={(params) => <TextField {...params} label="Usernames" variant="outlined" margin="normal" />}
                isOptionEqualToValue={(val, option) => option.name === val.name}
                multiple
                loading={usernames[0] === "Loading..."}
              />
            </Grid>
            <Grid item xs={2}>
              <OptionSelect
                value={filters.status || ""}
                onChange={(status) => dispatch(setFilters({ value: status, key: "status" }))}
                options={StatusOptions}
                label="Status"
                style={{ width: "100%" }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <OptionSelect
                value={filters.orderBy || ""}
                onChange={(orderBy) => dispatch(setFilters({ value: orderBy, key: "orderBy" }))}
                options={OrderOptions}
                label="Order by (Descending by default)"
                style={{ width: "100%" }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} style={{ marginTop: 16 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  format="MM/dd/yyyy"
                  label="Start Date"
                  value={filters.startDate || twoWeeksAgo}
                  onChange={(date) => dispatch(setFilters({ value: date.toISOString().replace("Z", "+00:00"), key: "startDate" }))}
                />
                <DatePicker
                  format="MM/dd/yyyy"
                  label="End Date"
                  value={filters.endDate || new Date()}
                  onChange={(date) => dispatch(setFilters({ value: date.toISOString().replace("Z", "+00:00"), key: "endDate" }))}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions className={classes.action}>
          {children}
        </AccordionActions>
      </Accordion>
      <Grid display="row">
        <Chips chipData={filters} setChipData={setFilters} />
      </Grid>
    </div>
  );
}
