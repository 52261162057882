import React, { useCallback, useEffect, useState } from "react";
import { TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import OptionSelect from "../../components/input/OptionSelect";
import useTerrClientDescriptions from "../../hooks/useTerrClientDescriptions";
import { API } from "../../utils/api";
import { mapStudyTypeOptions } from "./TerrServiceTerritoryMapsForm";

export default function TerrServiceTerritoryQAImpactReportForm({ setTaskConfig, client, submitHandler, children }) {
  // State
  const [territoryMap, setTerritoryMap] = useState({});
  const [territoryMapName, setTerritoryMapName] = useState("");
  const [territoryMapOptions, setTerritoryMapOptions] = useState([]);
  const [terrClientDescriptions, setTerrClientDescriptions] = useState({});
  const [mapStudyType, setMapStudyType] = useState("");

  const handleFormChange = useCallback(
    () => {
      setTaskConfig((prevTaskConfig) => ({
        ...prevTaskConfig,
        territory_map_entity_id: territoryMap.sk,
        territory_map_name: territoryMapName,
      }));
    },
    [setTaskConfig, territoryMap.sk, territoryMapName],
  );

  useTerrClientDescriptions(setTerrClientDescriptions);

  useEffect(() => {
    handleFormChange();
    setTerritoryMapOptions([]); // Reset value
    API.request({ url: `/di/territory/clients/${encodeURIComponent(client)}/maps`, method: "GET" }).then((response) => {
      const data = response.data.filter((d) => d.study === mapStudyType);
      setTerritoryMapOptions(data);
    });
  }, [territoryMap, territoryMapName, handleFormChange, client, mapStudyType]);

  return (
    <form schema={{}} onSubmit={(e) => { e.preventDefault(); submitHandler(true, e); }} noValidate autoComplete="off" onChange={handleFormChange}>
      <Typography gutterBottom variant="h5" component="h2">
        Territory QA Impact Report
      </Typography>
      <Typography variant="body2" color="textSecondary" component="h3">
        {`Client: ${terrClientDescriptions[client]}`}
      </Typography>
      {/*  */}
      <OptionSelect
        required
        style={{ width: "100%", marginBottom: 10 }}
        value={mapStudyType}
        onChange={setMapStudyType}
        options={mapStudyTypeOptions}
        label="Map Study"
      />
      <Autocomplete
        autoComplete="off"
        style={{ width: "100%" }}
        value={territoryMap}
        onChange={(event, newValue) => {
          setTerritoryMap(newValue);
          setTerritoryMapName(newValue.territory_map_name);
        }}
        options={territoryMapOptions}
        autoHighlight
        getOptionLabel={(option) => option?.territory_map_name}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Territory Maps"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              value: params.inputProps.value === "undefined" ? "" : params.inputProps.value,
              autoComplete: "new-password", // disable autocomplete and autofill from browser
            }}
          />
        )}
      />
      {children}
    </form>
  );
}
