import React, { useRef, useState } from "react";
import { FormControl, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";

import usePageTitle from "../../../../hooks/usePageTitle";
import TerritoryDropDown from "../TerritoryDropDown";
import AddFileStep from "./AddFileStep";
import AddTerritoriesStep from "./AddTerritoriesStep";

function replaceAt(array, index, value) {
  const ret = array.slice(0);
  ret[index] = value;
  return ret;
}

export default function UsersImport() {
  const inputRef = useRef();

  const [errorPerRow, setErrorPerRow] = useState({});
  const [nextStep, setNextStep] = useState(false);
  const [fileUpload, setFileUpload] = useState(null);
  const [csvData, setCsvData] = useState(undefined);
  const [studyType, setStudyType] = useState();
  const [client, setClient] = useState();

  const columns = [
    { header: "First Name", accessorKey: "first_name", required: true },
    { header: "Last Name", accessorKey: "last_name", required: true },
    { header: "Email", accessorKey: "email", required: true },
    { header: "Role",
      accessorKey: "role",
      filtering: false,
      render: rowData => (
        <FormControl required fullWidth variant="outlined" key={rowData.id}>
          <Typography variant="p">{rowData.role}</Typography>
        </FormControl>
      ) },
    { header: "Territories",
      accessorKey: "territories",
      filtering: false,
      render: rowData => (
        <TerritoryDropDown
          multiple
          study={studyType}
          clientId={client}
          userRole={rowData.role}
          value={rowData.territories || []}
          onChange={(newValue, _, defaultTerrIndex) => {
            setCsvData(replaceAt(csvData, rowData.tableData.id, { ...rowData, territories: newValue, defaultTerritory: newValue[defaultTerrIndex] }));
          }}
          disabled={false}
        />
      ),
    },
    { header: "",
      accessorKey: "errors",
      filtering: false,
      render: rowData => (
        <div>
          {errorPerRow[rowData.email] && <div><Alert severity="error">{`${errorPerRow[rowData.email]}`}</Alert></div>}
        </div>
      ),
    },
  ];

  usePageTitle("Import Wholesalers");

  function removeFile() {
    setFileUpload(null);
    if (inputRef.current) inputRef.current.value = null;
  }

  return (
    <div>
      <Typography variant="h5">Import Wholesalers</Typography>
      {(!nextStep)
        ? (
          <AddFileStep
            columns={columns}
            csvData={csvData}
            setCsvData={setCsvData}
            client={client}
            setNextStep={setNextStep}
            fileUpload={fileUpload}
            setFileUpload={setFileUpload}
            studyType={studyType}
            setStudyType={setStudyType}
            setClient={setClient}
          />
        )
        : (
          <AddTerritoriesStep
            columns={columns}
            csvData={csvData}
            setCsvData={setCsvData}
            removeFile={removeFile}
            setErrorPerRow={setErrorPerRow}
            client={client}
            setNextStep={setNextStep}
            setStudyType={setStudyType}
          />
        )}
    </div>
  );
}
