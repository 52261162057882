/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from "react";
import {
  useEditorContext,
  useExecutionContext,
  useSchemaContext,
} from "@graphiql/react";

import "./style.css";
import Explorer from "./components/explorer";
import ExplorerIcon from "./components/icon";

function ExplorerPlugin(props) {
  const { setOperationName } = useEditorContext({ nonNull: true });
  const { schema } = useSchemaContext({ nonNull: true });
  const { run } = useExecutionContext({ nonNull: true });

  const { title } = props;

  return (
    <div className="graphiql-explorer-wrap">
      <div className="graphiql-explorer-title">{title}</div>
      <Explorer
        schema={schema}
        onRunOperation={operationName => {
          if (operationName) {
            setOperationName(operationName);
          }
          run();
        }}
        {...props}
      />
    </div>
  );
}

export function useExplorerPlugin(props) {
  const propsRef = useRef(props);
  propsRef.current = props;

  const pluginRef = useRef(null);
  pluginRef.current = {
    title: "GraphiQL Explorer",
    icon: () => (<ExplorerIcon />),
    content: () => <ExplorerPlugin {...propsRef.current} />,
  };

  return pluginRef.current;
}
