import React from "react";
import { useSelector } from "react-redux";
import useFormData from "../../hooks/useFormData";
import BaseForm from "./common/BaseForm";

const uiSchema = {
  lock: {
    "ui:help": "Leave unselected for Live",
  },
};

export default function HOATrainingForm({ children, setTaskConfig, submitHandler, formDataProp }) {
  const formOptions = useSelector((state) => state.formOptions);
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  const hoaTrainingSchema = {
    title: "HOA Training Form",
    description: "HOA Training",
    type: "object",
    properties: {
      lock: {
        type: "string",
        title: "Lock (Data Set)",
        enum: formOptions.quarters,
      },
    },
  };

  return (
    <BaseForm
      schema={hoaTrainingSchema}
      uiSchema={uiSchema}
      formData={formData}
      submitHandler={submitHandler}
      setFormData={setFormData}
    >
      {children}
    </BaseForm>
  );
}
