import React from "react";
import { Chip } from "@mui/material";
import { taskTypes } from "../../../create_wizard/TaskMapping";

export default function StudyColumn({ rowData }) {
  const taskMatch = taskTypes.find(task => task.id === rowData?.taskType && task.label === rowData?.title);
  const study = taskMatch && taskMatch.study ? taskMatch.study : "NONE";

  return <Chip variant="outlined" label={study.toUpperCase()} style={{ width: 100 }} />;
}
