import React, { useEffect, useState } from "react";
import { Grid, Link, MenuItem } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

import { API } from "../../../utils/api";

export default function TaskDownloads({ taskId, attachments, display }) {
  const [attachmentsFullData, setAttachmentsFullData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    attachments.forEach((attachment) => {
      setLoading(true);
      const url = `/tasks/${taskId}/attachments/${attachment.attachmentId}/download`;
      API.request({ url, method: "GET" }).then((response) => {
        const FullAttachmentData = { attachmentId: attachment.attachmentId, attachmentLink: response.data.url, attachmentFilename: attachment.fileMeta.filename };
        setAttachmentsFullData(prevAttchData => [...prevAttchData, FullAttachmentData]);
        setLoading(false);
      }, (error) => console.error(error));
    });
  }, [taskId, attachments]);

  // Loading tied to downloads specifically
  if (loading) {
    return <Skeleton style={{ margin: "0.5em" }} />;
  }

  // Only render when attachments have links
  if (attachmentsFullData.length) {
    attachmentsFullData.sort((a, b) => {
      const aFileName = a.attachmentFilename;
      const bFileName = b.attachmentFilename;
      if (aFileName < bFileName) {
        return -1;
      }
      if (aFileName > bFileName) {
        return 1;
      }
      return 0;
    });
    if (display === "grid") {
      const midPoint = Math.ceil(attachmentsFullData.length / 2);
      const firstHalf = attachmentsFullData.slice(0, midPoint);
      const secondHalf = attachmentsFullData.slice(midPoint);
      return (
        <Grid container>
          <DownloadsColumn attachments={firstHalf} />
          <DownloadsColumn attachments={secondHalf} />
        </Grid>
      );
    }
    if (display === "list") {
      return (
        <>
          {attachmentsFullData.map((attachment) => (
            <MenuItem key={attachment.attachmentId}>
              <Link href={attachment.attachmentLink} color="inherit">
                {attachment.attachmentFilename}
              </Link>
            </MenuItem>
          ))}
        </>
      );
    }
  }

  return <MenuItem key="attachments" disabled>No Attachments</MenuItem>;
}

function DownloadsColumn({ attachments }) {
  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      {attachments.map((attachment) => (
        <MenuItem
          key={attachment.attachmentId}
          style={{ whiteSpace: "normal" }}
        >
          <Link
            href={attachment.attachmentLink}
            color="inherit"
            style={{ wordBreak: "break-all" }}
          >
            {attachment.attachmentFilename}
          </Link>
        </MenuItem>
      ))}
    </Grid>
  );
}
