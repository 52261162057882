import React from "react";
import { FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, TextField } from "@mui/material";

import CSVUpload from "./CSVUpload";

export default function DDSyncIDsInput({ taskConfig, setTaskConfig, csv, setCsv, firmOfficeIds, setFirmOfficeIds, hasIdsFromFile, setHasIdsFromFile }) {
  const handleIdsInputChange = e => {
    if (e.target.value === "file") {
      setHasIdsFromFile(true);
      setFirmOfficeIds("");
      /* eslint-disable no-param-reassign */
      delete taskConfig.firm_office_ids;
      /* eslint-disable no-param-reassign */
      return;
    }
    setHasIdsFromFile(false);
    setCsv({});
  };

  return (
    <>
      <FormControl style={{ float: "left" }}>
        <FormLabel id="demo-row-radio-buttons-group-label">Submit IDs by File or List</FormLabel>
        <RadioGroup
          row
          value={hasIdsFromFile ? "file" : "list"}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleIdsInputChange}
        >
          <FormControlLabel value="file" control={<Radio />} label="File" />
          <FormControlLabel value="list" control={<Radio />} label="List" />
        </RadioGroup>
      </FormControl>
      <br />
      {
        hasIdsFromFile
          ? (
            <CSVUpload
              onChange={(update) => {
                setCsv({ attachment: update.csv });
              }}
              value={{ csv, ...taskConfig }}
            />
          )
          : (
            <TextField
              multiline
              label="Firm Office IDs (comma separated)"
              value={firmOfficeIds}
              onChange={(e) => {
                setFirmOfficeIds(e.target.value);
                setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, firm_office_ids: e.target.value.split(",").map(o => parseInt(o, 10)) }));
              }}
              required
              error={firmOfficeIds.length > 0 && !firmOfficeIds.split(",").every(o => !Number.isNaN(parseInt(o, 10)))}
              helperText={firmOfficeIds.length > 0 && !firmOfficeIds.split(",").every(o => !Number.isNaN(parseInt(o, 10))) ? "Please enter comma separated numbers" : ""}
            />
          )
      }
    </>
  );
}
