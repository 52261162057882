import React, { useEffect, useMemo, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import usePageTitle from "../../hooks/usePageTitle";
import { API } from "../../utils/api";
import ErrorFallback from "./components/ErrorFallback";
import JobStatus from "./components/JobStatus";
import Renderer from "./components/Renderer";
import SideBarView from "./components/SideBar";
import TableOfContents from "./components/TableOfContents";
import { reportMappings } from "./constants";

export default function MISightQaDashboard({ selectedTab }) {
  const productionCycle = useSelector((state) => state.formOptions.productionCycle);
  const [loading, setLoading] = useState(false);
  const [showFallback, setShowFallback] = useState(false);
  const [currentView, setCurrentView] = useState(reportMappings[selectedTab][0].id);
  const [currentSheet, setCurrentSheet] = useState(reportMappings[selectedTab][0].items[0].id);
  const [currentSheetItems, setCurrentSheetItems] = useState([]);
  const [currentSheetApi, setCurrentSheetApi] = useState();
  const [currentSheetData, setCurrentSheetData] = useState();
  const currentSheetRef = useRef(reportMappings[selectedTab][0].items[0].id);
  usePageTitle("MISight QA Dashboard");

  useEffect(() => {
    setCurrentSheetItems(reportMappings[selectedTab].filter(item => item.id === currentView)[0]?.items.filter(item => item.id === currentSheet)[0].items);
    setCurrentSheetApi(reportMappings[selectedTab].filter(item => item.id === currentView)[0]?.items.filter(item => item.id === currentSheet)[0].dataURL);
  }, [currentView, currentSheet, selectedTab]);

  useEffect(() => {
    if (currentSheetApi) {
      const url = currentSheetApi;
      setShowFallback(false);
      setLoading(true);
      API.request({ url, method: "GET", params: { productionCycle: productionCycle[1], ...currentSheetItems[0]?.apiParams } }).then(
        (response) => {
          if (currentSheetApi.includes(currentSheetRef.current)) {
            setCurrentSheetData(response.data);
            setLoading(false);
            setShowFallback(false);
          }
        },
      ).catch((error) => {
        setLoading(false);
        setShowFallback(true);
        console.error(error);
      });
    }
  }, [currentSheetApi, currentSheetItems, productionCycle]);

  const memoizedDashboard = useMemo(() => (
    <Grid container>
      <Grid item style={{ width: "12%" }}>
        <SideBarView
          selectedTab={selectedTab}
          currentView={currentView}
          setCurrentView={setCurrentView}
          currentSheet={currentSheet}
          currentSheetRef={currentSheetRef}
          setCurrentSheet={setCurrentSheet}
        />
      </Grid>
      <Grid item style={{ width: "76%" }}>
        { showFallback ? <ErrorFallback /> : <Renderer currentView={currentView} currentSheetItems={currentSheetItems} currentSheetData={currentSheetData} loading={loading} />}
      </Grid>
      <Grid item style={{ width: "12%" }}>
        <TableOfContents currentSheetItems={currentSheetItems} currentSheetData={currentSheetData} />
      </Grid>
    </Grid>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [currentView, currentSheet, loading]);

  return (
    <>
      <Grid container style={{ marginTop: 10, marginBottom: 25 }}>
        <Grid item xs={4} />
        <Grid style={{ display: "flex", justifyContent: "flex-end" }} item xs={8}>
          <JobStatus currentView={currentView} selectedTab="reporting_ann_misight_qa_dashboard" />
        </Grid>
      </Grid>
      {memoizedDashboard}
    </>
  );
}
