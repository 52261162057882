import React, { useEffect, useState } from "react";
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import MaterialTable from "../../../components/Table";
import { API } from "../../../utils/api";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const columnStyles = {
  muiTableHeadCellProps: {
    align: "center",
  },
  muiTableBodyCellProps: {
    align: "center",
  },
};

function SetFileStatusDialog({ closeDialog, refreshTable, openDialog, taskList, clientRowData, states }) {
  const [data, setData] = useState([]);
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

  const submitData = () => {
    setLoading(true);
    API.request({
      url: "/lms/api/v1/file_load_monitor/set_clients_data",
      method: "POST",
      data: { ...states, client, not_expected_types: data },
    }).then(response => {
      if (response.status === 204) {
        setLoading(false);
        setSuccessSnackbarOpen(true);
        closeDialog();
        refreshTable(!states.refresh_table);
      }
    }).catch((error) => {
      console.error(error);
      setLoading(false);
      setErrorSnackbarOpen(true);
    });
  };

  const handleCheckboxStatusChange = (rowData) => {
    const dataUpdate = [...data].map((row) => ((row.task_type === rowData.task_type) ? { ...row, not_expected: !row.not_expected } : row));
    setData([...dataUpdate]);
  };

  const columns = [
    {
      header: "Task Name",
      accessorKey: "label",
      render: (rowData) => (
        <Chip
          variant="outlined"
          style={{
            width: 200,
            textAlign: "center",
            fontSize: "15px",
            backgroundColor: "white",
            color: "black",
          }}
          label={rowData.label}
        />
      ),
      ...columnStyles,
    },
    {
      header: "Set NOT EXPECTED",
      accessorKey: "not_expected",
      render: rowData => <Checkbox onChange={() => { handleCheckboxStatusChange(rowData); }} checked={rowData?.not_expected || false} name={rowData?.task_type} />,
      ...columnStyles,
    },
  ];

  useEffect(() => {
    const tempData = [];
    Object.keys(clientRowData).filter(element => element !== "name" && element !== "tableData").forEach(element => {
      const taskMatch = taskList.find(elem => (elem.field === element));
      tempData.push({ label: taskMatch?.headerName || element, task_type: element, not_expected: (clientRowData[element].status === "NOT_EXPECTED") });
    });
    setData(tempData);
    setClient(clientRowData.name.toUpperCase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  return (
    <div>
      <Dialog onClose={closeDialog} open={openDialog}>
        <DialogTitle>{`Update ${clientRowData.name.toUpperCase()} File Status`}</DialogTitle>
        <DialogContent dividers>
          <MaterialTable
            style={{ marginTop: "1.5em" }}
            columns={columns}
            data={data}
            enablePagination={false}
            enableBottomToolbar={false}
            options={{
              headerStyle: {
                backgroundColor: "#30455c",
                color: "#97a9bc",
                borderBottom: "1px solid #ddd",
                borderRight: "1px solid #ddd",
              },
              cellStyle: { borderBottom: "1px solid #ddd" },
              padding: "dense",
              toolbar: false,
              emptyRowsWhenPaging: false,
            }}
            isLoading={loading}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} style={{ color: "red" }}>
            Cancel
          </Button>
          <Button onClick={submitData} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => { setSuccessSnackbarOpen(false); }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div>
          <Alert severity="success" sx={{ width: "100%" }}>
            {`Succesfully saved status for ${clientRowData.name.toUpperCase()}`}
          </Alert>
        </div>
      </Snackbar>
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => { setErrorSnackbarOpen(false); }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div>
          <Alert severity="error" sx={{ width: "100%" }}>
            {`Error while saving status for ${clientRowData.name.toUpperCase()}. Please retry!`}
          </Alert>
        </div>
      </Snackbar>
    </div>
  );
}

function ClientButton({ rowData, refreshTable, taskList, states }) {
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <div>
      <Button
        variant="outlined"
        label={rowData.name.toUpperCase()}
        style={{
          width: "200px",
          fontSize: "15px",
          textAlign: "center",
          color: "black",
          boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.75)",
          marginBottom: "10px",
          marginTop: "10px",
        }}
        onClick={() => { setOpenDialog(rowData.name.toUpperCase() !== "TOTAL"); }}
      >
        {rowData.name.toUpperCase().replace("_", " ")}
      </Button>
      <SetFileStatusDialog
        openDialog={openDialog}
        closeDialog={() => { setOpenDialog(false); }}
        taskList={taskList}
        clientRowData={rowData}
        states={states}
        refreshTable={refreshTable}
      />
    </div>
  );
}

export default ClientButton;
