import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { ExpandLess } from "@mui/icons-material";
import { Card, List, ListItem, ListItemText, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Fab from "../../../components/Fab";
import { textPerPalette } from "../../../theme/common";

const useStyles = makeStyles((theme) => ({
  gridCards: {
    margin: "5px 8px",
  },
  sideBarTitle: {
    width: "100%",
    backgroundColor: theme.palette.info.main,
    color: textPerPalette(theme),
    padding: "4px 8px",
    textAlign: "center",
    fontSize: "1rem",
  },
}));

export default function TableOfContents({ currentSheetItems, currentSheetData }) {
  const [activeHeading, setActiveHeading] = useState("");
  const [scroll2TopVisible, setScroll2TopVisible] = useState(false);
  const theme = useTheme();
  const classes = useStyles(theme);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const ClickToScrollerHandler = (e, item) => {
    e.preventDefault();
    const elem = document.querySelector(`#${item.id}`);
    if (elem) {
      const elementPosition = elem.getBoundingClientRect().top + window.scrollY;
      window.scroll({
        top: elementPosition - 80,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    setActiveHeading(currentSheetItems?.[0]?.id);
    const observer = new IntersectionObserver((entries) => {
      const visibleSection = entries.find((entry) => entry.isIntersecting)?.target;
      if (visibleSection) {
        setActiveHeading(visibleSection.id);
      }
    }, { rootMargin: "0px 0px -75% 0px" });
    const sections = document.querySelectorAll("[data-section]");
    sections.forEach((section) => {
      observer.observe(section);
    });
    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
    // passing currentSheetData as dependency to change observer if sheet, view or API call is complete
  }, [currentSheetItems, currentSheetData]);

  useEffect(() => {
    setScroll2TopVisible((activeHeading !== currentSheetItems?.[0]?.id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeHeading]);

  return (
    <>
      <Card
        className={classes.gridCards}
        style={{ width: "10%", position: "fixed" }}
      >
        <Typography variant="h6" className={classes.sideBarTitle}>
          Contents
        </Typography>
        <List>
          {currentSheetItems?.map((item) => (
            <ListItem disableGutters key={item.id} onClick={(e) => ClickToScrollerHandler(e, item)} style={{ cursor: "pointer", padding: "10px 8px" }}>
              <ListItemText>
                {item.id === activeHeading
                  ? <strong>{item.label}</strong> : item.label}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Card>
      {scroll2TopVisible && (
        <Fab onClick={scrollToTop}>
          <ExpandLess />
        </Fab>
      )}
    </>
  );
}
