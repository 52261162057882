import React, { useEffect } from "react";
import { Chip, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import useClientConfiguration from "../../hooks/useClientConfiguration";
import useFormData from "../../hooks/useFormData";
import * as SharedDefinitions from "../../utils/SharedDefinitions";
import BaseForm from "./common/BaseForm";

const uiSchema = {
  lock: {
    "ui:help": "Leave unselected for Live",
  },
};

export default function SmaCombinedFeedForm({ children, studyType, client, setTaskConfig, submitHandler, formDataProp, displayHeader, setIsRequired }) {
  const formOptions = useSelector((state) => state.formOptions);
  const [formData, setFormData] = useFormData(setTaskConfig, formDataProp);

  useClientConfiguration(setFormData, studyType, client);

  const smaCombinedTerritoryMode = {
    oneOf: [
      {
        properties: {
          territory_mode: {
            enum: ["combined"],
          },
          combined_territory_config: {
            type: "object",
            title: "Combined Territory Configurations",
            required: ["territory_name"],
            properties: SharedDefinitions.getTerritoryConfigOptionsSMA(formOptions, client),
          },
        },
      },
      {
        properties: {
          territory_mode: {
            enum: ["split"],
          },
          sma_territory_config: {
            type: "object",
            title: "SMA Territory Configuration",
            required: ["territory_name"],
            properties: SharedDefinitions.getTerritoryConfigOptionsSMA(formOptions, client),
          },
          mf_territory_config: {
            type: "object",
            title: "MF Territory Configuration",
            required: ["territory_name"],
            properties: SharedDefinitions.getTerritoryConfigOptionsMF(formOptions, client),
          },
        },
      },
    ],
  };

  const smaCombinedFeedSchema = {
    title: "SMA Combined Feed Form",
    description: "SMA Combined Feed",
    type: "object",
    required: [
      "start_quarter",
      "end_quarter",
      "territory_settings_1",
    ],
    properties: {
      start_quarter: {
        type: "string",
        title: "Start Quarter",
        enum: formOptions.quarters,
      },
      end_quarter: {
        type: "string",
        title: "End Quarter",
        enum: formOptions.quarters,
      },
      lock: {
        type: "string",
        title: "Lock (Data Set)",
        enum: formOptions.locksSMA,
      },
      production_cycle: {
        type: "string",
        title: "Production Cycle",
        enum: formOptions.quarters,
      },
      verified_address_exclusions: SharedDefinitions.verifiedAddressExclusions,
      broker_zip_exclusions_settings: {
        type: "object",
        title: "Broker/Zip Exclusions Settings",
        properties: {
          sma_exclusion_settings: {
            type: "object",
            title: "SMA Broker/Zip Exclusions",
            properties: SharedDefinitions.brokerZipExclusions,
          },
          mf_exclusion_settings: {
            type: "object",
            title: "MF Broker/Zip Exclusions",
            properties: SharedDefinitions.brokerZipExclusions,
          },
        },
      },
      custom_file_name: SharedDefinitions.customFileName,
      territory_settings_1: {
        type: "object",
        title: "Territory Settings 1",
        required: [
          "territory_mode",
        ],
        properties: {
          territory_mode: {
            type: "string",
            title: "Territory Mode",
            enum: ["combined", "split"],
            default: "combined",
          },
        },
        dependencies: {
          territory_mode: smaCombinedTerritoryMode,
        },
      },
      territory_settings_2: {
        type: "object",
        title: "Territory Settings 2 (Optional)",
        properties: {
          territory_mode: {
            type: "string",
            title: "Territory Mode",
            enum: ["combined", "split"],
          },
        },
        dependencies: {
          territory_mode: smaCombinedTerritoryMode,
        },
      },
    },
  };

  useEffect(() => {
    if (smaCombinedFeedSchema.required) {
      setIsRequired((prevState) => ([...prevState, ...smaCombinedFeedSchema.required]));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsRequired]);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["start_quarter", "end_quarter", "lock", "custom_file_name"])} />
      </div>
      <BaseForm
        schema={smaCombinedFeedSchema}
        uiSchema={uiSchema}
        formData={formData}
        submitHandler={submitHandler}
        setFormData={setFormData}
      >
        {children}
      </BaseForm>
    </div>
  );
}
